import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { IPopupAnnouncement } from '../types';

export const QUERY_KEY = 'QueryKeys.GlobalConfig.BasicManagement.Popup';

export async function getPopupAnnouncement(
  popupId?: string | number,
): Promise<{ data: IPopupAnnouncement; message?: string }> {
  const response = await fetch(`/api/portal/setting/basic/popup/${popupId}`);

  const data = (await response.json()) as {
    data: IPopupAnnouncement;
    message?: string;
  };

  return data;
}
