import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useFilterContext } from './context';

interface Props {
  className?: string;
  option: {
    value: string;
    label: string;
  };
}

export function DateSelfInputOption({
  className,
  option,
  children,
}: PropsWithChildren<Props>) {
  const {
    selectedOptions,
    selectedOptionLabels,
    setSelectedOptions,
    setSelectedOptionLabels,
  } = useFilterContext();

  return (
    <button
      className={clsx(
        'flex flex-col px-[8px] w-full hover:bg-[#F2F2F2] rounded-[8px]',
        className,
      )}
      onClick={() => {
        const newLabels = new Map(selectedOptionLabels);
        newLabels.set(option.value, option.label);
        setSelectedOptionLabels(newLabels);
        if (selectedOptions.has(option.value)) {
          setSelectedOptions(new Set());
        } else {
          setSelectedOptions(new Set([option.value]));
        }
      }}
    >
      <div className="flex items-center h-[40px] w-full">
        <p>{option.label}</p>
        <div className="flex-grow" />
        {selectedOptions.has(option.value) && (
          <CheckIcon className="size-[20px]" color="#1C1C1C" />
        )}
      </div>
      {children}
    </button>
  );
}
