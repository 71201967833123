import { AppType } from './../types';
import { GROUPWARE_API_URL } from './constants';

export const MUTATION_KEY = 'appTransferMutation';

export async function transferApp(
  deptId: number,
  targetDeptId: number,
  selectedAppType: AppType,
  seletedAppIds: string[],
) {
  let res: Response;
  if (selectedAppType === 'approval') {
    res = await trasferApprovalFolder(deptId, targetDeptId, seletedAppIds);
  } else if (selectedAppType === 'board') {
    res = await transferBoard(targetDeptId, seletedAppIds);
  } else if (selectedAppType === 'report') {
    res = await transferReport(deptId, targetDeptId, seletedAppIds);
  } else {
    res = await transferContactGroup(targetDeptId, seletedAppIds);
  }

  if (!res.ok) {
    throw new Error('Failed to transfer');
  }
}

export async function trasferApprovalFolder(
  deptId: number,
  targetDeptId: number,
  ids: string[],
) {
  return await fetch(
    `${GROUPWARE_API_URL}/approval/deptfolder/transfer/${targetDeptId}/${deptId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    },
  );
}

export async function transferBoard(targetDeptId: number, ids: string[]) {
  return await fetch(
    `${GROUPWARE_API_URL}/board/transfer/dept/${targetDeptId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    },
  );
}

export async function transferReport(
  deptId: number,
  targetDeptId: number,
  ids: string[],
) {
  return await fetch(
    `${GROUPWARE_API_URL}/report/folder/department/${deptId}/transfer/${targetDeptId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    },
  );
}

export async function transferContactGroup(
  targetDeptId: number,
  ids: string[],
) {
  return await fetch(
    `${GROUPWARE_API_URL}/contact/transfer/dept/${targetDeptId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    },
  );
}
