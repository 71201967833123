import { useSessionContext } from '@dop-ui/react/features/authenticate';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { AppChildInfo, ShareInfo, ShareNodeInfo } from '../../../types';
import { getPostRequestDto } from '../../../utils';
import { useActionsContext, useValuesContext } from '../../provider';
import { ShareAreaConfig } from '../../share-area-config';
import { ShareConfigDialog } from '../../share-config-dialog';
import * as getCalenderShareList from './apis/get-calendar-share-list';
import {
  QUERY_KEY,
  getCompanyCalenderList,
} from './apis/get-company-calandar-list';
import { createCalendarShareList } from './apis/post-calendar-share-list';
import { CompanyCalendarInfo } from './types';
import { PlusIcon } from '@dop-ui/icons/react/dop/24';

interface Props {
  calendarShareList: ShareInfo[];
  shareConfigDialogOpenState: boolean;
  detailAppId: number | null;
  onChangeDetailAppId: (id: number | null) => void;
  onChangeShareConfigDialogOpenState: (state: boolean) => void;
}

export function ShareConfigDialogButton({
  calendarShareList,
  shareConfigDialogOpenState,
  detailAppId,
  onChangeDetailAppId,
  onChangeShareConfigDialogOpenState,
}: Props) {
  const { t } = useTranslation('component');
  const { companyList, selectedCompany } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [shareNodeList, setSharedNodeList] = useState<ShareNodeInfo[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<AppChildInfo>({
    id: 0,
    name: '',
  });

  const { me } = useSessionContext();

  const { data: calendarList, error } = useQuery({
    queryKey: [QUERY_KEY, selectedCompany?.companyUuid],
    queryFn: async () => {
      if (selectedCompany?.companyUuid === undefined) return [];
      return await getCompanyCalenderList(selectedCompany.companyUuid);
    },
    enabled: !!selectedCompany?.companyUuid,
  });

  useEffect(() => {
    const sharedTargetList = calendarShareList.filter(
      (item) =>
        item.companyUuid === selectedCompany?.companyUuid &&
        item.id === selectedCalendar?.id,
    )[0]?.shares;

    if (sharedTargetList?.length === 0) {
      return;
    }

    const sharedTargetNodeList = sharedTargetList
      ?.map((item) => item.nodes.map((item) => item))
      .flat();

    setSharedNodeList(sharedTargetNodeList ?? []);
  }, [selectedCalendar]);

  const createCalendarShareMutation = useMutation({
    mutationFn: async () => {
      if (me?.companyGroupId === undefined) return [];
      await createCalendarShareList(
        me?.companyGroupId,
        getPostRequestDto({
          companyGroupId: me?.companyGroupId,
          selectedApp: selectedCalendar,
          selectedCompany: selectedCompany!,
          shareNodeList: shareNodeList,
        }),
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getCalenderShareList.QUERY_KEY],
      });
      onChangeShareConfigDialogOpenState(false);
      toaster.info(t('globalconfig.common.toastMessage.success'));
    },
    onError: (e) => {
      console.log(e);
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  const handleSaveHandler = () => {
    if (shareNodeList.length === 0) {
      toaster.warning(
        t('globalconfig.multiCompanyManagement.appShare.toast.add.noData'),
      );
      return;
    }
    createCalendarShareMutation.mutate();
  };

  const handleClickAdd = () => {
    if (companyList && companyList[0]) {
      setSelectedCompany({
        companyId: companyList[0].companyId,
        companyName: companyList[0].companyName,
        companyUuid: companyList[0].companyUuid,
      });
    }
    onChangeDetailAppId(null);
    onChangeShareConfigDialogOpenState(true);
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > AppShare > Calendar > Dialog: ',
      error,
    );
  }

  if (!me) {
    return;
  }

  return (
    <ShareConfigDialog
      trigger={
        <Button
          className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded hover:bg-[#f2f2f2]"
          onClick={handleClickAdd}
        >
          <PlusIcon size={24} />
          {t('globalconfig.multiCompanyManagement.appShare.calendar.add')}
        </Button>
      }
      openState={shareConfigDialogOpenState}
      onOpenStateChange={(openState) =>
        onChangeShareConfigDialogOpenState(openState)
      }
      onSave={handleSaveHandler}
    >
      <ShareAreaConfig<CompanyCalendarInfo>
        shares={shareNodeList}
        appChildList={calendarList}
        defaultAppId={detailAppId}
        onChange={(calendar) => {
          setSelectedCalendar({ id: calendar.id, name: calendar.name });
        }}
        onChangeShares={setSharedNodeList}
      />
    </ShareConfigDialog>
  );
}

export default ShareConfigDialogButton;
