import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import DatePicker from '@daouoffice/ui/lib/foundation/DatePicker';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useGeneratePeriodPreview } from '../hooks/use-generate-period-preview';
import { formatDate, isAfter, isBefore } from '@dop-ui/react/shared/lib/date';
import { generateNumberOptions, getSortedDayOfWeeks } from '../utils';
import CheckBox from '@dop-ui/react/shared/ui/check-box';
import { CalendarIcon } from '@dop-ui/icons/react/dop/24';
import { isContentNeedsSaveAtom } from '../../../../store/isContentNeedsSaveAtom';
import { useAtom } from 'jotai';

import { LAST_DAY, LAST_WEEK, POPUP_DICTIONARY, WEEK_DATA } from '../constants';
import styles from './styles.module.css';
import type { IPopupAnnouncementForm, NoticePeriodType, Week } from '../types';

interface Props {
  popupForm: IPopupAnnouncementForm;
  setPopupForm: Dispatch<SetStateAction<IPopupAnnouncementForm>>;
}

function FormAnnouncementPeriod({ popupForm, setPopupForm }: Props) {
  const { t } = useTranslation('component');
  const { noticePeriodType } = popupForm;
  const periodPreview = useGeneratePeriodPreview(popupForm);
  const [, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);

  const handleOnChangeWeek = (checked: boolean, week: Week) => {
    setIsNeedSave(true);
    if (!popupForm.dayOfWeeks) return;
    const found = popupForm.dayOfWeeks.find((prevWeek) => prevWeek === week);
    if (!found && checked) {
      setPopupForm((prev) => ({
        ...prev,
        dayOfWeeks: getSortedDayOfWeeks(
          prev.dayOfWeeks ? [...prev.dayOfWeeks, week] : [week],
        ),
      }));
    } else if (found && !checked) {
      setPopupForm((prev) => ({
        ...prev,
        dayOfWeeks: getSortedDayOfWeeks(
          (prev.dayOfWeeks ?? []).filter((prevWeek) => prevWeek !== week),
        ),
      }));
    }
  };
  const handleOnChangeDate = (date: Date | null, name: string) => {
    if (!date) return;
    setIsNeedSave(true);

    const updating = {
      [name]: date,
    };

    const isStartDate = name === 'startDate';
    const isEndDate = name === 'endDate';

    if (
      (isStartDate &&
        isAfter({
          date: new Date(date),
          compareDate: new Date(popupForm.endDate),
          unit: 'day',
        })) ||
      (isEndDate &&
        isBefore({
          date: new Date(date),
          compareDate: new Date(popupForm.startDate),
          unit: 'day',
        }))
    ) {
      updating[isStartDate ? 'endDate' : 'startDate'] = date;
    }

    setPopupForm((prev) => ({
      ...prev,
      ...updating,
    }));
  };

  const handleOnChangePeriodType = (value: NoticePeriodType) => {
    setIsNeedSave(true);
    setPopupForm((prev) => ({
      ...prev,
      noticePeriodType: value,
    }));
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setIsNeedSave(true);
    setPopupForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const selector = (
    <div className={styles.RadioGroup}>
      <div className={styles.Radio}>
        <input
          id="period-specific"
          type="radio"
          checked={noticePeriodType === 'SPECIFIC_DATE'}
          radioGroup="period"
          onChange={() => handleOnChangePeriodType('SPECIFIC_DATE')}
        />
        <label htmlFor="period-specific">
          {t(`${POPUP_DICTIONARY}.form.period.specific`)}
        </label>
      </div>

      <div className={styles.Radio}>
        <input
          id="period-interval"
          type="radio"
          checked={noticePeriodType !== 'SPECIFIC_DATE'}
          radioGroup="period"
          onChange={() => handleOnChangePeriodType('DAILY_REPEAT')}
        />
        <label htmlFor="period-interval">
          {t(`${POPUP_DICTIONARY}.form.period.repeat`)}
        </label>
      </div>
    </div>
  );
  const renderDatePickers = () => (
    <div className={styles.SpecificDate}>
      <DatePicker
        defaultDate={popupForm.startDate}
        onDateSelect={(date) => handleOnChangeDate(date, 'startDate')}
      >
        <div className={styles.DatePicker}>
          <CalendarIcon size={20} />
          {formatDate({ date: popupForm.startDate, format: 'YYYY-MM-DD' })}
        </div>
      </DatePicker>
      <p>~</p>
      <DatePicker
        defaultDate={popupForm.endDate}
        onDateSelect={(date) => handleOnChangeDate(date, 'endDate')}
      >
        <div className={styles.DatePicker}>
          <CalendarIcon size={20} />
          {formatDate({ date: popupForm.endDate, format: 'YYYY-MM-DD' })}
        </div>
      </DatePicker>
    </div>
  );

  // 주 반복 선택 체크박스
  const renderWeeklyCheckboxes = () => (
    <ul className="flex gap-[10px]">
      {WEEK_DATA.map((week) => {
        const checked = popupForm.dayOfWeeks
          ? popupForm.dayOfWeeks.includes(week)
          : false;
        return (
          <li key={week}>
            <CheckBox
              id={week}
              label={t(`${POPUP_DICTIONARY}.${week}`)}
              defaultChecked={checked}
              onChange={(checked) => handleOnChangeWeek(!!checked, week)}
            />
          </li>
        );
      })}
    </ul>
  );

  // 월 반복 옵션 (일/주 기준 선택)
  const renderMonthlyOptions = () => (
    <>
      <div className={styles.Radio}>
        <input
          id="month-day"
          type="radio"
          radioGroup="month"
          checked={noticePeriodType === 'MONTHLY_DAY_REPEAT'}
          value="MONTHLY_DAY_REPEAT"
          onChange={() => handleOnChangePeriodType('MONTHLY_DAY_REPEAT')}
        />
        <select
          className={styles.Select}
          name="targetDay"
          value={popupForm.targetDay}
          onChange={handleOnChange}
        >
          {generateNumberOptions(1, 31)}
          <option value={LAST_DAY}>{t(`${POPUP_DICTIONARY}.last`)}</option>
        </select>
        <p>{t(`${POPUP_DICTIONARY}.day`)}</p>
      </div>
      <div className={styles.Radio}>
        <input
          id="month-week"
          type="radio"
          radioGroup="month"
          checked={noticePeriodType === 'MONTHLY_WEEKDAY_REPEAT'}
          onChange={() => handleOnChangePeriodType('MONTHLY_WEEKDAY_REPEAT')}
        />
        <select
          className={styles.Select}
          name="targetWeek"
          value={popupForm.targetWeek}
          onChange={handleOnChange}
        >
          {generateNumberOptions(1, 5)}
          <option value={LAST_WEEK}>{t(`${POPUP_DICTIONARY}.last`)}</option>
        </select>
        <p>{t(`${POPUP_DICTIONARY}.nth`)}</p>

        <select
          className={styles.Select}
          name="targetDayOfWeek"
          value={popupForm.targetDayOfWeek}
          onChange={handleOnChange}
        >
          {WEEK_DATA.map((week) => (
            <option key={week} value={week}>
              {t(`${POPUP_DICTIONARY}.${week}`)}
            </option>
          ))}
        </select>
        <p>{t(`${POPUP_DICTIONARY}.dayOfWeek`)}</p>
      </div>
    </>
  );

  return (
    <>
      {selector}

      {noticePeriodType === 'SPECIFIC_DATE' ? (
        renderDatePickers()
      ) : (
        <>
          <div className={styles.SpecificDate}>
            <select
              className={styles.Select}
              name="repeatValue"
              value={popupForm.repeatValue}
              onChange={handleOnChange}
            >
              {generateNumberOptions(1, 30)}
            </select>
            <div className={styles.SelectGroup}>
              <select
                className={styles.Select}
                value={noticePeriodType}
                onChange={(e) =>
                  handleOnChangePeriodType(e.target.value as NoticePeriodType)
                }
              >
                <option value="DAILY_REPEAT">
                  {t(`${POPUP_DICTIONARY}.day`)}
                </option>
                <option value="WEEKLY_REPEAT">
                  {t(`${POPUP_DICTIONARY}.week`)}
                </option>
                <option value="MONTHLY_DAY_REPEAT">
                  {t(`${POPUP_DICTIONARY}.months`)}
                </option>
                <option hidden value="MONTHLY_WEEKDAY_REPEAT">
                  {t(`${POPUP_DICTIONARY}.months`)}
                </option>
              </select>

              {noticePeriodType === 'WEEKLY_REPEAT' && renderWeeklyCheckboxes()}

              {(noticePeriodType === 'MONTHLY_DAY_REPEAT' ||
                noticePeriodType === 'MONTHLY_WEEKDAY_REPEAT') &&
                renderMonthlyOptions()}
            </div>
          </div>
          <p className={styles.PeriodPreview}>{periodPreview}</p>
        </>
      )}
    </>
  );
}

export default FormAnnouncementPeriod;
