import { useSetAtom } from 'jotai';
import * as FilterPrimitives from '@dop-ui/react/shared/ui/filter';
import { FilterOption, FilterType } from '../../types';
import {
  activatedFiltersAtom,
  deleteActivatedFiltersAtom,
} from '../../stores/activatedFilterAtom';

/**
 * @description Filter 컴포넌트의 Props
 * @property title 필터 타이틀
 * @property filterKey 필터 키
 * @property filterType 필터 타입 (* date 타입일 경우 options는 사용하지 않고 자체적으로 처리함)
 * @property options 필터 옵션
 */
export interface Props {
  className?: string;
  title: string;
  filterKey: string;
  filterType: FilterType;
  options: FilterOption[];
}

/**
 * @description Filter 컴포넌트의 틀
 * @prop Props
 */
export function Root({
  className,
  title,
  filterKey,
  filterType,
  options,
}: Props) {
  const addActivatedFilters = useSetAtom(activatedFiltersAtom);
  const deleteActiveFilters = useSetAtom(deleteActivatedFiltersAtom);

  const onOptionChange = (values: Set<string>) => {
    const selected = options.filter((option) => values.has(option.value));
    const nonSelected = options.filter((option) => !values.has(option.value));

    deleteActiveFilters(nonSelected.map((option) => filterKey + option.key));

    addActivatedFilters(
      selected.map((option) => ({
        key: filterKey + option.key,
        value: option.value,
      })),
    );
  };

  return (
    <FilterPrimitives.Root
      key={filterKey}
      name={title}
      multiSelectable={filterType === 'checkbox'}
      onChangeValues={onOptionChange}
    >
      <FilterPrimitives.Trigger className={className} />
      <FilterPrimitives.Content>
        {options.length > 0 &&
          options.map((option) => (
            <FilterPrimitives.Option
              key={option.key}
              option={{ value: option.value, label: option.title }}
            >
              {option.title}
            </FilterPrimitives.Option>
          ))}
      </FilterPrimitives.Content>
    </FilterPrimitives.Root>
  );
}
