import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

import { useStack } from './root';

export interface Props {
  name: string;
  className?: string;
}

function Content({ name, className, children }: PropsWithChildren<Props>) {
  const { stack } = useStack();

  const isVisible = stack[stack.length - 1] === name;
  const isExist = stack.includes(name);
  return (
    isExist && (
      <div
        className={clsx(className, {
          hidden: !isVisible,
        })}
      >
        {children}
      </div>
    )
  );
}

export default Content;
