import { useSetAtom } from 'jotai';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { setMenuByPathAtom } from '../../../store/setMenuByPathAtom';
import { USAGE_CURRENT_PATH } from '../../../menus/dataManagement/paths';
import { byteToProperUnit } from '../../../utils/byteConvertUtils';
import { ProgressBar } from './components/progress-bar';

interface Props {
  currentUsingData: number;
  totalData: number;
}

export function DataUsageInfo({ currentUsingData, totalData }: Props) {
  const { t } = useTranslation('component');
  const setCurrentSelectedMenu = useSetAtom(setMenuByPathAtom);
  const usingPercentage = (currentUsingData / totalData) * 100;
  const remainData = totalData - currentUsingData;

  const currentDataManagementPagePath = USAGE_CURRENT_PATH;

  return (
    <div className="border border-solid border-[#E6E7EA] rounded-[8px] px-[32px] 2xl:basis-1/3 flex-grow">
      <p className="mt-[32px] ml-[8px] text-[#333333] text-[18px] font-[500]">
        {t('globalconfig.basicManagement.serviceInfo.dataUsageInfo.title')}
      </p>
      <p className="mt-[16px] ml-[8px] text-[#333333] text-[36px] font-[500]">
        {usingPercentage.toFixed(2)}
        {t('globalconfig.basicManagement.serviceInfo.dataUsageInfo.inUse')}
      </p>
      <p className="mt-[48px] text-[#AAAAAA] text-[14px] font-[500]">
        {byteToProperUnit(remainData)}{' '}
        {t(
          'globalconfig.basicManagement.serviceInfo.dataUsageInfo.possibleToUse',
        )}{' '}
        | {byteToProperUnit(currentUsingData)}/{byteToProperUnit(totalData)}
      </p>
      <ProgressBar
        className="w-full mt-[8px]"
        total={totalData}
        used={currentUsingData}
      />

      <Button
        autosize="full"
        className="relative flex items-center justify-center mt-[16px] mb-[30px] py-[12px] border border-solid border-[#9A9CA0] rounded-[8px]"
        onClick={() => setCurrentSelectedMenu(currentDataManagementPagePath)}
      >
        <p className="text-[#888888] text-[14px] font-[500]">
          {t(
            'globalconfig.basicManagement.serviceInfo.dataUsageInfo.linkToDataManage',
          )}
        </p>
        <ArrowTopRightOnSquareIcon className="absolute size-[16px] top-[13px] right-[11px] bottom-[11px]" />
      </Button>
    </div>
  );
}
