import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon, SettingsIcon } from '@dop-ui/icons/react/dop/24';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { CloseIcon } from '@dop-ui/icons/react/dop/24/Close';
import { useToastMessage } from '@dop-ui/react/features/toast-message';

export interface Props {
  defaultBlackList: string[];
  onClickApply: (blackList: string[]) => void;
}

export function BatchDialog({ defaultBlackList, onClickApply }: Props) {
  const { t } = useTranslation('component');
  const [blackList, setBlackList] = useState<Set<string>>(new Set());
  const [blacklistText, setBlacklistText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const toaster = useToastMessage();

  const onChangeBlackList = (text: string) => {
    if (text.match(/[^,a-zA-Z0-9]/g)) {
      setErrorMessage(
        t(
          'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.notAllowCharacter',
        ),
      );
    } else {
      setErrorMessage('');
    }
    setBlacklistText(text.replace(/[^,a-zA-Z0-9]/g, ''));
  };

  const onClickAddBlackList = () => {
    if (blacklistText) {
      const newBlackList = new Set(blackList);
      const defaultList = new Set(defaultBlackList);
      const notAdded = new Array<string>();
      const alreadyAdded = new Array<string>();

      blacklistText
        .split(',')
        .filter((extensionName) => extensionName.trim().length > 0)
        .map((self) => self.toLowerCase())
        .forEach((extensionName) => {
          if (defaultList.has(extensionName)) notAdded.push(extensionName);
          else if (newBlackList.has(extensionName))
            alreadyAdded.push(extensionName);
          else newBlackList.add(extensionName);
        });

      setBlacklistText('');
      setBlackList(newBlackList);

      if (notAdded.length > 0) {
        toaster.info(
          notAdded.join(', ') +
            t(
              'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.basicList',
            ),
        );
      }

      if (alreadyAdded.length > 0) {
        toaster.info(
          alreadyAdded.join(', ') +
            t(
              'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.duplicate',
            ),
        );
      }
    }
  };

  const onDeleteBlackList = (extensionName: string) => {
    const newBlackList = new Set(blackList);
    newBlackList.delete(extensionName);
    setBlackList(newBlackList);
  };

  const onClickSave = () => {
    onClickApply(Array.from(blackList));
    setBlackList(new Set());
    setOpen(false);
  };

  const onClickClose = () => {
    setBlacklistText('');
    setBlackList(new Set());
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild className="absolute top-[46.5px] right-[45px]">
        <div className="flex items-center px-[16px] gap-[4px] cursor-pointer h-[40px]">
          <SettingsIcon />
          {t(
            'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.batchSet.button',
          )}
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 z-[999]" />
        <Dialog.Content
          className="fixed bg-white p-[40px] rounded-[16px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[999]"
          onInteractOutside={(e) => e.preventDefault()}
        >
          <Dialog.Close asChild>
            <Button
              className="absolute top-[24px] right-[24px]"
              size="medium"
              onClick={onClickClose}
            >
              <CloseIcon />
            </Button>
          </Dialog.Close>
          <p className="text-[#363636] //text-[--color-text-level1 text-[20px] font-[600]">
            {t(
              'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.batchSet.title',
            )}
          </p>
          <p className="mt-[8px] text-[14px] text-[#999999] //text-[--color-text-level3] whitespace-pre-wrap">
            {t(
              'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.batchSet.description',
            )}
          </p>

          <div className="flex mt-[24px] items-center h-[40px] gap-[24px]">
            <input
              className="px-[8px] h-full min-w-[400px] flex-grow border border-solid border-[#D8D8D8] rounded-[8px] disabled:opacity-20"
              placeholder={t(
                'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.placeholder',
              )}
              value={blacklistText}
              onChange={(e) => onChangeBlackList(e.target.value)}
            />
            <Button
              size="medium"
              shape="rect"
              variant="outline"
              colorset="level1"
              onClick={onClickAddBlackList}
              disabled={blacklistText.length === 0}
            >
              <PlusIcon className="size-[20px] mr-[8px]" />
              {t('globalconfig.common.add')}
            </Button>
          </div>
          {errorMessage && (
            <p className="text-[red] mt-[8px]">{errorMessage}</p>
          )}
          {blackList.size > 0 && (
            <>
              <div className="my-[24px] w-full h-[1px] bg-[#F2F2F2]" />
              <div className="flex flex-wrap gap-[12px]">
                {Array.from(blackList).map((info) => {
                  return (
                    <p
                      key={info}
                      className="min-w-[86px] px-[12px] border border-solid border-[#D8D8D8] h-[32px] rounded-full flex items-center"
                    >
                      <span className="flex-grow">{info}</span>
                      <Button
                        className="ml-[8px] size-[18px] p-[3px]"
                        onClick={() => onDeleteBlackList(info)}
                      >
                        <CloseIcon className="size-[12px]" />
                      </Button>
                    </p>
                  );
                })}
              </div>
            </>
          )}
          <div className="mt-[24px] py-[16px] flex items-center justify-end gap-[8px]">
            <Dialog.Close asChild>
              <Button
                colorset="level1"
                shape="rect"
                variant="outline"
                size="medium"
                onClick={onClickClose}
              >
                {t('dialog.cancel')}
              </Button>
            </Dialog.Close>
            <Button
              colorset="level1"
              shape="rect"
              variant="solid"
              size="medium"
              disabled={blackList.size === 0}
              onClick={onClickSave}
            >
              {t(
                'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.batchSet.apply.button',
              )}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
