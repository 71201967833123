import { Suspense } from 'react';
import { SortConfig } from './sort-config';

export function GnbSortTab() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <SortConfig />
      </Suspense>
    </>
  );
}
