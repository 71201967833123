import { ColorResult, SketchPicker } from 'react-color';

export interface Props {
  color: string;
  onChange: (hexColor: string) => void;
  className?: string;
}

export function ColorPicker({ color, className, onChange }: Props) {
  return (
    <SketchPicker
      className={className}
      color={color}
      onChange={(color: ColorResult) => onChange(color.hex)}
    />
  );
}

export default ColorPicker;
