import { FilterOption } from '@daouoffice/ui/lib/labs/Table/primitives/types';
import { TFunction } from 'i18next';

export const loginEventTypeOptions = (
  t: TFunction<'component', unknown>,
): FilterOption[] => [
  {
    key: 'LOGIN_SUCCESS',
    value: 'LOGIN_SUCCESS',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.loginSuccess',
    ),
  },
  {
    key: 'PASSWORD_INCORRECT',
    value: 'PASSWORD_INCORRECT',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.passwordIncorrect',
    ),
  },
  {
    key: 'CAPTCHA_LOGIN_FAIL',
    value: 'CAPTCHA_LOGIN_FAIL',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.captchaLoginFail',
    ),
  },
  {
    key: 'BLOCK_IP',
    value: 'BLOCK_IP',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockIp',
    ),
  },
  {
    key: 'COMPANY_INVALID',
    value: 'COMPANY_INVALID',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.companyInvalid',
    ),
  },
  {
    key: 'COMPANY_EXPIRED',
    value: 'COMPANY_EXPIRED',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.companyExpired',
    ),
  },
  {
    key: 'BLOCK_MOBILE_ACCESS',
    value: 'BLOCK_MOBILE_ACCESS',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockMobileAccess',
    ),
  },
  {
    key: 'BLOCK_MAM',
    value: 'BLOCK_MAM',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockAccess',
    ),
  },
  {
    key: 'BLOCK_ACCESS',
    value: 'BLOCK_ACCESS',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockAccess',
    ),
  },
  {
    key: 'STOP_user',
    value: 'STOP_user',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.stopUser',
    ),
  },
  {
    key: 'DELETE_user',
    value: 'DELETE_user',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.deleteUser',
    ),
  },
  {
    key: 'AUTH_LOGOUT_user',
    value: 'AUTH_LOGOUT_user',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.authLogoutUser',
    ),
  },
  {
    key: 'DUPLICATION_LOGIN',
    value: 'DUPLICATION_LOGIN',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.duplicationLogin',
    ),
  },
  {
    key: 'EXTRA_AUTH_ERROR',
    value: 'EXTRA_AUTH_ERROR',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.extraAuthError',
    ),
  },
  {
    key: 'PASSWORD_FORCE_CHANGE',
    value: 'PASSWORD_FORCE_CHANGE',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.passwordForceChange',
    ),
  },
  {
    key: 'UNKNOWN',
    value: 'UNKNOWN',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.unknown',
    ),
  },
];

export const deviceOptions = [
  {
    key: 'PC',
    value: 'PC',
    title: 'PC',
  },
  {
    key: 'PC_MESSENGER',
    value: 'PC_MESSENGER',
    title: 'PC_MESSENGER',
  },
  {
    key: 'MOBILE_APP',
    value: 'MOBILE_APP',
    title: 'MOBILE_APP',
  },
  {
    key: 'MOBILE_BROWSER',
    value: 'MOBILE_BROWSER',
    title: 'MOBILE_BROWSER',
  },
];

export const userTypeOptions = [
  {
    key: 'USER',
    value: 'user',
    title: 'user',
  },
  {
    key: 'ADMIN',
    value: 'admin',
    title: 'admin',
  },
];
