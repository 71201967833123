import * as React from 'react';

export interface IContextValues {
  readonly name: string;
  readonly multiSelectable?: boolean;
  readonly selectedOptions: Set<string>;
  readonly selectedOptionLabels: Map<string, string>;
}

export interface IContextActions {
  setSelectedOptions: (options: Set<string>) => void;
  setSelectedOptionLabels: React.Dispatch<
    React.SetStateAction<Map<string, string>>
  >;
}

export const ValuesContext = React.createContext<IContextValues>({
  name: '',
  selectedOptions: new Set(),
  selectedOptionLabels: new Map(),
});

export const ActionsContext = React.createContext<IContextActions>({
  setSelectedOptions: () => undefined,
  setSelectedOptionLabels: () => undefined,
});

interface Props {
  name: string;
  multiSelectable?: boolean;
  onChangeValues?: (values: Set<string>) => void;
}

export function Provider({
  name,
  multiSelectable,
  children,
  onChangeValues,
}: React.PropsWithChildren<Props>) {
  const [selectedOptions, setSelectedOptions] = React.useState<Set<string>>(
    new Set(),
  );
  const [selectedOptionLabels, setSelectedOptionLabels] = React.useState<
    Map<string, string>
  >(new Map());

  const ctxValues = {
    name,
    multiSelectable,
    selectedOptions,
    selectedOptionLabels,
  };

  const setOptions = (options: Set<string>) => {
    setSelectedOptions(options);
    onChangeValues && onChangeValues(options);
  };

  const ctxActions = {
    setSelectedOptions: setOptions,
    setSelectedOptionLabels,
  };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useFilterValuesContext = () => React.useContext(ValuesContext);
export const useFilterActionsContext = () => React.useContext(ActionsContext);

export const useFilterContext = () => {
  const values = useFilterValuesContext();
  const actions = useFilterActionsContext();

  return {
    ...values,
    ...actions,
  };
};

Provider.displayName = 'FilterContextProvider';
