import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import {
  combine,
  setCustomNativeDragPreview,
  attachClosestEdge,
  extractClosestEdge,
  pointerOutsideOfPreview,
} from '../../../lib/dnd/helper';
import { draggable, dropTargetForElements } from '../../../lib/dnd/core';
import { clsx } from 'clsx';

import { useDndContext } from './provider';
import type { DragState, PreviewInfo, StyleSet } from './types';
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types';
import { DropIndicator } from './drop-indicator';

export interface Props<T> {
  item: T;
  className?: string;
  dragHandleRef?: MutableRefObject<HTMLElement | null>;
  previewInfo?: PreviewInfo;
  dragOverClassName?: string;
  styleSet?: StyleSet;
  allowedEdges?: Edge[];
  useDropIndicator?: boolean;
  useDragOverlay?: boolean;
  droppable?: boolean;
  canDrag?: boolean;
  DropBoxIndicatorComp?: JSX.Element;
}

export function ListItem<T>({
  item,
  previewInfo,
  dragHandleRef,
  className,
  styleSet = {
    'is-dragging': 'opacity-40',
  },
  allowedEdges = ['top', 'bottom'],
  useDropIndicator = true,
  useDragOverlay = false,
  droppable = true,
  canDrag = true,
  DropBoxIndicatorComp,
  children,
}: PropsWithChildren<Props<T>>) {
  const { getData, dataValidation, name, idKey } = useDndContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const idle: DragState = { type: 'idle' };
  const [state, setState] = useState<DragState>(idle);

  if (!styleSet['is-dragging']) {
    styleSet['is-dragging'] = 'opacity-40';
  }

  useEffect(() => {
    const dragHandleElement = dragHandleRef
      ? dragHandleRef.current
      : ref.current;

    if (dragHandleElement) {
      if (canDrag) dragHandleElement.style.cursor = 'grab';
      else dragHandleElement.style.cursor = 'not-allowed';
    }

    const dropElement = ref.current;

    if (dragHandleElement && dropElement) {
      return combine(
        draggable({
          canDrag: () => canDrag,
          element: dragHandleElement,
          getInitialData() {
            return getData(item as Record<string | symbol, unknown>);
          },
          onGenerateDragPreview({ nativeSetDragImage }) {
            if (previewInfo) {
              return setCustomNativeDragPreview({
                nativeSetDragImage,
                getOffset:
                  previewInfo.offset &&
                  pointerOutsideOfPreview(previewInfo.offset),
                render({ container }) {
                  setState({ type: 'preview', container });
                },
              });
            }
            return nativeSetDragImage;
          },

          onDragStart() {
            setState({ type: 'is-dragging' });
          },
          onDrop() {
            setState(idle);
          },
        }),
        droppable
          ? dropTargetForElements({
              element: dropElement,
              canDrop({ source }) {
                if (source.element === dropElement) {
                  return false;
                }
                return dataValidation(source.data, name);
              },
              getData({ input }) {
                const data = getData(item as Record<string | symbol, unknown>);

                return attachClosestEdge(data, {
                  element: dropElement,
                  input,
                  allowedEdges: allowedEdges,
                });
              },
              getIsSticky() {
                return true;
              },
              onDragEnter({ self, source }) {
                if (source.data.id === self.data.id) {
                  return;
                }
                const closestEdge = extractClosestEdge(self.data);
                setState({ type: 'is-dragging-over', closestEdge });
              },
              onDrag({ self, source }) {
                if (source.data.id === self.data.id) {
                  return;
                }
                const closestEdge = extractClosestEdge(self.data);

                setState((current) => {
                  if (
                    current.type === 'is-dragging-over' &&
                    current.closestEdge === closestEdge
                  ) {
                    return current;
                  }
                  return { type: 'is-dragging-over', closestEdge };
                });
              },
              onDragLeave({ self, source }) {
                if (source.data.id === self.data.id) {
                  return;
                }
                setState(idle);
              },
              onDrop() {
                setState(idle);
              },
            })
          : dropTargetForElements({ element: dropElement }),
      );
    }
  }, [item, ref.current]);

  const DropBoxIndicator = (edge: Edge) =>
    state.type === 'is-dragging-over' &&
    state.closestEdge &&
    state.closestEdge === edge &&
    DropBoxIndicatorComp &&
    DropBoxIndicatorComp;

  return (
    <>
      {DropBoxIndicatorComp && DropBoxIndicator('top')}
      {state.type === 'is-dragging' && useDragOverlay ? (
        /**
         * DragOverlay Component 분리 필요
         */
        <div
          data-id={name + item[idKey as keyof T]}
          ref={ref}
          className={clsx(
            'relative',
            '!border-dashed !border-4 border-gray-400 rounded-2xl bg-gray-200 z-50',
            className,
          )}
        />
      ) : (
        <div
          data-id={name + item[idKey as keyof T]}
          ref={ref}
          className={clsx(
            'relative',
            className,
            `${styleSet[state.type] ?? ''}`,
          )}
        >
          {children}
          {state.type === 'is-dragging-over' &&
            state.closestEdge &&
            useDropIndicator &&
            !DropBoxIndicatorComp && (
              <DropIndicator
                edge={state.closestEdge}
                gap={'8px'}
                color="#363636"
              />
            )}
        </div>
      )}
      {DropBoxIndicatorComp && DropBoxIndicator('bottom')}

      {state.type === 'preview' &&
        previewInfo &&
        createPortal(previewInfo.preview, state.container)}
    </>
  );
}

export default ListItem;
