import { TemplateProps } from './types';

export function DefaultErrorTemplate({ message, className }: TemplateProps) {
  return (
    <div className={className}>
      <p>{message}</p>
    </div>
  );
}

export default DefaultErrorTemplate;
