import { useStack } from '../primitives/root';

export const useNavigate = <T>() => {
  const { setStack } = useStack();
  return (page: T) => {
    setStack((prev) => {
      const newStack = [...prev];
      newStack[newStack.length - 1] = page;
      return newStack;
    });
  };
};
