import { Editor } from '@daouoffice/ui/lib/foundation/Editor';
import { clsx } from 'clsx';

import { CheckIcon, XMarkCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { Dispatch, SetStateAction } from 'react';
import { MemoColors, MemoToolbarType } from '../../types';
import { MemoColorClassNames } from '../../constants';
import { useMemoContext } from '../../Context';
import { Button } from '@dop-ui/react/shared/ui/button';
import memoStyles from '../../memo.module.css';

interface Props {
  editor: Editor | null;
  setToolbarShow: Dispatch<SetStateAction<MemoToolbarType>>;
}
function ColorToolbar({ editor, setToolbarShow }: Props) {
  const {
    memoContext: { color },
    setMemoContext,
  } = useMemoContext();

  if (!editor) return null;

  const handleOnChangeBgColor = (changedColor: MemoColors) => {
    if (color === changedColor) return;

    setMemoContext((prev) => ({
      ...prev,
      requiredSave: true,
      color: changedColor,
    }));
  };

  return (
    <>
      <Button
        className={memoStyles.MemoIconBtn}
        onClick={() => setToolbarShow('default')}
      >
        <XMarkCircleIcon className="w-[20px] h-[20px] p-[3px]" />
      </Button>
      {Object.entries(MemoColorClassNames).map(([colorName, colorClass]) => (
        <Button
          key={colorName}
          className={clsx(
            'relative rounded-full !w-5 !h-5 m-1 group',
            colorClass,
          )}
          onClick={() => handleOnChangeBgColor(colorName as MemoColors)}
        >
          <CheckIcon
            size={10}
            className={clsx('group-hover:visible px-[3px] py-[4px]', {
              invisible: colorName !== color,
            })}
          />
        </Button>
      ))}
    </>
  );
}

export default ColorToolbar;
