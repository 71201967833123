import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import * as deviceAccessQuery from '../../api/device-access-query';
import {
  CHAT_APP_CODE,
  DASHBOARD_APP_CODE,
  ORG_CHART_APP_CODE,
} from '../../constants';
import { useValuesContext } from '../context';
import { AppAccessConfig } from './app-access-config';
import { DeviceAccessConfig } from './device-access-config';

export function AppContents() {
  const { t } = useTranslation('component');
  const { selectedAppId, selectedAppCode } = useValuesContext();
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [deviceAccessQuery.QUERY_KEY],
    queryFn: async () =>
      selectedAppId !== -1 &&
      (await deviceAccessQuery.getDeviceAccess(selectedAppId)),
  });

  useEffect(() => {
    refetch().catch(console.error);
  }, [selectedAppId, refetch]);

  if (error) {
    return <></>;
  }

  return (
    <div
      className={clsx('flex-col flex flex-grow h-auto w-full', {
        'items-center justify-center': selectedAppId === -1,
      })}
    >
      {selectedAppId !== -1 ? (
        <>
          {res &&
            selectedAppCode !== CHAT_APP_CODE &&
            selectedAppCode !== DASHBOARD_APP_CODE &&
            selectedAppCode !== ORG_CHART_APP_CODE && (
              <AppAccessConfig data={res} />
            )}
          {res && <DeviceAccessConfig data={res} />}
        </>
      ) : (
        <h2 className="text-lg font-normal">
          {t('globalconfig.secureManagement.appManagement.seletAppText')}
        </h2>
      )}
    </div>
  );
}

export default AppContents;
