import styles from './popup-announcement.module.css';
import { Provider as PopupAnnouncementProvider } from './context';
import PopupAnnouncementContents from './contents';

export function PopupAnnouncement() {
  return (
    <div className={styles.Container}>
      <div className={styles.Card}>
        <PopupAnnouncementProvider>
          <PopupAnnouncementContents />
        </PopupAnnouncementProvider>
      </div>
    </div>
  );
}
export default PopupAnnouncement;
