import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { PopupAnnouncementListResponse, UseOptions } from '../types';

export const QUERY_KEY = 'QueryKeys.GlobalConfig.BasicManagement.PopupList';

interface Params {
  page: number;
  size: number;
  keyword?: string;
  sortBy?: string;
  sortDirection?: string;
  searchType?: string;
  isUse: UseOptions;
}

export async function getPopupAnnouncementList({
  page,
  size,
  keyword,
  sortBy,
  sortDirection,
  searchType,
  isUse,
}: Params): Promise<PopupAnnouncementListResponse> {
  const query = new URLSearchParams();
  query.append('page', page.toString());
  query.append('size', size.toString());

  if (keyword && searchType) {
    query.append('keyword', keyword.toString());
    query.append('searchType', searchType.toString());
  }
  if (sortBy && sortDirection) {
    query.append('sortBy', sortBy.toString());
    query.append('sortDirection', sortDirection.toString());
  }

  if (isUse === 'use') {
    query.append('isUse', 'true');
  } else if (isUse === 'notUse') {
    query.append('isUse', 'false');
  }

  const response = await fetch(
    `/api/portal/setting/basic/popup?${query.toString()}`,
  );

  const responseData = (await response.json()) as {
    data: PopupAnnouncementListResponse;
    message?: string;
  };

  if (responseData.message) {
    throw new Error(responseData.message);
  }

  return responseData.data;
}
