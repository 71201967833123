import { favoriteFalseColor, favoriteTrueColor } from '../constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FavoriteInfo, updateMemoFavorite } from '../apis/updateMemoFavorite';
import { QUERY_KEY as getMemoListQueryId } from '../apis/getMemoList';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import '../memo-edit/memo-editor.css';
import * as getMemoSearchQuery from '../apis/getMemoSearch';

import { Button } from '@dop-ui/react/shared/ui/button';
import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';
import { clsx } from 'clsx';
import { StarSolidIcon } from '@dop-ui/icons/react/dop/24';

interface Props {
  memoId: number;
  isFavorite: boolean;
  className?: string;
}

export function FavoriteButton({ memoId, isFavorite, className }: Props) {
  const { t } = useTranslation();
  const { memoContext, setMemoContext } = useMemoContext();

  const queryClient = useQueryClient();

  const { info } = useToastMessage();

  const updateMemoFavoriteMutaion = useMutation({
    mutationFn: ({ id, isFavorite }: FavoriteInfo) =>
      updateMemoFavorite({ id, isFavorite }),
    onSuccess(data, variables) {
      const toastMessage = variables.isFavorite
        ? t('organizer.memo.toast.favorite.add')
        : t('organizer.memo.toast.favorite.cancel');

      info(<Templates.Basic description={toastMessage} />);
      listInvalidateQuery();
      setMemoContext((prev) => ({
        ...prev,
        isFavorite: variables.isFavorite,
      }));
      if (memoContext.keyword !== '') {
        void queryClient.invalidateQueries({
          queryKey: [getMemoSearchQuery.QUERY_KEY],
        });
      }
    },
    onError(error) {
      info(<Templates.Basic description={error.message} />);
      listInvalidateQuery();
    },
  });

  const listInvalidateQuery = () => {
    void queryClient.invalidateQueries({
      queryKey: [getMemoListQueryId],
    });
  };

  const handleClickStar = (e?: React.MouseEvent<HTMLElement>) => {
    if (!e) {
      return;
    }
    e.stopPropagation();

    if (memoContext.favoriteCount >= 5 && !isFavorite) {
      info(
        <Templates.Basic
          description={t('organizer.memo.toast.favorite.full')}
        />,
      );
    } else {
      updateMemoFavoriteMutaion.mutate({
        id: memoId,
        isFavorite: !isFavorite,
      });
    }
  };

  return (
    <Button
      onClick={handleClickStar}
      className={clsx('bg-transparent', className)}
    >
      <StarSolidIcon
        size={20}
        className={clsx(isFavorite ? favoriteTrueColor : favoriteFalseColor)}
      />
    </Button>
  );
}

export default FavoriteButton;
