/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  usePop as dopStackUsePop,
  usePush as dopStackUsePush,
  useNavigate as dopStackUseNavigate,
  useStackMethod as dopStackUseStackMethod,
} from '@dop-ui/react/shared/ui/stacker';

/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export const usePush = dopStackUsePush;
/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export const usePop = dopStackUsePop;

/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export const useNavigate = dopStackUseNavigate;

/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export const useStackMethod = dopStackUseStackMethod;
