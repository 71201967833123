import { Button } from '@dop-ui/react/shared/ui/button';
import { TitleAndDescription } from '../../../../secureManagement/LoginSecureConfig/TitleAndDescription';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { ChevronRightIcon } from '@dop-ui/icons/react/dop/24';

export function OrgDisplayOrderConfigIndicator() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();

  return (
    <div className="py-[28px]">
      <TitleAndDescription
        title={t(
          'globalconfig.multiCompanyManagement.basicInfo.orgDisplayOrder.title',
        )}
      >
        <Button
          className="!w-[24px] !h-[24px]"
          onClick={() => stacker.push('orgDisplayOrder')}
        >
          <ChevronRightIcon size={24} />
        </Button>
      </TitleAndDescription>
    </div>
  );
}

export default OrgDisplayOrderConfigIndicator;
