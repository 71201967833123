import { TFunction } from 'i18next';

export interface CompanyListResponse {
  companyGroupId: number;
  companyList: CompanyListInfo[];
}

export interface CompanyListInfo {
  companyId: number;
  companyName: string;
  usedAccountCount: number;
  availableAccountCount: number;
}

export interface OrgConfigInfo {
  companyGroupId: number;
  isOrganizationChartShare: string;
  shareTarget: shareTargetType;
  targetList: TargetInfo[];
  shareRange: shareRangeype;
  organizationChartOrder: OrganizationChartOrder[];
}

export interface TargetInfo {
  companyId: number;
  roleType: string;
  roleTypeId: number;
  roleTypeName?: string;
}
export interface OrganizationChartOrder {
  companyId: number;
  companyName?: string;
  sortOrder: number;
  depth: number;
}

export type shareTargetType = 'ALL' | 'TARGET_CLASS';
export type shareRangeype = 'CHART_AND_SEARCH' | 'SEARCH';

export interface TargetConfig {
  targetList: TargetInfo[];
  shareTarget: shareTargetType;
  shareRange: shareRangeype;
}

export type OrganizationCodeType = 'GRADE' | 'POSITION' | 'DUTY' | 'USER_GROUP';

export const getClassList = (t: TFunction<'component', undefined>) => {
  return [
    {
      id: 'USER',
      name: t(
        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.user',
      ),
    },
    {
      id: 'DEPARTMENT',
      name: t(
        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.dept',
      ),
    },
    {
      id: 'GRADE',
      name: t(
        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.grade',
      ),
    },
    {
      id: 'POSITION',
      name: t(
        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.position',
      ),
    },
    {
      id: 'DUTY',
      name: t(
        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.duty',
      ),
    },
    {
      id: 'USER_GROUP',
      name: t(
        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.usergroup',
      ),
    },
  ];
};
