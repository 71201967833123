import { fetch } from '../../../../../../lib/fetch/client';
import { ACCESS_ROLE_INFO_URL } from '../../constants';
import { AppLockInfo } from '../types';

export const MUTATION_KEY = 'deviceAccessMutation';

export async function updateDevice(
  selectedAppId: number,
  appLockInfo: AppLockInfo,
) {
  const res = await fetch(
    `${ACCESS_ROLE_INFO_URL}/${selectedAppId}/access-role-info`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        webAccessRoleInfo: appLockInfo?.webAccessRoleInfo,
        mobileAccessRoleInfo: appLockInfo?.mobileAccessRoleInfo,
        messengerAccessRoleInfo: appLockInfo?.messengerAccessRoleInfo,
      }),
    },
  );

  if (!res.ok) {
    throw new Error('Failed to update device access');
  }
}
