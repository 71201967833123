import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import Image from 'next/image';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { centerCrop, Crop, makeAspectCrop, ReactCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageInfo } from '../../types';

export interface Props {
  title: string;
  subTitle?: string;
  image: ImageInfo;
  isOpen: boolean;
  isFavicon?: boolean;
  aspect?: number;
  openStateChange: (isOpen: boolean) => void;
  onConfirm: (blob: Blob) => void;
}

export function ImageCropperDialog({
  title,
  subTitle,
  image,
  isOpen,
  isFavicon = false,
  aspect = 3 / 2,
  openStateChange,
  onConfirm,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('component');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completeCrop, setCompleteCrop] = useState<Crop>();
  const [isLoading, setLoadState] = useState<boolean>(false);

  const handleImageLoad = () => {
    if (imageRef.current) {
      makeCenterCrop();
    }
  };

  const makeCenterCrop = () => {
    if (!imageRef.current) {
      return;
    }

    const width = imageRef.current.naturalWidth;
    const height = imageRef.current.naturalHeight;

    const newCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(newCrop);
    setCompleteCrop(newCrop);
  };

  // canvas를 그리는 함수.
  const createCanvas = (crop: Crop) => {
    if (!crop || !canvasRef.current || !imageRef.current) {
      return;
    }

    // canvas의 context를 가져온다.
    const canvasContext = canvasRef.current.getContext('2d');
    if (!canvasContext) return;

    const { naturalWidth, naturalHeight } = imageRef.current;

    const pixelX = (crop.x / 100) * naturalWidth;
    const pixelY = (crop.y / 100) * naturalHeight;
    const pixelWidth = (crop.width / 100) * naturalWidth;
    const pixelHeight = (crop.height / 100) * naturalHeight;

    const pixelRatio = window.devicePixelRatio;

    canvasRef.current.width = pixelWidth * pixelRatio;
    canvasRef.current.height = pixelHeight * pixelRatio;

    canvasContext.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    canvasContext.imageSmoothingQuality = 'high';

    canvasContext.drawImage(
      imageRef.current,
      pixelX,
      pixelY,
      pixelWidth,
      pixelHeight,
      0,
      0,
      pixelWidth,
      pixelHeight,
    );

    canvasContext.save();
  };

  const onConfirmHandler = () => {
    setLoadState(true);
    canvasRef.current &&
      canvasRef.current.toBlob(
        (blob: Blob | null) => {
          const formData = new FormData();
          if (blob !== null) {
            formData.append('file', blob);
            onConfirm(blob);
          }
        },
        isFavicon ? 'image/x-icon' : 'image/png',
        0.95,
      );
    if (!isOpen) {
      clearCrop();
    }
  };

  const clearCrop = () => {
    setCrop(undefined);
    setCompleteCrop(undefined);
    if (canvasRef.current) {
      const canvasContext = canvasRef.current.getContext('2d');
      if (canvasContext) {
        canvasContext.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        );
      }
    }
  };

  const onCancelHandler = () => {
    openStateChange(false);
  };

  const reSelectHandler = () => {
    clearCrop();
    makeCenterCrop();
  };

  useEffect(() => {
    if (isOpen) {
      setLoadState(false);
      makeCenterCrop();
    } else {
      clearCrop();
    }
  }, [isOpen]);

  useEffect(() => {
    if (completeCrop && imageRef.current) {
      createCanvas(completeCrop);
    }
  }, [completeCrop, image, aspect]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={openStateChange}>
      <Dialog.Overlay />
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content>
        <div className="flex flex-col gap-6 px-10 relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center z-10 bg-white bg-opacity-40">
              Uploading...
            </div>
          )}
          <div className="flex items-center">
            <h2 className="font-medium text-[#363636] text-[20px] leading-[30px] -tracking-[0.4px]">
              {title}
            </h2>
            {subTitle && <span>{subTitle}</span>}
          </div>
          <div className="px-6 py-[25px] border border-[#E6E7EA] rounded-lg">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(_, percentCrop) => {
                setCompleteCrop(percentCrop);
              }}
              aspect={aspect}
            >
              <Image
                className="object-contain"
                ref={imageRef}
                src={image.path}
                onLoad={handleImageLoad}
                alt=""
                height={150}
                width={300}
              />
            </ReactCrop>
            <canvas ref={canvasRef} className="w-0 h-0" />
          </div>
          <div className="flex items-center gap-4 justify-end">
            <Button
              variant="ghost"
              size="md"
              colorset="level1"
              onClick={onCancelHandler}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.dialog.cancel',
              )}
            </Button>
            <Button
              variant="solid"
              size="md"
              shape="rect"
              colorset="level2"
              onClick={reSelectHandler}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.dialog.reSelect',
              )}
            </Button>
            <Button
              variant="solid"
              size="md"
              shape="rect"
              colorset="level1"
              onClick={onConfirmHandler}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.dialog.confirm',
              )}
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default ImageCropperDialog;
