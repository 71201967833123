import { useEffect, useRef } from 'react';
import NotificationItem from '../item';

import useInfinityNotificationList from './use-infinity-list';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useTranslation } from '../../../../shared/lib/i18n/client/use-translation';

import styles from './list.module.css';
import NoData from '../../../../shared/ui/svg/no-data';

interface Props {
  menu: string;
}

function InfinityNotificationList({ menu }: Props) {
  const { t } = useTranslation();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfinityNotificationList({ menu });

  const notificationList =
    data?.pages.flatMap((page) => page?.elements ?? []) ?? [];

  const notificationLength = notificationList?.length ?? 0;

  const virtualListRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: notificationLength,
    getScrollElement: () => virtualListRef.current,
    estimateSize: () => 58,
    measureElement: (el) => el.getBoundingClientRect().height,
  });

  const virtualList = virtualizer.getVirtualItems();

  useEffect(() => {
    const handleFetchNextPage = async () => {
      if (hasNextPage) await fetchNextPage();
    };

    const [lastItem] = [...virtualList].reverse();
    if (!lastItem) {
      return;
    }
    if (
      lastItem.index >= notificationLength - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      void handleFetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    notificationLength,
    isFetchingNextPage,
    virtualList,
  ]);

  const lastItem = virtualList.slice(-1)[0];
  const loadingItemTop = lastItem ? lastItem.start + lastItem.size : 0;

  if (isLoading) {
    return <p className={styles.Loading}>Loading...</p>;
  }

  if (!notificationList || notificationList.length === 0) {
    return (
      <div className={styles.EmptyContainer}>
        <NoData />
        <p>{t('notification.empty')}</p>
      </div>
    );
  }

  return (
    <div
      className={styles.VirtualList}
      style={{
        height: `${virtualizer.getTotalSize()}px`,
      }}
      ref={virtualListRef}
    >
      {virtualList.map((virtualItem) => {
        const data = notificationList[virtualItem.index];
        return (
          data && (
            <div
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={virtualizer.measureElement}
              className={styles.VirtualItem}
              style={{
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <NotificationItem key={data.notificationId} {...data} />
            </div>
          )
        );
      })}

      {isFetchingNextPage && (
        <p
          className={styles.NextLoading}
          style={{
            top: `${loadingItemTop}px`,
          }}
        >
          Loading...
        </p>
      )}
    </div>
  );
}

export default InfinityNotificationList;
