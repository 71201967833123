import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../../../../../lib/auth/client';
import { AppChildInfo, ShareInfo, ShareNodeInfo } from '../../../types';
import { getPostRequestDto } from '../../../utils';
import { useActionsContext, useValuesContext } from '../../provider';
import { ShareAreaConfig } from '../../share-area-config';
import { ShareConfigDialog } from '../../share-config-dialog';
import * as boardShareListAddMutation from './apis/board-share-list-add-mutation';
import * as boardShareListQuery from './apis/board-share-list-query';
import * as companyBoardListQuery from './apis/company-board-list-query';
import { CompanyBoardInfo } from './types';

interface Props {
  boardShareList: ShareInfo[];
  shareConfigDialogOpenState: boolean;
  detailAppId: number | null;
  onChangeDetailAppId: (id: number | null) => void;
  onChangeShareConfigDialogOpenState: (state: boolean) => void;
}

export function ShareConfigDialogButton({
  boardShareList,
  shareConfigDialogOpenState,
  detailAppId,
  onChangeDetailAppId,
  onChangeShareConfigDialogOpenState,
}: Props) {
  const { t } = useTranslation('component');
  const { companyList, selectedCompany } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [shareNodeList, setSharedNodeList] = useState<ShareNodeInfo[]>([]);
  const [selectedBoard, setselectedBoard] = useState<AppChildInfo>({
    id: 0,
    name: '',
  });

  const { me } = useAuthContext();

  const { data: boardList, error } = useQuery({
    queryKey: [companyBoardListQuery.QUERY_KEY, selectedCompany?.companyUuid],
    queryFn: async () => {
      if (selectedCompany?.companyUuid === undefined) return [];
      return await companyBoardListQuery.getCompanyCalenderList(
        selectedCompany.companyUuid,
      );
    },
    enabled: !!selectedCompany?.companyUuid,
  });

  useEffect(() => {
    const sharedTargetList = boardShareList.filter(
      (item) =>
        item.companyUuid === selectedCompany?.companyUuid &&
        item.id === selectedBoard?.id,
    )[0]?.shares;

    if (sharedTargetList?.length === 0) {
      return;
    }

    const sharedTargetNodeList = sharedTargetList
      ?.map((item) => item.nodes.map((item) => item))
      .flat();

    setSharedNodeList(sharedTargetNodeList ?? []);
  }, [selectedBoard]);

  const createBoardShareMutation = useMutation({
    mutationKey: [boardShareListAddMutation.MUTATION_KEY],
    mutationFn: async () => {
      if (me?.companyGroupId === undefined) return [];
      await boardShareListAddMutation.createBoardShareList(
        me?.companyGroupId,
        getPostRequestDto({
          companyGroupId: me?.companyGroupId,
          selectedApp: selectedBoard,
          selectedCompany: selectedCompany!,
          shareNodeList: shareNodeList,
        }),
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [boardShareListQuery.QUERY_KEY],
      });
      onChangeShareConfigDialogOpenState(false);
      toaster.info(t('globalconfig.common.toastMessage.success'));
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  const handleSaveHandler = () => {
    if (shareNodeList.length === 0) {
      toaster.warning(
        t('globalconfig.multiCompanyManagement.appShare.toast.add.noData'),
      );
      return;
    }
    createBoardShareMutation.mutate();
  };

  const handleClickAdd = () => {
    if (companyList && companyList[0]) {
      setSelectedCompany({
        companyId: companyList[0].companyId,
        companyName: companyList[0].companyName,
        companyUuid: companyList[0].companyUuid,
      });
    }
    onChangeDetailAppId(null);
    onChangeShareConfigDialogOpenState(true);
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > AppShare > Board > Dialog: ',
      error,
    );
  }

  if (!me) {
    return;
  }

  return (
    <ShareConfigDialog
      trigger={
        <Button
          className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded hover:bg-[#f2f2f2]"
          onClick={handleClickAdd}
        >
          <PlusIcon width={24} height={24} />
          {t(
            'globalconfig.multiCompanyManagement.appShare.board.addButton.title',
          )}
        </Button>
      }
      openState={shareConfigDialogOpenState}
      onOpenStateChange={(openState) =>
        onChangeShareConfigDialogOpenState(openState)
      }
      onSave={handleSaveHandler}
    >
      <ShareAreaConfig<CompanyBoardInfo>
        shares={shareNodeList}
        appChildList={boardList}
        defaultAppId={detailAppId}
        onChange={(board) => {
          setselectedBoard({ id: board.id, name: board.name });
        }}
        onChangeShares={setSharedNodeList}
      />
    </ShareConfigDialog>
  );
}

export default ShareConfigDialogButton;
