import { clsx } from 'clsx';
import { PropsWithChildren, useEffect, useState } from 'react';
import {
  ArrowUpTrayIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { IpListImportDialog } from './ip-list-import-dialog';
import { IpListItem } from './ip-list-item';
import { ipInfoToString, isTextIp } from '../../utils/ipInfoUtils';
import { IPInfo, IPType } from '../../types';

interface Props {
  className?: string;
  title?: string | JSX.Element;
  ipList: IPInfo[];
  onChangeIpList: (ipList: IPInfo[]) => void;
  onClickExportIpList?: () => void;
}

export function IPListManager({
  className,
  ipList,
  children,
  onChangeIpList,
  onClickExportIpList,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('component');

  const [selectedIPType, setSelectedIPType] = useState('SINGLE_IP');
  const [ipGroupName, setIpGroupName] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [subAddress, setSubAddress] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchedList, setSearchedList] = useState<IPInfo[]>([]);

  const toaster = useToastMessage();

  useEffect(() => {
    if (searchKeyword) {
      setSearchedList(
        ipList.filter(
          (ipInfo) =>
            ipInfoToString(ipInfo).includes(searchKeyword) ||
            ipInfo.ipGroupName?.includes(searchKeyword),
        ),
      );
    } else {
      setSearchedList([]);
    }
  }, [searchKeyword, ipList]);

  const clearInput = () => {
    setIpGroupName('');
    setIpAddress('');
    setSubAddress('');
  };

  const filteredList = (infoList: IPInfo[], ipType: IPType) => {
    const existingIpList = new Set(
      ipList
        .filter((info) => info.ipType === ipType)
        .map((info) => info.ipAddress),
    );

    return infoList.filter((info) => !existingIpList.has(info.ipAddress));
  };

  const onClickAdd = () => {
    if (selectedIPType === 'SINGLE_IP' && isTextIp(ipAddress)) {
      const singleIpListSet = new Set(
        ipList
          .filter((info) => info.ipType === 'SINGLE_IP')
          .map((info) => info.ipAddress),
      );

      if (!singleIpListSet.has(ipAddress))
        onChangeIpList([
          ...ipList,
          {
            ipType: 'SINGLE_IP',
            ipGroupName,
            ipAddress,
          },
        ]);
    } else if (
      selectedIPType === 'IP_RANGE' &&
      isTextIp(ipAddress) &&
      isTextIp(subAddress)
    ) {
      const ipRangeListSet = new Set(
        ipList
          .filter((info) => info.ipType === 'IP_RANGE')
          .map((info) => info.ipAddress),
      );

      if (!ipRangeListSet.has(`${ipAddress}-${subAddress}`))
        onChangeIpList([
          ...ipList,
          {
            ipType: selectedIPType,
            ipGroupName,
            ipAddress: `${ipAddress}-${subAddress}`,
          },
        ]);
    } else if (
      selectedIPType === 'SUBNET_MASK' &&
      isTextIp(ipAddress) &&
      subAddress.length !== 0
    ) {
      const subnetMaskListSet = new Set(
        ipList
          .filter((info) => info.ipType === 'SUBNET_MASK')
          .map((info) => info.ipAddress),
      );

      if (!subnetMaskListSet.has(`${ipAddress}/${subAddress}`))
        onChangeIpList([
          ...ipList,
          {
            ipType: selectedIPType,
            ipGroupName,
            ipAddress: `${ipAddress}/${subAddress}`,
          },
        ]);
    } else {
      toaster.warning(
        t('globalconfig.secureManagement.loginSecureConfig.ip.invalid'),
      );
    }
    clearInput();
  };

  const onClickAddFromDialog = (addList: IPInfo[]) => {
    const singleIpList = addList.filter((info) => info.ipType === 'SINGLE_IP');

    const ipRangeList = addList.filter((info) => info.ipType === 'IP_RANGE');

    const subnetMaskList = addList.filter(
      (info) => info.ipType === 'SUBNET_MASK',
    );

    const filteredSingleIpList = filteredList(singleIpList, 'SINGLE_IP');
    const filteredIpRangeList = filteredList(ipRangeList, 'IP_RANGE');
    const filteredSubnetMaskList = filteredList(subnetMaskList, 'SUBNET_MASK');

    onChangeIpList([
      ...ipList,
      ...filteredSingleIpList,
      ...filteredIpRangeList,
      ...filteredSubnetMaskList,
    ]);
  };

  return (
    <div className={clsx('flex flex-col flex-grow', className)}>
      <div className="min-h-[32px] flex flex-wrap gap-[4px] items-center">
        <select
          className="h-[32px] w-[200px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
          value={selectedIPType}
          onChange={(e) => setSelectedIPType(e.target.value)}
        >
          <option value={'SINGLE_IP'}>
            {t(
              'globalconfig.secureManagement.loginSecureConfig.ip.category.single',
            )}
          </option>
          <option value={'IP_RANGE'}>
            {t(
              'globalconfig.secureManagement.loginSecureConfig.ip.category.range',
            )}
          </option>
          <option value={'SUBNET_MASK'}>
            {t(
              'globalconfig.secureManagement.loginSecureConfig.ip.category.subnetMask',
            )}
          </option>
        </select>
        <input
          className="w-[200px] h-[32px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
          placeholder={t(
            'globalconfig.secureManagement.loginSecureConfig.ip.optionalGroupName',
          )}
          value={ipGroupName}
          onChange={(e) => setIpGroupName(e.target.value)}
        />
        <input
          className="w-[140px] h-[32px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
          placeholder="123.123.123.123"
          value={ipAddress}
          onChange={(e) => setIpAddress(e.target.value)}
        />
        {selectedIPType !== 'SINGLE_IP' && (
          <>
            {selectedIPType === 'IP_RANGE' ? <span>-</span> : <span>/</span>}
            <input
              className="w-[140px] h-[32px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
              placeholder={
                selectedIPType === 'IP_RANGE' ? '123.123.123.123' : '24'
              }
              value={subAddress}
              onChange={(e) => setSubAddress(e.target.value)}
            />
          </>
        )}
        <button
          className="flex items-center h-[32px] w-[72px] px-[12px] rounded-[4px] border border-solid border-[#C5C5C5]"
          onClick={() => onClickAdd()}
        >
          <PlusIcon className="inline size-[20px]" />
          <span className="ml-[4px] text-[14px] text-[#363636] font-[400]">
            {t('globalconfig.secureManagement.loginSecureConfig.ip.add')}
          </span>
        </button>
      </div>
      <div className="w-full h-[0px] my-[12px] border border-dashed stroke-[2px] border-[#E2E2E2]" />
      <div className="flex flex-wrap gap-[4px]">
        <div className="flex items-center w-[200px] h-[32px] px-[6px] rounded-[4px] border border-solid border-[#D8D8D8]">
          <MagnifyingGlassIcon
            className="size-[22px] pt-[3.5px] pl-[3.5px]"
            color="#AAAAAA"
          />
          <input
            className="flex items-center w-[200px] h-[32px] ml-[8px]"
            placeholder={t(
              'globalconfig.secureManagement.loginSecureConfig.ip.search',
            )}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
        <div className="spacer flex-grow" />
        <button
          className="flex items-center h-[32px] px-[12px] rounded-[4px] border border-solid border-[#C5C5C5]"
          onClick={onClickExportIpList}
        >
          <ArrowUpTrayIcon className="inline size-[16px]" />
          <span className="ml-[4px] text-[14px] text-[#363636] font-[400]">
            {t('globalconfig.secureManagement.loginSecureConfig.ip.listExport')}
          </span>
        </button>
        <IpListImportDialog onClickAdd={onClickAddFromDialog} />
      </div>
      <div className="flex justify-center flex-col mt-[8px] px-[12px] py-[8px] min-h-[72px] border border-[#D8D8D8] rounded-[4px]">
        {searchKeyword ? (
          searchedList.length !== 0 ? (
            searchedList.map((ipInfo, index) => (
              <IpListItem
                key={index}
                ipInfo={ipInfo}
                onClickDelete={() =>
                  onChangeIpList(ipList.filter((_, i) => i !== index))
                }
              />
            ))
          ) : (
            <p className="text-[14px] text-[#888888] font-[400]">
              {t(
                'globalconfig.secureManagement.loginSecureConfig.ip.noSearchResult',
              )}
            </p>
          )
        ) : (
          ipList &&
          ipList.map((ipInfo, index) => (
            <IpListItem
              key={index}
              ipInfo={ipInfo}
              onClickDelete={() => {
                onChangeIpList(ipList.filter((_, i) => i !== index));
              }}
            />
          ))
        )}
        {ipList.length === 0 && (
          <p className="text-center text-[14px] text-[#999999] text-[--color-text-level3] font-[400]">
            {t('globalconfig.common.ipList.empty')}
          </p>
        )}
      </div>
      {children}
    </div>
  );
}
