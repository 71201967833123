import { ChangeEvent, Suspense, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { deleteMultiMemberList } from './apis/delete-multi-member-list';
import * as getMultiMemberListQueries from './apis/get-multi-member-list';

import { Button } from '@dop-ui/react/shared/ui/button';

import MultiMemberSetting from './multi-member-setting';
import MultiMemberList from './multi-member-list';
import PasswordSyncSwitch from './password-sync';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';

import { DeleteIcon, PlusIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { QuestionCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';

import styles from './multi-member.module.css';
import { DEFAULT_OFFSET_OPTION_VALUES, TRANSLATION_PREFIX } from './constants';
import type { MultiMemberElements } from './types';
import { useAuthContext } from '../../../../../lib/auth/client';
import { useMultiMemberContext } from './context';

function MultiMemberContents() {
  const { t } = useTranslation('component');
  const { info, warning } = useToastMessage();
  const { me } = useAuthContext();
  const { pageOffset, setPage, setPageOffset } = useMultiMemberContext();
  const [addConfirmOpen, setAddConfirmOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [selectedMultiMember, setSelectedMultiMember] = useState<
    MultiMemberElements[]
  >([]);

  const queryClient = useQueryClient();

  const deleteMultiMemberMutation = useMutation({
    mutationFn: deleteMultiMemberList,
    onSuccess: () => {
      info(
        <Templates.Basic description={t(`${TRANSLATION_PREFIX}.deleted`)} />,
      );
      void queryClient.invalidateQueries({
        queryKey: [getMultiMemberListQueries.QUERY_KEY],
      });
      setSelectedMultiMember([]);
    },
    onError: () => {
      info(<Templates.Basic description={t(`${TRANSLATION_PREFIX}.error`)} />);
    },
  });

  const handleOnDelete = () => {
    if (!selectedMultiMember.length) {
      warning(
        <Templates.Basic
          description={t(`${TRANSLATION_PREFIX}.delete.empty`)}
        />,
      );
      return;
    }
    deleteMultiMemberMutation.mutate({
      integrationIds: selectedMultiMember.map((member) => member.integrationId),
      companyGroupId: me?.companyGroupId,
    });
  };

  const handleOnChangeOffset = (e: ChangeEvent<HTMLSelectElement>) => {
    setPageOffset(Number(e.target.value));
    setPage(0);
  };

  return (
    <div className={styles.Contents}>
      <ul className={styles.Header}>
        <Button
          className={styles.IconButton}
          onClick={() => setAddConfirmOpen(true)}
        >
          <PlusIcon size={24} />
          <p> {t(`${TRANSLATION_PREFIX}.add`)} </p>
        </Button>
        <MultiMemberSetting
          title={t(`${TRANSLATION_PREFIX}.setting`)}
          open={addConfirmOpen}
          onOpenChange={setAddConfirmOpen}
        />

        <Button className={styles.IconButton} onClick={handleOnDelete}>
          <DeleteIcon size={24} />
          <p> {t(`${TRANSLATION_PREFIX}.delete`)} </p>
        </Button>

        <div className={styles.Divider} />

        <div className="flex items-center gap-1">
          <p> {t(`${TRANSLATION_PREFIX}.passwordSync`)} </p>
          <Tooltip.Root open={tooltipOpen} onOpenChange={setTooltipOpen}>
            <Tooltip.Trigger asChild>
              <button>
                <QuestionCircleIcon size={16} />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Content side="left" align="start" alignOffset={24}>
              {t(`${TRANSLATION_PREFIX}.passwordSync.tooltip`)}
            </Tooltip.Content>
          </Tooltip.Root>

          <PasswordSyncSwitch />

          <select
            className={styles.Select}
            defaultValue={pageOffset}
            onChange={handleOnChangeOffset}
          >
            {DEFAULT_OFFSET_OPTION_VALUES.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </ul>
      <Suspense>
        <MultiMemberList
          selectedMultiMember={selectedMultiMember}
          setSelectedMultiMember={setSelectedMultiMember}
        />
      </Suspense>
    </div>
  );
}
export default MultiMemberContents;
