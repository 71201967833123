import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  Bars2Icon,
  HomeIcon,
  LockClosedIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { Bars2SlashIcon } from '@dop-ui/icons/react/dop/24/bars-two-slash';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { isContentNeedsSaveAtom } from '../../../../../store/isContentNeedsSaveAtom';
import { GNBMenuInfo } from '../../types';
import {
  getGnbMenuInfo,
  QUERY_KEY,
  updateGnbMenuSortInfo,
} from './apis/gnb-sort';

export function SortConfig() {
  const { t } = useTranslation('component');
  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const [menuList, setMenuList] = useState<GNBMenuInfo[]>([]);

  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const { data: menuListData, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getGnbMenuInfo,
  });

  const sortInfoUpdateMutation = useMutation({
    mutationFn: (ids: number[]) => updateGnbMenuSortInfo(ids),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setIsNeedSave(false);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  useEffect(() => {
    if (menuListData) {
      setMenuList(menuListData);
    }
  }, [menuListData]);

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > EmpPortalAppManagement > GnbSort Error: ',
      error,
    );
  }

  const onClickCancel = () => {
    setMenuList(menuListData || []);
    setIsNeedSave(false);
  };

  const onClickSave = () => {
    sortInfoUpdateMutation.mutate(menuList.map((menu) => menu.id));
  };

  return (
    <div className="mt-[24px] p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
      <div className="flex items-center h-[38px]">
        <p className="text-[#363636] text-[20px] font-[600]">
          {t('globalconfig.basicManagement.employeePortal.gnbSort.title')}
        </p>
      </div>
      <p className="mb-[24px] text-[#999999] text-[14px] font-[400]">
        {t('globalconfig.basicManagement.employeePortal.gnbSort.description')}
      </p>
      <div className="mt-[24px]">
        <div className="flex items-center pl-[8px] h-[48px] rounded-[8px] cursor-not-allowed opacity-50">
          <Bars2SlashIcon className="size-[40px] p-[8px]" />
          <HomeIcon className="size-[24px] ml-[32px]" />
          <p className="ml-[8px] text-[#262626] //text-[--color-text-primary] text-[14px] font-[400]">
            {t('globalconfig.basicManagement.employeePortal.gnbSort.home')}
          </p>
          <div className="flex-grow" />
          <LockClosedIcon className="size-[24px] mr-[16px]" />
        </div>
        <DnDList.Provider name="EmpGNBDnD" idKey="id">
          <DnDList.Container<GNBMenuInfo>
            list={menuList}
            setList={(list) => {
              setMenuList(list);
              setIsNeedSave(true);
            }}
          >
            {menuList.map((menu) => (
              <DnDList.Item
                key={menu.id}
                item={menu}
                className="flex items-center pl-[8px] h-[48px] hover:bg-[#F2F2F2] rounded-[8px]"
              >
                <Bars2Icon className="size-[40px] p-[8px] " color="#9A9A9A" />
                {menu.icon ? (
                  <i
                    className="size-[24px] ml-[32px]"
                    dangerouslySetInnerHTML={{
                      __html: menu.icon,
                    }}
                  />
                ) : (
                  <NoSymbolIcon
                    className="size-[20px] ml-[32px]"
                    color="#363636"
                  />
                )}
                <p className="ml-[8px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
                  {menu.name}
                </p>
              </DnDList.Item>
            ))}
          </DnDList.Container>
        </DnDList.Provider>
      </div>
      <div className="w-full mt-[24px] py-[46px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          variant="outline"
          colorset="level1"
          shape="rect"
          onClick={onClickCancel}
        >
          {t('globalconfig.common.button.cancel.title')}
        </Button>
        <Button
          size="medium"
          variant="solid"
          colorset="level1"
          shape="rect"
          disabled={!isNeedsSave}
          onClick={onClickSave}
        >
          {t('globalconfig.common.button.save.title')}
        </Button>
      </div>
    </div>
  );
}
