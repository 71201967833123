import { fetch } from '../../../shared/lib/fetch/client';

interface DeleteResponse {
  data: {
    count: number;
  };
}

export async function deleteNotification(
  notificationId?: number,
): Promise<DeleteResponse> {
  const response = await fetch(
    `/api/portal/common/notification/${notificationId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return (await response.json()) as DeleteResponse;
}
