interface Params<T> {
  key: string;
  defaultValue: T;
  type: 'string' | 'number' | 'object';
}

export function getLocalStorageItem<T>({ key, defaultValue, type }: Params<T>) {
  const storageData = localStorage.getItem(key);
  if (!storageData) return defaultValue;

  try {
    switch (type) {
      case 'string':
        return storageData as T;
      case 'number':
        return Number(storageData) as T;
      case 'object':
        if (typeof JSON.parse(storageData) === 'object')
          return JSON.parse(storageData) as T;
        else return defaultValue;
      default:
        return defaultValue;
    }
  } catch (e) {
    console.log('e', e);
    return defaultValue;
  }
}
