import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Switch } from '@daouoffice/ui';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import * as notiConfigApis from './apis/getNotificationConfig';

export function SetUsageNotification() {
  const { t } = useTranslation('component');
  const { data: configData, error } = useQuery({
    queryKey: [notiConfigApis.QUERY_KEY],
    queryFn: notiConfigApis.getNotificationConfig,
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > SetUsageNotification, getNotiConfig : ',
      error,
    );
  }

  const defaultNotiStandard = 80;
  const defaultNotiMethod = 'NOTI_AND_MAIL';

  const [notificationStandard, setNotificationStandard] =
    useState(defaultNotiStandard);

  const [notificationMethod, setNotificationMethod] =
    useState(defaultNotiMethod);

  const [isDifferenceExist, setIsDifferenceExist] = useState(false);

  const setIsContentNeesSave = useSetAtom(isContentNeedsSaveAtom);

  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const notiConfigMutation = useMutation({
    mutationFn: () =>
      notiConfigApis.updateNotificationConfig({
        notiCriteria: notificationStandard,
        notiMethod: notificationMethod,
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [notiConfigApis.QUERY_KEY],
      });
      console.log(
        'Success: GlobalConfig > SetUsageNotification, updateNotiConfig',
      );
      setIsContentNeesSave(false);
      toaster.info(t('globalconfig.common.toastMessage.success'));
    },
    onError: (error) => {
      toaster.warning(
        t('globalconfig.common.toastMessage.fail') + ': ' + error.message,
      );
    },
  });

  const applyConfigData = useCallback(() => {
    if (configData && configData.notiCriteria) {
      setNotificationStandard(configData.notiCriteria);
    } else {
      setNotificationStandard(defaultNotiStandard);
    }
    setNotificationMethod(configData?.notiMethod ?? defaultNotiMethod);
  }, [configData]);

  useEffect(() => {
    applyConfigData();
  }, [applyConfigData]);

  useEffect(() => {
    if (
      configData &&
      (configData.notiCriteria !== notificationStandard ||
        configData.notiMethod !== notificationMethod)
    ) {
      setIsDifferenceExist(true);
      setIsContentNeesSave(true);
    } else {
      setIsDifferenceExist(false);
      setIsContentNeesSave(false);
    }
  }, [
    configData,
    notificationStandard,
    notificationMethod,
    setIsContentNeesSave,
  ]);

  const onClickSave = () => notiConfigMutation.mutate();

  const onClickCancel = () => {
    applyConfigData();
  };

  const onSelectStandard = (value: string) => {
    setNotificationStandard(Number(value));
  };

  return (
    <div className="px-[72px] pt-[40px]">
      <div className="p-[40px] border border-solid border-[#DDDDDD] rounded-[8px]">
        <p className="text-[22px] text-[#333333] font-[500]">
          {t('globalconfig.dataManagement.setNotification.title')}
        </p>
        <div className="flex flex-wrap items-center mt-[24px] gap-[8px]">
          <span className="text-[16px] text-[#111111] font-[500] min-w-[160px]">
            {t('globalconfig.dataManagement.setNotification.standard.title')}
          </span>
          <select
            className="ml-[16px] px-[8px] h-[40px] rounded-[8px] min-w-[90px]"
            onChange={(e) => onSelectStandard(e.target.value)}
            value={notificationStandard}
          >
            <option value={70}>70 %</option>
            <option value={80}>80 %</option>
            <option value={90}>90 %</option>
            <option value={100}>100%</option>
          </select>
          <span className="text-[14px] text-[#333333] font-[400]">
            {t(
              'globalconfig.dataManagement.setNotification.standard.description',
            )}
          </span>
        </div>
        <div className="flex flex-wrap items-center mt-[8px] gap-[8px]">
          <span className="text-[16px] text-[#111111] font-[500] min-w-[160px]">
            {t('globalconfig.dataManagement.setNotification.method')}
          </span>
          <select
            className="flex-grow ml-[16px] px-[8px] h-[40px] rounded-[8px] min-w-[160px] max-w-[300px]"
            onChange={(e) => setNotificationMethod(e.target.value)}
            value={notificationMethod}
          >
            {configData &&
              configData.notiMethodList.map((method, index) => (
                <option key={`${method.key}${index}`} value={method.key}>
                  {method.value}
                </option>
              ))}
          </select>
        </div>
        <div className="w-full h-[1px] my-[24px] bg-[#E5E5E5]" />
        <div className="flex flex-wrap items-center mt-[8px] gap-[8px] justify-between">
          <span className="mr-[16px] text-[16px] text-[#111111] font-[500] min-w-[160px]">
            {t(
              'globalconfig.dataManagement.setNotification.overflowNoti.title',
            )}
          </span>
          <span className="text-[14px] text-[#AAAAAA] font-[400] flex-grow">
            {t(
              'globalconfig.dataManagement.setNotification.overflowNoti.description',
            )}
          </span>
          <Switch
            id="GlobalConfig.Datamanagement.UsageAlertSwitch"
            checked
            disabled
          />
        </div>
        <div className="mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
          <Button
            colorset="level1"
            shape="rect"
            variant="outline"
            size="medium"
            onClick={onClickCancel}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            colorset="level1"
            shape="rect"
            variant="solid"
            size="medium"
            disabled={!isDifferenceExist}
            onClick={onClickSave}
          >
            {t('dialog.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}
