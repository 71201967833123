import {
  DiabledHamburgerMenuIcon,
  HamburgerMenuIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import type { IUserMenuInfo } from '../../../../types';
import { useContext } from '../Context';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';

export interface Props {
  index: number;
  item: IUserMenuInfo;
}

function getAppIcon(item: IUserMenuInfo) {
  return item.icon as string;
}

export function AppsDockConfigItem({ index, item }: Props) {
  const ctx = useContext();

  const handleOnChange = (isChecked: boolean) => {
    // TODO : AppsDock API 생성 후 수정
    ctx.setUserApps(
      ctx.userApps.map((item, idx) => {
        if (idx === index)
          return {
            ...ctx.userApps[index],
            disabled: !isChecked,
          } as IUserMenuInfo;
        else return item;
      }),
    );
  };

  return (
    <DnDList.Item
      item={item}
      key={index}
      canDrag={!item.isDefault}
      droppable={!item.isDefault}
    >
      <li className="dop_drag_list" id={`${item.uid}`}>
        <div className="menu_name">
          <div className="btn_icon medium text-[#aaaaaa]">
            {item.isDefault ? (
              <DiabledHamburgerMenuIcon className="ic_medium" />
            ) : (
              <HamburgerMenuIcon className="ic_medium" />
            )}
          </div>
          <i
            className="icon ic_medium"
            dangerouslySetInnerHTML={{ __html: getAppIcon(item) }}
          />
          <span className="txt">{item.name}</span>
        </div>
        <div className="dop_switch">
          <div className="toggle">
            <input
              type="checkbox"
              id="toggle_home"
              checked={!ctx.userApps[index].disabled}
              onChange={(e) => handleOnChange(e.target.checked)}
            />
            <label htmlFor="toggle_home">ON</label>
          </div>
        </div>
      </li>
    </DnDList.Item>
  );
}

export default AppsDockConfigItem;
