import { ColumnDef } from '@tanstack/react-table';
import { CustomProfileInfo } from '../../type';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { PlusIcon, TrashCanIcon } from '@dop-ui/icons/react/dop/24';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomProfile } from '../../apis/delete-custom-profile';
import * as getCustomProfileList from '../../apis/get-custom-profile-list';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useProfileManagementContext } from '../../context';
import * as getProfileCardConfig from '../../apis/get-profile-card-config';
import { clsx } from 'clsx';
import { formatDateString } from '../../utils';

export function ProfileTable() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();
  const { confirm } = useMessageDialog();
  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const {
    profileManagementContext: { customProfileList },
    setProfileManagementContext,
  } = useProfileManagementContext();

  const days = {
    monday: t('days.monday'),
    tuesday: t('days.tuesday'),
    wednesday: t('days.wednesday'),
    thursday: t('days.thursday'),
    friday: t('days.friday'),
    saturday: t('days.saturday'),
    sunday: t('days.sunday'),
  };

  const columnDefs: ColumnDef<CustomProfileInfo>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.name',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'createdAt',
      accessorFn: (info) => formatDateString(days, info.createdAt),
      header: () => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.createdAt',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'activate',
      accessorFn: (info) =>
        info.activate
          ? t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.isUsed.yes',
            )
          : t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.isUsed.no',
            ),
      header: () => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.isUsed',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'manage',
      accessorFn: (info) => info.id,
      header: () => (
        <div className="flex items-center py-3 text-left px-[8px]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.management',
          )}
        </div>
      ),
      cell: (cell) => (
        <Button
          className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded bg-transparent"
          onClick={(e) => void handleClickDelete(cell.getValue() as number, e)}
        >
          <TrashCanIcon size={24} />
          {t('globalconfig.common.delete')}
        </Button>
      ),
    },
  ];

  const deleteCustomProfileMutation = useMutation({
    mutationFn: async (profileId: number) =>
      await deleteCustomProfile(profileId),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getCustomProfileList.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.deleted'));
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  const handleClickDelete = async (
    profileId: number,
    e?: React.MouseEvent<HTMLElement>,
  ) => {
    e?.stopPropagation();
    if (
      await confirm(
        <div>
          <h2 className="pb-[8px]">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete.title',
            )}
          </h2>
          <p>
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete.description1',
            )}
          </p>
          <p>
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete.description2',
            )}
          </p>
        </div>,
      )
    ) {
      deleteCustomProfileMutation.mutate(profileId);
    }
  };

  const handleClickRow = (index: number) => {
    setProfileManagementContext((prev) => ({
      ...prev,
      profileId: customProfileList[index]!.id,
    }));
    stacker.push('customProfileDetail');
  };

  const handleClickAdd = () => {
    if (customProfileList.length >= 5) {
      toaster.info(
        t(
          'globalconfig.basicManagement.profileManagement.customProfileItemManagement.maximum',
        ),
      );
      return;
    }
    setProfileManagementContext((prev) => ({
      ...prev,
      profileId: null,
    }));
    stacker.push('customProfileDetail');
  };

  return (
    <TablePrimitives.Root<CustomProfileInfo>
      className="flex flex-col mt-[24px] gap-[16px]"
      columnDefs={columnDefs}
      contents={customProfileList ?? []}
      onClickRow={handleClickRow}
    >
      <div className="flex justify-end items-center">
        <Button
          className={clsx(
            'flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded',
            {
              'text-[#a5a5a5]': customProfileList.length >= 5,
              'hover:bg-[#f2f2f2]': customProfileList.length < 5,
            },
          )}
          onClick={handleClickAdd}
        >
          <PlusIcon size={24} />
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.add',
          )}
        </Button>
      </div>
      <TablePrimitives.Contents
        emptyNotice={
          <div className="w-full h-[80px] flex items-center justify-center ">
            <span className="text-[--color-text-level3] text-[14px] font-[400]">
              {t(
                'globalconfig.basicManagement.profileManagement.customProfileItemManagement.empty',
              )}
            </span>
          </div>
        }
      ></TablePrimitives.Contents>
      <p className="flex justify-end text-[#888]">
        {t(
          'globalconfig.basicManagement.profileManagement.customProfileItemManagement.maximum',
        )}
      </p>
    </TablePrimitives.Root>
  );
}

export default ProfileTable;
