import { CalendarIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import { enUS, ja, ko, vi, zhCN, zhTW } from 'date-fns/locale';
import { forwardRef, PropsWithChildren, useCallback, useEffect } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerPrimitive } from './primitives';
import { CustomInputInfo } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import { isAfter } from '../../lib/date';
import './date-picker.css';

export interface Props {
  locale?: string;
  className?: string;
  dateFormat?: string;
  showYearPicker?: boolean;
  showTimeInput?: boolean;
  defaultDate?: Date | null;
  minTime?: Date;
  maxTime?: Date;
  minDate?: Date;
  maxDate?: Date;
  placeholderText?: string;
  withBorder?: boolean;
  disabled?: boolean;
  onDateSelect?: (date: Date | null) => void;
}

/**
 * DatePicker 컴포넌트
 * @returns
 */
export function DatePicker({
  locale = 'ko',
  className,
  dateFormat = 'yyyy-MM-dd',
  showYearPicker = false,
  showTimeInput = false,
  defaultDate: selectedDate = new Date(),
  minTime = new Date(),
  maxTime = new Date(new Date().setHours(23, 59, 59, 999)),
  minDate,
  maxDate,
  placeholderText,
  withBorder,
  disabled,
  onDateSelect,
  children,
}: PropsWithChildren<Props>) {
  const CustomInput = forwardRef<HTMLButtonElement, CustomInputInfo>(
    ({ onClick }, ref) => (
      <button ref={ref} onClick={onClick} className="w-full">
        {children}
      </button>
    ),
  );
  CustomInput.displayName = 'CustomInput';

  const onChangeHandler = (date: Date | null) => {
    onDateSelect && onDateSelect(date);
  };

  const registerLocaleHandler = () => {
    if (locale === 'ko') {
      registerLocale(locale, ko);
    } else if (locale === 'en') {
      registerLocale(locale, enUS);
    } else if (locale === 'ja') {
      registerLocale(locale, ja);
    } else if (locale === 'zh-CN') {
      registerLocale(locale, zhCN);
    } else if (locale === 'zh-TW') {
      registerLocale(locale, zhTW);
    } else if (locale === 'vi') {
      registerLocale(locale, vi);
    }
  };

  useEffect(() => {
    if (minDate && selectedDate) {
      if (
        isAfter({
          date: minDate,
          compareDate: selectedDate,
          unit: 'second',
        })
      ) {
        onDateSelect && onDateSelect(minDate);
      } else {
        onDateSelect && onDateSelect(selectedDate);
      }
    }
  }, [minDate, selectedDate]);

  registerLocaleHandler();

  const handelTouchStart = useCallback((e: Event) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
  }, []);

  const handleCalendarOpen = () => {
    document.addEventListener('touchstart', handelTouchStart, true);
  };

  const handleCalendarClose = () => {
    document.removeEventListener('touchstart', handelTouchStart, true);
  };

  const getMinTime = (date?: Date | null) => {
    if (!(date instanceof Date)) return minTime;

    return isToday(date) ? minTime : new Date(date.setHours(0, 0, 0, 0));
  };

  const isToday = (date?: Date) => {
    return (
      date instanceof Date && new Date().toDateString() === date.toDateString()
    );
  };

  return children ? (
    <ReactDatePicker
      dateFormat={dateFormat}
      showYearPicker={showYearPicker}
      showTimeSelect={showTimeInput}
      minTime={getMinTime(selectedDate)}
      maxTime={maxTime}
      placeholderText={placeholderText}
      shouldCloseOnSelect
      locale={locale}
      className={clsx('bg-white', className)}
      selected={selectedDate}
      onChange={onChangeHandler}
      minDate={minDate}
      maxDate={maxDate}
      customInput={<CustomInput />}
      disabled={disabled}
      onCalendarOpen={handleCalendarOpen}
      onCalendarClose={handleCalendarClose}
      renderCustomHeader={
        showYearPicker
          ? undefined
          : ({
              date: selectedDate,
              changeYear,
              changeMonth,
              increaseMonth,
              decreaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <DatePickerPrimitive.Header
                date={selectedDate}
                changeMonth={changeMonth}
                changeYear={changeYear}
                increaseMonth={increaseMonth}
                decreaseMonth={decreaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )
      }
    />
  ) : (
    <div
      className={clsx('w- flex items-center gap-1 py-1 ps-2', {
        'border rounded-md': withBorder,
      })}
    >
      <CalendarIcon className="w-4 h-4" />
      <ReactDatePicker
        dateFormat={dateFormat}
        showYearPicker={showYearPicker}
        showTimeSelect={showTimeInput}
        minTime={getMinTime(selectedDate)}
        maxTime={maxTime}
        placeholderText={placeholderText}
        shouldCloseOnSelect
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
        className={clsx('', className)}
        selected={selectedDate}
        onChange={onChangeHandler}
        renderCustomHeader={
          showYearPicker
            ? undefined
            : ({
                date,
                changeYear,
                changeMonth,
                increaseMonth,
                decreaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <DatePickerPrimitive.Header
                  date={date}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  decreaseMonth={decreaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )
        }
      />
    </div>
  );
}

export default DatePicker;
