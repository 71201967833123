import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { flushSync } from 'react-dom';
import { useDndContext } from './provider';
import { Edge } from '../../../lib/dnd/types';
import {
  BaseEventPayload,
  ElementDragType,
  MonitorArgs,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';

export interface DropParams {
  startIndex: number;
  targetIndex: number;
  edge: Edge | null;
  sourceData: Record<string, unknown>;
  targetData: Record<string, unknown>;
}

export interface Props<T> {
  list: T[];
  setList: Dispatch<SetStateAction<T[]>>;
  onDrop?: (data: DropParams) => void;
  onDropTargetChange?: (data: DropParams) => void;
}

export function Container<T>({
  list,
  setList,
  onDrop,
  onDropTargetChange,
  children,
}: PropsWithChildren<Props<T>>) {
  const { dataValidation, name, idKey } = useDndContext();
  const [previousEdge, setPreviousEdge] = useState<Edge | null>(null);

  useEffect(() => {
    let handleOnDrop;
    let handleOnDrag;

    handleOnDrop = ({
      location,
      source,
    }: BaseEventPayload<ElementDragType>) => {
      const target = location.current.dropTargets[0];

      if (!target) {
        return;
      }

      const sourceData = source.data;
      const targetData = target.data;

      if (
        !dataValidation(sourceData, name) ||
        !dataValidation(targetData, name)
      ) {
        return;
      }

      const indexOfSource = list.findIndex(
        (item: T) => item[idKey as keyof T] === sourceData[idKey],
      );
      const indexOfTarget = list.findIndex(
        (item: T) => item[idKey as keyof T] === targetData[idKey],
      );

      // 예외 있는지 확인 필요
      // if (indexOfTarget < 0 || indexOfSource < 0) {
      //   return;
      // }

      const closestEdgeOfTarget = extractClosestEdge(targetData);

      if (onDrop) {
        onDrop({
          startIndex: indexOfSource,
          targetIndex: indexOfTarget,
          edge: closestEdgeOfTarget,
          sourceData: sourceData,
          targetData: targetData,
        });
      } else {
        flushSync(() => {
          setList(
            reorderWithEdge({
              list: list,
              startIndex: indexOfSource,
              indexOfTarget,
              closestEdgeOfTarget,
              axis: 'vertical',
            }),
          );
        });
      }

      const element = document.querySelector(
        `[data-id="${name}${sourceData.id}"]`,
      );
      if (element instanceof HTMLElement) {
        triggerPostMoveFlash(element);
      }
    };

    onDropTargetChange &&
      (handleOnDrag = ({
        location,
        source,
      }: BaseEventPayload<ElementDragType>) => {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;
        if (
          !dataValidation(sourceData, name) ||
          !dataValidation(targetData, name)
        ) {
          return;
        }

        const indexOfSource = list.findIndex(
          (item: T) => item[idKey as keyof T] === sourceData[idKey],
        );
        const indexOfTarget = list.findIndex(
          (item: T) => item[idKey as keyof T] === targetData[idKey],
        );

        const closestEdgeOfTarget = extractClosestEdge(targetData);
        setPreviousEdge(closestEdgeOfTarget);

        if (
          closestEdgeOfTarget !== previousEdge ||
          sourceData[idKey] !== targetData[idKey]
        ) {
          if (onDropTargetChange) {
            onDropTargetChange({
              startIndex: indexOfSource,
              targetIndex: indexOfTarget,
              edge: closestEdgeOfTarget,
              sourceData: sourceData,
              targetData: targetData,
            });
          }
        }
      });

    const monitorElements: MonitorArgs<ElementDragType> = {
      canMonitor({ source }) {
        return dataValidation(source.data, name);
      },
      onDrop: handleOnDrop,
      onDrag: handleOnDrag,
    };

    return monitorForElements(monitorElements);
  }, [list, setList, dataValidation, name, idKey]);

  return <>{children}</>;
}

export default Container;
