import {
  Switch as DopSwitch,
  SwitchProps,
} from '@dop-ui/react/shared/ui/switch';

/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export type Props = SwitchProps;
/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export const Switch = DopSwitch;
/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export default Switch;
