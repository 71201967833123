import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  getPerPortalDataUsage,
  PER_PORTAL_DATA_USAGE_KEY,
} from './apis/getPerPortalDataUsage';

interface PortalDataUsageInfo {
  name: string;
  percent: number;
}

interface PortalLegendInfo {
  percent: number;
  color: string;
  name: string;
}

export function UsagePerPortal() {
  const { data, error } = useQuery({
    queryKey: [PER_PORTAL_DATA_USAGE_KEY],
    queryFn: getPerPortalDataUsage,
  });

  const { t } = useTranslation('component');

  if (error) {
    console.error(`Error: GlobalConfig > UsagePerPortal : ${error.message}`);
  }

  const optionMaker = (
    colorCodes: string[],
    optionData: PortalDataUsageInfo[],
  ): echarts.EChartsOption => ({
    series: [
      {
        type: 'pie',
        radius: ['55%', '90%'],
        label: { show: false },
        labelLine: { show: false },
        itemStyle: {
          borderColor: '#ffffff',
          borderWidth: 1,
        },
        clockwise: false,
        color: [...colorCodes],
        data: optionData.map((datum) => ({
          name: datum.name,
          value: datum.percent,
        })),
      },
    ],
  });

  const [legendData, setLegendData] = useState<PortalLegendInfo[]>([]);

  const chartRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<echarts.EChartsOption>(
    optionMaker([], []),
  );

  useEffect(() => {
    if (chartRef.current) {
      echarts.dispose(chartRef.current);
      const chart = echarts.init(chartRef.current);
      chart.setOption(options);
    }
  }, [chartRef, options]);

  useEffect(() => {
    if (data) {
      const colorCodes = ['#75ABFF', '#32DCE8', '#6EE488', '#EAECF2'];
      const total = data.companyQuota;
      const usageData: PortalDataUsageInfo[] = [
        {
          name: t('service.employeePortal.name'),
          percent: (data.employeeUsedQuota / total) * 100,
        },
        {
          name: t('service.businessPortal.name'),
          percent: (data.businessUsedQuota / total) * 100,
        },
        {
          name: t('globalconfig.dataManagement.currentUsage.common'),
          percent: (data.commonUsedQuota / total) * 100,
        },
      ];

      const legends = usageData
        .sort((a, b) => b.percent - a.percent)
        .map(
          (usageInfo, index): PortalLegendInfo => ({
            name: usageInfo.name,
            percent: usageInfo.percent,
            color: colorCodes[index] ?? '#FEFEFE',
          }),
        );
      setLegendData(legends);
      setOptions(
        optionMaker(colorCodes, [
          ...usageData,
          {
            name: 'etc',
            percent:
              ((data.companyQuota -
                (data.businessUsedQuota +
                  data.employeeUsedQuota +
                  data.commonUsedQuota)) /
                data.companyQuota) *
              100,
          },
        ]),
      );
    }
  }, [data, t]);

  const usingStoragePercentage =
    (data &&
      (
        ((data.businessUsedQuota +
          data.employeeUsedQuota +
          data.commonUsedQuota) /
          data.companyQuota) *
        100
      ).toFixed(1)) ??
    '0';

  return (
    <div className="flex-1 relative p-[40px] border border-solid border-[#E6E7EA] rounded-[8px] text-[#333333]">
      <p className="text-[18px] font-[600]">
        {t('globalconfig.dataManagement.currentUsage.perPortalUsage')}
      </p>
      <div className="flex gap-[40px] items-center">
        <div className="relative mt-[30px] mb-[10px] ml-[10px] size-[200px]">
          <div className="size-full" ref={chartRef} />
          <p className="absolute w-[100px] h-[40px] top-[80px] left-[50px] text-center text-[#333333] text-[24px] font-[600]">
            {usingStoragePercentage}
            <span className="text-[#333333] text-[24px] font-[500]">%</span>
          </p>
        </div>
        <div className="relative flex flex-col h-full w-[200px] m-w-[100px] justify-center gap-[16px]">
          {legendData.map((legend) => (
            <Legend
              key={legend.name}
              title={legend.name}
              percentage={legend.percent}
              color={legend.color}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Legend({
  className,
  color,
  title,
  percentage,
}: {
  className?: string;
  color: string;
  title: string;
  percentage: number;
}) {
  return (
    <div className={clsx(className, 'flex items-center py-[2px] pr-[4px]')}>
      <div
        className="size-[8px] rounded-[4px]"
        style={{ backgroundColor: color }}
      ></div>
      <span className="ml-[8px] font-[400] text-[14px] text-[#333333]">
        {title}
      </span>
      <span className="absolute right-0 ml-[8px] font-[400] text-[14px] text-[#333333]">
        {percentage.toFixed(1)}%
      </span>
    </div>
  );
}
