import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from 'react';
import { useValuesContext } from '../provider';

export interface Props {
  title: string;
  withAddButton?: boolean;
  permitStatus?: boolean;
  onPermitChange?: (newState: boolean) => void;
  onClickAddButton?: () => void;
}

/**
 * @description SelectConfig : 대상 사이트, 대상 클래스에 대한 알맞은 필드를 보여주는 컴포넌트
 */
export function SelectConfig({
  title,
  withAddButton = false,
  permitStatus,
  onPermitChange,
  onClickAddButton,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('component');
  const { selectedAppCode } = useValuesContext();

  const getLabel = () => {
    if (selectedAppCode === 'dop-board') {
      return t(
        'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.permitOption.board',
      );
    } else {
      return t(
        'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.permitOption.calendar',
      );
    }
  };

  return (
    <>
      <span className="mt-[12px] text-[#999] text-[13px] font-medium leading-[13px] tracking-[-0.52px]">
        {title}
      </span>
      <div className="flex w-full grow gap-2 items-center">
        {children}
        {withAddButton && (
          <>
            {permitStatus !== undefined && (
              <div className="dop_checkbox flex items-center justify-center">
                <input
                  type="checkbox"
                  id={`select-${title}`}
                  defaultChecked={permitStatus}
                  onChange={(e) =>
                    onPermitChange && onPermitChange(e.target.checked)
                  }
                />
                <label className="dop_checkbox" htmlFor={`select-${title}`}>
                  {getLabel()}
                </label>
              </div>
            )}
            <Button
              shape="rect"
              size="small"
              variant="outline"
              colorset="level1"
              className="!h-[32px]"
              onClick={onClickAddButton}
            >
              <PlusIcon className="size-4 stroke-[#363636]" />
              <span className="text-[#363636] text-[13px] font-medium leading-[13px] tracking-[-0.52px]">
                {t(
                  'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.addButton.title',
                )}
              </span>
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default SelectConfig;
