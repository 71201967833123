import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import type { IPopupAnnouncementForm, Week } from '../types';
import { POPUP_DICTIONARY } from '../constants';
import { getSortedDayOfWeeks } from '../utils';

export function useGeneratePeriodPreview(form: IPopupAnnouncementForm) {
  const { t } = useTranslation('component');

  if (form.noticePeriodType === 'SPECIFIC_DATE') return '';

  const {
    noticePeriodType,
    dayOfWeeks,
    repeatValue,
    targetWeek,
    targetDay,
    targetDayOfWeek,
  } = form;

  const getDayOfWeek = (weeks: Week[]) => {
    const sortedDayOfWeeks = getSortedDayOfWeeks(weeks);
    const dayOfWeeks = sortedDayOfWeeks.map(
      (week) =>
        `${t(`${POPUP_DICTIONARY}.${week}`)}${t(`${POPUP_DICTIONARY}.dayOfWeek`)}`,
    );
    return dayOfWeeks.join(', ');
  };

  switch (noticePeriodType) {
    case 'DAILY_REPEAT':
      return repeatValue === 1
        ? `${t(`${POPUP_DICTIONARY}.everyDay`)} ${t(`${POPUP_DICTIONARY}.repeat`)}`
        : `${repeatValue}${t(`${POPUP_DICTIONARY}.perDays`)} ${t(`${POPUP_DICTIONARY}.repeat`)}`;
    case 'WEEKLY_REPEAT':
      return `${repeatValue}${t(`${POPUP_DICTIONARY}.perWeeks`)} ${getDayOfWeek(dayOfWeeks ?? [])}`;
    case 'MONTHLY_DAY_REPEAT':
      return `${repeatValue}${t(`${POPUP_DICTIONARY}.perMonths`)} ${targetDay}${t(`${POPUP_DICTIONARY}.day`)}`;
    case 'MONTHLY_WEEKDAY_REPEAT':
      return `${repeatValue}${t(`${POPUP_DICTIONARY}.perMonths`)} ${targetWeek}${t(`${POPUP_DICTIONARY}.nth`)} ${t(`${POPUP_DICTIONARY}.${targetDayOfWeek}`)}${t(`${POPUP_DICTIONARY}.dayOfWeek`)}`;
  }
}
