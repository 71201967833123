import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { CustomItems } from './custom-items';
import { useProfileManagementContext } from '../../../context';
import { isCustomProfileNeedsAtom } from '../../../store/is-custom-profile-needs-save-atom';
import { useAtom } from 'jotai';
import { SaveConfirmDialog } from '../../../../../../parts/components/save-confirm-dialog';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { ArrowLeftIcon } from '@dop-ui/icons/react/dop/24';

export function CustomProfileDetail() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();
  const { confirm } = useMessageDialog();

  const { setProfileManagementContext } = useProfileManagementContext();
  const [isCustomProfileNeedsSave, setIsCustomProfileNeedsSave] = useAtom(
    isCustomProfileNeedsAtom,
  );

  const initializeCustomDetail = () => {
    setProfileManagementContext((prev) => ({
      ...prev,
      profileId: null,
    }));
    stacker.pop();
  };

  const onClickBackButton = async () => {
    if (isCustomProfileNeedsSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        setIsCustomProfileNeedsSave(false);
        initializeCustomDetail();
      }
    } else {
      initializeCustomDetail();
    }
  };

  return (
    <div className="flex flex-col flex-wrap gap-[32px] p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex flex-col gap-[8px]">
        <div className="flex items-center">
          <Button
            className="!size-[24px] mr-[8px]"
            size="none"
            onClick={() => void onClickBackButton()}
          >
            <ArrowLeftIcon size={24} />
          </Button>
          <p className="text-[#333] text-[18px] font-medium -tracking-[1.2px]">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement',
            )}
          </p>
        </div>
        <p className="py-[4px] text-[13px] text-[#888]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.description',
          )}
        </p>
      </div>
      <CustomItems />
    </div>
  );
}

export default CustomProfileDetail;
