export interface ProfileConfigInfo {
  editedProfile: boolean;
  profileCardItemDisplayDtos: ProfileCardItemDisplayDto[];
}

export interface ProfileCardItemDisplayDto {
  id: number;
  name: string;
  change: boolean;
  custom: boolean;
  display: boolean;
}

export interface EditePersonalInfo {
  editedProfile: boolean;
}

export interface CustomProfileInfo {
  id: number;
  name: string;
  createdAt: string;
  activate: boolean;
  type: string;
}

export interface CustomProfileDetailInfo {
  id?: number;
  activate: boolean;
  multiLanguageValueMap: MultiLanguageValueMap;
  autoSort: boolean;
  customProfileCardItemType: string;
}

export interface MultiLanguageValueMap {
  KOREAN: string;
  ENGLISH?: string;
  JAPANESE?: string;
  CHINESE_SIMPLIFIED?: string;
  CHINESE_TRADITIONAL?: string;
  VIETNAMESE?: string;
}

export type Direction = 'asc' | 'desc';

export type CustomInputName =
  | 'KOREAN'
  | 'ENGLISH'
  | 'JAPANESE'
  | 'CHINESE_SIMPLIFIED'
  | 'CHINESE_TRADITIONAL'
  | 'VIETNAMESE';

export const defaultCustomProfileDetail = {
  activate: true,
  multiLanguageValueMap: {
    KOREAN: '',
    ENGLISH: '',
    JAPANESE: '',
    CHINESE_SIMPLIFIED: '',
    CHINESE_TRADITIONAL: '',
    VIETNAMESE: '',
  },
  autoSort: false,
  customProfileCardItemType: 'TEXT',
};
