import OrgChart from '../../../../../../../components/OrgChart';
import { NodeProps } from '../../../../../../../components/OrgChart/interface';
import { useContext } from '../Provider';
import { AppContents } from './AppContents';
import { DefaultContents } from './DefaultContents';

export function Contents() {
  const { selectedAppType, setDeptName, setDeptId } = useContext();

  const nodeClickHandler = (node: NodeProps) => {
    if (node.memberType === 'UNSPECIFIED_MEMBER') {
      setDeptName('');
      setDeptId(-1);
    } else {
      setDeptName(
        node.nodeType === 'DEPARTMENT'
          ? node.name
          : node?.departmentName ?? node.name,
      );
      node?.departmentId && setDeptId(node.departmentId);
    }
  };

  return (
    <>
      <OrgChart
        useFooter={false}
        useHeader={false}
        style={{
          width: '264px',
          overflowY: 'auto',
          maxHeight: '600px',
          zIndex: 0,
          boxShadow: 'none',
          position: 'inherit',
          scrollBehavior: 'auto',
        }}
        onNodeClick={nodeClickHandler}
        useMultiSelect={false}
      />
      <div className="border-l border-solid px-2" />
      {selectedAppType ? <AppContents /> : <DefaultContents />}
    </>
  );
}

export default Contents;
