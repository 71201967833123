'use client';
import * as Jotai from 'jotai';
import { usePathname } from 'next/navigation';
import { PropsWithChildren, useEffect } from 'react';
import { appInfoAtom, loadStateAtom, useLockState } from '../model/atoms';
import { SimpleCodeFormClient } from './simple-code-form-client';

/**
 * @component AppScreenLock
 * 간편비밀번호 전체 로직이 포함되어 있는 ScreenLock.
 */
export function AppScreenLock({ children }: PropsWithChildren) {
  const [isLocked, setLockState] = useLockState();
  const [appInfo] = Jotai.useAtom(appInfoAtom);
  const [isLoaded, setLoadState] = Jotai.useAtom(loadStateAtom); // page 방식 사용할 경우, 해당 atom 제거
  const pathname = usePathname();

  useEffect(() => {
    if (appInfo.appUrl !== pathname) {
      setLockState(false);
      setLoadState(true); // page 방식 사용할 경우, 해당 로직 제거
    }
  }, [pathname, appInfo.appUrl, setLockState, setLoadState]);

  // 로딩 중일 경우, 로드 화면을 노출함.
  if (!isLoaded) {
    return <div className="h-full flex justify-start">App Loading...</div>;
    // 잠금 상태일 경우, 잠금 화면을 노출함.
  } else if (isLocked) {
    return (
      <div className="flex size-full justify-center main">
        <SimpleCodeFormClient
          title={appInfo.appName}
          appCode={appInfo.appCode}
          type="web"
          onSuccess={() => setLockState(false)}
        />
      </div>
    );
    // 잠금 상태와 로딩 중이 아닐 경우, 앱을 노출함.
  } else {
    return <>{children}</>;
  }

  /**
   * @description 그룹웨어 로드 안 될 경우를 대비한, Page 이동 로직 (window.location.href를 사용해 Reload 발생하는 문제가 있음.)
   */
  // useEffect(() => {
  //   if (appInfo.appUrl !== pathname) {
  //     setLockState(false);
  //   }
  // }, [pathname, appInfo.appUrl, setLockState]);

  // if (isLocked && appInfo.appUrl) {
  //   // if (appInfo.appName && appInfo.appCode && appInfo.appUrl) {
  //   //   const queryString: Record<string, string> = {
  //   //     appName: appInfo.appName,
  //   //     appCode: appInfo.appCode,
  //   //     url: appInfo.appUrl,
  //   //   };
  //   //   window.location.href = `/simple-code?${(new URLSearchParams(queryString)).toString()}`;
  //   // }
  // } else {
  //   return <>{children}</>;
  // }
}

export default AppScreenLock;
