import { clsx } from 'clsx';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { InUseAppInfo } from '../../../types';

export function AppItem({
  appInfo,
  selected,
  onClick,
}: {
  appInfo: InUseAppInfo;
  selected?: boolean;
  onClick: (id: number) => void;
}) {
  const icon = appInfo.icon ? (
    <i
      className="size-[20px]"
      dangerouslySetInnerHTML={{
        __html: appInfo.icon.replace(
          'width="24" height="24"',
          'width="20" height="20"',
        ),
      }}
    />
  ) : (
    <NoSymbolIcon className="size-[20px]" />
  );

  return (
    <button
      className={clsx(
        'flex items-center h-[32px] w-[236px] pl-[8px] rounded-[8px] hover:bg-[#F2F2F2] flex-shrink-0',
        {
          'bg-[#F2F2F2]': selected,
        },
      )}
      onClick={() => onClick(appInfo.id)}
    >
      {icon}
      <p className="pl-[8px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[500]">
        {appInfo.name}
      </p>
    </button>
  );
}
