import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { TrashCanIcon } from '@dop-ui/icons/react/dop/24/TrashCan';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ScheduleInfo } from '../../types';
import { deleteScheduleDialogOpenAtom } from '../../store/dialog_open_atom';
import { Content } from './content';

interface Props {
  scheduleInfoList: ScheduleInfo[];
}

export function DeleteScheduleDialog({ scheduleInfoList }: Props) {
  const { t } = useTranslation('component');
  const [isOpen, setIsOpen] = useAtom(deleteScheduleDialogOpenAtom);
  const disabled = scheduleInfoList.length === 0;

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger disabled={disabled}>
        <div
          className={clsx('h-[40px] p-[4px] flex items-center', {
            'text-[#363636] cursor-pointer': !disabled,
            'text-[#C4C4C4]': disabled,
          })}
        >
          <TrashCanIcon className="mt-[4px] size-[22px]" />
          <p className="ml-[4px] //text-[--color-text-level1] text-[14px] font-[400]">
            {t('globalconfig.basicManagement.companySchedule.schedule.delete')}
          </p>
        </div>
      </Dialog.Trigger>
      <Dialog.Primitives.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="w-[500px] bg-white px-[24px] py-[24px] rounded-[16px]"
          showCloseButton={false}
          useAutoClose={false}
        >
          <Content scheduleInfoList={scheduleInfoList} />
        </Dialog.Content>
      </Dialog.Primitives.Portal>
    </Dialog.Root>
  );
}
