import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { APP_APPROVAL_SHARE_URL } from '../constants';

export const MUTATION_KEY = 'enableApprovalBetweenSitesMutation';

export async function enableApprovalBetweenSitesStatus(
  companyGroupId: number | string,
  useCompanyGroupApproval: boolean,
) {
  const res = await fetch(APP_APPROVAL_SHARE_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({
      companyGroupId,
      useCompanyGroupApproval,
    }),
  });

  if (!res.ok) {
    throw new Error('Failed to enable approval between sites.');
  }
}
