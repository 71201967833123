import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid';
import { CellContext } from '@tanstack/react-table';

export type Direction = 'asc' | 'desc';

export function columnDefGenerator<T>(
  key: string,
  title: string,
  filter?: string,
  selectedFilterValue?: string,
  onChangeFilter?: (Direction: Direction, fieldName: string) => void,
  direction?: Direction,
) {
  return {
    accessorKey: key,
    id: key,
    header: () => (
      <div className="flex items-center justify-center py-3 text-center gap-1">
        {title}
        {filter && (
          <SortChevron
            filterValue={filter}
            selectedValue={selectedFilterValue}
            direction={direction ?? 'asc'}
            onChange={onChangeFilter}
          />
        )}
      </div>
    ),
    cell: (value: CellContext<T, unknown>) => {
      const valueString = (value?.renderValue() ?? '-') as string;
      return (
        <div className="py-3 text-center overflow-hidden whitespace-nowrap text-elipsis">
          {valueString === '' ? '-' : valueString}
        </div>
      );
    },
  };
}

export function SortChevron({
  selectedValue,
  filterValue,
  direction,
  onChange,
}: {
  selectedValue?: string;
  filterValue: string;
  direction: Direction;
  onChange?: (direction: Direction, fieldName: string) => void;
}) {
  if (selectedValue === filterValue) {
    if (direction === 'asc')
      return (
        <ChevronUpIcon
          className="inline ml-[8px] size-[16px]"
          onClick={() => onChange && onChange('desc', filterValue)}
        />
      );
    else
      return (
        <ChevronDownIcon
          className="inline ml-[8px] size-[16px]"
          onClick={() => onChange && onChange('asc', filterValue)}
        />
      );
  } else {
    return (
      <ChevronUpDownIcon
        className="inline ml-[8px] size-[16px]"
        color="#AAAAAA"
        onClick={() => onChange && onChange('asc', filterValue)}
      />
    );
  }
}

export default SortChevron;
