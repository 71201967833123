'use client';

import OrgChart from '@daouoffice/next/components/OrgChart';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { OrgChartIcon } from '@dop-ui/icons/react/dop/24/OrgChart';
import { FuncButton } from '../FuncButton';

export function OrgChartButton() {
  const { t } = useTranslation();

  return (
    <Dialog.Root modal={false}>
      <Dialog.Trigger>
        <FuncButton icon={OrgChartIcon} label={t('조직도')} />
      </Dialog.Trigger>
      <Dialog.Content size="resizeable">
        <OrgChart
          useHeader={false}
          useFooter={false}
          useMultiSelect={false}
          useResize={true}
          style={{
            top: '160px',
            left: '81px',
            bottom: '16px',
            borderRadius: '16px',
            minWidth: '360px',
            maxWidth: '720px',
          }}
        ></OrgChart>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default OrgChartButton;
