import { fetch } from '../../../../../../lib/fetch/client';
import { UPLOAD_FILE_POLICY_API } from '../../constants';
import { BaseResponse } from '../../types';
import { Policy } from '../types';

export const QUERY_KEY =
  'QueryKeys.GloablConfig.DataManagement.UploadPolicyManagement';

export async function getUploadPolicy() {
  const response = await fetch(UPLOAD_FILE_POLICY_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData = (await response.json()) as BaseResponse<Policy>;

  return responseData.data;
}

export async function setUploadPolicy(policy: Policy) {
  const response = await fetch(UPLOAD_FILE_POLICY_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(policy),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponse<undefined>;
    throw new Error(responseData.message);
  }
}
