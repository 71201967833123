import { Switch } from '@daouoffice/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePersonalInfo } from '../../apis/update-personal-info';
import * as getProfileCardConfig from '../../apis/get-profile-card-config';
import { useToastMessage } from '@dop-ui/react/features/toast-message';

interface Props {
  editedProfile: boolean;
}

export function AllowPersonalInfoEdit({ editedProfile }: Props) {
  const { t } = useTranslation('component');
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [isProfileEditAllowed, setIsProfileEditAllowed] = useState(false);

  useEffect(() => {
    setIsProfileEditAllowed(editedProfile);
  }, [editedProfile]);

  const updatePersonalInfoMutation = useMutation({
    mutationFn: async () =>
      await updatePersonalInfo({
        editedProfile: !isProfileEditAllowed,
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  const onSwitchChange = () => {
    updatePersonalInfoMutation.mutate();
  };

  return (
    <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex justify-between items-center">
        <div className="flex flex-col justify-center gap-[8px]">
          <p className="text-[#333333] text-[22px] font-[500]">
            {t(
              'globalconfig.basicManagement.profileManagement.isProfileEditAllowed',
            )}
          </p>
          <p className="text-[#AAA] font-[500]">
            {t(
              'globalconfig.basicManagement.profileManagement.isProfileEditAllowed.description',
            )}
          </p>
        </div>
        <Switch
          id="profile.management.switch"
          checked={isProfileEditAllowed}
          onChange={onSwitchChange}
        />
      </div>
    </div>
  );
}

export default AllowPersonalInfoEdit;
