import { MemoColors } from './types';

export const defaultTimeFormat = 'YYYY-MM-DD';
export const favoriteFalseColor = 'text-[#FFF]';
export const favoriteTrueColor = 'text-[#FFD940]';

export const MemoColorClassNames: { [k in MemoColors]: string } = {
  yellow: 'bg-[#FFF9B7]',
  blue: 'bg-[#D7EEFF]',
  mint: 'bg-[#EDFBC6]',
  skyblue: 'bg-[#D5F2F5]',
  purple: 'bg-[#EEE5FF]',
};
