export interface Props {
  title: string;
  contents: string;
}

export function ConfirmDialog({ title, contents }: Props) {
  return (
    <div className="size-full px-[24px]">
      <p className="text-[#1A1A1A] text-[20px] font-[500]">{title}</p>
      <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
        {contents}
      </p>
    </div>
  );
}

export default ConfirmDialog;
