import { usePopupAnnouncementContext } from './context';
import { useAtom } from 'jotai';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';

import PopupAnnouncementForm from './popup-announcement-form';
import PopupAnnouncementList from './popup-announcement-list';
import { ErrorBoundary } from '@dop-ui/react/shared/ui/error-boundary';
import { DefaultErrorTemplate } from '@dop-ui/react/widgets/error-templates';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import { SaveConfirmDialog } from '../../../parts/components/save-confirm-dialog';
import { ArrowLeftIcon } from '@dop-ui/icons/react/dop/24';
import { IconButton } from '@dop-ui/react/shared/ui/button';

import { POPUP_DICTIONARY } from './constants';
import styles from './popup-announcement.module.css';

function PopupAnnouncementContents() {
  const { t } = useTranslation('component');

  const { path, setPath, updateIdx } = usePopupAnnouncementContext();
  const [isNeedSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const { confirm } = useMessageDialog();
  const handleSwitchToMain = async () => {
    if (isNeedSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        setIsNeedSave(false);
        setPath('main');
      }
    } else {
      setPath('main');
    }
  };

  const title = t(
    `${POPUP_DICTIONARY}${
      path === 'form' ? (updateIdx ? '.update' : '.create') : ''
    }`,
  );

  return (
    <div className={styles.Contents}>
      <p className={styles.CardTitle}>
        {path === 'form' && (
          <IconButton
            icon={ArrowLeftIcon}
            onClick={() => void handleSwitchToMain()}
          />
        )}
        {title}
      </p>
      <ErrorBoundary
        FallbackComponent={({ error }) => (
          <DefaultErrorTemplate message={(error as Error).message} />
        )}
      >
        {path === 'main' && <PopupAnnouncementList />}
      </ErrorBoundary>

      {path === 'form' && <PopupAnnouncementForm />}
    </div>
  );
}

export default PopupAnnouncementContents;
