import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useSuspenseQuery } from '@tanstack/react-query';
import * as backUpUrlQuery from './apis/back-up-url-query';

export function BackUpManagement() {
  const { t } = useTranslation('component');
  const { data: backupDataUrl, error } = useSuspenseQuery({
    queryKey: [backUpUrlQuery.QUERY_KEY],
    queryFn: () => backUpUrlQuery.getBackUpUrl(),
  });

  if (error) {
    return <></>;
  }

  return (
    <div className="flex px-[75px] pb-[75px] pt-10">
      <div className="flex flex-col  w-full h-full border rounded-xl gap-1 p-10">
        <h2 className="text-[22px] -tracking-[0.88px] font-medium text-[#333]">
          {t('globalconfig.dataManagement.backupManagement.title')}
        </h2>
        <p className="text-[#AAA] font-medium text-[14px] -tracking-[0.28px]">
          {t('globalconfig.dataManagement.backupManagement.info')}
        </p>
        <a
          className="mt-10 inline-flex justify-center items-center !w-[186px] ps-6 pe-3 py-2 border border-solid rounded-md gap-[10px] text-[#262626] text-[14px] font-medium -tracking-[0.56px]"
          href={backupDataUrl ?? ''}
        >
          {t('globalconfig.dataManagement.backupManagement.button')}
          <ChevronRightIcon className="w-4 h-4" />
        </a>
      </div>
    </div>
  );
}

export default BackUpManagement;
