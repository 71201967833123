import { ShareInfo } from '../../../../types';
import { APP_SHARE_CALENDER_LIST_URL } from '../constants';

export async function deleteCalendarShareList(
  companyGroupId: string | number,
  shareRequestModel: ShareInfo[],
) {
  const response = await fetch(APP_SHARE_CALENDER_LIST_URL(companyGroupId), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(shareRequestModel),
  });
  if (!response.ok) {
    throw new Error('deleteCalendarShareList 오류 발생.');
  }
}
