import { PropsWithChildren } from 'react';
import {
  Root as DopStackerRoot,
  RootProps as DopStackerRootProps,
  useStack as dopStackerUseStack,
} from '@dop-ui/react/shared/ui/stacker';

export type Props<T> = DopStackerRootProps<T>;

export function Root<T extends string>(props: PropsWithChildren<Props<T>>) {
  return <DopStackerRoot {...props} />;
}

export default Root;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const useStack = dopStackerUseStack;
