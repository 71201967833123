import { EarthIcon, OrgChartIcon } from '@dop-ui/icons/react/dop/24';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { useSuspenseQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import * as appListQuery from '../../api/app-list-query';
import { CHAT_APP_CODE, ORG_CHART_APP_CODE } from '../../constants';
import { AppInfo } from '../../types';
import { useContext } from '../context';

export function AppList() {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const { isEditMode, selectedAppId, setSelectedAppCode, setSelectedAppId } =
    useContext();
  const { data: res, error } = useSuspenseQuery({
    queryKey: [appListQuery.QUERY_KEY],
    queryFn: async () => await appListQuery.getAppList(),
  });

  if (error) {
    return (
      <div>
        {t('globalconfig.secureManagement.appManagement.applist.not.load.data')}
      </div>
    );
  }

  const onSelectAppHandler = (appId: number, appCode: string) => {
    setSelectedAppId(appId);
    setSelectedAppCode(appCode);
  };

  const onClickHandler = (appInfo: AppInfo) => {
    const handleConfirm = async () => {
      return await confirm(
        <div className="size-full px-[24px]">
          <p className="text-[#1A1A1A] text-[20px] font-[500]">
            {t('globalconfig.common.dialog.saveConfirm.title')}
          </p>
          <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
            {t('globalconfig.common.dialog.saveConfirm.description')}
          </p>
        </div>,
      );
    };
    if (isEditMode) {
      handleConfirm()
        .then((result) => {
          if (result) {
            onSelectAppHandler(appInfo.companyAppId, appInfo.appCode);
          }
        })
        .catch(console.error);
    } else {
      onSelectAppHandler(appInfo.companyAppId, appInfo.appCode);
    }
  };

  const appInconBlock = (appInfo: AppInfo) => (
    <>
      {appInfo.appCode === ORG_CHART_APP_CODE ? (
        <OrgChartIcon size={24} />
      ) : appInfo.appCode === CHAT_APP_CODE ? (
        <ChatBubbleOvalLeftEllipsisIcon className="size-6" />
      ) : appInfo?.icon ? (
        <i dangerouslySetInnerHTML={{ __html: appInfo.icon }} />
      ) : (
        <EarthIcon size={24} />
      )}
    </>
  );

  return (
    <div className="flex h-auto">
      <div className="overflow-y-scroll gap-[2px] flex flex-col min-w-[240px]">
        {res?.data?.elements.map((appInfo) => (
          <button
            onClick={() => onClickHandler(appInfo)}
            key={`${appInfo.appCode}-${appInfo.appName}`}
            className={clsx(
              'h-8 flex py-2 w-[236px] items-center rounded-lg hover:bg-[#F2F2F2] px-2 gap-2 text-ellipsis text-[14px] font-medium -tracking-[0.28px] text-[#363636]',
              {
                'bg-[#F2F2F2]': selectedAppId === appInfo.companyAppId,
                'bg-transparent': selectedAppId !== appInfo.companyAppId,
              },
            )}
          >
            {appInconBlock(appInfo)}
            <span className="text-left flex-grow overflow-hidden text-ellipsis whitespace-nowrap">
              {appInfo.appName}
            </span>
          </button>
        ))}
      </div>
      <div className="flex-grow border-l" />
    </div>
  );
}

export default AppList;
