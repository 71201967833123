import { WEEK_DATA } from '../constants';
import type {
  IPopupAnnouncement,
  IPopupAnnouncementForm,
  Week,
} from '../types';
export const getSortedDayOfWeeks = (week: Week[]) => {
  return week.sort((a, b) => {
    return WEEK_DATA.indexOf(a) - WEEK_DATA.indexOf(b);
  });
};

export const generateNumberOptions = (
  start: number,
  end: number,
  name?: string,
) => {
  const res = [];
  for (let i = start; i < end + 1; i++) {
    res.push(
      <option key={`${name}-${i}`} value={i}>
        {i}
      </option>,
    );
  }

  return res;
};

export function convertToForm(
  data: IPopupAnnouncement,
): IPopupAnnouncementForm {
  return {
    content: data.content,
    device: data.device,
    endDate: data.endDate,
    isUse: data.isUse,
    option: data.option,
    startDate: data.startDate,
    targetList: data.targetList,
    targetType: data.targetType,
    title: data.title,
    attachFileList: data.attachFileList,
    noticePeriodType: data.noticePeriodType ?? 'SPECIFIC_DATE',
    dayOfWeeks: data.dayOfWeeks ?? [],
    repeatValue: data.repeatValue ?? 1,
    targetDay: data.targetDay ?? 1,
    targetDayOfWeek: data.targetDayOfWeek ?? 'MONDAY',
    targetWeek: data.targetWeek ?? 1,
  };
}
