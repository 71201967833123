import { Dispatch, SetStateAction } from 'react';
import { ClassListManager } from '../../../secureManagement/components/class-list-manager';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useAtom } from 'jotai';
import { isContentNeedsSaveAtom } from '../../../../store/isContentNeedsSaveAtom';
import { POPUP_DICTIONARY } from '../constants';
import type {
  IPopupAnnouncementForm,
  IPopupTarget,
  PopupTargetTypes,
} from '../types';
import styles from './styles.module.css';

interface Props {
  popupForm: IPopupAnnouncementForm;
  setPopupForm: Dispatch<SetStateAction<IPopupAnnouncementForm>>;
}
function FormAnnouncementTarget({ popupForm, setPopupForm }: Props) {
  const { t } = useTranslation('component');
  const { targetType } = popupForm;
  const [, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);

  const handleOnChangeTarget = (info: IPopupTarget[]) => {
    setIsNeedSave(true);
    setPopupForm((prev) => ({
      ...prev,
      targetList: info,
    }));
  };

  const handleOnChangeTargetType = (value: PopupTargetTypes) => {
    setIsNeedSave(true);
    setPopupForm((prev) => ({
      ...prev,
      targetType: value,
    }));
  };

  const selector = (
    <div className={styles.RadioGroup}>
      <div className={styles.Radio}>
        <input
          id="target-all"
          type="radio"
          checked={targetType === 'ALLOW'}
          radioGroup="target"
          onChange={() => handleOnChangeTargetType('ALLOW')}
        />
        <label htmlFor="target-all">
          {t(`${POPUP_DICTIONARY}.form.target.all`)}
        </label>
      </div>

      <div className={styles.Radio}>
        <input
          id="target-specific"
          type="radio"
          checked={targetType === 'DISALLOW'}
          radioGroup="target"
          onChange={() => handleOnChangeTargetType('DISALLOW')}
        />
        <label htmlFor="target-specific">
          {t(`${POPUP_DICTIONARY}.form.target.select`)}
        </label>
      </div>
    </div>
  );
  return (
    <div>
      {selector}
      {targetType === 'DISALLOW' && (
        <ClassListManager
          classRoleInfo={popupForm.targetList}
          accessDeviceType="ALL"
          accessLimitTargetType="EXCLUDE"
          onClassInfoChange={(info) => handleOnChangeTarget(info)}
        />
      )}
    </div>
  );
}

export default FormAnnouncementTarget;
