import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { AppContentsInfo, Option, Page, ResponseBody, Status } from '../types';
import { searchParamGenerator } from '../utils/searchParamGenerator';
import { GROUPWARE_API_URL } from './constants';

interface GroupData {
  id?: number;
  status?: Status;
  name?: string;
  contactCount?: number;
}

export interface ResponseGroupBody {
  page?: Page;
  data?: GroupData[];
}

export async function groupDataQuery(deptId: number, options?: Option) {
  try {
    const FETCH_URL = `${GROUPWARE_API_URL}/department/${deptId}/groups?${searchParamGenerator(options)}`;
    const res = (await fetch(FETCH_URL)).json();
    const data = (await res) as ResponseGroupBody;
    return adapter(data);
  } catch (error) {
    console.error(error);
  }
}

function adapter(res: ResponseGroupBody): ResponseBody {
  return {
    page: res?.page,
    data: res?.data?.map((group) => {
      return {
        id: group?.id,
        name: group?.name ?? '',
        status: group?.status,
        count: group?.contactCount,
      } as AppContentsInfo;
    }),
  };
}
