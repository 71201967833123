import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { getShareUrl } from '../utils/get-share-url';

export interface IResponseBody {
  data: boolean;
}

export const QUERY_KEY = 'enableApprovalBetweenSitesQuery';

export async function getApprovalBetweenSitesStatus(
  companyGroupId: string | number,
  isEnabled: boolean,
) {
  try {
    const { data } = (await (
      await fetch(getShareUrl(companyGroupId))
    ).json()) as IResponseBody;
    return data;
  } catch (error) {
    console.error(error);
    return isEnabled;
  }
}
