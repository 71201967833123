import {
  TAB_KEY_CHANGE_MENU_ORDER,
  TAB_KEY_LINKS,
  TAB_KEY_SET_APPS,
} from '../../constants';
import { useTabContext } from '../tab-provider';
import { SetAppsTab } from '../set-apps-tab';
import { SetLinksTab } from '../set-links-tab';
import { GnbSortTab } from '../gnb-sort-tab';

export function TabContents() {
  const { currentTab } = useTabContext();

  if (currentTab === TAB_KEY_SET_APPS) return <SetAppsTab />;
  if (currentTab === TAB_KEY_LINKS) return <SetLinksTab />;
  if (currentTab === TAB_KEY_CHANGE_MENU_ORDER) return <GnbSortTab />;

  return <div>No-Info</div>;
}
