import { XMarkIcon } from '@daouoffice/ui/lib/icons/heroicons/24/solid';
import { IPInfo } from '../../types';
import { ipInfoToString } from '../../utils/ipInfoUtils';
import { Button } from '@dop-ui/react/shared/ui/button';

export interface IpListItemProps {
  ipInfo: IPInfo;
  disabled?: boolean;
  onClickDelete: () => void;
}

export function IpListItem({
  ipInfo,
  disabled = false,
  onClickDelete,
}: IpListItemProps) {
  return (
    <div className="flex items-center gap-[8px] h-[32px] px-[12px] rounded-[26px] hover:bg-[#F2F2F2] flex-shrink-0">
      <p className="flex-grow">{ipInfoToString(ipInfo)}</p>
      <Button
        className="bg-transparent"
        size="none"
        onClick={onClickDelete}
        disabled={disabled}
      >
        <XMarkIcon className="size-[14px]" strokeWidth={12} color="#000000" />
      </Button>
    </div>
  );
}
