import {
  AppChildInfo,
  CompanyInfo,
  ShareNodeInfo,
  ShareRequestModel,
  Shares,
} from './types';

interface GetPostRequestDtoParams {
  companyGroupId: string | number;
  selectedCompany: CompanyInfo;
  selectedApp: AppChildInfo;
  shareNodeList: ShareNodeInfo[];
}

export const getPostRequestDto = ({
  companyGroupId,
  selectedCompany,
  selectedApp,
  shareNodeList,
}: GetPostRequestDtoParams): ShareRequestModel => {
  return {
    id: selectedApp.id,
    name: selectedApp.name,
    companyUuid: selectedCompany.companyUuid,
    companyName: selectedCompany.companyName,
    companyGroupId: companyGroupId,
    shares: getSharesDto(shareNodeList),
  };
};

const getSharesDto = (shareNodeList: ShareNodeInfo[]): Shares[] => {
  const shares = shareNodeList.reduce<Shares[]>((shareList, currentNode) => {
    const { nodeCompanyUuid } = currentNode;

    var group = shareList.find(
      (group: Shares) => group.companyUuid === nodeCompanyUuid,
    );

    if (!group) {
      group = {
        nodes: [],
        companyUuid: nodeCompanyUuid,
      };
      shareList.push(group);
    }

    group.nodes.push(currentNode);

    return shareList;
  }, []);

  return shares;
};
