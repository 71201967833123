import { clsx } from 'clsx';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '@dop-ui/react/shared/ui/button';
import { Primitives } from '@dop-ui/react/shared/ui/dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  CalendarRepeatType,
  DateTypeAndRepeat,
  DayOfWeek,
  HolidayInfo,
  HolidayPayType,
  HolidayType,
  ScheduleDateType,
  ScheduleInfo,
  ScheduleType,
  SelectDateType,
} from '../../types';
import { dateToYearMonthDayString } from '../../utils/date-to-string';
import { scheduleDialogOpenAtom } from '../../store/dialog_open_atom';
import {
  addCompanySchedule,
  QUERY_KEY,
  updateCompanySchedule,
} from '../../apis/schedule-manage';
import { Title } from './title';
import { HolidayInfoSelect } from './holiday-info-select';
import { ScheduleTypeSelect } from './schedule-type-select';
import { DateTypeAndRepeatSelect } from './date-type-and-repeat-select';
import { dayOfWeekToNumber } from '../../utils/day-of-week';

interface Props {
  currentYear: number;
  originInfo?: ScheduleInfo;
}

const defaultScheduleInfo: ScheduleInfo = {
  id: undefined,
  name: '',
  scheduleType: 'ANNIVERSARY',
  dateType: 'DATE_SPECIFICATION',
  year: undefined,
  month: 1,
  sequence: 1,
  dayOfWeek: 'MONDAY',
  calendarRepeatType: 'NONE',
  holidayType: 'STATUTORY_HOLIDAY',
  isSubstituteHoliday: true,
  holidayPayType: 'PAID',
  wageRate: undefined,
};

const isGivenYearThisYear = (year: number) => {
  return year === new Date().getFullYear();
};

export function Content({ currentYear, originInfo }: Props) {
  const [continuousAddCount, setContinuousAddCount] = useState<number>(0);

  const defaultStartDate = useMemo(() => {
    return isGivenYearThisYear(currentYear)
      ? new Date()
      : new Date(currentYear, 0, 1);
  }, [currentYear]);
  const defaultEndDate = defaultStartDate;

  const getPossibleModifyRangeInfo: () => {
    isModifiable: boolean;
    isPaidModifiable: boolean;
    isHolidaySelectable?: boolean;
  } = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (!originInfo) return { isModifiable: true, isPaidModifiable: true };

    const isSystem =
      originInfo.calendarType === 'SYSTEM' ||
      originInfo.calendarType === 'SUDDEN';
    let isPast = false;

    if (originInfo.dateType === 'DATE_SPECIFICATION') {
      const startDate = new Date(originInfo.startDate ?? '');
      isPast = startDate < today;
    } else if (originInfo.dateType === 'DAY_SPECIFICATION') {
      const year = originInfo.year ?? 1;
      const month = originInfo.month ?? 1;
      const sequence = originInfo.sequence ?? 1;
      const dayOfWeek = originInfo.dayOfWeek ?? 'MONDAY';

      const firstDayOfMonth = new Date(year, month - 1, 1);
      const firstDayOfMonthDayOfWeek = firstDayOfMonth.getDay();
      const daysToAdd = (7 - firstDayOfMonthDayOfWeek) % 7;
      const firstMonday = new Date(year, month - 1, 1 + daysToAdd);

      const targetDate = new Date(firstMonday);
      targetDate.setDate(
        firstMonday.getDate() +
          (sequence - 1) * 7 +
          dayOfWeekToNumber(dayOfWeek) -
          1,
      );

      isPast = targetDate < today;
    }

    // 시스템 등록 휴일이며 과거일 경우
    if (isSystem && isPast)
      return { isModifiable: false, isPaidModifiable: false };

    // 시스템 등록 휴일이며 현재일 이후일 경우
    if (isSystem && !isPast)
      return { isModifiable: false, isPaidModifiable: true };

    // 사용자 등록 휴일이며 과거일 경우.
    const isHoliday = originInfo.scheduleType === 'HOLIDAY';
    if (isHoliday && isPast)
      return { isModifiable: false, isPaidModifiable: false };

    // 사용자 등록 휴일이며 현재일 이후일 경우
    if (isHoliday && !isPast)
      return { isModifiable: true, isPaidModifiable: true };

    // 기념일이며 과거일 경우
    if (isPast)
      return {
        isModifiable: true,
        isPaidModifiable: true,
        isHolidaySelectable: false,
      };

    // 기념일이며 현재일 이후일 경우
    return { isModifiable: true, isPaidModifiable: true };
  }, [originInfo]);

  const { t } = useTranslation('component');
  const setDialogOpen = useSetAtom(scheduleDialogOpenAtom);

  const [name, setName] = useState<string>('');
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [scheduleType, setScheduleType] = useState<ScheduleType>('ANNIVERSARY');
  const [selectedDateType, setSelectedDateType] = useState<SelectDateType>(
    'DATE_SPECIFICATION_SINGLE',
  );
  const [dateType, setDateType] =
    useState<ScheduleDateType>('DATE_SPECIFICATION');
  const [repeatType, setRepeatType] = useState<CalendarRepeatType>('NONE');
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [month, setMonth] = useState<number | undefined>();
  const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek | undefined>();
  const [sequence, setSequence] = useState<number | undefined>();
  const [holidayType, setHolidayType] = useState<HolidayType | undefined>();
  const [isSubstituteHoliday, setIsSubstituteHoliday] = useState<
    boolean | undefined
  >();
  const [holidayPayType, setHolidayPayType] = useState<
    HolidayPayType | undefined
  >();
  const [isWageRateChecked, setIsWageRateChecked] = useState<
    boolean | undefined
  >(false);
  const [wageRate, setWageRate] = useState<number | undefined>();

  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const addScheduleMutation = useMutation({
    mutationFn: (info: ScheduleInfo) => addCompanySchedule(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setDialogOpen(false);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  const addScheduleAndContinueMutation = useMutation({
    mutationFn: (info: ScheduleInfo) => addCompanySchedule(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      initScheduleInfo(true);
      setContinuousAddCount((prev) => prev + 1);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  const updateScheduleMutation = useMutation({
    mutationFn: (info: ScheduleInfo) => updateCompanySchedule(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setDialogOpen(false);
      initScheduleInfo();
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const initName = useCallback(() => {
    if (originInfo) {
      setName(originInfo.name);
    } else {
      setName(defaultScheduleInfo.name);
    }
  }, [originInfo]);

  const initDateAndRepeat = useCallback(() => {
    if (originInfo) {
      setDateType(originInfo.dateType);
      if (originInfo.dateType === 'DATE_SPECIFICATION') {
        if (
          originInfo.startDate &&
          originInfo.endDate &&
          originInfo.startDate === originInfo.endDate
        ) {
          setSelectedDateType('DATE_SPECIFICATION_SINGLE');
        } else {
          setSelectedDateType('DATE_SPECIFICATION');
        }
      } else {
        setSelectedDateType('DAY_SPECIFICATION');
      }
      setStartDate(
        originInfo.startDate ? new Date(originInfo.startDate) : undefined,
      );
      setEndDate(originInfo.endDate ? new Date(originInfo.endDate) : undefined);
      setRepeatType(originInfo.calendarRepeatType ?? 'NONE');
      setMonth(originInfo.month ?? 1);
      setDayOfWeek(originInfo.dayOfWeek ?? 'MONDAY');
      setSequence(originInfo.sequence ?? 1);
    } else {
      setDateType(defaultScheduleInfo.dateType);
      setSelectedDateType('DATE_SPECIFICATION_SINGLE');
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      setMonth(defaultScheduleInfo.month);
      setDayOfWeek(defaultScheduleInfo.dayOfWeek);
      setSequence(defaultScheduleInfo.sequence);
      setRepeatType(defaultScheduleInfo.calendarRepeatType);
    }
  }, [defaultEndDate, defaultStartDate, originInfo]);

  const initHolidayInfo = useCallback(() => {
    if (originInfo) {
      setHolidayType(originInfo.holidayType);
      setIsSubstituteHoliday(originInfo.isSubstituteHoliday);
      setHolidayPayType(originInfo.holidayPayType);
      setWageRate(originInfo.wageRate);
    } else {
      setHolidayType(defaultScheduleInfo.holidayType);
      setIsSubstituteHoliday(defaultScheduleInfo.isSubstituteHoliday);
      setHolidayPayType(defaultScheduleInfo.holidayPayType);
      setWageRate(defaultScheduleInfo.wageRate);
    }
  }, [originInfo]);

  const initScheduleInfo = useCallback(
    (keepScheduleType: boolean = false) => {
      initName();
      if (!keepScheduleType && originInfo) {
        setScheduleType(originInfo.scheduleType);
      } else if (!keepScheduleType && !originInfo) {
        setScheduleType(defaultScheduleInfo.scheduleType);
      }
      initDateAndRepeat();
      initHolidayInfo();
    },
    [initName, initDateAndRepeat, initHolidayInfo, originInfo],
  );

  useEffect(() => {
    initScheduleInfo();
  }, [initScheduleInfo]);

  const handleChangeScheduleType = (type: ScheduleType) => {
    if (type !== scheduleType) {
      setHolidayType('STATUTORY_HOLIDAY');
      setIsSubstituteHoliday(true);
      setHolidayPayType('PAID');
    }
    setScheduleType(type);
    initDateAndRepeat();
  };

  const handleChangeDateData = (data: DateTypeAndRepeat) => {
    setSelectedDateType(data.dateType);
    switch (data.dateType) {
      case 'DATE_SPECIFICATION_SINGLE':
      case 'DATE_SPECIFICATION':
        setDateType('DATE_SPECIFICATION');
        break;
      case 'DAY_SPECIFICATION':
        setDateType('DAY_SPECIFICATION');
    }
    setRepeatType(data.repeatType);
    setStartDate(data.dateInfo?.startDate);
    setEndDate(data.dateInfo?.endDate);
    setMonth(data.dateInfo?.month);
    setDayOfWeek(data.dateInfo?.dayOfWeek);
    setSequence(data.dateInfo?.sequence);
  };

  const handleChangeHolidayInfo = (info: HolidayInfo) => {
    setHolidayType(info.holidayType);
    setIsSubstituteHoliday(info.isSubstituteHoliday);
    setHolidayPayType(info.holidayPayType);
    setIsWageRateChecked(info.isWageRateChecked);
    setWageRate(info.wageRate);
  };

  const isErrorExist = () => {
    if (!name || name.length === 0) {
      setNameErrorMessage(
        t(
          'globalconfig.basicManagement.companySchedule.schedule.name.mandatory',
        ),
      );
      return true;
    }
    return false;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (newName.length > 32) {
      return;
    }
    setName(newName);
    setNameErrorMessage('');
  };

  const getProperInfo = () => {
    const nonChangeableInfo: ScheduleInfo = {
      id: originInfo?.id,
      name,
      scheduleType,
      dateType,
      calendarRepeatType: repeatType,
    };

    let finalInfo: ScheduleInfo = nonChangeableInfo;

    if (selectedDateType === 'DATE_SPECIFICATION_SINGLE') {
      finalInfo = {
        ...nonChangeableInfo,
        startDate: dateToYearMonthDayString(startDate),
        endDate: dateToYearMonthDayString(startDate),
      };
    } else if (selectedDateType === 'DATE_SPECIFICATION') {
      finalInfo = {
        ...nonChangeableInfo,
        startDate: dateToYearMonthDayString(startDate),
        endDate: dateToYearMonthDayString(endDate),
      };
    } else {
      finalInfo = {
        ...nonChangeableInfo,
        month,
        dayOfWeek,
        sequence,
        year: currentYear,
      };
    }

    if (scheduleType === 'HOLIDAY') {
      finalInfo = {
        ...finalInfo,
        holidayType,
        isSubstituteHoliday,
        holidayPayType,
        wageRate:
          holidayPayType === 'PAID' && isWageRateChecked ? wageRate : undefined,
      };
    }

    return finalInfo;
  };

  const handleOnAddAndContinue = () => {
    if (isErrorExist()) {
      return;
    }
    addScheduleAndContinueMutation.mutate(getProperInfo());
  };

  const handleOnAddSchedule = () => {
    if (isErrorExist()) {
      return;
    }
    addScheduleMutation.mutate(getProperInfo());
  };

  const handleOnModifySchedule = () => {
    if (isErrorExist()) {
      return;
    }
    updateScheduleMutation.mutate(getProperInfo());
  };

  return (
    <>
      <p className="text-[#363636] //text-[--color-text-level1] text-[20px] font-[600] leading-[30px]">
        {originInfo
          ? t('globalconfig.basicManagement.companySchedule.schedule.edit')
          : t('globalconfig.basicManagement.companySchedule.schedule.add')}
      </p>
      <div className="flex mt-[24px] gap-[8px]">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.table.header.name',
          )}
          isRequired
        />
        <div className="flex-grow">
          <input
            className={clsx(
              'w-full h-[40px] border border-solid  rounded-[8px] px-[12px]',
              {
                'border-[#E84B4B]': nameErrorMessage,
                'border-[#D8D8D8]': !nameErrorMessage,
              },
            )}
            placeholder={t(
              'globalconfig.basicManagement.companySchedule.schedule.name.placeholder',
            )}
            value={name}
            onChange={handleNameChange}
          />
          {nameErrorMessage && (
            <p className="mt-[12px] text-[#E84B4B] //text-[--color-text-caution] text-[13px] font-[400]">
              {nameErrorMessage}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center mt-[8px] gap-[8px]">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.type.title',
          )}
        />
        <ScheduleTypeSelect
          isHolidaySelectable={
            currentYear >= new Date().getFullYear() &&
            (getPossibleModifyRangeInfo().isHolidaySelectable ?? true)
          }
          scheduleType={scheduleType}
          disabled={!getPossibleModifyRangeInfo().isModifiable}
          onChangeScheduleType={handleChangeScheduleType}
        />
      </div>
      <DateTypeAndRepeatSelect
        currentYear={currentYear}
        data={{
          isAnniversary: scheduleType === 'ANNIVERSARY',
          dateType: selectedDateType,
          repeatType: repeatType,
          dateInfo: { startDate, endDate, month, dayOfWeek, sequence },
        }}
        disabled={
          !(
            getPossibleModifyRangeInfo().isModifiable &&
            (!originInfo || originInfo.calendarRepeatType === 'NONE')
          )
        }
        onDateDataChange={handleChangeDateData}
      />
      {scheduleType === 'HOLIDAY' && (
        <HolidayInfoSelect
          info={{
            holidayType,
            holidayPayType,
            isSubstituteHoliday,
            isWageRateChecked,
            wageRate,
          }}
          disabled={!getPossibleModifyRangeInfo().isModifiable}
          isPaidModifiable={getPossibleModifyRangeInfo().isPaidModifiable}
          onHolidayInfoChange={handleChangeHolidayInfo}
        />
      )}
      <div className="flex justify-end mt-[24px] gap-[12px]">
        {originInfo ? (
          <>
            <Primitives.Close asChild>
              <Button
                size="medium"
                shape="rect"
                colorset="level2"
                variant="ghost"
              >
                <p className="font-[500]">{t('dialog.cancel')}</p>
              </Button>
            </Primitives.Close>
            <Button
              size="medium"
              shape="rect"
              colorset="level1"
              variant="solid"
              onClick={handleOnModifySchedule}
            >
              <p className="font-[500]">{t('dialog.modify')}</p>
            </Button>
          </>
        ) : (
          <>
            <Primitives.Close asChild>
              <Button
                size="medium"
                shape="rect"
                colorset="level2"
                variant="ghost"
              >
                <p className="font-[500]">{t('dialog.cancel')}</p>
              </Button>
            </Primitives.Close>
            <Button
              size="medium"
              shape="rect"
              colorset="level2"
              variant="solid"
              onClick={handleOnAddAndContinue}
            >
              <p className="font-[500]">
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.dialog.saveAndContinue',
                )}
                {continuousAddCount > 0 && (
                  <span className="ml-[2px] opacity-50">
                    {continuousAddCount}
                  </span>
                )}
              </p>
            </Button>
            <Button
              size="medium"
              shape="rect"
              colorset="level1"
              variant="solid"
              onClick={handleOnAddSchedule}
            >
              <p className="font-[500]">{t('dialog.save')}</p>
            </Button>
          </>
        )}
      </div>
    </>
  );
}
