import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { PORTAL_GNB_MENU_API } from '../../../../constants';
import { BaseResponseWithPage } from '../../../../types';
import { GNBMenuInfo } from '../../../types';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.EmpAppManagement.GNBSort';

export async function getGnbMenuInfo() {
  const response = await fetch(PORTAL_GNB_MENU_API('EMPLOYEE'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponseWithPage<GNBMenuInfo>;

  return responseData.data.elements;
}

export async function updateGnbMenuSortInfo(ids: number[]) {
  const idArray = ids.map((id) => ({ id }));
  const response = await fetch(PORTAL_GNB_MENU_API('EMPLOYEE'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(idArray),
  });

  if (!response.ok) {
    throw new Error('Failed to update gnb menu sort info');
  }
}
