import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { ErrorResponseBody } from '../types';

export async function postMasterDataInfo(userId: number) {
  const pathUrl = `/api/portal/admin/security/master/${userId}`;

  const resp = await fetch(pathUrl, {
    method: 'POST',
  });

  if (!resp.ok) {
    throw new Error(((await resp.json()) as ErrorResponseBody)?.message);
  }
}

export async function putTableDataInfo(
  companyAppId: string,
  userIds: number[],
) {
  const pathUrl = `/api/portal/setting/security/app-manager/app/${companyAppId}`;

  const resp = await fetch(pathUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: userIds,
    }),
  });

  if (!resp.ok) {
    throw new Error(((await resp.json()) as ErrorResponseBody)?.message);
  }
}
