import { useAtomValue, useSetAtom } from 'jotai';
import * as Stacker from '@daouoffice/ui/lib/foundation/Stacker';
import { isOrgDisplayOrderNeedsSaveAtom } from './store/is-org-display-order-needs-save-atom';
import { isOrgSahreNeedsSaveAtom } from './store/is-org-share-needs-save-atom';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import { Suspense, useEffect } from 'react';
import { Main } from './main';
import { OrgShareConfig } from './components/org-share-config';
import { OrgDisplayOrderConfig } from './components/org-display-order-config';
import { Provider as BasicInfoProvider } from './Context';

export function BasicInfo() {
  const isOrgDisplayOrderNeedsSave = useAtomValue(
    isOrgDisplayOrderNeedsSaveAtom,
  );
  const isOrgSahreNeedsSave = useAtomValue(isOrgSahreNeedsSaveAtom);

  const setNeedsSave = useSetAtom(isContentNeedsSaveAtom);

  useEffect(() => {
    setNeedsSave(isOrgDisplayOrderNeedsSave || isOrgSahreNeedsSave);
  }, [isOrgDisplayOrderNeedsSave, isOrgSahreNeedsSave, setNeedsSave]);

  return (
    <BasicInfoProvider>
      <div className="px-[72px] py-[40px] overflow-y-scroll">
        <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
          <Stacker.Root defaultPage="main">
            <Stacker.Content name="main">
              <Suspense fallback={<div>Loading...</div>}>
                <Main />
              </Suspense>
            </Stacker.Content>
            <Stacker.Content name="orgShare">
              <OrgShareConfig />
            </Stacker.Content>
            <Stacker.Content name="orgDisplayOrder">
              <OrgDisplayOrderConfig />
            </Stacker.Content>
          </Stacker.Root>
        </div>
      </div>
    </BasicInfoProvider>
  );
}

export default BasicInfo;
