import {
  GearSettingsIcon,
  PowerOnOffIcon,
  XMarkIcon,
} from '@dop-ui/icons/react/dop/24';
import { Avatar } from '../../../../shared/ui/avatar';
import { UserProfileInfo } from '../../types';
import { useContext } from './primitives-context';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useAuthContext } from '../../../../../next/lib/auth/client/Context';
import { IconButton } from '../../../../shared/ui/button';

export interface Props {
  profileInfo: UserProfileInfo;
}

export function Card({ profileInfo }: Props) {
  const { cardOpenState, setCardOpenState } = useContext();
  const { logout } = useAuthContext();
  const pathname = usePathname();
  const closeHandler = () => {
    setCardOpenState(false);
  };

  return cardOpenState ? (
    <div className="layer card">
      <IconButton icon={XMarkIcon} size="sm" onClick={closeHandler} />
      <div className="user_profile">
        <div className="avatar_large">
          <Avatar
            size={64}
            alt={profileInfo.name}
            src={profileInfo.avatarImageURL}
            backgroundColor={profileInfo.color}
          />
        </div>
        <dl className="personal_info">
          <dt>
            <span className="txt blind">사용자 명</span>
          </dt>
          <dd>
            <span className="txt user_name">{profileInfo.name}</span>
            <span className="txt user_position">{profileInfo.gradeName}</span>
          </dd>
          <dt>
            <span className="txt blind">소속</span>
          </dt>
          {/* 여러 부서가 있는 경우, 각 부서를 개행시켜서 카드에 노출 */}
          {profileInfo.departmentNames.map((name) => (
            <dd key={name}>
              <span className="txt user_team">{name}</span>
            </dd>
          ))}
          <dt>
            <span className="txt blind">계정</span>
          </dt>
          <dd>
            <span className="txt user_account">{profileInfo.email}</span>
          </dd>
        </dl>
        <div className="group_btn">
          <Link
            className="btn_menu"
            href={`./user-config?previousUrl=${pathname}`}
          >
            <GearSettingsIcon
              viewBox="0 0 20 20"
              size={20}
              className="w-[48px] h-[48px] rounded-[20px] bg-[#f3f3f3]"
            />
            <span>{'환경 설정'}</span>
          </Link>

          <button className="btn_menu" onClick={() => logout()}>
            <PowerOnOffIcon
              viewBox="0 0 20 20"
              size={20}
              className="w-[48px] h-[48px] rounded-[20px] bg-[#f3f3f3]"
            />
            <span>{'로그아웃'}</span>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
