import { TFA_REGISTRATION_STATUS_URL } from '../../constants';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export async function deleteTfaMember(statusIdList: number[]) {
  const response = await fetch(TFA_REGISTRATION_STATUS_URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({ data: statusIdList }),
  });

  if (!response.ok) {
    throw new Error('deleteTfaMember 오류 발생.');
  }
}
