import { fetch } from '../../../shared/lib/fetch/client';

interface ReadResponse {
  data: {
    count: number;
  };
}

export async function readAllNotification(): Promise<ReadResponse> {
  const response = await fetch(`/api/portal/common/notification/read`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return (await response.json()) as ReadResponse;
}
