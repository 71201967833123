import Link from 'next/link';
import { extensionMap, getFileIcon } from '../../lib/utils';
import { formatFileSize } from '../../lib/utils/format-file-size';
import styles from './styles.module.css';

interface Props {
  fileName: string;
  fileType: string;
  fileSize: number;
  downloadUrl?: string;
  iconSize?: number;
  maxLength?: number;
}

export function FileItem({
  fileName,
  fileType,
  fileSize,
  downloadUrl,
  iconSize = 16,
  maxLength,
}: Props) {
  const Icon = getFileIcon(
    extensionMap.get(fileType.toLowerCase()) || 'default',
  );

  //ex)
  /**
   * @returns 14글자 기준으로 했을 때 (n/2)-1 자 ... (n/2)-1자
   */
  const getFileName = () => {
    if (!maxLength || fileName.length <= maxLength) {
      return fileName;
    } else {
      const slicedLength = Math.floor(maxLength / 2 - 1);
      return `${fileName.slice(0, slicedLength)}...${fileName.slice(maxLength - 1 - slicedLength, maxLength - 1)}.${fileType}`;
    }
  };

  return downloadUrl ? (
    <Link href={downloadUrl} target="_blank" download className={styles.File}>
      <Icon size={iconSize} />
      <p className={styles.FileName}>{getFileName()}</p>
      <p className={styles.FileSize}>{`(${formatFileSize(fileSize)})`}</p>
    </Link>
  ) : (
    <div className={styles.File}>
      <Icon size={iconSize} />
      <p className={styles.FileName}>{getFileName()}</p>
      <p className={styles.FileSize}>{`(${formatFileSize(fileSize)})`}</p>
    </div>
  );
}
