import { CUSTOM_PROFILE_CARD_LIST_API } from '../../constants';
import { BaseResponseWithPage } from '../../types';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { CustomProfileInfo } from '../type';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.ProfileManagement.CustomProfileList';

export async function getCustomProfileList(): Promise<CustomProfileInfo[]> {
  try {
    const response = await fetch(CUSTOM_PROFILE_CARD_LIST_API, {
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    });

    if (!response.ok) {
      throw new Error('getCustomProfileList 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponseWithPage<CustomProfileInfo>;

    return resData.data.elements;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
