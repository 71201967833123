import { fetch } from '../../../../../../lib/fetch/client';
import {
  AppContentsInfo,
  Option,
  Owner,
  Page,
  ResponseBody,
  Status,
} from '../types';
import { searchParamGenerator } from '../utils/searchParamGenerator';
import { GROUPWARE_API_URL } from './constants';

interface BoardInfo {
  owners?: Owner[];
  id?: number;
  status?: Status;
  attachSize?: number;
  name?: string;
}

export interface ResponseBoardBody {
  page?: Page;
  data?: BoardInfo[];
}

export async function boardDataQuery(deptId: number, options?: Option) {
  try {
    const FETCH_URL = `${GROUPWARE_API_URL}/department/${deptId}/boards/${searchParamGenerator(options)}`;
    const res = (await fetch(FETCH_URL)).json();
    const data = (await res) as ResponseBoardBody;
    return adapter(data);
  } catch (error) {
    console.error(error);
  }
}

function adapter(res: ResponseBoardBody): ResponseBody {
  return {
    page: res?.page,
    data: res?.data?.map((board) => {
      return {
        id: board?.id,
        name: board?.name ?? '',
        status: board?.status,
        shared: board?.owners?.map((owner) => owner.ownerInfo),
        storageUsage: board.attachSize,
      } as AppContentsInfo;
    }),
  };
}
