import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { NodeShareType, ShareNodeInfo } from '../../../types';

export interface Props {
  shares: ShareNodeInfo[];
  onChangeShares?: (newShares: ShareNodeInfo[]) => void;
}

export function OwnerList({ shares, onChangeShares }: Props) {
  const { t } = useTranslation('component');

  const onDeleteHandler = (nodeId: number) => {
    onChangeShares &&
      onChangeShares(shares.filter((share) => share.nodeId !== nodeId));
  };

  const getClassString = (type: NodeShareType) => {
    if (type === 'user') {
      return t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.user',
      );
    } else if (type === 'department') {
      return t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.department',
      );
    } else if (type === 'position') {
      return t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.position',
      );
    } else if (type === 'grade') {
      return t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.grade',
      );
    } else if (type === 'duty') {
      return t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.duty',
      );
    } else {
      return t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.userGroup',
      );
    }
  };

  return (
    <>
      {shares.length > 0 ? (
        <ul className="mt-[6px] border border-solid rounded-[8px] w-full max-h-[140px] h-[132px] boder-[#D8D8D8] overflow-y-auto">
          {shares.map((shareNodeInfo, index) => (
            <li
              key={`list-${shareNodeInfo.nodeType}-${shareNodeInfo.nodeId}-${index}`}
              className="h-8 px-3 w-full flex justify-between items-center gap-2 hover:rounded-[26px] hover:bg-[#F2F2F2]"
            >
              {shareNodeInfo.nodeType === 'company' ? (
                // 대상 사이트인 경우
                <span className="text-[13px] flex grow font-normal leading-[19.5px] tracking-[-0.52px] text-[#363636] text-ellipsis">
                  {shareNodeInfo.nodeCompanyName}
                </span>
              ) : (
                // 대상 클래스가 존재하는 경우
                <div className="flex grow justify-start gap-1 items-center w-[150px] overflow-hidden">
                  <span className="whitespace-nowrap text-[#999999] text-[12px] font-normal leading-[18px] tracking-[-0.48px]">
                    {`${getClassString(shareNodeInfo.nodeType)}:`}
                  </span>
                  <span className="whitespace-nowrap text-[13px] font-normal leading-[19.5px] tracking-[-0.52px] text-[#363636] overflow-hidden text-ellipsis">
                    {`${shareNodeInfo.nodeCompanyName} - ${shareNodeInfo.nodeValue}`}
                  </span>
                </div>
              )}
              {/* permissionType : 읽기, 쓰기 */}
              <span className="text-[13px] font-normal leading-[19.5px] tracking-[-0.52px] text-[#363636] w-[120px]">
                {shareNodeInfo.actions == 'read'
                  ? t(
                      'globalconfig.multiCompanyManagement.appShare.classManagement.permissionType.readOnly',
                    )
                  : t(
                      'globalconfig.multiCompanyManagement.appShare.classManagement.permissionType.readWrite',
                    )}
              </span>
              {/* 삭제 버튼 */}
              <Button
                onClick={() => onDeleteHandler(shareNodeInfo.nodeId)}
                className="p-[3px] bg-transparent"
              >
                <XMarkIcon className="stroke-[#363636] size-3" />
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        // 데이터가 없는 경우
        <div className="mt-[6px] border border-solid rounded-[8px] w-full max-h-[140px] h-[132px] boder-[#D8D8D8] flex items-center justify-center">
          <span className="text-[#999] text-[14px] font-normal leading-[14px] tracking-[-0.28px]">
            {t(
              'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.noClassSiteData',
            )}
          </span>
        </div>
      )}
    </>
  );
}

export default OwnerList;
