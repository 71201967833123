import { Switch } from '@daouoffice/ui';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  getPlatformTfaUseConfig,
  QUERY_KEY,
  updateTfaConfig,
} from './apis/tfaConfig';
import { ContentBox } from '../../../parts/components/content-box';

export function TwoFactorAuthConfig() {
  const { t } = useTranslation('component');
  const { data: configData, error } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: getPlatformTfaUseConfig,
  });
  const [useTfa, setUseTfa] = useState(false);
  const messageDialog = useMessageDialog();

  const queryClient = useQueryClient();

  const platformConfigMutation = useMutation({
    mutationFn: async (value: boolean) => await updateTfaConfig(value),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  useEffect(() => {
    setUseTfa(configData?.useTfa ?? false);
  }, [configData]);

  const onPlatformConfigSwitchChange = (checked: boolean) => {
    void confirmWithMessageDialog(checked);
  };

  const confirmWithMessageDialog = async (checked: boolean) => {
    if (checked) {
      if (
        await messageDialog.confirm(
          <div className="size-full">
            <p className="text-[#363636] text-[20px] font-[600]">
              {t('globalconfig.secureManagement.tfaConfig.dialog.use.title')}
            </p>
            <p className="mt-[14px] text-[#363636] text-[14px] font-[400] whitespace-pre-wrap">
              {t(
                'globalconfig.secureManagement.tfaConfig.dialog.use.description1',
              )}
            </p>
            <p className="mt-[14px] text-[#363636] text-[14px] font-[400] whitespace-pre-wrap">
              {t(
                'globalconfig.secureManagement.tfaConfig.dialog.use.description2',
              )}
            </p>
          </div>,
        )
      ) {
        platformConfigMutation.mutate(true);
      }
    } else {
      if (
        await messageDialog.confirm(
          <div className="size-full">
            <p className="text-[#363636] text-[20px] font-[600]">
              {t('globalconfig.secureManagement.tfaConfig.dialog.noUse.title')}
            </p>
            <p className="mt-[14px] text-[#363636] text-[14px] font-[400] whitespace-pre-wrap">
              {t(
                'globalconfig.secureManagement.tfaConfig.dialog.noUse.description',
              )}
            </p>
          </div>,
        )
      ) {
        platformConfigMutation.mutate(false);
      }
    }
  };

  if (error) {
    console.error(
      'GlobalConfig > SecureManagement > TwoFactorAuthConfig > error : ',
      error,
    );
  }

  return (
    <ContentBox title={t('globalconfig.secureManagement.tfaConfig.title')}>
      <div className="flex flex-wrap items-center h-[24px] w-full mt-[48px] text-[--color-text-level1] text-[16px]">
        <span className="w-[264px] text-[--color-text-level1] text-[16px] font-[500] whitespace-nowrap text-ellipsis overflow-hidden">
          {t('globalconfig.secureManagement.tfaConfig.platformLogin.title')}
        </span>
        <span className="text-[--color-text-level3] text-[14px] font-[400]">
          {t(
            'globalconfig.secureManagement.tfaConfig.platformLogin.description',
          )}
        </span>
        <div className="spacer flex-grow" />
        <Switch
          id="twoFactorAuth.cofig.switch"
          checked={useTfa}
          onChange={onPlatformConfigSwitchChange}
        />
      </div>
      <div className="hdivider mt-[24px] w-full h-[1px] bg-[--color-border-level3]" />
      <div className="flex flex-wrap items-center h-[24px] w-full my-[24px] text-[--color-text-level1] text-[16px]">
        <span className="w-[264px] text-[--color-text-level1] text-[16px] font-[500] whitespace-nowrap text-ellipsis overflow-hidden">
          {t('globalconfig.secureManagement.tfaConfig.mpLogin.title')}
        </span>
        <span className="text-[--color-text-level3] text-[14px] font-[400]">
          {t('globalconfig.secureManagement.tfaConfig.mpLogin.description')}
        </span>
        <div className="spacer flex-grow" />
        <Switch
          id="twoFactorAuth.cofig.nonSwitchable.switch"
          checked={true}
          disabled
        />
      </div>
    </ContentBox>
  );
}
