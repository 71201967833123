import { fetch } from '../../../../../../lib/fetch/client';
import {
  APP_CODE_APPROVAL,
  APP_CODE_BOARD,
  APP_CODE_CONTACT,
  APP_CODE_REPORT,
} from '../constants';
import { AppOverView } from '../types';
import { GROUPWARE_API_URL } from './constants';

export const QUERY_KEY = 'deptEntireDataQuery';

export interface ResponseDeptEntireData {
  deptId?: number;
  createdAt?: string;
  deletedAt?: string;
  attachSize?: number;
  appList?: AppOverView[];
}

interface ResponseBody {
  code?: number;
  message?: string;
  data?: ResponseDeptEntireData;
}

export async function deptEntireDataQuery(deptId?: number) {
  try {
    if (!deptId) return {};

    const appCodeList: string[] = [
      APP_CODE_APPROVAL,
      APP_CODE_BOARD,
      APP_CODE_CONTACT,
      APP_CODE_REPORT,
    ];
    const queryString = appCodeList.reduce(
      (acc, cur, idx) =>
        acc + `appCodes=${cur}` + (idx === appCodeList.length - 1 ? '' : '&'),
      '',
    );

    const FETCH_URL = `${GROUPWARE_API_URL}/deletedepts/${deptId}?${queryString}`;
    const res = (await fetch(FETCH_URL)).json() as ResponseBody;
    return res;
  } catch (error) {
    console.error(error);
  }
}
