import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export interface Props {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  increaseMonth: () => void;
  decreaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export function Header({
  date,
  changeYear,
  changeMonth,
  increaseMonth,
  decreaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: Props) {
  // option에 들어갈 연/월 계산. (as-is : 100년 전부터 10년 후까지, 12개월)
  const PAST_YEAR_COUNT = 100;
  const FUTURE_YEAR_COUNT = 10;
  const MONTH_COUNT = 12;
  const years: number[] = Array.from(
    { length: PAST_YEAR_COUNT + FUTURE_YEAR_COUNT + 1 },
    (_, i) => new Date(date).getFullYear() - PAST_YEAR_COUNT + i,
  );
  const monthes: number[] = Array.from({ length: MONTH_COUNT }, (_, i) => i);

  return (
    <div className="w-full px-2 flex items-center justify-between bg-white">
      <div className="flex items-center gap-2">
        <select
          className="text-sm border border-white  px-2 py-1 font-bold hover:border hover:border-[#ddd] hover:bg-white hover:rounded"
          value={new Date(date).getFullYear()}
          onChange={(e) => changeYear(Number(e.target.value))}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          className="text-sm border border-white  px-2 py-1 font-bold hover:border hover:border-[#ddd] hover:bg-white hover:rounded"
          value={new Date(date).getMonth()}
          onChange={(e) => changeMonth(monthes.indexOf(Number(e.target.value)))}
        >
          {monthes.map((month) => (
            <option key={`datePick_month_${month}`} value={month}>
              {month + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center gap-2">
        <button
          title="btn_prev_month"
          className="hover:border-solid hover:border-[#ddd] hover:border hover:bg-white hover:rounded border border-solid border-transparent"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <ChevronLeftIcon className="w-6 h-6 fill-gray-400" />
        </button>
        <button
          className="hover:border-solid hover:border-[#ddd] border border-solid border-transparent hover:border hover:bg-white hover:rounded"
          title="btn_next_month"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <ChevronRightIcon className="w-6 h-6 fill-gray-400" />
        </button>
      </div>
    </div>
  );
}

export default Header;
