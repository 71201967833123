import { clsx } from 'clsx';
import * as Popover from '@radix-ui/react-popover';

export function Content({
  className,
  children,
  ...props
}: Popover.PopoverContentProps) {
  return (
    <Popover.Portal>
      <Popover.Content
        className={clsx(
          'left-0 p-[8px] min-w-[200px] shadow-[0_4px_16px_0_rgba(0,0,0,0.08)] rounded-[8px] bg-white z-[999]',
          className,
        )}
        align="start"
        {...props}
        onPointerDownOutside={(e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        }}
      >
        {children}
      </Popover.Content>
    </Popover.Portal>
  );
}
