import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { HomeIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { Bars2SlashIcon } from '@dop-ui/icons/react/dop/24/bars-two-slash';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { isContentNeedsSaveAtom } from '../../../../../store/isContentNeedsSaveAtom';
import { GNBMenuInfo } from '../../types';
import {
  getGnbMenuInfo,
  QUERY_KEY,
  updateGnbMenuSortInfo,
} from './apis/gnb-sort';
import { GroupGnbSort } from './group-gnb-sort';

export function SortConfig() {
  const { t } = useTranslation('component');
  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const [groupList, setGroupList] = useState<string[]>([]);
  const [groupedMenuList, setGroupedMenuList] = useState<
    Map<string, GNBMenuInfo[]>
  >(new Map());

  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const { data: menuListData, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getGnbMenuInfo,
  });

  const sortInfoUpdateMutation = useMutation({
    mutationFn: (ids: number[]) => updateGnbMenuSortInfo(ids),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setIsNeedSave(false);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  const setGroupedInfo = useCallback(() => {
    if (menuListData) {
      const nameSet = new Set<string>();
      const groupNameList: string[] = [];
      const groupedMenuListMap = new Map<string, GNBMenuInfo[]>();

      menuListData.forEach((menu) => {
        if (!nameSet.has(menu.groupName)) {
          nameSet.add(menu.groupName);
          groupNameList.push(menu.groupName);
        }

        if (!groupedMenuListMap.has(menu.groupName)) {
          groupedMenuListMap.set(menu.groupName, []);
        }

        groupedMenuListMap.get(menu.groupName)?.push(menu);
      });

      setGroupList(groupNameList);
      setGroupedMenuList(groupedMenuListMap);
    }
  }, [menuListData]);

  useEffect(() => {
    setGroupedInfo();
  }, [setGroupedInfo]);

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > BusinessPortalAppManagement > GnbSort Error: ',
      error,
    );
  }

  const onChangeGroupMenuSort = (
    groupName: string,
    menuList: GNBMenuInfo[],
  ) => {
    setGroupedMenuList((prev) => {
      const newGroupedMenuList = new Map(prev);
      newGroupedMenuList.set(groupName, menuList);
      return newGroupedMenuList;
    });
    setIsNeedSave(true);
  };

  const onClickCancel = () => {
    setGroupedInfo();
    setIsNeedSave(false);
  };

  const onClickSave = () => {
    sortInfoUpdateMutation.mutate(
      groupList.flatMap((groupName) => {
        return groupedMenuList.get(groupName)?.map((menu) => menu.id) || [];
      }),
    );
  };

  return (
    <div className="mt-[24px] p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
      <div className="flex items-center h-[38px]">
        <p className="text-[#363636] text-[20px] font-[600]">
          {t('globalconfig.basicManagement.businessPortal.gnbSort.title')}
        </p>
      </div>
      <p className="mb-[24px] text-[#999999] text-[14px] font-[400]">
        {t('globalconfig.basicManagement.businessPortal.gnbSort.description')}
      </p>
      <div className="mt-[24px]">
        <div className="flex items-center pl-[8px] h-[48px] rounded-[8px] cursor-not-allowed opacity-50">
          <Bars2SlashIcon className="size-[40px] p-[8px]" />
          <HomeIcon className="size-[24px] ml-[32px]" />
          <p className="ml-[8px] text-[#262626] //text-[--color-text-primary] text-[14px] font-[400]">
            {t('globalconfig.basicManagement.businessPortal.gnbSort.home')}
          </p>
          <div className="flex-grow" />
          <LockClosedIcon className="size-[24px] mr-[16px]" />
        </div>
        {groupList.map((groupName) => {
          const menuList = groupedMenuList.get(groupName) || [];
          return (
            <GroupGnbSort
              key={groupName}
              groupName={groupName}
              menuList={menuList}
              onChange={onChangeGroupMenuSort}
            />
          );
        })}
      </div>
      <div className="w-full mt-[24px] py-[46px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          variant="outline"
          colorset="level1"
          shape="rect"
          onClick={onClickCancel}
        >
          {t('globalconfig.common.button.cancel.title')}
        </Button>
        <Button
          size="medium"
          variant="solid"
          colorset="level1"
          shape="rect"
          disabled={!isNeedsSave}
          onClick={onClickSave}
        >
          {t('globalconfig.common.button.save.title')}
        </Button>
      </div>
    </div>
  );
}
