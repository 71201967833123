/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { memo, useEffect, useRef, useState } from 'react';
import { useDextEditorContext } from './provider';
import { DEXT5_CONFIG_HANDLER_URL } from './contants';
import { getDext5ConfigInitXML } from './utils';
import { clsx } from 'clsx';
import { editorCustomAction } from './editor-custom-action';

export interface Props {
  id: string;
  content?: string;
  className?: string;
  width?: string;
  height?: string;
}

export function DextEditor({
  id,
  content: initContent,
  className,
  width = '100%',
  height = '500px',
}: Props) {
  const { isEditorLoaded, setIsEditorLoaded } = useDextEditorContext();
  const [isLoading, setIsLoading] = useState(false);

  const loaded = useRef(false);

  useEffect(() => {
    if (!(window as any).dext_editor_custom_action) {
      (window as any).dext_editor_custom_action = editorCustomAction;
    }

    if (!(window as any).dext_editor_loaded_event) {
      (window as any).dext_editor_loaded_event = function (editor: any) {
        if (editor.ID === id) {
          DEXT5.setHtmlContentsEw(initContent ?? '', id);
          setIsEditorLoaded(true);
          setIsLoading(false);
        }
      };
    }
    if (DEXT5 && !isLoading && !isEditorLoaded && !loaded.current) {
      loaded.current = true;
      setIsLoading(true);
      DEXT5.config.Width = width;
      DEXT5.config.Height = height;
      DEXT5.config.InitXml = getDext5ConfigInitXML();
      DEXT5.config.HandlerUrl = DEXT5_CONFIG_HANDLER_URL;
      DEXT5.config.EditorHolder = id;
      new Dext5editor(id);
    }

    return () => {
      if (!isLoading && DEXT5) {
        DEXT5.destroy(id, true);
      }
      delete (window as any).dext_editor_loaded_event;
    };
  }, [isLoading, isEditorLoaded]);

  return (
    <div className={clsx('relative', className)}>
      {isLoading && (
        <div
          className="bg-white z-10 absolute top-0 border border-[#b1b2b4]"
          style={{
            width,
            height,
          }}
        >
          Editor Loading...
        </div>
      )}

      <div id={id} className="absoulte top-0"></div>
    </div>
  );
}

export const MemoizedDextEditor = memo(DextEditor);
