import { useRef } from 'react';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';
import { OrganizationChartOrder } from '../../type';
import { clsx } from 'clsx';

export interface Props {
  index: number;
  item: OrganizationChartOrder;
  companyName: string;
  selectedSite: OrganizationChartOrder;
  onSetSelectedSite: (item: OrganizationChartOrder) => void;
}

export function OrgOrderItem({
  index,
  item,
  companyName,
  selectedSite,
  onSetSelectedSite,
}: Props) {
  const dragHandleRef = useRef<HTMLDivElement>(null);

  const offset = {
    x: '10px',
    y: '20px',
  };

  const preview = (
    <div className="flex">
      <span className="ml-5 max-w-[120px] rounded-sm text-white bg-[#363636] overflow-hidden whitespace-nowrap text-ellipsis px-2 py-1">
        {companyName}
      </span>
    </div>
  );

  return (
    <DnDList.Item
      item={item}
      key={index}
      previewInfo={{ preview, offset }}
      dragHandleRef={dragHandleRef}
      styleSet={{
        'is-dragging-over': 'flex items-center bg-[#F2F2F2] rounded-lg',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        ref={dragHandleRef}
        key={item.companyId}
        className="flex items-center w-full h-[40px] py-[6px]"
        onClick={() => {
          onSetSelectedSite(item);
        }}
      >
        <div
          className={clsx(
            'w-full h-[32px] p-[4px] hover:bg-[#F2F2F2] rounded-lg',
            {
              'bg-[#F2F2F2]': selectedSite.companyId === item.companyId,
            },
          )}
          style={{ paddingLeft: `${item.depth * 24 + 4}px` }}
        >
          {companyName}
        </div>
      </div>
    </DnDList.Item>
  );
}

export default OrgOrderItem;
