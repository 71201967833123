export function formatFileSize(fileSize: number): string {
  if (fileSize < 1024) {
    return `${fileSize} B`;
  } else if (fileSize < 1024 * 1024) {
    return `${Math.floor(fileSize / 1024)} KB`;
  } else if (fileSize < 1024 * 1024 * 1024) {
    return `${Math.floor(fileSize / (1024 * 1024))} MB`;
  } else {
    return `${Math.floor(fileSize / (1024 * 1024 * 1024))} GB`;
  }
}
