import { TFA_REGISTRATION_STATUS_URL } from '../../constants';
import { BaseResponseWithPage } from '../../types';
import { TableQueryInfo, TfaMemberInfo, TfaMemberList } from '../types';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export const QUERY_KEY =
  'GlobalConfig.SecureManagement.TfaMemberConfig.TfaMemberList';

export async function getTfaMemberList(
  page: number,
  size: number,
  info: TableQueryInfo,
): Promise<TfaMemberList> {
  try {
    const query = new URLSearchParams();
    query.append('page', page.toString());
    query.append('size', size.toString());
    query.append('orderType', info.orderType);
    query.append('direction', info.direction);
    query.append('searchType', info.searchType);
    query.append('keyword', info.keyword);

    const response = await fetch(
      TFA_REGISTRATION_STATUS_URL + '?' + query.toString(),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getTfaMemberList 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponseWithPage<TfaMemberInfo>;

    return {
      elements: resData.data.elements,
      page: resData.data.page,
    };
  } catch (error) {
    console.log('error', error);
    return {
      elements: [],
      page: {
        pageNumber: 0,
        pageSize: 20,
        totalPages: 0,
        totalElements: 0,
      },
    };
  }
}
