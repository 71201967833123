import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import type { ApiResponse, IPopupAnnouncementForm } from '../types';

interface Params {
  data: IPopupAnnouncementForm;
}

export async function createPopupAnnouncement({ data }: Params) {
  const response = await fetch(`/api/portal/setting/basic/popup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseJson = (await response.json()) as ApiResponse<{ data: string }>;

  if (responseJson.message) {
    throw new Error(responseJson.message);
  }

  return responseJson;
}
