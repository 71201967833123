import { formatDate } from '@dop-ui/react/shared/lib/date';

export const dateToYearMonthDayString = (date: Date | undefined) => {
  if (!date) return undefined;
  return formatDate({ date, format: 'YYYY-MM-DD' });
};

export const dateToMonthDayString = (date: Date | undefined) => {
  if (!date) return undefined;
  return formatDate({ date, format: 'MM-DD' });
};
