import { PropsWithChildren } from 'react';
import {
  Content as DopStackerContent,
  ContentProps,
} from '@dop-ui/react/shared/ui/stacker';

/**
 * @deprecated Use '@dop-ui/react' instead.
 */
export type Props = ContentProps;

/**
 * @deprecated Use '@dop-ui/react' instead.
 */
function Content(props: PropsWithChildren<Props>) {
  return <DopStackerContent {...props} />;
}

export default Content;
