import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid';

interface Props {
  title: string;
  value: string;
  placeholder?: string;
  isMandatory?: boolean;
  minLength?: number;
  maxLength?: number;
  className?: string;
  onChangeName: (name: string) => void;
}

export function TitleAndTextInput({
  title,
  value,
  placeholder = '',
  isMandatory = false,
  minLength = 1,
  maxLength = 64,
  className,
  onChangeName,
}: Props) {
  const [lengthError, setLengthError] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  const onChangeText = (text: string) => {
    onChangeName(text);
  };

  const handleOnBlur = () => {
    setTimeout(() => setOnFocus(false), 10);
  };

  useEffect(() => {
    if (value.length > maxLength || value.length < minLength) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
    if (!isMandatory && value.length === 0) {
      setLengthError(false);
    }
  }, [value, maxLength, minLength, isMandatory]);

  return (
    <>
      <div className={clsx('flex flex-wrap', className)}>
        {isMandatory && (
          <p className="absolute left-[-10px] top-[10px] text-[#E84B4B] //text-[--color-text-caution] text-[14px] font-[400]">
            *
          </p>
        )}
        <p
          className={clsx(
            'pt-[8px] w-[192px] text-[#363636] //text-[--color-text-level1] text-[14px]',
            {
              'font-[500]': isMandatory,
              'font-[400]': !isMandatory,
            },
          )}
        >
          {title}
        </p>

        <div className="flex-grow">
          <div
            className={clsx(
              'relative h-[40px] border border-solid rounded-[8px] px-[12px]',
              {
                'border-[#E84B4B] //border-[--color-border-caution]':
                  lengthError,
                'border-[#D8D8D8] //border-[--color-border-level1]':
                  !lengthError && !onFocus,
                'border-[#808080] //border-[--color-border-field-active]':
                  !lengthError && onFocus,
              },
            )}
          >
            <input
              className="size-full text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]"
              type="text"
              value={value}
              onChange={(e) => onChangeText(e.target.value)}
              onFocus={() => setOnFocus(true)}
              onBlur={() => {
                handleOnBlur();
              }}
              tabIndex={0}
              placeholder={placeholder}
            />
            {onFocus && value !== '' && (
              <button
                className="absolute flex items-center justify-center right-[4px] top-[4px] bottom-[4px] w-[32px] p-[4px]"
                onMouseDown={(e) => {
                  e?.preventDefault();
                  onChangeText('');
                }}
              >
                <XCircleIcon className="size-full" color="#AAAAAA" />
              </button>
            )}
          </div>
          {lengthError && (
            <p className="text-[#E84B4B] //text-[--color-text-caution] text-[13px] font-[400] leading-[19.5px] //leading-[--font-multi-line-height-XSmall]">
              {minLength}자 이상 {maxLength}자 이하로 입력해야 합니다.
            </p>
          )}
        </div>
      </div>
    </>
  );
}
