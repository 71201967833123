import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useProfileManagementContext } from '../../context';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  QUERY_KEY,
  getCustomProfileList,
} from '../../apis/get-custom-profile-list';
import { useEffect } from 'react';
import { ChevronRightIcon } from '@dop-ui/icons/react/dop/24';

export function CustomProfileManagement() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();
  const { setProfileManagementContext } = useProfileManagementContext();

  const { data: customProfileList, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getCustomProfileList(),
  });

  useEffect(() => {
    if (customProfileList) {
      setProfileManagementContext((prev) => ({
        ...prev,
        customProfileList,
      }));
    }
  }, [customProfileList]);

  if (error) {
    console.error(
      'Error: GlobalConfig > BasicManagement > ProfileConfig > CustomProfileManagement: ',
      error,
    );
  }

  return (
    <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex justify-between items-center">
        <p className="text-[#333333] text-[22px] font-[500]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement',
          )}
        </p>
        <div className="flex gap-[24px] justify-center items-center">
          <p className="text-[12px] text-[#888]">
            {customProfileList.length}
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.count',
            )}
          </p>
          <Button
            className="!w-[12px] !h-[12px]"
            onClick={() => stacker.push('customProfile')}
          >
            <ChevronRightIcon size={12} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomProfileManagement;
