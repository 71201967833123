import * as React from 'react';
import { TAB_KEY_SET_APPS } from '../../constants';

interface IContextValue {
  currentTab: string;
}

interface IContextAction {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const TabContextValue = React.createContext<IContextValue>({
  currentTab: '',
});

export const TabContextAction = React.createContext<IContextAction>({
  setCurrentTab: () => undefined,
});

export function TabProvider({ children }: { children?: React.ReactNode }) {
  const [currentTab, setCurrentTab] = React.useState(TAB_KEY_SET_APPS);

  const contextValue = {
    currentTab,
  };

  const contextAction = {
    setCurrentTab,
  };

  return (
    <TabContextAction.Provider value={contextAction}>
      <TabContextValue.Provider value={contextValue}>
        {children}
      </TabContextValue.Provider>
    </TabContextAction.Provider>
  );
}

export const useTabContextValue = () => React.useContext(TabContextValue);
export const useTabContextAction = () => React.useContext(TabContextAction);
export const useTabContext = () => ({
  ...useTabContextValue(),
  ...useTabContextAction(),
});
