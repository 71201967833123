import { GROUPWARE_API } from '../../constants';
import { AssetTreeNodeModel } from '../types';

export interface Response {
  data: AssetTreeNodeModel[];
}

export const QUERY_KEY = 'assetListQuery';

export async function getAssetList(
  companyUuid?: string,
): Promise<AssetTreeNodeModel[]> {
  try {
    const data = (await (
      await fetch(`${GROUPWARE_API}/system/asset/list/${companyUuid}`)
    ).json()) as Response;
    return data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}
