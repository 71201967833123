import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Field } from './field';
import { useActionsContext, useValuesContext } from './provider';

export interface Props {
  openState: boolean;
  trigger: ReactNode;
  onOpenStateChange: (openState: boolean) => void;
  onCancel?: () => void;
  onSave?: () => void;
}

export function ShareConfigDialog({
  openState,
  onOpenStateChange,
  trigger,
  onCancel,
  onSave,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('component');
  const { selectedAppCode, selectedCompany, companyList } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();

  const replaceContents = () => {
    if (selectedAppCode === 'dop-board') {
      return {
        appName: t('globalconfig.multiCompanyManagement.appShare.board.title'),
      };
    } else if (selectedAppCode === 'dop-asset') {
      return {
        appName: t('globalconfig.multiCompanyManagement.appShare.asset.title'),
      };
    } else if (selectedAppCode === 'dop-calendar') {
      return {
        appName: t(
          'globalconfig.multiCompanyManagement.appShare.calendar.title',
        ),
      };
    } else {
      return {
        appName: t(
          'globalconfig.multiCompanyManagement.appShare.approval.title',
        ),
      };
    }
  };

  const onCancelHandler = () => {
    onCancel && onCancel();
    onOpenStateChange(false);
  };

  const onSaveHandler = () => {
    onSave && onSave();
    onOpenStateChange(false);
  };

  useEffect(() => {
    setSelectedCompany(companyList?.[0] ?? null);
  }, [companyList, setSelectedCompany]);

  return (
    <Dialog.Root open={openState} onOpenChange={onOpenStateChange}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Overlay />
      <Dialog.Content>
        <div className="!w-[640px] flex flex-col px-10 gap-4 py-2">
          <h1 className="text-[#363636] text-[20px] font-medium leading-[30px] tracking-[-0.4px] mb-1">
            {t(
              'globalconfig.multiCompanyManagement.appShare.dialog.title',
              replaceContents(),
            )}
          </h1>
          <Field
            title={t(
              'globalconfig.multiCompanyManagement.appShare.dialog.field.selectSite',
            )}
          >
            <select
              className="grow px-3 text-[14px] h-[40px] text-[#363636] font-normal rounded-[8px] border border-solid border-[#D8D8D8]"
              value={
                selectedCompany?.companyUuid ?? companyList?.[0]?.companyUuid
              }
              onChange={(e) => {
                setSelectedCompany(
                  companyList.find(
                    (companyInfo) => companyInfo.companyUuid === e.target.value,
                  ) ?? null,
                );
              }}
            >
              {companyList.map((companyInfo) => (
                <option
                  key={`option-${companyInfo.companyUuid}`}
                  value={companyInfo.companyUuid}
                >
                  {companyInfo.companyName}
                </option>
              ))}
            </select>
          </Field>
          {children}
          <div className="mt-[30px] flex justify-end gap-4">
            <Button
              styleType="ghost"
              shape="round"
              size="medium"
              onClick={onCancelHandler}
            >
              {t('globalconfig.common.button.cancel.title')}
            </Button>
            <Button
              styleType="solid"
              shape="rect"
              size="medium"
              variant="solid"
              colorset="level1"
              onClick={onSaveHandler}
            >
              {t('globalconfig.common.button.ok.title')}
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default ShareConfigDialog;
