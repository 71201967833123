import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { QueryClient } from '@tanstack/react-query';
import { formatFileNameWithDate } from '../../../../../../../../lib/utils/date/withLocale';
import * as deletedDeptDataDownloadQuery from '../../../api/deletedDeptListQuery';
import { Page } from '../../../types';
interface ApprovalData {
  deptId?: number;
  docCount?: number;
  folderName?: string;
  folderType?: string;
}

export interface ResponseApprovalBody {
  page?: Page;
  data?: ApprovalData[];
}

export function Header() {
  const { t } = useTranslation('component');

  const onClickDownloadButtonHandler = async () => {
    try {
      const queryClient = new QueryClient();
      await queryClient
        .fetchQuery({
          queryKey: [deletedDeptDataDownloadQuery.DOWNLOAD_QUERY_KEY],
          queryFn: () =>
            deletedDeptDataDownloadQuery.deletedDeptListDownloadQuery(),
        })
        .then((blob: Blob | undefined) => blob && blobHandler(blob));
    } catch (error) {
      console.error(error);
    }
  };

  const blobHandler = (blob: Blob) => {
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = formatFileNameWithDate(
      t('globalconfig.dataManagement.deletedDeptManagement.fileTitle'),
      new Date(),
      'csv',
    );
    link.click();
  };

  return (
    <div className="flex items-center justify-between">
      <h2 className="ms-2 font-medium text-[22px] -tracking-[0.88px] mr-3">
        {t('globalconfig.dataManagement.deletedDeptManagement.title')}
      </h2>
      <Button
        variant="outline"
        colorset="level2"
        size="md"
        shape="rect"
        onClick={() => void onClickDownloadButtonHandler()}
      >
        <ArrowDownTrayIcon className="size-4" />
        {t('globalconfig.common.button.downloadList.title')}
      </Button>
    </div>
  );
}

export default Header;
