import { Button } from '@dop-ui/react/shared/ui/button';
import { InUseIndicator } from '../../../../secureManagement/LoginSecureConfig/InUseIndicator';
import { TitleAndDescription } from '../../../../secureManagement/LoginSecureConfig/TitleAndDescription';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useBasicInfoContext } from '../../Context';
import { ChevronRightIcon } from '@dop-ui/icons/react/dop/24';

export function OrgShareConfigIndicator() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();
  const {
    basicInfoContext: {
      orgConfig: { isOrganizationChartShare },
    },
  } = useBasicInfoContext();

  return (
    <div className="py-[28px]">
      <TitleAndDescription
        title={t(
          'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.title',
        )}
      >
        <InUseIndicator inUse={isOrganizationChartShare === 'true'} />
        <Button
          className="!w-[24px] !h-[24px]"
          onClick={() => stacker.push('orgShare')}
        >
          <ChevronRightIcon size={24} />
        </Button>
      </TitleAndDescription>
    </div>
  );
}

export default OrgShareConfigIndicator;
