/* eslint-disable import/no-named-as-default-member */

import dayjs, { isDayjs } from 'dayjs';

export interface FormatDateProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  format: string;
}

export const formatDate = ({ date: receivedDate, format }: FormatDateProps) => {
  const parsedDate = date(receivedDate);
  if (!dayjs.isDayjs(parsedDate)) return '';
  return parsedDate.format(format);
};

interface CompareDateProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  compareDate: string | number | Date | dayjs.Dayjs | null | undefined;
  unit: dayjs.UnitType;
}
export const isBefore = ({ date, compareDate, unit }: CompareDateProps) => {
  const parsedDate = dayjs(date);
  const parsedCompareDate = dayjs(compareDate);

  return parsedDate.isBefore(parsedCompareDate, unit);
};

export const isAfter = ({ date, compareDate, unit }: CompareDateProps) => {
  const parsedDate = dayjs(date);
  const parsedCompareDate = dayjs(compareDate);

  return parsedDate.isAfter(parsedCompareDate, unit);
};

export const isSame = ({ date, compareDate, unit }: CompareDateProps) => {
  const parsedDate = dayjs(date);
  const parsedCompareDate = dayjs(compareDate);

  return parsedDate.isSame(parsedCompareDate, unit);
};

interface OperationProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  value: number;
  unit: dayjs.ManipulateType;
}

export const add = ({ date, value, unit }: OperationProps) => {
  const parsedDate = dayjs(date);

  return parsedDate.add(value, unit);
};

export const subtract = ({ date, value, unit }: OperationProps) => {
  const parsedDate = dayjs(date);
  return parsedDate.subtract(value, unit);
};

export const getDay = (date?: string) => {
  const parsedDate = dayjs(date);
  return parsedDate.day();
};

export const getMonth = (date?: string) => {
  const parsedDate = dayjs(date);
  return parsedDate.month();
};

export const getYear = (date?: string) => {
  const parsedDate = dayjs(date);
  return parsedDate.year();
};

export const date = (date?: unknown) => {
  try {
    return dayjs(
      date as string | number | Date | dayjs.Dayjs | null | undefined,
    );
  } catch (e) {
    return dayjs(new Date());
  }
};

export const isValid = (date: unknown) => {
  return isDayjs(date);
};
