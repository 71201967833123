import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface IDextEditorContexts {
  id: string;
  content: string;
  isEditorLoaded: boolean;
  setId: (val: string) => void;
  setContent: (val: string) => void;
  setIsEditorLoaded: (val: boolean) => void;
}

function createDefaultDnDContext(): IDextEditorContexts {
  return {
    id: '',
    content: '',
    isEditorLoaded: false,
    setId: () => false,
    setContent: () => {},
    setIsEditorLoaded: () => {},
  };
}

export const DextEditorContext = createContext<IDextEditorContexts>(
  createDefaultDnDContext(),
);

export function Provider({ children }: PropsWithChildren) {
  const [id, setId] = useState('');
  const [content, setContent] = useState('');
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);

  const ctx: IDextEditorContexts = {
    id,
    content,
    isEditorLoaded,
    setId,
    setContent,
    setIsEditorLoaded,
  };

  return (
    <DextEditorContext.Provider value={ctx}>
      {children}
    </DextEditorContext.Provider>
  );
}

export const useDextEditorContext = () => useContext(DextEditorContext);

export default Provider;
