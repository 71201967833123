import { CloudDownloadIcon } from '@dop-ui/icons/react/dop/24/cloud-download';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { loadNewHolidayList, QUERY_KEY } from '../../apis/schedule-manage';

interface Props {
  searchingYear: number;
}

export function LoadHolidaysConfirmDialog({ searchingYear }: Props) {
  const { t } = useTranslation('component');
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const loadScheduleMutation = useMutation({
    mutationFn: () => loadNewHolidayList(searchingYear),
    onSuccess: (data) => {
      if (data === 0) {
        toaster.info(
          t(
            'globalconfig.basicManagement.companySchedule.loadHolidays.success.nomore',
          ),
        );
      } else if (data > 0) {
        toaster.info(
          t(
            'globalconfig.basicManagement.companySchedule.loadHolidays.success',
            { count: data },
          ),
        );
      } else {
        toaster.warning(t('globalconfig.common.toastMessage.success'));
      }
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      setIsOpen(false);
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const onClickLoadHolidays = () => {
    loadScheduleMutation.mutate();
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <div className="flex items-center ml-[4px] h-[40px] px-[4px]">
          <CloudDownloadIcon className="size-[24px]" />
          <p className="ml-[4px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
            {t(
              'globalconfig.basicManagement.companySchedule.loadHolidays.title',
            )}
          </p>
        </div>
      </Dialog.Trigger>
      <Dialog.Primitives.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="w-[460px] bg-white px-[24px] py-[24px] rounded-[16px]"
          showCloseButton={false}
          useAutoClose={false}
        >
          <p className="text-[#363636] //text-[--color-text-level1] text-[20px] font-[600] leading-[30px]">
            {t(
              'globalconfig.basicManagement.companySchedule.loadHolidays.title',
            )}
          </p>
          <p className="mt-[16px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400] whitespace-pre-wrap">
            {t(
              'globalconfig.basicManagement.companySchedule.loadHolidays.description',
            )}
          </p>
          <div className="flex justify-end mt-[24px] gap-[12px]">
            <Dialog.Primitives.Close asChild>
              <Button
                size="medium"
                shape="rect"
                colorset="level2"
                variant="ghost"
              >
                <p className="font-[400]">{t('dialog.cancel')}</p>
              </Button>
            </Dialog.Primitives.Close>
            <Button
              size="medium"
              shape="rect"
              colorset="level1"
              variant="solid"
              onClick={onClickLoadHolidays}
            >
              <p className="font-[400]">
                {t(
                  'globalconfig.basicManagement.companySchedule.loadHolidays.buttonTitle',
                )}
              </p>
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Primitives.Portal>
    </Dialog.Root>
  );
}
