import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import * as deletedDeptListQuery from '../../../api/deletedDeptListQuery';
import { DeletedDeptInfo, Option } from '../../../types';
import { formatBytes } from '../../../utils/byteFormatter';
import { useActionsContext, useContext } from '../../Provider';

export function DeletedDeptTable() {
  const { t } = useTranslation('component');
  const { setSelectedRows, setDeptId, setDeptName } = useActionsContext();
  const { deptId, selectedAppType } = useContext();
  const [currentPage, setCurrentPage] = useState(0);

  const onPageChangeHandler = (page: number) => setCurrentPage(page);
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [deletedDeptListQuery.LIST_QUERY_KEY],
    queryFn: async () =>
      await deletedDeptListQuery.deletedDeptListQuery({ offset: 10 } as Option),
  });

  useEffect(() => {
    refetch().catch((error) => console.error(error));
  }, [currentPage, selectedAppType, deptId, refetch]);

  if (error) {
    return <>{error}</>;
  }

  const columnDefs: ColumnDef<DeletedDeptInfo>[] = [
    columnDefGenerator<DeletedDeptInfo>(
      'name',
      t('globalconfig.common.table.header.dept'),
    ),
    {
      accessorKey: 'attachSize',
      id: 'attachSize',
      header: () => (
        <div className="flex items-center px-5 py-3 text-left gap-1">
          {t('globalconfig.common.table.header.storageUsage')}
        </div>
      ),
      cell: (value: CellContext<DeletedDeptInfo, unknown>) => (
        <div className="px-5 py-3 text-left">
          {formatBytes(value.renderValue() as number)}
        </div>
      ),
    },
    columnDefGenerator<DeletedDeptInfo>(
      'deletedAt',
      t('globalconfig.common.table.header.deletedDate'),
    ),
    columnDefGenerator<DeletedDeptInfo>(
      'deleteAdminName',
      t('globalconfig.common.table.header.deletedName'),
    ),
  ];

  const rowClickHandler = (selectedRowIndex: number) => {
    const selectedDeptInfo = res?.data?.at(selectedRowIndex);
    if (selectedDeptInfo) {
      setDeptId(selectedDeptInfo.deptId);
      setDeptName(selectedDeptInfo.name);
    }
  };

  return res?.data?.length === 0 ? (
    <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
      {t('globalconfig.common.table.noData')}
    </div>
  ) : (
    <TablePrimitives.Root
      selectable={false}
      contents={res?.data ?? []}
      columnDefs={columnDefs}
      onSelectContents={setSelectedRows}
      onClickRow={rowClickHandler}
    >
      <TablePrimitives.Contents />
      <TablePrimitives.Pagination
        currentPage={currentPage}
        onPreviousPage={onPageChangeHandler}
        onNextPage={onPageChangeHandler}
        onClickPage={onPageChangeHandler}
        onFirstPage={() => setCurrentPage(0)}
        onLastPage={() =>
          setCurrentPage(res?.page?.totalPage ? res?.page?.totalPage - 1 : 0)
        }
        maxPageCount={res?.page?.offset ?? 10}
        totalPage={res?.page?.totalPage ?? 0}
      />
    </TablePrimitives.Root>
  );
}

export default DeletedDeptTable;
