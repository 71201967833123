import { useEffect, useState } from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMultiMember } from '../apis/create-multi-member';
import { updateMultiMember } from '../apis/update-multi-member';
import { useAuthContext } from '../../../../../../lib/auth/client';
import * as getMultiMemberListQueries from '../apis/get-multi-member-list';

import { Button } from '@dop-ui/react/shared/ui/button';
import OrgChart from '../../../../../OrgChart';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import SelectedMultiMemberList from './selected-multi-member-list';

import { FoldIcon } from '@daouoffice/ui/lib/icons/dop/16';
import styles from './multi-member-setting.module.css';
import { TRANSLATION_PREFIX } from '../constants';
import type { MultiMember } from '../types';
import { NodeProps } from '../../../../../OrgChart/interface';
import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';

interface Props {
  title: string;
  open: boolean;
  onOpenChange: (value: boolean) => void;
  integrationId?: number;
  initialValue?: MultiMember[];
}
function MultiMemberSetting({
  title,
  open,
  onOpenChange,
  initialValue,
  integrationId,
}: Props) {
  const { t } = useTranslation('component');
  const { me } = useAuthContext();

  const { info } = useToastMessage();
  const handleOnCancel = () => {
    onOpenChange(false);
  };

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: createMultiMember,

    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getMultiMemberListQueries.QUERY_KEY],
      });
      info(<Templates.Basic description={t(`${TRANSLATION_PREFIX}.saved`)} />);
      handleOnCancel();
    },
    onError: (e) => {
      info(<Templates.Basic description={e.message} />);
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateMultiMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getMultiMemberListQueries.QUERY_KEY],
      });
      info(<Templates.Basic description={t(`${TRANSLATION_PREFIX}.saved`)} />);
      handleOnCancel();
    },
    onError: (e) => {
      info(<Templates.Basic description={e.message} />);
    },
  });

  const [selectedMember, setSelectedMember] = useState<NodeProps>();
  const [memberList, setMemberList] = useState(
    initialValue ? [...initialValue] : [],
  );

  const handleOnConfirm = () => {
    if (integrationId) {
      updateMutation.mutate({
        integrationId,
        companyGroupId: me?.companyGroupId,
        userList: memberList,
      });
    } else {
      createMutation.mutate({
        userList: memberList,
        companyGroupId: me?.companyGroupId,
      });
    }
  };

  const adapter = (node: NodeProps) => {
    const multiMember: MultiMember = {
      positionId: node.positionId,
      positionName: node.position ?? '',
      profileImageUrl: node.profileImageUrl,
      userId: node.userId!,
      userName: node.name,
      companyId: node.companyId,
      companyName: node.companyName ?? '',
      gradeId: node.gradeId,
      gradeName: node.gradeName ?? '',
      loginId: node.loginId ?? '',
      email: node.email,
      departmentPathList: node.departmentPathList ?? [],
    };
    return multiMember;
  };

  const appendMemberToMultiMember = () => {
    if (selectedMember) {
      const member = adapter(selectedMember);

      setMemberList((prevList) => {
        const found = prevList.find(
          (item) =>
            item.companyId === member.companyId &&
            item.userId === member.userId,
        );
        if (found) return prevList;
        return [...prevList, member];
      });
      setSelectedMember(undefined);
    }
  };

  useEffect(() => {
    setMemberList(initialValue ? [...initialValue] : []);
  }, [initialValue, open]);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        title={title}
        position="center"
        animation="popup"
        usePortal
        useOverlay
        useAutoClose={false}
        className={styles.Container}
      >
        <Dialog.Description className={styles.Body}>
          <div className={styles.DescriptionList}>
            <p>{t(`${TRANSLATION_PREFIX}.setting.desc1`)}</p>
            <p>{t(`${TRANSLATION_PREFIX}.setting.desc2`)}</p>
          </div>

          <div className={styles.Organization}>
            <OrgChart
              type="MEMBER"
              style={{
                width: '360px',
                height: '550px',
                overflowY: 'scroll',
                padding: '24px 0px',
                borderRadius: '16px',
                border: '1px solid #f2f2f2',
                boxShadow: 'none',
                position: 'static',
              }}
              useGroupOrg
              useMultiSelect={false}
              setLastSelectedNode={setSelectedMember}
              useHeader={false}
              useFooter={false}
            />
            <Button
              className={styles.AddButton}
              onClick={appendMemberToMultiMember}
            >
              <FoldIcon />
            </Button>
            <SelectedMultiMemberList
              memberList={memberList}
              setMemberList={setMemberList}
            />
          </div>

          <div className={styles.Footer}>
            <Button
              size="medium"
              shape="rect"
              variant="ghost"
              colorset="level1"
              onClick={handleOnCancel}
            >
              {t(`${TRANSLATION_PREFIX}.setting.cancel`)}
            </Button>
            <Button
              size="medium"
              shape="rect"
              colorset="level1"
              variant="solid"
              onClick={handleOnConfirm}
              disabled={memberList.length < 2}
            >
              {t(`${TRANSLATION_PREFIX}.setting.save`)}
            </Button>
          </div>
        </Dialog.Description>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default MultiMemberSetting;
