import { date, formatDate } from '@dop-ui/react/shared/lib/date';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as FilterPrimitives from '@dop-ui/react/shared/ui/filter';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { DatePicker } from '../../../../../foundation/DatePicker';
import {
  FILTER_OPTION_DATE_30DAYS,
  FILTER_OPTION_DATE_7DAYS,
  FILTER_OPTION_DATE_90DAYS,
  FILTER_OPTION_DATE_SELF_INPUT,
  FILTER_OPTION_DATE_SELF_INPUT_END,
  FILTER_OPTION_DATE_SELF_INPUT_START,
  FILTER_OPTION_DATE_TODAY,
} from '../../constants';
import {
  activatedFiltersAtom,
  deleteActivatedFiltersAtom,
} from '../../stores/activatedFilterAtom';
import { FilterOption } from '../../types';

export interface Props {
  className?: string;
  title: string;
  filterKey: string;
  dateOptions?: FilterOption[];
  minDate?: Date;
  maxDate?: Date;
}

export function DateFilter({
  className,
  title,
  filterKey,
  dateOptions,
  minDate,
  maxDate = new Date(),
}: Props) {
  const { t } = useTranslation();
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);
  const [, addActivatedFilters] = useAtom(activatedFiltersAtom);
  const deleteActiveFilters = useSetAtom(deleteActivatedFiltersAtom);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const options: FilterOption[] = dateOptions
    ? dateOptions
    : [
        {
          key: FILTER_OPTION_DATE_TODAY,
          value: '0',
          title: t('days.filter.today'),
        },
        {
          key: FILTER_OPTION_DATE_7DAYS,
          value: '7',
          title: t('days.filter.ago.7days'),
        },
        {
          key: FILTER_OPTION_DATE_30DAYS,
          value: '30',
          title: t('days.filter.ago.30days'),
        },
        {
          key: FILTER_OPTION_DATE_90DAYS,
          value: '90',
          title: t('days.filter.ago.90days'),
        },
        {
          key: FILTER_OPTION_DATE_SELF_INPUT,
          value: 'selfInput',
          title: t('days.filter.selfInput'),
        },
      ];

  const onOptionChange = (values: Set<string>) => {
    const selected = options.filter((option) => values.has(option.value));

    if (selected.length === 0) {
      setStartDate(undefined);
      setEndDate(undefined);
      return;
    }

    const selectedOption = selected[0]!;

    if (selectedOption.value === 'selfInput') {
      setIsDatePickerDisabled(false);
    } else {
      setIsDatePickerDisabled(true);
      const today = date();
      const prevDay = today.subtract(Number(selectedOption.value), 'day');
      setStartDate(prevDay.toDate());
      setEndDate(today.toDate());
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      addActivatedFilters([
        {
          key: `${filterKey}startDate`,
          value: formatDate({ date: startDate, format: 'YYYY-MM-DD' }),
        },
        {
          key: `${filterKey}endDate`,
          value: formatDate({ date: endDate, format: 'YYYY-MM-DD' }),
        },
      ]);
    } else {
      deleteActiveFilters([`${filterKey}startDate`, `${filterKey}endDate`]);
    }
  }, [startDate, endDate]);

  return (
    <FilterPrimitives.Root
      name={title}
      onChangeValues={(options) => onOptionChange(options)}
    >
      <FilterPrimitives.Trigger className={className} />
      <FilterPrimitives.Content>
        {options.length > 0 &&
          options
            .filter((option) => option.key !== FILTER_OPTION_DATE_SELF_INPUT)
            .map((option) => (
              <FilterPrimitives.Option
                key={option.key}
                option={{ value: option.value, label: option.title }}
              >
                {option.title}
              </FilterPrimitives.Option>
            ))}
        {options.length > 0 &&
          options
            .filter((option) => option.key === FILTER_OPTION_DATE_SELF_INPUT)
            .map((option) => (
              <FilterPrimitives.DateSelfInputOption
                className="flex flex-col justify-start items-start"
                key={option.key}
                option={{ value: option.value, label: option.title }}
              >
                <div
                  className="mt-[4px] mb-[8px] w-full flex gap-[4px] items-center"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.stopPropagation();
                    }
                  }}
                >
                  <DatePicker
                    disabled={isDatePickerDisabled}
                    defaultDate={startDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onDateSelect={(date) => {
                      setStartDate(date ? date : undefined);
                      addActivatedFilters([
                        {
                          key: FILTER_OPTION_DATE_SELF_INPUT_START,
                          value: formatDate({
                            date: date,
                            format: 'YYYY-MM-DD',
                          }),
                        },
                      ]);
                    }}
                  >
                    <div className="w-[77px] p-[6px] border border-solid border-[#D8D8D8] rounded-[4px] text-[11px]">
                      {formatDate({ date: startDate, format: 'YYYY-MM-DD' })}
                    </div>
                  </DatePicker>
                  {' - '}
                  <DatePicker
                    disabled={isDatePickerDisabled}
                    defaultDate={endDate}
                    minDate={startDate}
                    maxDate={maxDate}
                    onDateSelect={(date) => {
                      setEndDate(date ? date : undefined);
                      addActivatedFilters([
                        {
                          key: FILTER_OPTION_DATE_SELF_INPUT_END,
                          value: formatDate({
                            date: date,
                            format: 'YYYY-MM-DD',
                          }),
                        },
                      ]);
                    }}
                  >
                    <div className="w-[77px] p-[6px] border border-solid border-[#D8D8D8] rounded-[4px] text-[11px]">
                      {formatDate({ date: endDate, format: 'YYYY-MM-DD' })}
                    </div>
                  </DatePicker>
                </div>
              </FilterPrimitives.DateSelfInputOption>
            ))}
      </FilterPrimitives.Content>
    </FilterPrimitives.Root>
  );
}
