import { GROUPWARE_API } from '../../constants';
import { ShareInfo } from '../types';

export interface Response {
  data: ShareInfo[];
  hasNext: boolean;
  totalPage: number;
  message?: string;
}

export const QUERY_KEY = 'sharedAssetListQuery';

interface Props {
  page: number;
  offset: number;
  companyGroupId?: string | number;
  sortDirection: string;
}

export async function getSharedAssetList({
  page,
  offset,
  companyGroupId,
  sortDirection,
}: Props): Promise<Response> {
  const response = await fetch(
    `${GROUPWARE_API}/system/companygroup/${companyGroupId}/asset/shares`,
  );
  const data = (await response.json()) as Response;

  if (data.message && data.message !== 'OK') {
    return {
      data: [],
      hasNext: false,
      totalPage: 0,
      message: data.message,
    };
  }

  if (!data.data) data.data = [];

  let list = data.data;
  if (sortDirection) {
    list = list.sort((a, b) => {
      return sortDirection === 'asc'
        ? a.companyName.localeCompare(b.companyName)
        : b.companyName.localeCompare(a.companyName);
    });
  }
  const startIndex = page * offset;
  const endIndex = startIndex + offset;
  const totalPage = Math.ceil(list.length / offset - 1);
  const hasNextPage = page < totalPage;

  return {
    data: list.slice(startIndex, endIndex),
    totalPage,
    hasNext: hasNextPage,
  };
}
