import { PopupOptions } from './types';

export const POPUP_DICTIONARY =
  'globalconfig.basicManagement.popupAnnouncement';

export const POPUP_OPTIONS: Record<PopupOptions, string> = {
  DO_NOT_OPEN_ANYMORE: `${POPUP_DICTIONARY}.options.doNotOpenAnymore`,
  DO_NOT_OPEN_FOR_A_WEEK: `${POPUP_DICTIONARY}.options.doNotOpenForAWeek`,
  DO_NOT_OPEN_FOR_A_DAY: `${POPUP_DICTIONARY}.options.doNotOpenForADay`,
  SHOW_EVERY_TIME: `${POPUP_DICTIONARY}.options.showEveryTime`,
};

export const POPUP_STORAGE_KEY = 'LocalKeys.PopupAnnouncement.Unsubscribe';
