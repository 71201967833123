import { DeletedDeptTable } from './DeletedDeptTable';
import { Header } from './Header';

export function DefaultContents() {
  return (
    <div className="flex flex-col w-full gap-6">
      <Header />
      <DeletedDeptTable />
    </div>
  );
}

export default DefaultContents;
