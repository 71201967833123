import { Response, ShareInfo } from '../../../../types';
import { APP_SHARE_BOARD_LIST_URL } from '../constants';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.AppShare.boardShareListQuery';

export async function getBoardSharedList(
  companyGroupId: string | number,
): Promise<ShareInfo[]> {
  try {
    const response = await fetch(APP_SHARE_BOARD_LIST_URL(companyGroupId), {
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get board share list.');
    }

    const resData = (await response.json()) as Response<ShareInfo>;

    return resData.data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
