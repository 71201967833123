import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { GROUPWARE_API } from '../../constants';
import type { ShareInfo, ShareRequestModel } from '../types';

interface Params {
  companyGroupId?: string | number;
  data: ShareRequestModel;
}

export async function createAssetShare({ companyGroupId, data }: Params) {
  const response = await fetch(
    `${GROUPWARE_API}/system/companygroup/${companyGroupId}/asset/shares`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );
  const responseJson = (await response.json()) as {
    data: ShareInfo[];
    message?: string;
  };

  if (!response.ok) {
    throw new Error(responseJson.message ?? 'createAssetShareList 오류 발생');
  }

  return responseJson;
}
