import { AppInfoTable } from './AppInfoTable';
import { Header } from './Header';

export function AppContents() {
  return (
    <div className="flex flex-col w-full gap-6">
      <Header />
      <AppInfoTable />
    </div>
  );
}

export default AppContents;
