import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useFilterContext } from './context';

interface Props {
  className?: string;
  option: {
    value: string;
    label: string;
  };
}

export function Option({
  className,
  option,
  children,
}: PropsWithChildren<Props>) {
  const {
    multiSelectable,
    selectedOptions,
    selectedOptionLabels,
    setSelectedOptions,
    setSelectedOptionLabels,
  } = useFilterContext();

  if (multiSelectable) {
    return (
      <div
        className={clsx(
          'dop_checkbox bg-transparent',
          'flex items-center px-[8px] h-[40px] w-full hover:bg-[#F2F2F2] rounded-[8px]',
          className,
        )}
      >
        <input
          type="checkbox"
          id={`schedule-filter-${option.value}`}
          checked={selectedOptions.has(option.value)}
          value={option.value}
          onChange={() => {
            const newSelectedOptions = new Set(selectedOptions);

            if (selectedOptions.has(option.value)) {
              newSelectedOptions.delete(option.value);
            } else {
              newSelectedOptions.add(option.value);
            }
            setSelectedOptions(newSelectedOptions);
            const newLabels = new Map(selectedOptionLabels);
            newLabels.set(option.value, option.label);
            setSelectedOptionLabels(newLabels);
          }}
        />
        <label
          className="w-full text-start"
          htmlFor={`schedule-filter-${option.value}`}
        >
          {children}
        </label>
      </div>
    );
  }

  return (
    <button
      className={clsx(
        'flex items-center px-[8px] min-h-[40px] w-full hover:bg-[#F2F2F2] rounded-[8px]',
        className,
      )}
      onClick={() => {
        const newLabels = new Map(selectedOptionLabels);
        newLabels.set(option.value, option.label);
        setSelectedOptionLabels(newLabels);
        if (selectedOptions.has(option.value)) {
          setSelectedOptions(new Set());
        } else {
          setSelectedOptions(new Set([option.value]));
        }
      }}
    >
      {children}
      <div className="flex-grow" />
      {selectedOptions.has(option.value) && (
        <CheckIcon className="size-[20px]" color="#1C1C1C" />
      )}
    </button>
  );
}
