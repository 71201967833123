'use client';

import { CloseIcon } from '@dop-ui/icons/react/dop/24';
import { Avatar } from '../../../../shared/ui/avatar';
import { Button } from '../../../../shared/ui/button';
import type { Notification } from '../types';
import styles from './noti-item.module.css';
import Link from 'next/link';
import { MouseEvent } from 'react';

import useNotificationMutations from '../hooks/use-notification-mutations';
import useRelativeTime from '../../../../shared/lib/date/use-relative-time';

interface Props extends Notification {
  useDelete?: boolean;
}
function NotificationItem({
  companyName,
  senderType,
  createdAt,
  linkUrl,
  message,
  notificationId,
  read,
  senderName,
  senderProfile,
  useDelete = true,
}: Props) {
  const textOverLengthCut = (message: string) => {
    if (message.length <= 500) return message;
    else {
      return `${message.slice(0, 500)}...`;
    }
  };

  const { readOne, deleteOne } = useNotificationMutations();

  const handleOnDelete = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent> | undefined,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    deleteOne(notificationId);
  };

  const handleOnLink = () => {
    readOne(notificationId);
  };

  const { relativeTime } = useRelativeTime({ date: createdAt, locale: 'ko' });

  return (
    <Link
      href={linkUrl}
      key={notificationId}
      className={styles.Notification}
      onClick={handleOnLink}
    >
      {/* TODO: senderType에 따른 로직 분리 */}
      <Avatar
        className={read ? 'opacity-50' : ''}
        size={40}
        src={senderProfile}
        alt={senderName}
      />
      <div className={styles.Contents}>
        <p className={(styles.Message, read ? 'opacity-50' : '')}>
          {textOverLengthCut(message)}
        </p>
        <div className={styles.MessageInfos}>
          <p>{relativeTime}</p>·<p>{senderName}</p>·<p>{companyName}</p>
        </div>
      </div>
      {useDelete && (
        <Button className={styles.CloseButton} onClick={handleOnDelete}>
          <CloseIcon size={16} />
        </Button>
      )}
    </Link>
  );
}

export default NotificationItem;
