import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  ArrowLeftIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip/dop-tooltip';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { SaveConfirmDialog } from '../../../parts/components/save-confirm-dialog';
import { IPListManager } from '../components/ip-list-manager';
import {
  getDetailIpPolicyConfig,
  QUERY_KEY,
  updateRestrictedIPList,
} from './apis/accessRestrictedIP';
import * as loginSecureConfigAPIs from './apis/loginSecureConfig';
import { isIpBlockListNeedsSaveAtom } from './store/isIpBlockListNeedsSaveAtom';
import { TitleAndDescription } from './TitleAndDescription';
import { DetailLoginIpPolicyConfig } from './types';
import { downloadIpList } from './apis/download-ip-list';

export function AccessRestrictedIP() {
  const { t } = useTranslation('component');
  const [isUsingRestriction, setIsUsingRestriction] = useState(false);

  const [isConfigNeedsSave, setIsConfigNeedsSave] = useAtom(
    isIpBlockListNeedsSaveAtom,
  );
  const {
    data: configData,
    error,
    isLoading,
  } = useQuery({
    queryKey: [loginSecureConfigAPIs.QUERY_KEY_ACCESS_IP],
    queryFn: loginSecureConfigAPIs.getAccessIpConfig,
  });

  const {
    data: detailConfig,
    error: detailConfigError,
    isLoading: ipListLoading,
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getDetailIpPolicyConfig(),
  });

  const [detailIpPolicyConfig, setDetailIpPolicyConfig] =
    useState<DetailLoginIpPolicyConfig>({
      ipAccessPolicyType: 'DISALLOW',
      ipAccessPolicyInfoList: [],
    });

  const queryClient = useQueryClient();
  const { confirm } = useMessageDialog();

  const downloadIpListMutation = useMutation({
    mutationFn: downloadIpList,
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  useEffect(() => {
    setIsUsingRestriction(configData?.isBlockAccessIp ?? false);
  }, [configData]);

  useEffect(() => {
    if (isUsingRestriction)
      setDetailIpPolicyConfig({ ...detailConfig, isUseBlockAccessIp: true });
    else
      setDetailIpPolicyConfig({ ...detailConfig, isUseBlockAccessIp: false });
  }, [detailConfig, isUsingRestriction]);

  useEffect(() => {
    return () => {
      setIsConfigNeedsSave(false);
    };
  }, [setIsConfigNeedsSave]);

  useEffect(() => {
    if (detailConfig) setDetailIpPolicyConfig(detailConfig);
  }, [detailConfig]);

  const toaster = useToastMessage();
  const stacker = useStackMethod();

  const updateDetailConfigMutation = useMutation({
    mutationFn: updateRestrictedIPList,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [loginSecureConfigAPIs.QUERY_KEY_ACCESS_IP],
      });
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
      setIsConfigNeedsSave(false);
      toaster.info(
        t('globalconfig.secureManagement.loginSecureConfig.ip.saved'),
      );
    },
    onError: (error) => {
      toaster.warning(
        t('globalconfig.common.toastMessage.fail') + ':\n' + error.message,
      );
    },
  });

  if (error) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > IP Access Error: ',
      error,
    );
  }

  if (detailConfigError) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > IP List Error: ',
      detailConfigError,
    );
  }

  if (isLoading || ipListLoading) return;

  const onClickUseRestrictionSwitch = (useRestriction: boolean) => {
    setIsUsingRestriction(useRestriction);
    if (configData && configData.isBlockAccessIp === useRestriction) {
      setIsConfigNeedsSave(false);
    } else {
      setIsConfigNeedsSave(true);
    }
  };

  const onClickCancel = () => {
    setIsUsingRestriction(configData?.isBlockAccessIp ?? false);
    if (detailConfig) setDetailIpPolicyConfig(detailConfig);
    setIsConfigNeedsSave(false);
  };

  const onClickSave = () => {
    updateDetailConfigMutation.mutate(detailIpPolicyConfig);
  };

  const onClickBackButton = async () => {
    if (isConfigNeedsSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        stacker.pop();
      }
    } else {
      stacker.pop();
    }
  };

  return (
    <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="h-[38px] flex items-center">
        <Button className="mr-[8px]" onClick={() => void onClickBackButton()}>
          <ArrowLeftIcon className="size-[24px]" />
        </Button>
        <p className="text-[#333333] text-[22px] font-[500]">
          {t(
            'globalconfig.secureManagement.loginSecureConfig.restrictIp.title',
          )}
        </p>
      </div>
      <div className="w-full mt-[54px]">
        <TitleAndDescription
          title={t(
            'globalconfig.secureManagement.loginSecureConfig.restrictIp.title',
          )}
          description={t(
            'globalconfig.secureManagement.loginSecureConfig.restrictIp.description',
          )}
        >
          <Switch
            id={'globalConfig.secure.login.restrictedIP'}
            checked={isUsingRestriction}
            onChange={onClickUseRestrictionSwitch}
          />
        </TitleAndDescription>
        {isUsingRestriction && (
          <div className="mt-[20px] p-[24px] w-full border border-dashed stroke-[2px] border-[#E2E2E2] rounded-[8px]">
            <div className="flex flex-wrap h-[32px] mt-[8px] mb-[20px] gap-[24px]">
              <p className="flex items-center text-[#111111] text-[14px] font-[400] min-w-[240px]">
                {t(
                  'globalconfig.secureManagement.loginSecureConfig.blockOption.title',
                )}
                <ToolTip.Root>
                  <ToolTip.Trigger>
                    <QuestionMarkCircleIcon
                      className="size-[16px] ml-[4px]"
                      color="#AAAAAA"
                    />
                  </ToolTip.Trigger>
                  <ToolTip.Portal>
                    <ToolTip.Content className="max-w-full z-[1000]">
                      {t(
                        'globalconfig.secureManagement.loginSecureConfig.blockOption.tooltip',
                      )}
                    </ToolTip.Content>
                  </ToolTip.Portal>
                </ToolTip.Root>
              </p>
              <div className="flex flex-wrap items-center gap-[24px]">
                <div>
                  <input
                    className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                    type="radio"
                    name="overseasIpAllow"
                    id={'ip.block'}
                    value={'DISALLOW'}
                    checked={
                      detailIpPolicyConfig.ipAccessPolicyType === 'DISALLOW'
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDetailIpPolicyConfig({
                          ...detailIpPolicyConfig,
                          ipAccessPolicyType: 'DISALLOW',
                        });
                        setIsConfigNeedsSave(true);
                      }
                    }}
                  />
                  <label htmlFor={'ip.block'} className="ml-[8px]">
                    {t(
                      'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.allowedIp.blockRule',
                    )}
                  </label>
                </div>
                <div>
                  <input
                    className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                    type="radio"
                    name="overseasIpAllow"
                    id={'ip.allow'}
                    value={'ALLOW'}
                    checked={
                      detailIpPolicyConfig.ipAccessPolicyType === 'ALLOW'
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDetailIpPolicyConfig({
                          ...detailIpPolicyConfig,
                          ipAccessPolicyType: 'ALLOW',
                        });
                        setIsConfigNeedsSave(true);
                      }
                    }}
                  />
                  <label htmlFor={'ip.allow'} className="ml-[8px]">
                    {t(
                      'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.allowedIp.allowRule',
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap 2xl:flex-nowrap mt-[36px]">
              <p className="text-[#111111] text-[14px] font-[400] min-w-[240px]">
                {detailIpPolicyConfig.ipAccessPolicyType === 'DISALLOW'
                  ? t(
                      'globalconfig.secureManagement.loginSecureConfig.restrictIp.allowedIP',
                    )
                  : t(
                      'globalconfig.secureManagement.loginSecureConfig.restrictIp.blockedIP',
                    )}
              </p>
              <IPListManager
                className="ml-[24px]"
                ipList={detailIpPolicyConfig.ipAccessPolicyInfoList}
                onChangeIpList={(newIpList) => {
                  setDetailIpPolicyConfig({
                    ...detailIpPolicyConfig,
                    ipAccessPolicyInfoList: newIpList,
                  });
                  setIsConfigNeedsSave(true);
                }}
                onClickExportIpList={downloadIpListMutation.mutate}
              />
            </div>
          </div>
        )}
        <div className="mt-[50px] flex items-center justify-center gap-[8px]">
          <Button
            size="medium"
            shape="rect"
            variant="outline"
            colorset="level1"
            onClick={onClickCancel}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            size="medium"
            shape="rect"
            variant="solid"
            colorset="level1"
            disabled={!isConfigNeedsSave}
            onClick={onClickSave}
          >
            {t('dialog.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}
