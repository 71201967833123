import { Bars2Icon, NoSymbolIcon } from '@heroicons/react/24/outline';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';
import { GNBMenuInfo } from '../../types';
import { useEffect, useState } from 'react';

interface Props {
  groupName: string;
  menuList: GNBMenuInfo[];
  onChange: (groupName: string, menuList: GNBMenuInfo[]) => void;
}

export function GroupGnbSort({ groupName, menuList, onChange }: Props) {
  const [groupMenuList, setGroupMenuList] = useState(menuList);

  useEffect(() => {
    if (groupMenuList !== menuList) onChange(groupName, groupMenuList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMenuList]);

  return (
    <>
      <div className="px-[8px] pt-[16px] pb-[4px] h-[40px] w-full border-t border-solid border-[#F2F2F2] //border-[--color-border-level4]">
        <p className="leading-[20px] text-[#999999] //text-[--color-text-level3] text-[13px] font-[500]">
          {groupName}
        </p>
      </div>
      <DnDList.Provider name={groupName + 'BusinessPortalGNB'} idKey="id">
        <DnDList.Container<GNBMenuInfo>
          list={menuList}
          setList={(newList) => {
            setGroupMenuList(newList);
          }}
        >
          {menuList.map((menu) => (
            <DnDList.Item
              key={menu.id}
              item={menu}
              className="flex items-center pl-[8px] h-[48px] hover:bg-[#F2F2F2] rounded-[8px]"
            >
              <Bars2Icon className="size-[40px] p-[8px] " color="#9A9A9A" />
              {menu.icon ? (
                <i
                  className="size-[24px] ml-[32px]"
                  dangerouslySetInnerHTML={{
                    __html: menu.icon,
                  }}
                />
              ) : (
                <NoSymbolIcon
                  className="size-[20px] ml-[32px]"
                  color="#363636"
                />
              )}
              <p className="ml-[8px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
                {menu.name}
              </p>
            </DnDList.Item>
          ))}
        </DnDList.Container>
      </DnDList.Provider>
    </>
  );
}
