import * as React from 'react';
import { SubTabType } from '../types';

export interface IContext {
  isEditMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedType: SubTabType;
  setSelectedType: React.Dispatch<React.SetStateAction<SubTabType>>;
}

const Context = React.createContext<IContext>({
  isEditMode: false,
  setEditMode: () => undefined,
  selectedType: 'perApps',
  setSelectedType: () => undefined,
});

export interface Props {
  selectedTab?: SubTabType;
}

export function Provider({
  selectedTab,
  children,
}: React.PropsWithChildren<Props>) {
  const [selectedType, setSelectedType] = React.useState<SubTabType>('perApps');
  const [isEditMode, setEditMode] = React.useState(false);

  React.useEffect(() => {
    if (selectedTab) {
      setSelectedType(selectedTab);
    }
  }, [selectedTab]);

  const contextValues: IContext = {
    isEditMode,
    setEditMode,
    selectedType,
    setSelectedType,
  };

  return <Context.Provider value={contextValues}>{children}</Context.Provider>;
}

export const useContextValues = () => React.useContext(Context);
