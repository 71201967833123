import { Response } from '../../../../types';
import { APP_SHARE_COMPANY_CALENDER_LIST_URL } from '../constants';
import { CompanyCalendarInfo } from '../types';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.AppShare.CompanyCalendarList';

export async function getCompanyCalenderList(
  companyUuid: string,
): Promise<CompanyCalendarInfo[]> {
  try {
    const response = await fetch(
      APP_SHARE_COMPANY_CALENDER_LIST_URL + '/' + companyUuid,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getCompanyCalenderList 오류 발생.');
    }

    const resData = (await response.json()) as Response<CompanyCalendarInfo>;

    return resData.data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
