const SECURE_MANAGEMENT_PREFIX = '/api/portal/setting/security';
export const SECURE_MANAGEMENT_MOBILE_PREFIX =
  '/api/portal/setting/security/mobile';

/**
 * @description 보안 설정 변경 API
 */
export const UPDATE_SECURE_CONFIG_API = SECURE_MANAGEMENT_PREFIX;

/**
 * @description 접속 허용 / 차단 IP 목록 조회 / 변경 API
 */
export const IP_BLOCK_LIST_API = `${SECURE_MANAGEMENT_PREFIX}/auth/ip-access-limit-info`;
/**
 * @description 접속 허용 / 차단 IP 목록 다운로드 API
 */
export const IP_BLOCK_LIST_DOWNLOAD_API = `${SECURE_MANAGEMENT_PREFIX}/auth/ip-access-limit-info/download`;
/**
 * @description 접속 허용 / 차단 IP 목록 타입 : 접속 허용 IP
 */
export const ACCESS_ALLOWED_IP_TYPE = 'ACCESS_ALLOWED';
/**
 * @description 접속 허용 / 차단 IP 목록 타입 : 해외 접속 허용 IP
 */
export const ABROAD_ACCESS_ALLOWED_IP_TYPE = 'ABROAD_ACCESS_ALLOWED';
/**
 * @description 접속 허용 / 차단 IP 목록 타입 : 2차 인증 예외 IP
 */
export const EXTRA_AUTH_ACCESS_EXCLUDE_IP_TYPE = 'EXTRA_AUTH_ACCESS_EXCLUDE';

/**
 * @description 해외 로그인 차단 제외 IP 사용 조회 / 변경 API
 */
export const OVERSEAS_LOGIN_EXCLUDE_IP_USE_API = `${SECURE_MANAGEMENT_PREFIX}/auth/abroad-ip/use`;
/**
 * @description 해외 로그인 차단 설정 조회 / 변경 API
 */
export const OVERSEAS_LOGIN_DETAIL_BLOCK_CONFIG_API = `${SECURE_MANAGEMENT_PREFIX}/auth/abroad-ip-access-limit-info`;
/**
 * @description 해외 로그인 차단 설정 IP 사용 다운로드 API
 */
export const OVERSEAS_LOGIN_EXCLUDE_IP_USE_DOWNLOAD_API = `${SECURE_MANAGEMENT_PREFIX}/auth/abroad-ip-access-limit-info/download`;

/**
 * @description 보안관리 > 앱/서비스 접근 설정 > 앱 리스트 조회 API URL
 */
export const APP_LIST_URL = `${SECURE_MANAGEMENT_PREFIX}/app-list`;

/**
 * @description 보안관리 > 앱/서비스 접근 설정 > 디바이스별 접근 권한 설정
 */
export const ACCESS_ROLE_INFO_URL = `${SECURE_MANAGEMENT_PREFIX}/app`;

/**
 * @description 보안관리 > 앱/서비스 접근 설정 > 앱 진입 보안 변경
 */
export const APP_LOCK_CONFIG_URL = `${ACCESS_ROLE_INFO_URL}/lock-config`;

/**
 * @description 보안관리 > 앱/서비스 접근 설정 > 보안 설정
 */
export const SECURITY_SETTINGS_URL = `${SECURE_MANAGEMENT_PREFIX}`;

/**
 * @description 보안관리 > 조직 코드 타입에 따른 리스트 조회
 */
export const POSITION_LIST_URL = 'api/portal/admin/organization-code/list';

/**
 * @description 보안관리 > 모바일 보안 관리 > 모바일 기기 접근 설정 > 접근 허용/요청 모바일 기기 리스트 조회
 */
export const MOBILE_ACCESS_REQUEST_PERMIT_LIST_URL = `${SECURE_MANAGEMENT_MOBILE_PREFIX}`;

/**
 * @description 보안관리 > 모바일 보안 관리 > 모바일 기기 접근 설정 > 접근 허용/요청 모바일 기기 접근 제한 설정 변경
 */
export const MOBILE_ACCESS_LIMIT_CONFIG_URL = `${SECURE_MANAGEMENT_MOBILE_PREFIX}/access-limit-config`;

/**
 * @description 보안관리 > 모바일 관리 > 모바일 기기 접근 설정 > 모바일 기기 요청 승인/반려
 */
export const REQUEST_MOBILE_ACCESS_URL = `${SECURE_MANAGEMENT_MOBILE_PREFIX}/request`;

/**
 * @description 보안관리 > 2차 인증 관리 > 2차 인증 제외 IP 목록, 클래스 조회 / 변경 API
 */
export const TFA_EXCEPTION_DETAIL_CONFIG_API = `${SECURE_MANAGEMENT_PREFIX}/extra-auth/exclude-info`;

/**
 * @description 2차 인증 오류 로그 조회 API
 */
export const TFA_ERROR_LOG_API = `${SECURE_MANAGEMENT_PREFIX}/extra-auth/error`;

/**
 * @description 보안관리 > 모바일 보안 관리 > 모바일 접속 차단 (MAM) > 접근 허용/요청 모바일 기기 리스트 조회
 */
export const MOBILE_APP_ACCESS_LIST_URL = `${SECURE_MANAGEMENT_MOBILE_PREFIX}/mam`;

/**
 * @description 보안관리 > 로그 관리 > 멤버 접근 로그 > 멤버 접근 로그 조회
 */
export const MEMBER_LOG_URL = `${SECURE_MANAGEMENT_PREFIX}/user/access/history`;

/**
 * @description 보안관리 > 로그 관리 > 멤버 접근 로그 > 관리자 로그 조회
 */
// TODO : API 작업 완료 후, 원복.
// export const ADMIN_LOG_URL = `${SECURE_MANAGEMENT_PREFIX}/admin-work-history`;
export const ADMIN_LOG_URL = MEMBER_LOG_URL;

/**
 * @description 보안관리 > 2차 인증 > 2차 인증 수단 등록 현황 API(조회, 삭제)
 */
export const TFA_REGISTRATION_STATUS_URL = `${SECURE_MANAGEMENT_PREFIX}/extra-auth/user`;
