import { fetch } from '../../../shared/lib/fetch/client';

interface ReadResponse {
  data: {
    count: number;
  };
}

export async function readNotification(
  notificationId?: number,
): Promise<ReadResponse> {
  const response = await fetch(
    `/api/portal/common/notification/${notificationId}/read`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return (await response.json()) as ReadResponse;
}
