import { CUSTOM_PROFILE_CARD_API } from '../../constants';
import { CustomProfileDetailInfo } from '../type';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export async function updateCustomProfile(
  customProfileDetailInfo: CustomProfileDetailInfo,
) {
  const response = await fetch(CUSTOM_PROFILE_CARD_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(customProfileDetailInfo),
  });

  if (!response.ok) {
    throw new Error('updateCustomProfile 오류 발생.');
  }

  return response;
}
