import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { HolidayType, ScheduleType } from '../types';

interface Props {
  scheduleType: ScheduleType;
  holidayType: HolidayType;
}

export function ScheduleTypeChip({ scheduleType, holidayType }: Props) {
  const { t } = useTranslation('component');
  return (
    <span
      className={clsx(
        'rounded-full py-[5px] px-[8px] leading-[12px] text-center border border-solid text-[12px] font-[500]',
        {
          'border-[#999999] text-[#999999]':
            scheduleType === 'HOLIDAY' && holidayType === 'STATUTORY_HOLIDAY',
        },
        {
          'border-[#0798AE] text-[#0798AE]':
            scheduleType === 'HOLIDAY' && holidayType !== 'STATUTORY_HOLIDAY',
        },
        {
          'border-[#FF8B8B] text-[#FF8B8B]': scheduleType === 'ANNIVERSARY',
        },
      )}
    >
      {scheduleType === 'HOLIDAY' && holidayType === 'STATUTORY_HOLIDAY'
        ? t(
            'globalconfig.basicManagement.companySchedule.schedule.holidayType.statutory',
          )
        : scheduleType === 'HOLIDAY' && holidayType !== 'STATUTORY_HOLIDAY'
          ? t(
              'globalconfig.basicManagement.companySchedule.schedule.holidayType.contract',
            )
          : t(
              'globalconfig.basicManagement.companySchedule.schedule.type.anniversary',
            )}
    </span>
  );
}
