import { Button } from '@dop-ui/react/shared/ui/button';
import { UnfoldIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { useOrganizerAtom } from '../Provider';
import { useTranslation } from '../../../lib/i18n/client/useTranslation';

import styles from '../organizer-body.module.css';

export function UnfoldButton() {
  const [organizerAtom, setOrganizerAtom] = useOrganizerAtom();
  const { t } = useTranslation();
  const handleOnClick = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
    }));
  };

  return (
    <>
      {organizerAtom.organizerState === 'flip' && (
        <Button className={styles.btn_flip} onClick={handleOnClick}>
          <i className="icon ic_small">
            <UnfoldIcon size={18} />
          </i>
          <span className="blind">{t('organizer.expand')}</span>
        </Button>
      )}
    </>
  );
}
