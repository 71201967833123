export const SHORTCUT_ITEM_HEIGHT = 54;

//BOTTOM 영역에 unfold, setting, chatbot 세 개
export const SETTING_AREA_HEIGHT = SHORTCUT_ITEM_HEIGHT * 3;

//SETTING AREA 이외에 아이콘 요소가 6개 들어감.
export const ORGANIZER_MIN_HEIGHT =
  6 * SHORTCUT_ITEM_HEIGHT + SETTING_AREA_HEIGHT;

export const GPT_TRAINER_URL =
  'https://app.gpt-trainer.com/widget/4c8923892e0742429119d796563ee19c';
