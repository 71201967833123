import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import {
  AddStorageIcon,
  StorageSettingsIcon,
} from '../../../../../../../../ui-icons/react/dop/24';
import * as memberDataListQuery from '../../api/memberDataListQuery';
import { quotaAtom } from '../../store';
import { MemberDataInfo, Option } from '../../types';
import { Header } from './Header';
import { Provider } from './Provider';
import { StorageSettingsDialog } from './StorageSettingsDialog';

export function MemberDataCard() {
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation('component');
  const quota = useAtomValue(quotaAtom);
  const [selectedRows, setSelectedRows] = useState<MemberDataInfo[]>([]);
  const isDisabled = selectedRows.length === 0;
  const [, setStorageSettingsLayerOpen] = useState(false);

  const onClickStorageSettingsHandler = () => {
    // 링크로 이동 -> TODO: 링크 연결
  };
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [memberDataListQuery.QUERY_KEY],
    queryFn: () =>
      memberDataListQuery.memberDataListQuery({
        size: 5,
        page: currentPage,
      } as Option),
  });

  const columnDefs: ColumnDef<MemberDataInfo>[] = [
    columnDefGenerator<MemberDataInfo>(
      'userName',
      t('globalconfig.dataManagement.memberManagement.field.name'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'userEmail',
      t('globalconfig.dataManagement.memberManagement.field.address'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'position',
      t('globalconfig.dataManagement.memberManagement.field.position'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'mail',
      t('globalconfig.dataManagement.memberManagement.field.mailstorage'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'webFolder',
      t('globalconfig.dataManagement.memberManagement.field.folderstorage'),
    ),
  ];

  useEffect(() => {
    refetch({}).catch((error) => console.error(error));
  }, [currentPage, refetch]);

  if (error) {
    return <>{error}</>;
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <Provider defaultSelectedRows={selectedRows}>
        <Header />
        {res?.elements && res?.elements.length === 0 ? (
          <TablePrimitives.Root<MemberDataInfo>
            selectable={true}
            contents={res?.elements ?? []}
            columnDefs={columnDefs}
            onSelectContents={setSelectedRows}
          >
            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                onClick={onClickStorageSettingsHandler}
                className="flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA] text-[#383838]"
              >
                <StorageSettingsIcon className="relative top size-5 top-[4px] stroke-[#383838]" />
                {t(
                  'globalconfig.dataManagement.memberManagement.button.storageusage.settings.detail',
                )}
              </Button>
              <StorageSettingsDialog
                availableStorage={quota?.availableQuota ?? 0}
              >
                <Button
                  variant="ghost"
                  onClick={() =>
                    !isDisabled && setStorageSettingsLayerOpen(true)
                  }
                  className="flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA] text-[#383838]"
                >
                  <AddStorageIcon
                    className={clsx('relative top size-5 top-[4px]', {
                      'stroke-[#d8d8d8]': isDisabled,
                      'stroke-[#383838]': !isDisabled,
                    })}
                  />
                  {t(
                    'globalconfig.dataManagement.memberManagement.button.storageusage.add',
                  )}
                </Button>
              </StorageSettingsDialog>
            </div>
            );
            <TablePrimitives.Contents />
            <TablePrimitives.Pagination
              currentPage={currentPage}
              totalPage={res?.page?.totalPages ?? 0}
              onNextPage={setCurrentPage}
              onPreviousPage={setCurrentPage}
              onClickPage={setCurrentPage}
              onFirstPage={() => setCurrentPage(0)}
              onLastPage={() =>
                setCurrentPage(
                  res?.page?.totalPages ? res.page.totalPages - 1 : 0,
                )
              }
            />
          </TablePrimitives.Root>
        ) : (
          <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
            {res?.data || t('globalconfig.common.table.noData')}
          </div>
        )}
      </Provider>
    </div>
  );
}

export default MemberDataCard;
