/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  uploadCommonFile,
  UploadResponse,
} from '../../../features/file-upload';

export const editorCustomAction = (command: string, editorID: string) => {
  if (command === 'c_inline_image') {
    imageUploader(editorID);
  }

  if (command === 'c_inline_html') {
    const fileReader = document.getElementById('dextFileReader');
    if (fileReader) {
      fileReader.click();
    } else {
      const fileReader = document.createElement('input');
      fileReader.id = 'fileReader';
      fileReader.style.display = 'none';
      fileReader.type = 'file';

      fileReader.addEventListener('change', (event) => {
        if (
          event &&
          event.target &&
          event.target instanceof HTMLInputElement &&
          event.target.files
        ) {
          const file = event.target.files[0];

          if (!file) {
            document.body.removeChild(fileReader);
            return;
          }

          if (file.type !== 'text/html') {
            alert('HTML 파일만 가능합니다.');
            document.body.removeChild(fileReader);
            return;
          }

          if (file.size / 1024 > 512) {
            alert('512KB 이하 파일만 가능합니다.');
            document.body.removeChild(fileReader);
            return;
          }

          const reader = new FileReader();
          reader.onload = function (e: ProgressEvent<FileReader>) {
            if (e.target === null) return;
            const content = e.target.result as string;
            DEXT5.setHtmlContents(content, editorID);
            document.body.removeChild(fileReader);
          };
          reader.readAsText(file);
        } else {
          document.body.removeChild(fileReader);
          return;
        }
      });
      document.body.insertAdjacentElement('beforeend', fileReader);
      fileReader.click();
    }
  }
};

const imageUploader = (editorID: string) => {
  const imageInput = document.createElement('input');
  imageInput.type = 'file';
  imageInput.accept = 'image/jpeg, image/jpg, image/png, image/gif, image/bmp';
  imageInput.multiple = false;

  imageInput.addEventListener('change', async (event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];

      if (file) {
        //TODO 추후 appCode가 들어가는 영역에서는 분기처리가 필요할 수 있음. 확인 필요
        const response = (await uploadCommonFile(file)) as UploadResponse;

        const image = document.createElement('img');
        image.setAttribute('src', response.tempFileDownloadLink);
        image.setAttribute('data-filename', response.fileName);
        image.setAttribute('alt', response.fileName);
        image.setAttribute('data-filepath', response.filePath);
        image.setAttribute('data-inline', 'true');

        DEXT5.setHtmlContents(image.outerHTML, editorID);
        target.value = '';
      }
    }
  });

  imageInput.click();
};
