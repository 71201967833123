interface Days {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

export function formatDateString(days: Days, dateString: string): string {
  const date = new Date(dateString);

  const dayNames = [
    days.sunday,
    days.monday,
    days.tuesday,
    days.wednesday,
    days.thursday,
    days.friday,
    days.saturday,
  ];

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const weekday = dayNames[date.getDay()];

  return `${year}-${month}-${day}(${weekday})`;
}

export const localeMap: { [key: string]: string } = {
  ko_KR: 'KOREAN',
  en_US: 'ENGLISH',
  ja_JP: 'JAPANESE',
  zh_CN: 'CHINESE_SIMPLIFIED',
  zh_TW: 'CHINESE_TRADITIONAL',
  vi: 'VIETNAMESE',
};
