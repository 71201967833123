import { IconButton } from '@daouoffice/ui';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  ListBulletIcon,
  PencilSquareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import {
  APP_CODE_APPROVAL,
  APP_CODE_BOARD,
  APP_CODE_REPORT,
} from '../../../../constants';
import { AppInfo, AppOverView } from '../../../../types';
import { useContext } from '../../../Provider';

export interface Props {
  appList: AppOverView[];
}

export function AppList({ appList }: Props) {
  const { t } = useTranslation('component');
  const { setSelectedAppType } = useContext();

  const getApp = (app: AppOverView): AppInfo => {
    if (app.appCode === APP_CODE_APPROVAL) {
      return {
        name: t('globalconfig.dataManagement.deptManagement.approval.title'),
        type: 'approval',
        icon: <PencilSquareIcon className="h-6 w-6" key="approval" />,
        summary: t(
          'globalconfig.dataManagement.deptManagement.approval.summary',
          {
            deptCount: `${app.rowCount}`,
            approvalListCount: `${app.attachCount}`,
          },
        ),
      };
    } else if (app.appCode === APP_CODE_BOARD) {
      return {
        name: t('globalconfig.dataManagement.deptManagement.board.title'),
        type: 'board',
        icon: <ListBulletIcon className="h-6 w-6" key="board" />,
        summary: t('globalconfig.dataManagement.deptManagement.board.summary', {
          deptCount: `${app.rowCount}`,
        }),
      };
    } else if (app.appCode === APP_CODE_REPORT) {
      return {
        name: t('globalconfig.dataManagement.deptManagement.report.title'),
        type: 'report',
        icon: <ClipboardDocumentListIcon className="h-6 w-6" key="report" />,
        summary: t(
          'globalconfig.dataManagement.deptManagement.report.summary',
          { deptCount: `${app.rowCount}` },
        ),
      };
    } else {
      return {
        name: t('globalconfig.dataManagement.deptManagement.group.title'),
        type: 'group',
        icon: <UserIcon className="h-6 w-6" key="group" />,
        summary: t('globalconfig.dataManagement.deptManagement.group.summary', {
          deptCount: `${app.rowCount}`,
          groupList: `${app.attachCount}`,
        }),
      };
    }
  };

  const appInfoList = appList?.map((app) => getApp(app));

  return (
    <>
      {appInfoList?.map((info, index) => (
        <>
          <div
            className="flex items-center justify-between py-7"
            key={info.name}
          >
            <div className="flex items-center">
              {info.icon}
              <div className="grid ms-6 gap-[5.5px]">
                <span className="font-medium text-base -tracking-[0.28px]">
                  {info.name}
                </span>
                <span className="font-normal text-sm -tracking-[0.28px]">
                  {info.summary}
                </span>
              </div>
            </div>
            <IconButton
              className="relative right-0"
              title="btn_icon"
              onClick={() => setSelectedAppType(info.type)}
            >
              <ChevronRightIcon className="h-6 w-6" />
            </IconButton>
          </div>
          {appInfoList.length - 1 !== index && (
            <hr className="border-[#E6E7EA] border !m-0 !ms-12" />
          )}
        </>
      ))}
    </>
  );
}

export default AppList;
