import { ShareInfo } from '../../../../types';
import { APP_SHARE_BOARD_LIST_URL } from '../constants';

export async function deleteBoardShareList(
  companyGroupId: string | number,
  shareRequestModel: ShareInfo[],
) {
  try {
    const response = await fetch(APP_SHARE_BOARD_LIST_URL(companyGroupId), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
      body: JSON.stringify(shareRequestModel),
    });
    if (!response.ok) {
      throw new Error('Failed to delete board share list.');
    }
  } catch (error) {
    console.log('error', error);
  }
}
