import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import * as logoThemeMutation from './apis/logo-theme-mutation';
import * as logoThemeQuery from './apis/logo-theme-query';
import * as thumbnailQuery from './apis/thumbnail-query';
import { BrowserPreview } from './components/browser-preview';
import { BrowserTitleInput } from './components/browser-title-input';
import { ColorPickerInput } from './components/color-picker-input';
import { ImageFormField } from './components/image-form-field';
import ThemePreview from './components/theme-preview';
import { CustomThemeGraphic } from './components/theme-preview/custom-theme-graphic';
import {
  faviconDefaultData,
  loginDefaultData,
  logoDefaultData,
} from './data/default-logo-theme-data';
import { ImageInfo, LogoThemeConfigInfo, ThemeInfo, ThemeType } from './types';
import { isHexColor } from './utils/hexColorValidator';

export function LogoThemeManagement() {
  const [isContentNeedSave, setContentNeedSaveState] = useAtom(
    isContentNeedsSaveAtom,
  );
  const { info, warning } = useToastMessage();
  const { t } = useTranslation('component');
  const queryClient = useQueryClient();
  const {
    data: logoThemeData,
    error: logoThemeDataError,
    refetch: refetchDefaultLogoThemeData,
  } = useSuspenseQuery({
    queryKey: [logoThemeQuery.QUERY_KEY],
    queryFn: async () => {
      setContentNeedSaveState(false);
      return await logoThemeQuery.getLogoThemeConfig();
    },
  });
  const [logoImageInfo, setLogoImageInfo] = useState<ImageInfo | null>(null);
  const [loginImageInfo, setLoginImageInfo] = useState<ImageInfo | null>(null);
  const [faviconImageInfo, setFaviconImageInfo] = useState<ImageInfo | null>(
    null,
  );

  const { data, error } = useSuspenseQuery({
    queryKey: [thumbnailQuery.QUERY_KEY, logoThemeData],
    queryFn: async () => {
      try {
        const logoImageInfo = logoThemeData?.logoImagePath
          ? await thumbnailQuery.getThumbnailByStoragePath(
              logoThemeData?.logoImagePath ?? '',
              'logo.png',
            )
          : logoDefaultData;
        const loginImageInfo = logoThemeData?.loginImagePath
          ? await thumbnailQuery.getThumbnailByStoragePath(
              logoThemeData?.loginImagePath ?? '',
              'login-image.png',
            )
          : loginDefaultData;
        const faviconImageInfo = logoThemeData?.faviconImagePath
          ? await thumbnailQuery.getThumbnailByStoragePath(
              logoThemeData?.faviconImagePath ?? '',
              'favicon.ico',
            )
          : faviconDefaultData;
        setContentNeedSaveState(false);
        return { logoImageInfo, loginImageInfo, faviconImageInfo };
      } catch (e) {
        return {
          logoImageInfo: logoImageInfo,
          loginImageInfo: loginImageInfo,
          faviconImageInfo: faviconImageInfo,
        };
      }
    },
  });
  const saveMutation = useMutation({
    mutationKey: [logoThemeMutation.MUTATION_KEY],
    mutationFn: (req: LogoThemeConfigInfo) =>
      logoThemeMutation.putLogoThemeConfig(req),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [logoThemeQuery.QUERY_KEY, thumbnailQuery.QUERY_KEY],
      });
      info(
        t(
          'globalconfig.basicManagement.logoThemeManagement.toast.save.successed',
        ),
      );
    },
    onError: () =>
      warning(
        t('globalconfig.basicManagement.logoThemeManagement.toast.save.failed'),
      ),
  });

  const [themeType, setThemeType] = useState<ThemeType>('dop-theme');
  const [browserTitle, setBrowserTitle] = useState<string>(
    logoThemeData?.browserTitle ?? '',
  );
  const MIN_BROWSER_TITLE_LENGTH = 1;
  const MAX_BROWSER_TITLE_LENGTH = 32;
  const isBrowserTitleValid =
    browserTitle.length >= MIN_BROWSER_TITLE_LENGTH &&
    browserTitle.length <= MAX_BROWSER_TITLE_LENGTH;
  const [welcomeTutorialState, setWelcomTutorialState] = useState<boolean>(
    logoThemeData?.welcomeTutorial ?? false,
  );
  const [themeColor, setThemeColor] = useState<string>(
    logoThemeData?.themeColor ?? '#000000',
  );
  const [textColor, setTextColor] = useState<string>(
    logoThemeData?.textColor ?? '#000000',
  );

  const onCancelHandler = () => {
    setBrowserTitle(logoThemeData?.browserTitle ?? '');
    setWelcomTutorialState(logoThemeData?.welcomeTutorial ?? false);
    setThemeColor(logoThemeData?.themeColor ?? '');
    setTextColor(logoThemeData?.themeColor ?? '');
    setLoginImageInfo(data.loginImageInfo);
    setLogoImageInfo(data.logoImageInfo);
    setFaviconImageInfo(data.faviconImageInfo);
    setContentNeedSaveState(false);
  };

  const onSaveHandler = () => {
    if (isBrowserTitleValid) {
      saveMutation.mutate({
        logoImagePath:
          logoImageInfo?.type === 'default' ? undefined : logoImageInfo?.path,
        loginImagePath:
          loginImageInfo?.type === 'default' ? undefined : loginImageInfo?.path,
        faviconImagePath:
          faviconImageInfo?.type === 'default'
            ? undefined
            : faviconImageInfo?.path,
        browserTitle,
        welcomeTutorial: welcomeTutorialState,
        themeColor,
        textColor,
      } as LogoThemeConfigInfo);
    } else setContentNeedSaveState(false);
  };

  const themeList: ThemeInfo[] = [
    {
      id: 'dop-theme-green',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.green',
      ),
      themeColor: '#2FCAD7',
      textColor: '#2FCAD7',
    },
    {
      id: 'dop-theme-blue',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.blue',
      ),
      themeColor: '#6495ED',
      textColor: '#6495ED',
    },
    {
      id: 'dop-theme-purple',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.purple',
      ),
      themeColor: '#A45BCF',
      textColor: '#A45BCF',
    },
    {
      id: 'dop-theme-black',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.black',
      ),
      themeColor: '#4A4B4C',
      textColor: '#4A4B4C',
    },
  ];

  useEffect(() => {
    setLogoImageInfo(data.logoImageInfo);
    setLoginImageInfo(data.loginImageInfo);
    setFaviconImageInfo(data.faviconImageInfo);
  }, [data]);

  useEffect(() => {
    refetchDefaultLogoThemeData().catch(console.error);
  }, []);

  if (error || logoThemeDataError) {
    return <></>;
  }

  return (
    <div className="flex flex-col px-[75px] pb-[75px] pt-10 gap-8">
      <div className="flex-col w-full h-full border border-[#D8D8D8] //border-[--color-border-level3] rounded-xl p-10 justify-center">
        <section id="logo-login-config">
          <h1 className="text-[20px] font-medium leading-[30px] -tracking-[0.4px] text-[#363636] mb-3">
            {t(
              'globalconfig.basicManagement.logoThemeManagement.logoConfig.title',
            )}
          </h1>
          <ImageFormField
            value={logoImageInfo}
            onChange={setLogoImageInfo}
            onDelete={() => {
              setLogoImageInfo(logoDefaultData);
              console.log('!!!logoDefaultData', logoDefaultData);
              setContentNeedSaveState(true);
            }}
            className="mb-6"
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.logo.title',
            )}
          />
          <ImageFormField
            value={loginImageInfo}
            onChange={setLoginImageInfo}
            onDelete={() => {
              setContentNeedSaveState(true);
              setLoginImageInfo(loginDefaultData);
            }}
            className="mb-[42px]"
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.logo.loginPage',
            )}
          />
        </section>
        <div className="w-full h-0 border-solid border-t-[1px] border-[#D8D8D8] mb-6" />
        <section id="browser-config">
          <ImageFormField
            value={faviconImageInfo}
            onChange={setFaviconImageInfo}
            onDelete={() => {
              setContentNeedSaveState(true);
              setFaviconImageInfo(faviconDefaultData);
            }}
            className="mb-6"
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.browserConfig.favicon.title',
            )}
            withTooltip={t(
              'globalconfig.basicManagement.logoThemeManagement.browserConfig.favicon.tooltip',
            )}
            isFavicon
          />
          <div className="flex items-start justify-between">
            <div className="flex gap-4 items-center mb-6">
              <span className="flex items-center gap-1 w-[200px] text-[#363636] font-medium leading-[21px] -tracking-[0.28px] text-[14px]">
                {t(
                  'globalconfig.basicManagement.logoThemeManagement.browserConfig.title',
                )}
              </span>
              <BrowserTitleInput
                value={browserTitle}
                onChange={(value) => {
                  setContentNeedSaveState(true);
                  setBrowserTitle(value);
                }}
                minLength={MIN_BROWSER_TITLE_LENGTH}
                maxLength={MAX_BROWSER_TITLE_LENGTH}
              />
            </div>
            <BrowserPreview />
          </div>
        </section>
        <div className="w-full h-0 border-solid border-t-[1px] border-[#D8D8D8] mb-6" />
        <section id="theme-config">
          <h1 className="text-[20px] font-medium leading-[30px] -tracking-[0.4px] text-[#363636] mb-3">
            {t(
              'globalconfig.basicManagement.logoThemeManagement.themeConfig.title',
            )}
          </h1>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              colorset="level1"
              shape="round"
              size="md"
              className={clsx({
                '!border-[#D8D8D8]': themeType !== 'dop-theme',
                '!border-black': themeType === 'dop-theme',
              })}
              onClick={() => {
                setContentNeedSaveState(true);
                if (
                  !themeList.some((theme) => theme.themeColor === themeColor)
                ) {
                  setThemeColor(themeList.at(0)?.themeColor ?? themeColor);
                  setTextColor(themeList.at(0)?.themeColor ?? textColor);
                }
                setThemeType('dop-theme');
              }}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop',
              )}
            </Button>
            <Button
              variant="outline"
              colorset="level1"
              shape="round"
              size="md"
              className={clsx({
                '!border-[#D8D8D8]': themeType !== 'custom-theme',
                '!border-black': themeType === 'custom-theme',
              })}
              onClick={() => setThemeType('custom-theme')}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.user',
              )}
            </Button>
          </div>
          {themeType === 'dop-theme' ? (
            <div className="flex mt-6 mb-6 py-6 gap-2 px-6 outline-dashed rounded-lg border-[#E5E5E5] outline-[#E5E5E5] overflow-x-scroll">
              {themeList.map((themeInfo) => (
                <ThemePreview
                  key={themeInfo.id}
                  defaultChecked={themeInfo.themeColor === themeColor}
                  themeInfo={themeInfo}
                  onChange={(selectedTheme) => {
                    setContentNeedSaveState(true);
                    setTextColor(selectedTheme.textColor);
                    setThemeColor(selectedTheme.themeColor);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-between">
              <div className="flex flex-col gap-3">
                <ColorPickerInput
                  color={themeColor}
                  onChange={(color) => {
                    setContentNeedSaveState(true);
                    isHexColor(color) && setThemeColor(color);
                  }}
                  title={t(
                    'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.user.themeColor',
                  )}
                />
                <ColorPickerInput
                  color={textColor}
                  onChange={(color) => {
                    setContentNeedSaveState(true);
                    isHexColor(color) && setTextColor(color);
                  }}
                  title={t(
                    'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.user.textColor',
                  )}
                />
              </div>
              <CustomThemeGraphic />
            </div>
          )}
        </section>
        {themeType === 'custom-theme' && (
          <div className="w-full h-0 border-solid border-t-[1px] border-[#D8D8D8] my-6" />
        )}
        <section
          id="tutorial-config"
          className="flex items-center justify-between mb-6"
        >
          <div className="flex flex-col gap-2">
            <h3 className="text-[16px] font-medium leading-[24px] -tracking-[0.96px] text-[#111]">
              {t(
                'globalconfig.basicManagement.logoThemeManagement.welcomeTutorial.title',
              )}
            </h3>
            {/* TODO: 튜토리얼 기능 구현 필요 */}
            <span className="text-[14px] font-normal leading-normal -tracking-[0.28px] text-[#AAA]">
              {/* {t(
                'globalconfig.basicManagement.logoThemeManagement.welcomeTutorial.contents',
              )} */}
              현재 튜토리얼 노출 기능은 동작하지 않습니다.
            </span>
          </div>
          <Switch
            id="welcomeTutorial"
            checked={welcomeTutorialState}
            onChange={(value) => {
              setContentNeedSaveState(true);
              setWelcomTutorialState(value);
            }}
          />
        </section>
        <section
          id="submit"
          className="flex items-center gap-2 justify-center py-4"
        >
          <Button
            variant="outline"
            colorset="level1"
            shape="rect"
            size="md"
            className="!border-[#C5C5C5]"
            onClick={onCancelHandler}
          >
            {t('globalconfig.basicManagement.logoThemeManagement.cancel')}
          </Button>
          <Button
            variant="solid"
            colorset="level1"
            shape="rect"
            size="md"
            onClick={onSaveHandler}
            disabled={!isContentNeedSave || !isBrowserTitleValid}
          >
            {t('globalconfig.basicManagement.logoThemeManagement.save')}
          </Button>
        </section>
      </div>
    </div>
  );
}

export default LogoThemeManagement;
