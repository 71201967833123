import { TAB_KEY_CHANGE_MENU_ORDER, TAB_KEY_SET_APPS } from '../../constants';
import { GnbSortTab } from '../gnb-sort-tab';
import { SetAppsTab } from '../set-apps-tab';
import { useTabContext } from '../tab-provider';

export function TabContents() {
  const { currentTab } = useTabContext();
  if (currentTab === TAB_KEY_SET_APPS) return <SetAppsTab />;
  if (currentTab === TAB_KEY_CHANGE_MENU_ORDER) return <GnbSortTab />;

  return <div>No-Info</div>;
}
