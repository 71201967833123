import type { MenuList, MenuListResponse } from '../ui/types';

export const QUERY_KEY = 'QueryKeys.Notification.MenuList';

export async function getMenuList(): Promise<MenuListResponse> {
  const response = await fetch(`/api/portal/common/notification/user/app`);

  const { data } = (await response.json()) as { data: MenuListResponse };

  if (!data.elements || data.elements.length === 0) {
    return {
      elements: [],
      hasElements: false,
    };
  }

  const uniqueData = data.elements.reduce<MenuList[]>((acc, cur) => {
    const found = acc.find(
      (item) =>
        item.appCode === cur.appCode && item.portalType === cur.portalType,
    );
    if (!found) {
      acc.push(cur);
    }
    return acc;
  }, []);

  return { elements: uniqueData, hasElements: !!uniqueData.length };
}
