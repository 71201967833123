import { ShareRequestModel } from '../../../../types';
import { APP_SHARE_BOARD_LIST_URL } from '../constants';

export const MUTATION_KEY =
  'GlobalConfig.MultiCompanyManagement.AppShare.BoardShareListAddMutation';

export async function createBoardShareList(
  companyGroupId: string | number,
  shareRequestModel: ShareRequestModel,
) {
  const response = await fetch(APP_SHARE_BOARD_LIST_URL(companyGroupId), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(shareRequestModel),
  });

  if (!response.ok) {
    throw new Error('Failed to create board share list.');
  }
}
