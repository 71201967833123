import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { GROUPWARE_API } from '../../constants';
import { ShareInfo } from '../types';

interface Params {
  companyGroupId?: string | number;
  data: ShareInfo[];
}

export async function deleteAssetShare({ companyGroupId, data }: Params) {
  const response = await fetch(
    `${GROUPWARE_API}/system/companygroup/${companyGroupId}/asset/shares`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    const responseJson = (await response.json()) as {
      data: ShareInfo[];
      message?: string;
    };
    throw new Error(responseJson.message ?? 'deleteAssetShareList 오류 발생');
  }
}
