import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { SaveConfirmDialog } from '../../../../../../parts/components/save-confirm-dialog';
import { isContentNeedsSaveAtom } from '../../../../../../store/isContentNeedsSaveAtom';
import { getAppsUsageStatus, QUERY_KEY } from '../apis/apps-setting';
import { AppItem } from './app-item';
import { AppConfig } from './app-config';

export function AppsContainer() {
  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const { confirm } = useMessageDialog();

  const [currentSelectedAppId, setCurrentSelectedAppId] = useState(0);

  const { data: inUseAppsData, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getAppsUsageStatus,
  });

  useEffect(() => {
    if (
      inUseAppsData &&
      inUseAppsData.defaultCompanyAppUsages &&
      inUseAppsData.defaultCompanyAppUsages.length > 0 &&
      currentSelectedAppId === 0
    ) {
      const info = inUseAppsData.defaultCompanyAppUsages.at(0);
      setCurrentSelectedAppId(info?.id || 0);
    }
  }, [inUseAppsData, currentSelectedAppId]);

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > BusinessPortalAppManagement > SetApps Error: ',
      error,
    );
  }

  const checkSave = async (id: number) => {
    if (await confirm(<SaveConfirmDialog />)) {
      setIsNeedSave(false);
      setCurrentSelectedAppId(id);
    }
  };

  const onClickApp = (id: number) => {
    if (currentSelectedAppId === id) return;
    if (isNeedsSave) {
      void checkSave(id);
    } else {
      setCurrentSelectedAppId(id);
    }
  };

  return (
    <div className="flex gap-[8px]">
      <div className="flex flex-col gap-[4px] pt-[8px] pr-[8px] max-h-[790px] overflow-scroll border-r border-solid border-[#D8D8D8]">
        {inUseAppsData &&
          inUseAppsData.defaultCompanyAppUsages.map((app) => (
            <AppItem
              key={app.id}
              appInfo={app}
              selected={currentSelectedAppId === app.id}
              onClick={onClickApp}
            />
          ))}
        <div className="h-divider h-[1px] w-full flex-shrink-0 bg-[#D8D8D8]" />
        {inUseAppsData &&
          inUseAppsData.purchasedCompanyAppUsages.map((app) => (
            <AppItem
              key={app.id}
              appInfo={app}
              selected={currentSelectedAppId === app.id}
              onClick={onClickApp}
            />
          ))}
      </div>
      <AppConfig
        appInfo={
          inUseAppsData.defaultCompanyAppUsages.find(
            (info) => info.id === currentSelectedAppId,
          ) ||
          inUseAppsData.purchasedCompanyAppUsages.find(
            (info) => info.id === currentSelectedAppId,
          )
        }
      />
    </div>
  );
}
