import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { ORG_CODE_URL } from '../../constants';
import { ClassType } from '../types';
import { BaseResponse, OrganizationCodeInfo } from '../../types';

export const QUERY_KEY = 'classSubSelectionQuery';

export async function getSubSelectionList(
  type: ClassType,
  companyUuid: string,
): Promise<OrganizationCodeInfo[]> {
  try {
    if (type === 'USER' || type === 'DEPARTMENT' || type === 'COMPANY')
      return [];
    else {
      const response = await fetch(
        ORG_CODE_URL(
          (type.toLowerCase() === 'user_group'
            ? 'usergroup'
            : type.toLowerCase()) as
            | 'position'
            | 'grade'
            | 'duty'
            | 'usergroup',
        ) +
          '/' +
          `?companyUuid=${companyUuid}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Referer-Info': window.location.host,
          },
        },
      );

      if (!response.ok) {
        throw new Error('getSubSelectionList 오류 발생.');
      }

      const resData = (await response.json()) as BaseResponse<
        OrganizationCodeInfo[]
      >;
      return resData.data;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}
