import {
  AdditionIcon,
  SettingsIcon,
  ChatbotIcon,
} from '@daouoffice/ui/lib/icons/dop/24';

import { DocumentIcon } from '@daouoffice/ui/lib/icons/dop/24/Document';
import { PanelState } from '../types';

export function getDefaultAppIcon(appName: PanelState) {
  switch (appName) {
    case 'memo':
      return <DocumentIcon className="text-white" size={20} />;
    case 'shortcut':
      return <AdditionIcon size={20} className="text-black" />;
    case 'chatbot':
      return <ChatbotIcon />;
    case 'setting':
      return <SettingsIcon className="text-black" size={20} />;
    default:
      return <></>;
  }
}
