import { useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as passwordPolicyQueries from './api/get-password-policy-config';
import * as passwordPolicyMutations from './api/update-passwrod-policy-config';
import { useAtom } from 'jotai';
import { clsx } from 'clsx';

import { ConfigItem } from './config-item';
import { Switch } from '@daouoffice/ui';
import { Button } from '@dop-ui/react/shared/ui/button';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';
import {
  passwordRuleDatas,
  requireCharacterDatas,
  writablePasswordPolicyKeys,
} from './datas';
import {
  PASSWORD_CYCLE_OPTIONS,
  PASSWORD_ERROR_COUNT_OPTIONS,
  PASSWORD_LIMIT_OPTIONS,
  TRANSLATE_PREFIX,
} from './constants';
import type {
  PasswordPolicyConfig,
  PasswordPolicyConfigCheckList,
  WritablePasswordPolicyConfig,
} from './types';
import styles from './password-policy-config.module.css';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';

export function PolicyConfig() {
  const { t } = useTranslation('component');
  const { info } = useToastMessage();

  const { data, error } = useSuspenseQuery({
    queryKey: [passwordPolicyQueries.QUERY_KEY],
    queryFn: async () => await passwordPolicyQueries.getPasswordPolicy(),
  });

  const [isNeedSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const queryClient = useQueryClient();

  const passwordPolicyConfigMutation = useMutation({
    mutationFn: passwordPolicyMutations.updatePasswordPolicyConfig,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [passwordPolicyQueries.QUERY_KEY],
      });
      info(<Templates.Basic description={t(`${TRANSLATE_PREFIX}saved`)} />);
      setIsNeedSave(false);
    },
    onError: () => {
      info(<Templates.Basic description={t(`${TRANSLATE_PREFIX}error`)} />);
    },
  });

  const getOriginData = () => {
    return { ...data };
  };

  const [policyConfig, setPolicyConfig] =
    useState<PasswordPolicyConfig>(getOriginData());

  const handleOnClickCancel = () => {
    setIsNeedSave(false);
    setPolicyConfig(getOriginData());
  };

  const handleOnChangePolicyConfig = (
    name: keyof WritablePasswordPolicyConfig,
    value: number | boolean,
  ) => {
    setPolicyConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
    setIsNeedSave(true);
  };

  const handleOnClickSave = () => {
    let isChanged = false;
    if (passwordPolicyConfigMutation.isPending) {
      return;
    }

    for (const key of writablePasswordPolicyKeys) {
      if (policyConfig[key] !== data[key]) {
        passwordPolicyConfigMutation.mutate(policyConfig);
        isChanged = true;
        return;
      }
    }

    if (!isChanged) {
      info(<Templates.Basic description={t(`${TRANSLATE_PREFIX}noChanged`)} />);
      return;
    }
  };

  const generateCheckBox = (data: PasswordPolicyConfigCheckList) => {
    const { id, disabled, translationKey } = data;

    return (
      <CheckBox
        key={id}
        id={id}
        label={t(`${TRANSLATE_PREFIX}${translationKey}`)}
        defaultChecked={policyConfig[data.id] as boolean}
        className={clsx({
          disable: disabled,
        })}
        defaultDisabled={disabled}
        onChange={(checked) =>
          handleOnChangePolicyConfig(
            id as keyof WritablePasswordPolicyConfig,
            !!checked,
          )
        }
      />
    );
  };

  if (error) {
    return <div className={styles.Card}>{t(`${TRANSLATE_PREFIX}error`)}</div>;
  }

  return (
    <>
      <div className={styles.Card}>
        <p className={styles.CardTitle}>{t(`${TRANSLATE_PREFIX}title`)}</p>

        <li className={styles.ContentList}>
          <ConfigItem
            title={t(`${TRANSLATE_PREFIX}passwordErrorCount.title`)}
            description={t(`${TRANSLATE_PREFIX}passwordErrorCount.description`)}
          >
            <select
              className={styles.Select}
              value={policyConfig.passwordErrorCount}
              onChange={(e) =>
                handleOnChangePolicyConfig(
                  'passwordErrorCount',
                  Number(e.target.value),
                )
              }
            >
              {PASSWORD_ERROR_COUNT_OPTIONS.map((value) => (
                <option value={value} key={value}>
                  {value + t(`${TRANSLATE_PREFIX}count`)}
                </option>
              ))}
            </select>
          </ConfigItem>

          <ConfigItem
            title={t(`${TRANSLATE_PREFIX}passwordLength.title`)}
            description={t(`${TRANSLATE_PREFIX}passwordLength.description`)}
          >
            <div className={styles.InputList}>
              <input
                readOnly
                className={styles.Input}
                value={policyConfig.minPasswordLength}
              />
              <span>{t(`${TRANSLATE_PREFIX}length`)}</span>
              <span>-</span>
              <input
                readOnly
                className={styles.Input}
                value={policyConfig.maxPasswordLength}
              />
              <span>{t(`${TRANSLATE_PREFIX}length`)}</span>
            </div>
          </ConfigItem>

          <ConfigItem
            hasList
            title={t(`${TRANSLATE_PREFIX}requireCharacter.title`)}
            description={t(`${TRANSLATE_PREFIX}requireCharacter.description`)}
          >
            {requireCharacterDatas.map((data) => generateCheckBox(data))}
          </ConfigItem>

          <ConfigItem
            hasList
            title={t(`${TRANSLATE_PREFIX}passwordRule.title`)}
            description={t(`${TRANSLATE_PREFIX}passwordRule.description`)}
          >
            {passwordRuleDatas.map((data) => generateCheckBox(data))}
          </ConfigItem>

          <ConfigItem
            title={t(`${TRANSLATE_PREFIX}passwordReuseLimit.title`)}
            description={t(`${TRANSLATE_PREFIX}passwordReuseLimit.description`)}
          >
            <select
              className={styles.Select}
              value={policyConfig.passwordReuseLimit}
              onChange={(e) =>
                handleOnChangePolicyConfig(
                  'passwordReuseLimit',
                  Number(e.target.value),
                )
              }
            >
              {PASSWORD_LIMIT_OPTIONS.map((value) => (
                <option value={value} key={value}>
                  {value + t(`${TRANSLATE_PREFIX}count`)}
                </option>
              ))}
            </select>
          </ConfigItem>
          <ConfigItem
            title={t(`${TRANSLATE_PREFIX}passwordChangeCycle.title`)}
            description={t(
              `${TRANSLATE_PREFIX}passwordChangeCycle.description`,
            )}
          >
            <select
              className={styles.Select}
              value={policyConfig.passwordChangeCycle}
              onChange={(e) =>
                handleOnChangePolicyConfig(
                  'passwordChangeCycle',
                  Number(e.target.value),
                )
              }
            >
              {PASSWORD_CYCLE_OPTIONS.map((value) => (
                <option key={value} value={value}>
                  {value + t(`${TRANSLATE_PREFIX}month`)}
                </option>
              ))}
            </select>
          </ConfigItem>
          <ConfigItem
            title={t(`${TRANSLATE_PREFIX}forcePasswordChange.title`)}
            description={t(
              `${TRANSLATE_PREFIX}forcePasswordChange.description`,
            )}
          >
            <Switch
              id="forcePasswordChange"
              checked={policyConfig.forcePasswordChange}
              onChange={(value) =>
                handleOnChangePolicyConfig('forcePasswordChange', value)
              }
            />
          </ConfigItem>

          <div className={styles.Bottom}>
            <Button
              shape="rect"
              size="medium"
              variant="outline"
              colorset="level2"
              onClick={handleOnClickCancel}
            >
              {t(`${TRANSLATE_PREFIX}cancel`)}
            </Button>
            <Button
              shape="rect"
              size="medium"
              variant="solid"
              colorset="level1"
              onClick={handleOnClickSave}
              disabled={!isNeedSave}
            >
              {t(`${TRANSLATE_PREFIX}save`)}
            </Button>
          </div>
        </li>
      </div>
    </>
  );
}

export default PolicyConfig;
