import { ShareRequestModel } from '../../../../types';
import { APP_SHARE_CALENDER_LIST_URL } from '../constants';

export async function createCalendarShareList(
  companyGroupId: string | number,
  shareRequestModel: ShareRequestModel,
) {
  const response = await fetch(APP_SHARE_CALENDER_LIST_URL(companyGroupId), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(shareRequestModel),
  });

  if (!response.ok) {
    throw new Error('createCalendarShareList 오류 발생.');
  }
}
