import { clsx } from 'clsx';
import { LinkIcon } from '@dop-ui/icons/react/dop/24/link';

interface Props {
  linkId: number;
  linkName: string;
  selected: boolean;
  onClickLink: (linkId: number) => void;
}

export function LinkItem({ linkId, linkName, selected, onClickLink }: Props) {
  return (
    <button
      className={clsx(
        'flex items-center h-[32px] w-full pl-[8px] rounded-[8px] hover:bg-[#F2F2F2] flex-shrink-0',
        {
          'bg-[#F2F2F2]': selected,
        },
      )}
      onClick={() => onClickLink(linkId)}
    >
      <LinkIcon size={20} />
      <p className="pl-[8px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[500]">
        {linkName}
      </p>
    </button>
  );
}
