import { PropsWithChildren } from 'react';
import { SubTabType } from '../types';
import { Provider } from './Context';

export interface Props {
  selectedTab?: SubTabType;
}

export default function Root({
  selectedTab,
  children,
}: PropsWithChildren<Props>) {
  return <Provider selectedTab={selectedTab}>{children}</Provider>;
}
