import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Suspense } from 'react';
import { AppContents } from './app-contents';
import { MainContents } from './main-contents';
import { useValuesContext } from './provider';

export function Contents() {
  const { t } = useTranslation('component');
  const { selectedAppCode } = useValuesContext();

  return (
    <div className="flex-col w-full h-full border border-[#D8D8D8] //border-[--color-border-level3] rounded-xl p-10 gap-6 justify-center">
      {selectedAppCode === null && (
        <h2 className="mb-6 text-[#363636] text-[20px] font-bold leading-[--font-multi-line-height-XLarge] tracking-[--font-letter-spacing-XLarge]">
          {t('globalconfig.multiCompanyManagement.appShare.title')}
        </h2>
      )}

      <Suspense
        fallback={
          <div className="min-h-[282px] w-full flex items-center justify-center text-lg text-black font-medium">
            Loading...
          </div>
        }
      >
        {selectedAppCode === null ? <MainContents /> : <AppContents />}
      </Suspense>
    </div>
  );
}

export default Contents;
