import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Dialog from '../../../shared/ui/dialog';
import DOMPurify from 'dompurify';
import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';

import { NextIcon, PreviousIcon } from '@dop-ui/icons/react/dop/16';
import { useAuthContext } from '../../../../next/lib/auth/client/Context';
import { date } from '../../../shared/lib/date';
import { Button } from '../../../shared/ui/button';
import { FileItem } from '../../../shared/ui/file-item';
import { chunkArray } from '../../../shared/lib/utils';
import { getLocalStorageItem } from '../../../shared/lib/utils/local-storage';
import { POPUP_OPTIONS, POPUP_STORAGE_KEY } from './constants';
import type {
  IPopupAnnouncement,
  IPopupAnnouncementStorage,
  UploadFile,
} from './types';
import styles from './styles.module.css';
import { getPopupFileDownloadUrl } from './utils';

interface Props {
  previewMode: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  list: IPopupAnnouncement[];
  setList: Dispatch<SetStateAction<IPopupAnnouncement[]>>;
}
function PopupAnnouncementList({
  previewMode,
  open,
  setOpen,
  list,
  setList,
}: Props) {
  const { t } = useTranslation('component');
  const { me } = useAuthContext();

  const [page, setPage] = useState(0);

  const handleOnNext = () => {
    if (list.length - 1 <= page) return;
    setPage((prev) => prev + 1);
  };

  const handleOnPrev = () => {
    if (page === 0) return;
    setPage((prev) => prev - 1);
  };

  const hasPrev = page > 0;
  const hasNext = page < list.length - 1;

  useEffect(() => {
    if (list.length === 0) {
      setOpen(false);
    }
  }, [page, list]);

  const handleOnClickOption = (announcement: IPopupAnnouncement) => {
    if (!previewMode) {
      const announcementStorage = getLocalStorageItem<
        IPopupAnnouncementStorage[]
      >({
        key: `${POPUP_STORAGE_KEY}-${me?.companyId}-${me?.id}`,
        defaultValue: [],
        type: 'object',
      });

      const key = String(announcement.id);
      const current = date().set('h', 23).set('m', 59).set('s', 59);

      switch (announcement.option) {
        case 'DO_NOT_OPEN_ANYMORE':
          announcementStorage.push({
            key,
            value: date('9999-12-31').toString(),
          });
          break;
        case 'DO_NOT_OPEN_FOR_A_DAY':
          announcementStorage.push({
            key,
            value: current.toDate().toString(),
          });
          break;
        case 'DO_NOT_OPEN_FOR_A_WEEK':
          announcementStorage.push({
            key,
            value: date(current).add(1, 'week').toDate().toString(),
          });
      }
      localStorage.setItem(
        `${POPUP_STORAGE_KEY}-${me?.companyId}-${me?.id}`,
        JSON.stringify(announcementStorage),
      );
    }
    setList((prev) => prev.filter((item) => item.id !== announcement.id));
  };

  const currentItem = list[page];
  return (
    currentItem && (
      <Dialog.Root modal open={open} onOpenChange={setOpen}>
        <Dialog.Content usePortal useOverlay className={styles.Wrapper}>
          <p className={styles.Header}>{currentItem.title}</p>
          <div
            className={styles.Contents}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(currentItem.content),
            }}
          />
          {currentItem.attachFileList && (
            <div className={styles.FileListContainer}>
              <div className={styles.FileList}>
                {chunkArray<UploadFile>(currentItem.attachFileList, 3).map(
                  (fileList, idx) => (
                    <div key={idx} className={styles.FileRow}>
                      {fileList.map((file) => (
                        <FileItem
                          key={file.path + file.name}
                          fileName={file.name}
                          fileSize={file.size}
                          fileType={file.extension}
                          maxLength={14}
                          downloadUrl={getPopupFileDownloadUrl({
                            popupId: currentItem.id,
                            fileId: file.fileId!,
                          })}
                        />
                      ))}
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
          {
            <div className={styles.PageNavigation}>
              <Button disabled={!hasPrev} onClick={() => handleOnPrev()}>
                <PreviousIcon
                  className={
                    hasPrev
                      ? styles.NavigationActive
                      : styles.NavigationInActive
                  }
                />
              </Button>
              <Button disabled={!hasNext} onClick={() => handleOnNext()}>
                <NextIcon
                  className={
                    hasNext
                      ? styles.NavigationActive
                      : styles.NavigationInActive
                  }
                />
              </Button>
            </div>
          }

          {currentItem.option !== 'SHOW_EVERY_TIME' && (
            <div className={styles.Footer}>
              <Button
                onClick={() => handleOnClickOption(currentItem)}
                className={styles.FooterButton}
              >
                <span className={styles.FooterCheckBox}></span>
                {t(`${POPUP_OPTIONS[currentItem.option]}`)}
              </Button>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Root>
    )
  );
}

export default PopupAnnouncementList;
