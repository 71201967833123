import { Suspense } from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { LinksContainer } from './components/links-container';

export function SetLinksTab() {
  const { t } = useTranslation('component');
  return (
    <div className="mt-[24px] p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
      <div className="flex items-center h-[38px]">
        <p className="text-[#363636] text-[20px] font-[600]">
          {t('globalconfig.basicManagement.employeePortal.link.title')}
        </p>
      </div>
      <p className="mb-[24px] text-[#999999] text-[14px] font-[400]">
        {t('globalconfig.basicManagement.employeePortal.link.description')}
      </p>
      <Suspense fallback={<div>Loading...</div>}>
        <LinksContainer />
      </Suspense>
    </div>
  );
}
