import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export async function putAuthData(userId: number, companyAppIds: number[]) {
  const path = '/api/portal/setting/security/app-manager';
  const resp = await fetch(path, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      companyAppIds,
    }),
  });

  if (!resp.ok) throw new Error(`Failed to PUT ${path}` + resp.statusText);

  return resp.json();
}
