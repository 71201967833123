import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { isContentNeedsSaveAtom } from '../../../../../store/isContentNeedsSaveAtom';
import { SaveConfirmDialog } from '../../../../../parts/components/save-confirm-dialog';
import { useTabContextAction } from '../tab-provider';
import { TAB_KEY_CHANGE_MENU_ORDER, TAB_KEY_SET_APPS } from '../../constants';

export function TabSelector() {
  const { t } = useTranslation('component');

  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const { confirm } = useMessageDialog();
  const { setCurrentTab } = useTabContextAction();
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabWidth, setTabWidth] = useState(0);
  const [tabLeftMargin, setTabLeftMargin] = useState(0);

  useEffect(() => {
    const currentTab = tabsRef.current[currentTabIndex] as HTMLElement;
    setTabWidth(currentTab?.offsetWidth ?? 0);
    setTabLeftMargin(currentTab?.offsetLeft ?? 0);
  }, [currentTabIndex]);

  const selectTab = (index: number) => {
    setCurrentTabIndex(index);
    if (index === 0) {
      setCurrentTab(TAB_KEY_SET_APPS);
    } else {
      setCurrentTab(TAB_KEY_CHANGE_MENU_ORDER);
    }
  };

  const checkSave = async (index: number) => {
    if (await confirm(<SaveConfirmDialog />)) {
      setIsNeedSave(false);
      selectTab(index);
    }
  };

  const onClickTab = (index: number) => {
    if (isNeedsSave) {
      void checkSave(index);
    } else {
      selectTab(index);
    }
  };

  return (
    <div className="relative flex items-center gap-[24px] w-full h-[42px]">
      <div
        className="tabIndicator absolute bottom-0 h-[2px] bg-black transition-all duration"
        style={{
          width: `${tabWidth}px`,
          left: `${tabLeftMargin}px`,
        }}
      />
      <div
        className="h-full px-[8px]"
        ref={(el) => {
          if (el) tabsRef.current[0] = el;
        }}
        onClick={() => onClickTab(0)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClickTab(0);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <p
          className={clsx('text-[#5B5B5B] text-[18px]', {
            'font-[500]': currentTabIndex !== 0,
            'font-[600]': currentTabIndex === 0,
          })}
        >
          {t('globalconfig.basicManagement.businessPortal.setApps.title')}
        </p>
      </div>
      <div
        className="h-full mb-[2px] px-[8px]"
        ref={(el) => {
          if (el) tabsRef.current[1] = el;
        }}
        onClick={() => onClickTab(1)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClickTab(1);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <p
          className={clsx('text-[#5B5B5B] text-[18px]', {
            'font-[500]': currentTabIndex !== 1,
            'font-[600]': currentTabIndex === 1,
          })}
        >
          {t('globalconfig.basicManagement.businessPortal.gnbSort.title')}
        </p>
      </div>
    </div>
  );
}
