import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import * as adminTableDeleteMutation from '../../../AdminTable/api/adminTableDeleteMutation';
import * as adminTableQueries from '../../../AdminTable/api/adminTableQueries';
import {
  useActionsContext as pageActionsContext,
  useValuesContext as pageValuesContext,
} from '../../../Context';
import { useContextValues as useTabContextValues } from '../SubTabGroup/Context';
import * as authDataInfoQuery from './api/authDataInfoQuery';
import * as authDataInsertMutation from './api/authDataInsertMutation';
import CheckList from './CheckList';
import { useContextValues } from './Context';
import { AuthListInfo, CheckInfo } from './types';

export default function Content() {
  const { authList, setAuthList } = useContextValues();
  const { setEditMode } = useTabContextValues();
  const { currentMember } = pageValuesContext();
  const { setCurrentMember } = pageActionsContext();
  const { t } = useTranslation('component');

  const { info, warning } = useToastMessage();
  const getAuthInfoList = (resData: authDataInfoQuery.AuthInfoResponseData) => {
    const responseData: AuthListInfo[] = [
      {
        title: t(
          'globalconfig.secureManagement.adminManagement.organization.role.app',
        ),
        checkList: resData.organizationRoleAppList,
      },
      {
        title: t('globalconfig.secureManagement.adminManagement.setting.app'),
        checkList: resData.settingAppList,
      },
      {
        title: t('globalconfig.secureManagement.adminManagement.employee.app'),
        checkList: resData.employeeAppList,
      },
      {
        title: t('globalconfig.secureManagement.adminManagement.business.app'),
        checkList: resData.businessAppList,
      },
    ];

    if (currentMember?.isMaster) {
      responseData.unshift({
        isMaster: true,
        title: t('globalconfig.secureManagement.adminManagement.master.app'),
        checkList: [],
      });
    }

    return responseData;
  };
  const queryClient = useQueryClient();
  const {
    data: initiallAuthInfoListData,
    error,
    refetch,
  } = useSuspenseQuery({
    queryFn: async () =>
      getAuthInfoList(
        await authDataInfoQuery.getAuthInfoResponseData(
          currentMember?.companyAppIds ?? [],
        ),
      ),
    queryKey: [authDataInfoQuery.QUERY_KEY],
  });

  const saveAdminMutation = useMutation({
    mutationFn: async () => {
      const checkList: CheckInfo[] = [];
      authList.forEach((item) => checkList.push(...item.checkList));
      const checkedAppList = checkList
        .filter((item) => item.isChecked)
        .map((item) => item.id);
      if (
        checkedAppList.length === 0 &&
        currentMember?.userId &&
        !currentMember?.isMaster
      ) {
        await adminTableDeleteMutation.deleteMasterDataInfo([
          currentMember?.userId,
        ]);
      } else {
        currentMember &&
          (await authDataInsertMutation.putAuthData(
            currentMember?.userId,
            checkedAppList,
          ));
      }
    },
    onSuccess: async () => {
      setCurrentMember(undefined);
      info(t('globalconfig.secureManagement.adminManagement.toast.save'));
      await queryClient.invalidateQueries({
        queryKey: [adminTableQueries.QUERY_KEY],
      });
    },
    onError: async () => {
      warning(t('globalconfig.common.toastMessage.fail'));
      setCurrentMember(undefined);
      await queryClient.invalidateQueries({
        queryKey: [adminTableQueries.QUERY_KEY, authDataInfoQuery.QUERY_KEY],
      });
    },
  });

  useEffect(() => {
    if (initiallAuthInfoListData) {
      setAuthList(initiallAuthInfoListData);
    }
  }, [initiallAuthInfoListData, setAuthList]);

  useEffect(() => {
    refetch().catch(console.error);
  }, [currentMember, refetch]);

  const onCancelHandler = () => {
    setEditMode(false);
    setCurrentMember(undefined);
    void queryClient.invalidateQueries({
      queryKey: [adminTableQueries.QUERY_KEY],
    });
  };

  const onSaveHandler = () => {
    setEditMode(false);
    saveAdminMutation.mutate();
  };

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      {currentMember ? (
        <div
          className={clsx(
            'section narrow !overflow-scroll max-h-[680px] w-[40%] !border-solid !border-l-[1px] border-[#EDEDED] p-4',
            {
              '!grid !place-items-center': !currentMember,
            },
          )}
        >
          {authList.map((info) => {
            return <CheckList key={info.title} info={info} />;
          })}
          <div className="group_btn half">
            <Button
              size="medium"
              shape="rect"
              colorset="level1"
              variant="outline"
              onClick={onCancelHandler}
            >
              {t('globalconfig.common.button.cancel.title')}
            </Button>
            <Button
              size="medium"
              shape="rect"
              variant="solid"
              colorset="level1"
              onClick={onSaveHandler}
              disabled={authList?.at(0)?.isMaster}
            >
              {t('globalconfig.common.button.save.title')}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
