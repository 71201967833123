import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { PropsWithChildren, Suspense } from 'react';
import { ContentBox } from '../../../../parts/components/content-box';

export function Contents({ children }: PropsWithChildren) {
  const { t } = useTranslation('component');

  return (
    <ContentBox title={t('globalconfig.secureManagement.adminLogConfig.title')}>
      <Suspense
        fallback={
          <div className="min-h-[282px] w-full flex items-center justify-center text-lg text-black font-medium">
            Loading...
          </div>
        }
      >
        <div className="flex-col flex w-full h-full">{children}</div>
      </Suspense>
    </ContentBox>
  );
}

export default Contents;
