import { clsx } from 'clsx';
import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { ArrowDownTrayIcon } from '@daouoffice/ui/lib/icons/heroicons/24/solid';
import { Button, IconButton } from '@daouoffice/ui/lib/foundation/Button';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ipInfoToString, parseIpFromText } from '../../utils/ipInfoUtils';
import { IPInfo } from '../../types';

export interface Props {
  onClickAdd: (ipList: IPInfo[]) => void;
}

export function IpListImportDialog({ onClickAdd }: Props) {
  const { t } = useTranslation('component');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ipList, setIpList] = useState<IPInfo[]>([]);
  const isIpListEmpty = ipList.length === 0;

  const toaster = useToastMessage();

  const parseIpList = async (text: string) => {
    const parsedList = await parseIpFromText(text);
    if (parsedList.length !== 0) {
      setIpList(parsedList);
      toaster.info(
        t('globalconfig.secureManagement.loginSecureConfig.ip.added'),
      );
    } else
      toaster.warning(
        t('globalconfig.secureManagement.loginSecureConfig.ip.invalid'),
      );
    setIsLoading(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setIsLoading(true);
        const text = e.target?.result as string;
        void parseIpList(text);
      };
      reader.readAsText(file);
    }
  };

  const onClose = () => {
    setIpList([]);
    setIsLoading(false);
    setOpen(false);
  };

  const onClickSave = () => {
    onClickAdd(ipList);
    onClose();
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button className="flex items-center ml-[8px] h-[32px] px-[12px] rounded-[4px] border border-solid border-[#C5C5C5]">
          <ArrowDownTrayIcon className="inline size-[16px]" />
          <span className="ml-[4px] text-[14px] text-[#363636] font-[400]">
            {t('globalconfig.secureManagement.loginSecureConfig.ip.listImport')}
          </span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 z-[999]" />
        <Dialog.Content className="fixed bg-white p-[40px] overflow-clip rounded-[16px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[999]">
          {isLoading && (
            <div className="loading_indicator absolute flex items-center justify-center top-0 right-0 left-0 bottom-0 z-[2000] bg-black bg-opacity-75">
              <p className="text-white text-center">...Loading</p>
            </div>
          )}
          <Dialog.Close asChild>
            <IconButton
              title="close"
              className="absolute size-[40px] top-[8px] right-[4px]"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Dialog.Close>
          <p className="text-[#1A1A1A] text-[26px] font-[500]">
            {t('globalconfig.secureManagement.ipImportDialog.title')}
          </p>
          <div className="flex mt-[32px]">
            <div className="flex items-center h-[40px] w-[120px]">
              <p className="text-[#1A1A1A] text-[14px] font-[500]">
                {t('globalconfig.secureManagement.ipImportDialog.file.select')}
              </p>
            </div>
            <div className="ml-[8px] min-w-[344px] flex flex-col">
              <div className="flex items-center h-[40px]">
                <p className="h-[40px]"></p>
                <input
                  className="hidden"
                  title={t(
                    'globalconfig.secureManagement.ipImportDialog.file.search',
                  )}
                  type="file"
                  accept=".txt"
                  id="IP_LIST_TXT"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="IP_LIST_TXT"
                  className={clsx(
                    'flex items-center h-full px-[16px] rounded-[8px] cursor-pointer',
                    {
                      'bg-[#08A7BF] text-white': isIpListEmpty,
                      'border border-solid border-[#C5C5C5]': !isIpListEmpty,
                    },
                  )}
                >
                  {t(
                    'globalconfig.secureManagement.ipImportDialog.file.search',
                  )}
                </label>
              </div>
              <p className="mt-[8px] text-[#888888] text-[13px] font-[400]">
                {t('globalconfig.secureManagement.ipImportDialog.description')}
              </p>
              <p className="text-[#888888] text-[13px] font-[400]">
                {t('globalconfig.secureManagement.ipImportDialog.description2')}
              </p>
            </div>
          </div>
          {!isIpListEmpty && (
            <div className="flex mt-[24px]">
              <p className="h-[40px] w-[120px] text-[#1A1A1A] text-[14px] font-[500]">
                {t('globalconfig.secureManagement.ipImportDialog.ipList')}
              </p>
              <textarea
                className="ml-[8px] pl-[12px] py-[8px] flex-grow bg-transparent resize-none border border-solid border-[#D8D8D8] rounded-[8px] focus:outline-none hover:border-[#D8D8D8]"
                value={ipList.map(ipInfoToString).join('\n')}
                disabled
              />
            </div>
          )}
          <div className="flex mt-[40px] justify-end gap-[16px]">
            <Dialog.Close asChild>
              <Button
                title={t('dialog.cancel')}
                size="medium"
                styleType="ghost"
                shape="rect"
                onClick={onClose}
              />
            </Dialog.Close>
            {!isIpListEmpty && (
              <Button
                title={t(
                  'globalconfig.secureManagement.loginSecureConfig.ip.add',
                )}
                size="medium"
                styleType="solid"
                shape="rect"
                onClick={onClickSave}
              />
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
