import type { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import { ChevronDownIcon } from '@daouoffice/ui/lib/icons/dop/24/ChevronDown';
export interface Props {
  title: string;
  hasChildren?: boolean;
  href?: string;
  target?: string;
}

export function Item({
  children,
  title,
  hasChildren = false,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <li>
      <Tooltip.Root>
        <Tooltip.Trigger>
          <div
            // text={title}
            role="button"
            className={clsx(
              {
                btn_icon_select: hasChildren,
                btn_icon: !hasChildren,
              },
              'medium',
            )}
            {...props}
          >
            {children}
            {hasChildren && <ChevronDownIcon size={14} />}
          </div>
        </Tooltip.Trigger>
        {/* 반드시 Portal로 띄워야 오거나이저의 패널 뒤에 숨지 않음. 추후 z-index 정리 필요 */}
        <Tooltip.Portal>
          <Tooltip.Content align="center" side="bottom" sideOffset={5}>
            {title}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </li>
  );
}

export default Item;
