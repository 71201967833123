import { Avatar } from '@daouoffice/ui/lib/foundation/Avatar';
import { default as ArrowRotateLeftIcon } from '@daouoffice/ui/lib/icons/dop/24/ArrowRotateLeft';
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@daouoffice/ui/lib/icons/heroicons/24/outline';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ContentBox } from '../../../parts/components/content-box';
import { formatDateString } from '../utils/dateTimeUtils';
import {
  getTfaErrorLogs,
  QUERY_KEY,
  resetUserState,
} from './apis/tfaErrorLogList';
import {
  OrderType,
  SearchType,
  TableQueryInfo,
  TfaErrorLogInfo,
} from './types';

export function TfaErrorLog() {
  const { t } = useTranslation('component');
  const [tableInfo, setTableInfo] = useState<TableQueryInfo>({
    orderType: 'failTime',
    direction: 'desc',
    searchType: 'name',
    keyword: '',
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [selectedContents, setSelectedContents] = useState<TfaErrorLogInfo[]>(
    [],
  );
  const { confirm } = useMessageDialog();

  const queryClient = useQueryClient();

  const toaster = useToastMessage();

  const { data: tfaErrorLogs, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY, page, size, tableInfo],
    queryFn: async () => await getTfaErrorLogs(page, size, tableInfo),
  });

  useEffect(() => {
    if (!tfaErrorLogs) return;
    setPage(tfaErrorLogs.page.pageNumber);
    setSize(tfaErrorLogs.page.pageSize);
  }, [tfaErrorLogs]);

  useEffect(() => {
    setPage(0);
  }, [tableInfo.keyword, size]);

  const days = {
    monday: t('days.monday'),
    tuesday: t('days.tuesday'),
    wednesday: t('days.wednesday'),
    thursday: t('days.thursday'),
    friday: t('days.friday'),
    saturday: t('days.saturday'),
    sunday: t('days.sunday'),
  };

  const onClickSort = (orderType: OrderType) => {
    if (tableInfo.orderType === orderType) {
      setTableInfo({
        ...tableInfo,
        direction: tableInfo.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setTableInfo({
        ...tableInfo,
        orderType: orderType,
        direction: 'desc',
      });
    }
  };

  const resetUserStateMutaion = useMutation({
    mutationFn: async (userIds: number[]) => await resetUserState(userIds),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.secureManagement.tfaErrorLog.init.success'));
    },
  });

  const onChangeSearchKeyword = (keyword: string) => {
    setTableInfo({ ...tableInfo, keyword });
  };

  const onClickReset = () => {
    if (!selectedContents || selectedContents.length === 0) {
      toaster.warning(
        t('globalconfig.secureManagement.tfaErrorLog.init.selectMember'),
      );
    } else {
      void confirmReset();
    }
  };

  const confirmReset = async () => {
    if (
      await confirm(
        <div className="w-full px-[24px]">
          {t('globalconfig.secureManagement.tfaErrorLog.init.confirm', {
            count: selectedContents.length,
          })}
        </div>,
      )
    ) {
      resetUserStateMutaion.mutate(selectedContents.map((c) => c.id));
    }
  };

  const SortChevron = ({ orderType }: { orderType: OrderType }) => {
    if (tableInfo.orderType === orderType) {
      if (tableInfo.direction === 'asc')
        return <ChevronUpIcon className="inline ml-[8px] size-[16px]" />;
      else return <ChevronDownIcon className="inline ml-[8px] size-[16px]" />;
    } else {
      return (
        <ChevronUpDownIcon
          className="inline ml-[8px] size-[16px]"
          color="#AAAAAA"
        />
      );
    }
  };

  const columnDefs: ColumnDef<TfaErrorLogInfo>[] = [
    {
      id: 'userName',
      accessorFn: (info) => ({ name: info.name, userImage: info.userImage }),
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => onClickSort('name')}
        >
          {t('globalconfig.secureManagement.tfaErrorLog.table.header.name')}
          <SortChevron orderType={'name'} />
        </button>
      ),
      cell: (cell) => {
        const value = cell.getValue() as { name: string; userImage?: string };
        return (
          <p className="flex items-center">
            <Avatar size={24} src={value.userImage} alt={value.name} />
            <span className="ml-[8px] text-[#262626] text-[14px] font-[400]">
              {value.name}
            </span>
          </p>
        );
      },
    },
    {
      id: 'account',
      accessorKey: 'loginId',
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => {
            onClickSort('loginId');
          }}
        >
          {t('globalconfig.secureManagement.tfaErrorLog.table.header.account')}
          <SortChevron orderType={'loginId'} />
        </button>
      ),
    },
    {
      id: 'department',
      accessorFn: (info) => info.departmentNames.join(', '),
      header: () => (
        <div className="text-left px-[--Space-Small]">
          {t(
            'globalconfig.secureManagement.tfaErrorLog.table.header.department',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'failTime',
      accessorFn: (info) => formatDateString(days, info.failTime),
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => {
            onClickSort('failTime');
          }}
        >
          {t('globalconfig.secureManagement.tfaErrorLog.table.header.failTime')}
          <SortChevron orderType={'failTime'} />
        </button>
      ),
    },
  ];

  if (error) {
    console.error(
      'Error: GlobalConfig > SecureManagement > TfaErrorLog : ',
      error,
    );
  }

  return (
    <ContentBox
      title={t('globalconfig.secureManagement.tfaErrorLog.title')}
      description={t('globalconfig.secureManagement.tfaErrorLog.description')}
    >
      <TablePrimitives.Root<TfaErrorLogInfo>
        className="mt-[24px]"
        columnDefs={columnDefs}
        contents={tfaErrorLogs?.elements ?? []}
        selectable
        onSelectContents={setSelectedContents}
      >
        <div className="flex mb-[16px]">
          <div className="flex flex-col">
            <TablePrimitives.SearchBar
              className="inline-block w-[240px] h-[40px]"
              onKeywordChange={onChangeSearchKeyword}
            >
              <select
                className="w-[50px] border-none mb-[2px] flex-shrink-0"
                onChange={(e) =>
                  setTableInfo({
                    ...tableInfo,
                    searchType: e.target.value as SearchType,
                  })
                }
              >
                <option value={'name'}>
                  {t(
                    'globalconfig.secureManagement.tfaErrorLog.table.header.name',
                  )}
                </option>
                <option value={'loginId'}>
                  {t(
                    'globalconfig.secureManagement.tfaErrorLog.table.header.account',
                  )}
                </option>
              </select>
            </TablePrimitives.SearchBar>
            {tableInfo.keyword && (
              <div className="pt-3 text-[#81A9FC]">{`${tfaErrorLogs.elements.length}${t('globalconfig.common.table.search.result')}`}</div>
            )}
          </div>
          <div className="spacer flex-grow" />
          <div className="flex justify-between items-end">
            <Button
              className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded"
              onClick={onClickReset}
            >
              <ArrowRotateLeftIcon size={24} className="" />
              {t('globalconfig.secureManagement.tfaErrorLog.init.button.title')}
            </Button>
            <TablePrimitives.SizeSelector
              className="w-[52px]"
              tableSize={size}
              onChangeTableSize={setSize}
            />
          </div>
        </div>
        <TablePrimitives.Contents
          emptyNotice={
            <div className="w-full h-[80px] flex items-center justify-center ">
              <span className="text-[--color-text-level3] text-[14px] font-[400]">
                {t('globalconfig.secureManagement.tfaErrorLog.table.noList')}
              </span>
            </div>
          }
        ></TablePrimitives.Contents>
        <TablePrimitives.Pagination
          className="mt-[16px] py-[16px]"
          currentPage={page}
          totalPage={tfaErrorLogs?.page.totalPages ?? 0}
          onNextPage={setPage}
          onPreviousPage={setPage}
          onClickPage={setPage}
          onFirstPage={() => setPage(0)}
          onLastPage={() =>
            setPage(tfaErrorLogs ? tfaErrorLogs.page.totalPages - 1 : 0)
          }
        />
      </TablePrimitives.Root>
    </ContentBox>
  );
}
