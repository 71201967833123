'use client';
/**
 * @deprecated
 * @description @dop-ui/react/features/authenticate 패키지를 사용하세요.
 */
import {
  SessionContext,
  SessionContextValues,
  useSessionContext,
} from '@dop-ui/react/features/authenticate/ui/client/session-context';

/**
 * @deprecated
 */
export interface AuthContextValues extends SessionContextValues {}
/**
 * @deprecated
 */
export const AuthContext = SessionContext;
/**
 * @deprecated
 */
export const useAuthContext = useSessionContext;
