import { DayOfWeek } from '../types';

export function dayOfWeekToLocalizedCode(dayOfWeek: DayOfWeek): string {
  switch (dayOfWeek) {
    case 'SUNDAY':
      return 'days.full.sunday';
    case 'MONDAY':
      return 'days.full.monday';
    case 'TUESDAY':
      return 'days.full.tuesday';
    case 'WEDNESDAY':
      return 'days.full.wednesday';
    case 'THURSDAY':
      return 'days.full.thursday';
    case 'FRIDAY':
      return 'days.full.friday';
    case 'SATURDAY':
      return 'days.full.saturday';
  }
}

export function dayOfWeekToNumber(dayOfWeek: DayOfWeek): number {
  switch (dayOfWeek) {
    case 'SUNDAY':
      return 0;
    case 'MONDAY':
      return 1;
    case 'TUESDAY':
      return 2;
    case 'WEDNESDAY':
      return 3;
    case 'THURSDAY':
      return 4;
    case 'FRIDAY':
      return 5;
    case 'SATURDAY':
      return 6;
  }
}
