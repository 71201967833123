import { Suspense, useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { Switch } from '@daouoffice/ui';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { SortConfig } from './sort-config';
import {
  getUserModificationAllowInfo,
  QUERY_KEY,
  updateUserModificationAllowInfo,
} from './apis/user-modify-allow';

export function GnbSortTab() {
  const { t } = useTranslation('component');
  const queryClient = useQueryClient();
  const toaster = useToastMessage();
  const { confirm } = useMessageDialog();

  const [isModificationAllowed, setIsModificationAllowed] = useState(false);

  const { data: configInfo, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getUserModificationAllowInfo,
  });

  const updateModicitionConfigMutation = useMutation({
    mutationFn: (configValue: boolean) =>
      updateUserModificationAllowInfo({
        configName: configInfo.configName ?? '',
        configValue: configValue,
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.success'));
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  useEffect(() => {
    if (configInfo) {
      setIsModificationAllowed(configInfo.configValue);
    }
  }, [configInfo]);

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > EmpPortalAppManagement > GnbUserSet Error: ',
      error,
    );
  }

  const checkConfigSave = async (toBeValue: boolean) => {
    const dialogMessage = toBeValue ? (
      <div className="size-full px-[24px]">
        <p className="text-[#1A1A1A] text-[20px] font-[500]">
          {t(
            'globalconfig.basicManagement.employeePortal.gnbSort.allow.modify',
          )}
        </p>
        <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
          {t(
            'globalconfig.basicManagement.employeePortal.gnbSort.allow.modify.description2',
          )}
        </p>
      </div>
    ) : (
      <div className="size-full px-[24px]">
        <p className="text-[#1A1A1A] text-[20px] font-[500]">
          {t(
            'globalconfig.basicManagement.employeePortal.gnbSort.notAllow.modify',
          )}
        </p>
        <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
          {t(
            'globalconfig.basicManagement.employeePortal.gnbSort.notAllow.modify.description',
          )}
        </p>
      </div>
    );

    if (await confirm(dialogMessage)) {
      updateModicitionConfigMutation.mutate(toBeValue);
    }
  };

  const onChangeConfig = (checked: boolean) => {
    void checkConfigSave(checked);
  };

  return (
    <>
      <div className="mt-[24px] p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
        <div className="flex items-center h-[38px]">
          <p className="text-[#363636] text-[20px] font-[600]">
            {t(
              'globalconfig.basicManagement.employeePortal.gnbSort.allow.modify',
            )}
          </p>
        </div>
        <div className="flex flex-wrap mt-[16px] h-[66px] py-[12px]">
          <p className="w-[192px] flex-shrink-0 text-[#363636] text-[14px] font-[500]">
            {t(
              'globalconfig.basicManagement.employeePortal.gnbSort.allow.modify',
            )}
          </p>
          <p className="mb-[24px] text-[#999999] text-[14px] font-[400] whitespace-pre-wrap">
            {t(
              'globalconfig.basicManagement.employeePortal.gnbSort.allow.modify.description',
            )}
          </p>
          <div className="flex-grow" />
          <Switch
            className="mt-[12px]"
            id={'Gnb.SortConfig'}
            checked={isModificationAllowed}
            onChange={onChangeConfig}
          />
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <SortConfig />
      </Suspense>
    </>
  );
}
