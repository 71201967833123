import { AppsDockConfigItem } from '../AppsDockConfigItem';
import { useContext } from '../Context';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';

export function AppsDockConfigList() {
  const { userApps, setUserApps } = useContext();
  return (
    <DnDList.Provider idKey="uid" name="user_apps">
      <DnDList.Container list={userApps} setList={setUserApps}>
        {userApps.map((item, index) => (
          <AppsDockConfigItem index={index} key={item.uid} item={item} />
        ))}
      </DnDList.Container>
    </DnDList.Provider>
  );
}

export default AppsDockConfigList;
