import { Response, Share } from '../../../../types';
import { APP_SHARE_ACTIVE_COMPANY_BOARD_LIST_URL } from '../constants';
import { CompanyBoardInfo } from '../types';

interface ICompanyBoardInfo {
  companyShares: Share[];
  board: IBoardInfo;
  id: number;
}

interface IBoardInfo {
  name: string;
}

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.AppShare.CompanyBoardListQuery';

export async function getCompanyCalenderList(
  companyUuid: string,
): Promise<CompanyBoardInfo[]> {
  try {
    const response = await fetch(
      APP_SHARE_ACTIVE_COMPANY_BOARD_LIST_URL(companyUuid),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getCompanyCalenderList 오류 발생.');
    }

    const resData = (await response.json()) as Response<ICompanyBoardInfo>;
    return resData.data.map(mapper);
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

function mapper(item: ICompanyBoardInfo): CompanyBoardInfo {
  return {
    id: item.id,
    name: item.board.name,
    companyShares: item.companyShares,
  };
}
