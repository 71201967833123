import { UPLOAD_COMMON_FILE_API } from '../constants';
import { Option, UploadResponse } from '../types';
import { BaseResponse } from './../../../../next/components/GlobalConfig/pages/multi-company-management/types';

export async function uploadCommonFile(file: File, option?: Option) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await fetch(
      `${UPLOAD_COMMON_FILE_API}${option ? '?' + new URLSearchParams(option as Record<string, string>).toString() : ''}`,
      {
        method: 'POST',
        headers: {
          'X-Referer-Info': window.location.host,
        },
        body: formData,
      },
    );
    const { data } = (await res.json()) as BaseResponse<UploadResponse>;
    return data;
  } catch (error) {
    return error;
  }
}
