import { LinkInfo } from './types';

export const TAB_KEY_SET_APPS = 'TabKey.EmployeePortalAppManagement.Apps';
export const TAB_KEY_LINKS = 'TabKey.EmployeePortalAppManagement.Links';
export const TAB_KEY_CHANGE_MENU_ORDER =
  'TabKey.EmployeePortalAppManagement.ChangeMenuOrder';

export const BASE_LINK_INFO: LinkInfo = {
  url: '',
  webActivated: true,
  mobileActivated: true,
  multiLanguage: {
    KOREAN: '새 링크',
    ENGLISH: '',
    JAPANESE: '',
    CHINESE_SIMPLIFIED: '',
    CHINESE_TRADITIONAL: '',
    VIETNAMESE: '',
  },
  webNavigationMode: 'SAME_PAGE',
  mobileNavigationMode: 'NEW_PAGE',
};
