import { ExtensionInfo } from './types';

export function removeDuplicates(array: string[]): string[] {
  const set = new Set(array);
  return Array.from(set);
}

export function newBlackListAndDuplicatedListInfo(
  defaultList: string[] = [],
  oldList: ExtensionInfo[] = [],
  newList: ExtensionInfo[],
): { resultList: ExtensionInfo[]; notAdded: string[]; alreadyAdded: string[] } {
  const defaultNameSet = new Set<string>(defaultList);
  const nameSet = new Set<string>();
  const notAdded = new Array<string>();
  const alreadyAdded = new Array<string>();
  oldList.forEach((info) => nameSet.add(info.extensionName));

  newList.forEach((info) => {
    if (defaultNameSet.has(info.extensionName)) {
      notAdded.push(info.extensionName);
    } else if (nameSet.has(info.extensionName)) {
      alreadyAdded.push(info.extensionName);
    }
  });

  const resultList = [
    ...oldList,
    ...newList.filter(
      (name) =>
        !nameSet.has(name.extensionName) &&
        !defaultNameSet.has(name.extensionName),
    ),
  ];

  return {
    resultList,
    notAdded,
    alreadyAdded,
  };
}

export function oneDeletedBlacklist(
  oldList: ExtensionInfo[] = [],
  target: ExtensionInfo,
): ExtensionInfo[] {
  return oldList.filter((info) => info.extensionName !== target.extensionName);
}
