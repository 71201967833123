import { useNavigate } from './use-navigate';
import { usePop } from './use-pop';
import { usePush } from './use-push';

export { usePop } from './use-pop';
export { usePush } from './use-push';
export { useNavigate } from './use-navigate';

export const useStackMethod = <T>() => {
  return { push: usePush<T>(), pop: usePop(), navigate: useNavigate<T>() };
};
