import { fetch } from '../../../../../../lib/fetch/client';
import { AppType } from '../types';
import { GROUPWARE_API_URL } from './constants';

export async function dataDownloadQuery(
  deptId: number,
  selcectedAppType: AppType | undefined,
) {
  if (selcectedAppType === 'board') {
    return await boardDataDownloadQuery(deptId);
  } else if (selcectedAppType === 'report') {
    return await reportDataDownloadQuery(deptId);
  } else if (selcectedAppType === 'group') {
    return await groupDataDownloadQuery(deptId);
  } else if (selcectedAppType === 'approval') {
    return await approvalDataDownloadQuery(deptId);
  }
}

export async function boardDataDownloadQuery(deptId: number) {
  try {
    const FETCH_URL = `${GROUPWARE_API_URL}/department/${deptId}/boards/download`;
    return (await fetch(FETCH_URL)).blob();
  } catch (error) {
    console.error(error);
  }
}

export async function reportDataDownloadQuery(deptId: number) {
  try {
    const FETCH_URL = `${GROUPWARE_API_URL}/setting/storage/report/department/${deptId}/folders/download`;
    return (await fetch(FETCH_URL)).blob();
  } catch (error) {
    console.error(error);
  }
}

export async function groupDataDownloadQuery(deptId: number) {
  try {
    const FETCH_URL = `${GROUPWARE_API_URL}/contact/department/${deptId}/groups/download`;
    return (await fetch(FETCH_URL)).blob();
  } catch (error) {
    console.error(error);
  }
}

export async function approvalDataDownloadQuery(deptId: number) {
  try {
    const FETCH_URL = `${GROUPWARE_API_URL}/approval/deptfolder/download/${deptId}`;
    return (await fetch(FETCH_URL)).blob();
  } catch (error) {
    console.error(error);
  }
}
