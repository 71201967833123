import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { useQueryClient } from '@tanstack/react-query';
import * as getMemoList from '../apis/getMemoList';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';
import { useOrganizerAtom } from '../../../Provider';
import MemoSearchList from './memo-search-list';
import {
  useStack,
  useStackMethod,
} from '@daouoffice/ui/lib/foundation/Stacker';

import PanelHeader from '../../panel-header';
import {
  PreviousIndicatorIcon,
  XMarkCircleIcon,
} from '@daouoffice/ui/lib/icons/dop/16';
import { OrganizerMemoPages } from '..';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { Button } from '@dop-ui/react/shared/ui/button';
import styles from '../../../organizer-panel.module.css';
import memoStyles from '../memo.module.css';

export function OrganizerMemoSearch() {
  const { stack } = useStack<OrganizerMemoPages>();
  const { pop } = useStackMethod<OrganizerMemoPages>();
  const canPop = stack.length > 1;

  const { t } = useTranslation();

  const [, setOrganizerAtom] = useOrganizerAtom();

  const queryClient = useQueryClient();

  const { memoContext, setMemoContext } = useMemoContext();

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setKeyword(memoContext.keyword);
  }, [memoContext.keyword]);

  const updateSearchKeyword = (searchKeyword: string) => {
    setMemoContext({
      ...memoContext,
      keyword: searchKeyword,
    });
  };

  const updateSearch = (searchKeyword: string) => {
    updateSearchKeyword(searchKeyword);
    void queryClient.invalidateQueries({
      queryKey: [getMemoRecentlySearchList.QUERY_KEY],
    });
  };

  const handleClickCloseBtn = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
      panelState: 'none',
    }));
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      updateSearch(keyword);
    }
  };

  const handleClickPrevBtn = () => {
    pop();

    updateSearchKeyword('');
    void queryClient.invalidateQueries({
      queryKey: [getMemoList.QUERY_KEY],
    });
  };

  const handleClickClearBtn = () => {
    setKeyword('');
    updateSearch('');
  };

  const handleClickSearchBtn = () => {
    updateSearch(keyword);
  };

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.memo.title')}
        onClickClose={() => void handleClickCloseBtn()}
      />
      <div className={clsx(styles.Content)}>
        <div
          className={clsx(styles.ContentHeader, 'w-[350px] gap-1 py-[12px]')}
        >
          {canPop && (
            <Button
              className={memoStyles.MemoIconBtn}
              onClick={handleClickPrevBtn}
            >
              <PreviousIndicatorIcon size={24} />
            </Button>
          )}
          <div className="flex items-center h-[40px] px-[12px] bg-[#FFF] border border-[#D8D8D8] rounded-lg">
            <input
              type="text"
              className="w-[85%] text-[14px]"
              placeholder={t('organizer.memo.search.input')}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={handleOnKeyDown}
            />
            {keyword != '' && (
              <Button
                className={memoStyles.MemoSvgBtn}
                onClick={handleClickClearBtn}
              >
                <i className="icon w-[20px] h-[20px]">
                  <XMarkCircleIcon
                    size={15}
                    className="text-[#AAAAAA] p-[0.5px]"
                  />
                </i>
              </Button>
            )}
          </div>
          <Button
            shape="rect"
            size="medium"
            variant="outline"
            colorset="level1"
            className="hover:!bg-[#FFFFFF]"
            onClick={handleClickSearchBtn}
          >
            {t('organizer.memo.button.search')}
          </Button>
        </div>
        <div className="flex flex-col w-full ">
          <MemoSearchList />
        </div>
      </div>
    </div>
  );
}

export default OrganizerMemoSearch;
