export const FILTER_OPTION_DATE_TODAY = 'FilterOptionKey.date.today';
export const FILTER_OPTION_DATE_7DAYS = 'FilterOptionKey.date.7days';
export const FILTER_OPTION_DATE_30DAYS = 'FilterOptionKey.date.30days';
export const FILTER_OPTION_DATE_90DAYS = 'FilterOptionKey.date.90days';
export const FILTER_OPTION_DATE_SELF_INPUT = 'FilterOptionKey.date.selfInput';
export const FILTER_OPTION_DATE_SELF_INPUT_START =
  'FilterOptionKey.date.selfInput.start';
export const FILTER_OPTION_DATE_SELF_INPUT_END =
  'FilterOptionKey.date.selfInput.end';

export const FILTER_KEY_DATE_START = 'FilterOptionKey.date.startDate';
export const FILTER_KEY_DATE_END = 'FilterOptionKey.date.endDate';
