import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { LOGO_THEME_CONFIG_API } from '../../constants';
import { LogoThemeConfigInfo } from '../types';

export const MUTATION_KEY =
  'GlobalConfig.BasicManagement.LogoThemeManagement.LogoThemeMutation';

export async function putLogoThemeConfig(req: LogoThemeConfigInfo) {
  const res = await fetch(LOGO_THEME_CONFIG_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(req),
  });

  if (!res.ok) {
    throw new Error('Failed to update logo theme config');
  }
}
