import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { HolidayInfo } from '../../types';
import { Title } from './title';

interface Props {
  info: HolidayInfo;
  disabled?: boolean;
  isPaidModifiable?: boolean;
  onHolidayInfoChange: (data: HolidayInfo) => void;
}

export function HolidayInfoSelect({
  info,
  disabled = false,
  isPaidModifiable = true,
  onHolidayInfoChange,
}: Props) {
  const { t } = useTranslation('component');
  const handleChangeWageRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      onHolidayInfoChange({
        ...info,
        wageRate: 0,
      });
      return;
    }

    const regex = /^[0-9]+$/;

    if (!regex.test(value)) {
      return;
    }

    let rate = parseInt(value, 10);

    if (rate <= 0) rate = 0;
    if (rate > 999) rate = 999;

    onHolidayInfoChange({
      ...info,
      wageRate: rate,
    });
  };

  return (
    <div className="mt-[32px] flex flex-col gap-[8px]">
      <p className="leading-[24px] py-[8px] text-[16px] text-[#363636] //text-[--color-text-level1] font-[600]">
        {t('globalconfig.basicManagement.companySchedule.schedule.setHoliday')}
      </p>
      <div className="flex items-center">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.holidayType.title',
          )}
        />
        <div className="flex itmes-center flex-grow gap-[24px]">
          <div className="w-[80px]">
            <input
              id={'holidayType_statutory_holiday'}
              className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              name="holidayType"
              value="STATUTORY_HOLIDAY"
              title={t(
                'globalconfig.basicManagement.companySchedule.schedule.holidayType.statutory',
              )}
              checked={info.holidayType === 'STATUTORY_HOLIDAY'}
              disabled={disabled}
              onChange={() =>
                onHolidayInfoChange({
                  ...info,
                  holidayType: 'STATUTORY_HOLIDAY',
                })
              }
            />
            <label className="ml-[4px]" htmlFor="holidayType_statutory_holiday">
              {t(
                'globalconfig.basicManagement.companySchedule.schedule.holidayType.statutory',
              )}
              <ToolTip.Root>
                <ToolTip.Trigger>
                  <QuestionMarkCircleIcon
                    className="mb-[2px] ml-[2px] size-[20px]"
                    color="#AAAAAA"
                  />
                </ToolTip.Trigger>
                <ToolTip.Content className="z-[1001]">
                  {t(
                    'globalconfig.basicManagement.companySchedule.schedule.holidayType.statutory.description',
                  )}
                </ToolTip.Content>
              </ToolTip.Root>
            </label>
          </div>
          <div className="w-[80px]">
            <input
              id={'holidayType_contract_holiday'}
              className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              name="holidayType"
              value="CONTRACT_HOLIDAY"
              title={t(
                'globalconfig.basicManagement.companySchedule.schedule.holidayType.contract',
              )}
              checked={info.holidayType === 'CONTRACT_HOLIDAY'}
              disabled={disabled}
              onChange={() =>
                onHolidayInfoChange({
                  ...info,
                  holidayType: 'CONTRACT_HOLIDAY',
                })
              }
            />
            <label className="ml-[4px]" htmlFor="holidayType_contract_holiday">
              {t(
                'globalconfig.basicManagement.companySchedule.schedule.holidayType.contract',
              )}
              <ToolTip.Root>
                <ToolTip.Trigger>
                  <QuestionMarkCircleIcon
                    className="mb-[2px] ml-[2px] size-[20px]"
                    color="#AAAAAA"
                  />
                </ToolTip.Trigger>
                <ToolTip.Content className="z-[1001]">
                  {t(
                    'globalconfig.basicManagement.companySchedule.schedule.holidayType.contract.description',
                  )}
                </ToolTip.Content>
              </ToolTip.Root>
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.allowSubstituteHoliday.title',
          )}
        >
          <ToolTip.Root>
            <ToolTip.Trigger>
              <QuestionMarkCircleIcon
                className="ml-[2px] size-[20px]"
                color="#AAAAAA"
              />
            </ToolTip.Trigger>
            <ToolTip.Content className="z-[1001]">
              {t(
                'globalconfig.basicManagement.companySchedule.schedule.allowSubstituteHoliday.description',
              )}
            </ToolTip.Content>
          </ToolTip.Root>
        </Title>
        <div className="flex itmes-center flex-grow gap-[24px]">
          <div className="w-[80px]">
            <input
              id={'substituteHoliday_allow'}
              className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              name="substituteHoliday"
              value="true"
              title={t(
                'globalconfig.basicManagement.companySchedule.schedule.allowSubstituteHoliday.allow',
              )}
              checked={info.isSubstituteHoliday}
              disabled={disabled}
              onChange={() =>
                onHolidayInfoChange({
                  ...info,
                  isSubstituteHoliday: true,
                })
              }
            />
            <label className="ml-[4px]" htmlFor="substituteHoliday_allow">
              {t(
                'globalconfig.basicManagement.companySchedule.schedule.allowSubstituteHoliday.allow',
              )}
            </label>
          </div>
          <div className="w-[80px]">
            <input
              id={'substituteHoliday_disallow'}
              className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              name="substituteHoliday"
              value="false"
              title={t(
                'globalconfig.basicManagement.companySchedule.schedule.allowSubstituteHoliday.notAllow',
              )}
              checked={!info.isSubstituteHoliday}
              disabled={disabled}
              onChange={() =>
                onHolidayInfoChange({
                  ...info,
                  isSubstituteHoliday: false,
                })
              }
            />
            <label className="ml-[4px]" htmlFor="substituteHoliday_disallow">
              {t(
                'globalconfig.basicManagement.companySchedule.schedule.allowSubstituteHoliday.notAllow',
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="flex">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.paidUnpaid',
          )}
        />
        <div className="flex-grow flex flex-col">
          <div className="flex itmes-center gap-[24px] h-[40px] py-[10px]">
            <div className="w-[80px]">
              <input
                id={'paid_unpaid_paid'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="paid_unpaid"
                value="true"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.paid',
                )}
                checked={info.holidayPayType === 'PAID'}
                disabled={disabled}
                onChange={() =>
                  onHolidayInfoChange({
                    ...info,
                    holidayPayType: 'PAID',
                  })
                }
              />
              <label className="ml-[4px]" htmlFor="paid_unpaid_paid">
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.paid',
                )}
              </label>
            </div>
            <div className="w-[80px]">
              <input
                id={'paid_unpaid_unpaid'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="paid_unpaid"
                value="false"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.unpaid',
                )}
                checked={info.holidayPayType === 'UNPAID'}
                disabled={disabled}
                onChange={() =>
                  onHolidayInfoChange({
                    ...info,
                    holidayPayType: 'UNPAID',
                  })
                }
              />
              <label className="ml-[4px]" htmlFor="paid_unpaid_unpaid">
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.unpaid',
                )}
              </label>
            </div>
          </div>
          {info.holidayPayType === 'PAID' && (
            <div className="mt-[2px] flex items-center h-[56px] py-[8px] px-[16px] border border-dashed border-[#D8D8D8] rounded-[8px]">
              <CheckBox
                id={'wagerate'}
                label={t(
                  'globalconfig.basicManagement.companySchedule.schedule.wageRate.title',
                )}
                defaultDisabled={disabled && !isPaidModifiable}
                onChange={(checked) => {
                  onHolidayInfoChange({ ...info, isWageRateChecked: checked });
                }}
              />
              <input
                className="ml-[8px] mr-[4px] w-[60px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px] px-[12px]"
                disabled={
                  !info.isWageRateChecked || (disabled && !isPaidModifiable)
                }
                value={info.wageRate}
                onChange={handleChangeWageRate}
              />
              %
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
