import { clsx } from 'clsx';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { DatePicker } from '@daouoffice/ui/lib/foundation/DatePicker';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { CalendarRepeatType, DateTypeAndRepeat, DayOfWeek } from '../../types';
import { Title } from './title';
import { dateToYearMonthDayString } from '../../utils/date-to-string';

interface Props {
  currentYear: number;
  data: DateTypeAndRepeat;
  disabled?: boolean;
  onDateDataChange: (data: DateTypeAndRepeat) => void;
}

export function DateTypeAndRepeatSelect({
  currentYear,
  data,
  disabled = false,
  onDateDataChange,
}: Props) {
  const { t, i18n } = useTranslation('component');
  const minDate = data.isAnniversary ? new Date(currentYear, 0, 1) : new Date();
  const maxDate = new Date(currentYear, 11, 31);

  return (
    <>
      <div className="flex mt-[8px] gap-[8px]">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.dateType.title',
          )}
          isRequired
        />
        <div className="flex-grow">
          <div className="flex items-center gap-[24px] h-[40px]">
            <div className="w-[80px]">
              <input
                id={'dateType_date_specification_single'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="dateType"
                value="DATE_SPECIFICATION_SINGLE"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.dateType.dateSpecification',
                )}
                checked={data.dateType === 'DATE_SPECIFICATION_SINGLE'}
                disabled={disabled}
                onChange={() =>
                  onDateDataChange({
                    ...data,
                    dateType: 'DATE_SPECIFICATION_SINGLE',
                    repeatType: 'NONE',
                  })
                }
              />
              <label
                className="ml-[4px]"
                htmlFor="dateType_date_specification_single"
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.dateType.dateSpecification',
                )}
              </label>
            </div>
            <div className="w-[80px]">
              <input
                id={'dateType_day_specification'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="dateType"
                value="DAY_SPECIFICATION"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.dateType.daySpecification',
                )}
                checked={data.dateType === 'DAY_SPECIFICATION'}
                disabled={disabled}
                onChange={() =>
                  onDateDataChange({
                    ...data,
                    dateType: 'DAY_SPECIFICATION',
                    repeatType: 'NONE',
                  })
                }
              />
              <label className="ml-[4px]" htmlFor="dateType_day_specification">
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.dateType.daySpecification',
                )}
              </label>
            </div>
            <div className="w-[80px]">
              <input
                id={'dateType_date_specification'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="dateType"
                value="DATE_SPECIFICATION"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.dateType.periodSpecification',
                )}
                checked={data.dateType === 'DATE_SPECIFICATION'}
                disabled={disabled}
                onChange={() =>
                  onDateDataChange({
                    ...data,
                    dateType: 'DATE_SPECIFICATION',
                    repeatType: 'NONE',
                  })
                }
              />
              <label className="ml-[4px]" htmlFor="dateType_date_specification">
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.dateType.periodSpecification',
                )}
              </label>
            </div>
          </div>
          <div className="flex items-center gap-[8px]">
            {data.dateType !== 'DAY_SPECIFICATION' && (
              <DatePicker
                locale={i18n.language}
                onDateSelect={(date) => {
                  onDateDataChange({
                    ...data,
                    dateInfo: {
                      ...data.dateInfo,
                      startDate: date ? date : undefined,
                      endDate:
                        date &&
                        data.dateInfo?.endDate &&
                        date > data.dateInfo.endDate
                          ? date
                          : data.dateInfo?.endDate,
                    },
                  });
                }}
                defaultDate={data.dateInfo?.startDate}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
              >
                <div
                  className={clsx(
                    'flex items-center h-[40px] px-[12px] border border-solid border-[#D8D8D8] rounded-[8px]',
                    {
                      'bg-[#F9F9F9] text-[#ABABAB]': disabled,
                      'text-[#363636]': !disabled,
                    },
                  )}
                >
                  <CalendarIcon className="size-[24px]" />
                  <p className="px-[4px]  //text-[--color-text-level1] text-[14px] font-[400]">
                    {dateToYearMonthDayString(data.dateInfo?.startDate)}
                  </p>
                </div>
              </DatePicker>
            )}
            {data.dateType === 'DATE_SPECIFICATION' && (
              <>
                <p>~</p>
                <DatePicker
                  locale={i18n.language}
                  onDateSelect={(date) => {
                    onDateDataChange({
                      ...data,
                      dateInfo: {
                        ...data.dateInfo,
                        endDate: date ? date : undefined,
                      },
                    });
                  }}
                  defaultDate={data.dateInfo?.endDate}
                  disabled={disabled}
                  minDate={data.dateInfo?.startDate}
                  maxDate={maxDate}
                >
                  <div
                    className={clsx(
                      'flex items-center h-[40px] px-[12px] border border-solid border-[#D8D8D8] rounded-[8px]',
                      {
                        'bg-[#F9F9F9] text-[#ABABAB]': disabled,
                        'text-[#363636]': !disabled,
                      },
                    )}
                  >
                    <CalendarIcon className="size-[24px]" />
                    <p className="px-[4px] //text-[--color-text-level1] text-[14px] font-[400]">
                      {dateToYearMonthDayString(data.dateInfo?.endDate)}
                    </p>
                  </div>
                </DatePicker>
              </>
            )}
            {data.dateType === 'DAY_SPECIFICATION' && (
              <>
                <select
                  className="w-[100px] h-[40px] px-[12px] border border-solid border-[#D8D8D8] rounded-[8px]"
                  value={data.dateInfo?.month ?? 1}
                  onChange={(e) =>
                    onDateDataChange({
                      ...data,
                      dateInfo: {
                        ...data.dateInfo,
                        month: Number(e.target.value),
                      },
                    })
                  }
                  disabled={disabled}
                >
                  <option value={1}>{t('months.january')}</option>
                  <option value={2}>{t('months.february')}</option>
                  <option value={3}>{t('months.march')}</option>
                  <option value={4}>{t('months.april')}</option>
                  <option value={5}>{t('months.may')}</option>
                  <option value={6}>{t('months.june')}</option>
                  <option value={7}>{t('months.july')}</option>
                  <option value={8}>{t('months.august')}</option>
                  <option value={9}>{t('months.september')}</option>
                  <option value={10}>{t('months.october')}</option>
                  <option value={11}>{t('months.november')}</option>
                  <option value={12}>{t('months.december')}</option>
                </select>
                <select
                  className="w-[100px] h-[40px] px-[12px] border border-solid border-[#D8D8D8] rounded-[8px]"
                  value={data.dateInfo?.sequence ?? 1}
                  onChange={(e) => {
                    onDateDataChange({
                      ...data,
                      dateInfo: {
                        ...data.dateInfo,
                        sequence: Number(e.target.value),
                      },
                    });
                  }}
                  disabled={disabled}
                >
                  <option value={1}>{t('weeks.first')}</option>
                  <option value={2}>{t('weeks.second')}</option>
                  <option value={3}>{t('weeks.third')}</option>
                  <option value={4}>{t('weeks.fourth')}</option>
                  <option value={5}>{t('weeks.fifth')}</option>
                </select>
                <select
                  className="w-[100px] h-[40px] px-[12px] border border-solid border-[#D8D8D8] rounded-[8px]"
                  value={data.dateInfo?.dayOfWeek ?? 'MONDAY'}
                  onChange={(e) => {
                    onDateDataChange({
                      ...data,
                      dateInfo: {
                        ...data.dateInfo,
                        dayOfWeek: e.target.value as DayOfWeek,
                      },
                    });
                  }}
                  disabled={disabled}
                >
                  <option value={'MONDAY'}>{t('days.full.monday')}</option>
                  <option value={'TUESDAY'}>{t('days.full.tuesday')}</option>
                  <option value={'WEDNESDAY'}>
                    {t('days.full.wednesday')}
                  </option>
                  <option value={'THURSDAY'}>{t('days.full.thursday')}</option>
                  <option value={'FRIDAY'}>{t('days.full.friday')}</option>
                  <option value={'SATURDAY'}>{t('days.full.saturday')}</option>
                  <option value={'SUNDAY'}>{t('days.full.sunday')}</option>
                </select>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center mt-[8px] gap-[8px]">
        <Title
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.dateRepeat.title',
          )}
        />
        <select
          className="w-[130px] px-[12px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px]"
          value={data.repeatType}
          onChange={(e) =>
            onDateDataChange({
              ...data,
              repeatType: e.target.value as CalendarRepeatType,
            })
          }
          disabled={disabled}
        >
          <option value={'NONE'}>
            {t(
              'globalconfig.basicManagement.companySchedule.schedule.dateRepeat.none',
            )}
          </option>
          {data.dateType === 'DATE_SPECIFICATION_SINGLE' && (
            <option value={'MONTH'}>
              {t(
                'globalconfig.basicManagement.companySchedule.schedule.dateRepeat.monthly',
              )}
            </option>
          )}
          <option value={'YEAR'}>
            {t(
              'globalconfig.basicManagement.companySchedule.schedule.dateRepeat.yearly',
            )}
          </option>
        </select>
      </div>
    </>
  );
}
