import { PropsWithChildren } from 'react';
import styles from './styles.module.css';
import clsx from 'clsx';

interface Props {
  title?: string;
  description?: string;
  className?: string;
}
export function ContentBox({
  title,
  description,
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className={clsx(styles.Box, className)}>
      {title && (
        <div className={styles.Header}>
          {<p className={styles.Title}>{title}</p>}
          {description && <p className={styles.Description}>{description}</p>}
        </div>
      )}
      {children}
    </div>
  );
}
