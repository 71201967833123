import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { useContext } from './primitives-context';
import { UserProfileInfo } from '../../types';

export interface Props {
  profileInfo: UserProfileInfo;
}

export function Content({ children }: { children: ReactNode }) {
  const { cardOpenState } = useContext();
  return (
    <div className={clsx('profile', { open: cardOpenState })}>{children}</div>
  );
}
