import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

export interface Props {
  title: string;
  className?: string;
}

export function Field({
  title,
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className="flex items-start gap-3">
      <span
        className={clsx(
          'w-[106px] text-[14px] text-[#363636] font-medium leading-[21px] tracking-[-0.28px]',
          className,
        )}
      >
        {title}
      </span>
      {children}
    </div>
  );
}
