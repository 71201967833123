import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Share } from '../../types';
import { useValuesContext } from '../provider';

interface Props {
  shareTargetList: Share[];
}

export function UsePermission({ shareTargetList }: Props) {
  const { t } = useTranslation('component');
  const { selectedAppCode } = useValuesContext();

  const replaceContents = () => {
    if (selectedAppCode === 'dop-board') {
      return {
        appName: t('globalconfig.multiCompanyManagement.appShare.board.title'),
      };
    } else if (selectedAppCode === 'dop-asset') {
      return {
        appName: t('globalconfig.multiCompanyManagement.appShare.asset.title'),
      };
    } else if (selectedAppCode === 'dop-calendar') {
      return {
        appName: t(
          'globalconfig.multiCompanyManagement.appShare.calendar.title',
        ),
      };
    } else {
      return {
        appName: t(
          'globalconfig.multiCompanyManagement.appShare.approval.title',
        ),
      };
    }
  };

  return (
    <div className="flex flex-col justify-center py-2">
      {shareTargetList.map((item) => {
        const shareTarget = item.nodes;
        return shareTarget.map((orgInfo) => {
          return (
            <p key={orgInfo.id}>
              {orgInfo.actions === 'read'
                ? t(
                    'globalconfig.multiCompanyManagement.appShare.calendar.permission.read',
                    replaceContents(),
                  )
                : t(
                    'globalconfig.multiCompanyManagement.appShare.calendar.permission.readWrite',
                    replaceContents(),
                  )}
            </p>
          );
        });
      })}
    </div>
  );
}

export default UsePermission;
