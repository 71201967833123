import { BASE_INFO_ORG_CODE_URL } from '../../constants';
import { BaseResponseWithPage, OrganizationCodeInfo } from '../../types';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.BasicInfo.OrgCode';

export async function getOrgCodeList(
  companyId: number,
  type: string,
): Promise<OrganizationCodeInfo[]> {
  try {
    const response = await fetch(
      BASE_INFO_ORG_CODE_URL + '/' + companyId + '/' + type,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getOrgCodeList 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponseWithPage<OrganizationCodeInfo>;
    return resData.data.elements;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
