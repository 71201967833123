import * as React from 'react';

export interface IContextValues {
  selectedAppId: number;
  selectedAppCode: string;
  isEditMode: boolean;
}

export interface IContextActions {
  setSelectedAppId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedAppCode: React.Dispatch<React.SetStateAction<string>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  selectedAppId: -1,
  selectedAppCode: '',
  isEditMode: false,
});

export const ActionsContext = React.createContext<IContextActions>({
  setSelectedAppId: () => undefined,
  setSelectedAppCode: () => undefined,
  setEditMode: () => undefined,
});

export function Provider({ children }: React.PropsWithChildren) {
  const [selectedAppId, setSelectedAppId] = React.useState(-1);
  const [isEditMode, setEditMode] = React.useState<boolean>(false);
  const [selectedAppCode, setSelectedAppCode] = React.useState<string>('');

  React.useEffect(() => {
    setEditMode(false);
  }, [selectedAppId]);

  const contextValues = {
    selectedAppId,
    selectedAppCode,
    isEditMode,
  };

  const contextActions: IContextActions = {
    setSelectedAppId,
    setSelectedAppCode,
    setEditMode,
  };

  return (
    <ActionsContext.Provider value={contextActions}>
      <ValuesContext.Provider value={contextValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
