import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { Button } from '../../Button';
import { IconButton } from '../../Button/IconButton';
import { SelectType } from '../Select';
import { useContext } from './Context';

export interface Props {
  type: SelectType;
  withTitle: boolean;
  children?: ReactNode;
}

export function Trigger({ type, withTitle, children }: Props) {
  const Comp = withTitle ? Button : IconButton;
  const { selectedOption, isOpened, setOpenState } = useContext();

  const handleOnClick = () => {
    setOpenState(!isOpened);
  };

  return (
    <Comp
      variant="ghost"
      title={selectedOption?.title ?? ''}
      className={clsx({
        btn_select: type === 'system',
      })}
      onClick={handleOnClick}
    >
      {children}
    </Comp>
  );
}

export default Trigger;
