import { AppDataInfo, MemberDataInfo, Option, Page } from '../types';
import { searchParamGenerator } from '../utils/searchParamGenerator';
import { usageStringFormatter } from './../utils/usageStringFormatter';

// TODO: 추후 API 개발 후 수정 필요
export const QUERY_KEY = 'memberDataListQuery';

interface IMemberDataInfo {
  userId: number;
  userName: string;
  userEmail: string;
  userStatus: 'NORMAL';
  position: string;
  mail: AppDataInfo;
  webFolder: AppDataInfo;
}

interface IResponseBody {
  code?: number; // Error Code
  data?: string; // Error Data
  message?: string; // Error Message
  name?: string; // Error Name
  elements?: IMemberDataInfo[];
  page?: Page;
}

export interface ResponseBody {
  code?: number; // Error Code
  data?: string; // Error Data
  message?: string; // Error Message
  name?: string; // Error Name
  elements?: MemberDataInfo[];
  page?: Page;
}

export async function memberDataListQuery(options?: Option) {
  try {
    console.log(options);
    const res = (
      await fetch(
        `/api/portal/setting/storage/user/list?${searchParamGenerator(options)}`,
      )
    ).json();
    const data = (await res) as IResponseBody;
    return adapter(data);
  } catch (e) {
    console.error(e);
  }
}

function adapter(res: IResponseBody): ResponseBody {
  return {
    elements: res.elements?.map((element) => {
      return {
        ...element,
        mail: usageStringFormatter(
          element.mail.usedQuota,
          element.mail.allocatedQuota,
        ),
        webFolder: usageStringFormatter(
          element.webFolder.usedQuota,
          element.webFolder.allocatedQuota,
        ),
        position: element.position === '' ? '-' : element.position,
      };
    }),
    page: res.page,
    message: res.message,
    name: res.name,
    code: res.code,
    data: res.data,
  };
}
