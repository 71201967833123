import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

export function Header() {
  const { t } = useTranslation('component');

  const titleBlock = (
    <div className="flex items-center">
      <h2 className="ms-2 font-medium text-[22px] -tracking-[0.88px] mr-3">
        {t('globalconfig.dataManagement.memberManagement.title.data')}
      </h2>
    </div>
  );

  return (
    <>
      <div className="flex items-center">{titleBlock}</div>
    </>
  );
}

export default Header;
