export interface Props {
  themeColor: string;
}

export function ThemeGraphic({ themeColor }: Props) {
  return (
    <svg
      width="179"
      height="104"
      viewBox="0 0 179 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2335_92853)">
        <rect
          width="175.968"
          height="102"
          rx="6"
          transform="matrix(-1 0 0 1 176.558 0)"
          fill="white"
        />
      </g>
      <rect
        x="-0.5"
        y="0.5"
        width="123.842"
        height="78"
        rx="3.5"
        transform="matrix(-1 0 0 1 150.59 18)"
        fill="#F8F8F8"
        stroke="#E8E8E8"
      />
      <rect
        x="58.3079"
        y="14"
        width="32.0643"
        height="10"
        rx="3"
        transform="rotate(180 58.3079 14)"
        fill="#EFEFEF"
      />
      <rect
        x="151.829"
        y="14"
        width="88.1767"
        height="10"
        rx="3"
        transform="rotate(180 151.829 14)"
        fill="#EFEFEF"
      />
      <rect
        width="14.2676"
        height="93"
        rx="3"
        transform="matrix(-1 0 0 1 20.803 4)"
        fill={themeColor}
      />
      <rect
        width="14.6961"
        height="10"
        rx="3"
        transform="matrix(-1 0 0 1 171.869 4)"
        fill={themeColor}
      />
      <rect
        width="14.2676"
        height="79"
        rx="3"
        transform="matrix(-1 0 0 1 171.802 18)"
        fill="#EFEFEF"
      />
      <defs>
        <filter
          id="filter0_d_2335_92853"
          x="0.590576"
          y="0"
          width="177.968"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2335_92853"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2335_92853"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default ThemeGraphic;
