import { TRANSLATION_PREFIX } from './constants';
import MultiMemberContents from './contents';
import { Provider as MultiMemberContextProvider } from './context';
import styles from './multi-member.module.css';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

export function MultiMember() {
  const { t } = useTranslation('component');
  return (
    <div className={styles.Container}>
      <div className={styles.Card}>
        <p className={styles.CardTitle}>{t(`${TRANSLATION_PREFIX}.list`)}</p>
        <MultiMemberContextProvider>
          <MultiMemberContents />
        </MultiMemberContextProvider>
      </div>
    </div>
  );
}
