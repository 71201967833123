import { Contents } from './components/contents';
import { LogList } from './components/detail-config';

export function AdminLogConfig() {
  return (
    <Contents>
      <LogList />
    </Contents>
  );
}

export default AdminLogConfig;
