import { clsx } from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { MenuInfo, SimpleMenuInfo } from '../../../../types';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { openSubMenusAtom } from '../../../../store/openSubMenusAtom';
import { currentSelectedMenuInfoAtom } from '../../../../store/currentSelectedMenuInfoAtom';
import { addRecentUsedMenuInfoAtom } from '../../../../store/recentUsedAppsAtom';
import { isContentNeedsSaveAtom } from '../../../../store/isContentNeedsSaveAtom';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { SaveConfirmDialog } from '../../../components/save-confirm-dialog';
import { appCodesAtom } from '../../../../store/appCodesAtom';

interface Props {
  menuInfo: MenuInfo;
}

export function Item({ menuInfo }: Props) {
  const appCodes = useAtomValue(appCodesAtom);
  const [openNodes, setOpenNodes] = useAtom(openSubMenusAtom);
  const [currentMenuInfo, setCurrentMenuInfo] = useAtom(
    currentSelectedMenuInfoAtom,
  );
  const setRecentUsedMenuInfo = useSetAtom(addRecentUsedMenuInfoAtom);
  const isLeaf =
    menuInfo.children === undefined || menuInfo.children.length === 0;

  const { confirm } = useMessageDialog();
  const [isContentsNeedsSave, setIsConfigNeedsSave] = useAtom(
    isContentNeedsSaveAtom,
  );

  const checkSavingNeeds = async (menuInfo: SimpleMenuInfo) => {
    if (isContentsNeedsSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        setCurrentMenuInfo(menuInfo);
        setRecentUsedMenuInfo(menuInfo);
        setIsConfigNeedsSave(false);
      }
    } else {
      setCurrentMenuInfo(menuInfo);
      setRecentUsedMenuInfo(menuInfo);
    }
  };

  const onClickHandler = () => {
    if (isLeaf) {
      const simpleInfo = {
        menuCode: menuInfo.menuCode,
        menuPath: menuInfo.menuPath,
      };
      if (currentMenuInfo.menuPath !== menuInfo.menuPath) {
        void checkSavingNeeds(simpleInfo);
      } else {
        setCurrentMenuInfo(simpleInfo);
        setRecentUsedMenuInfo(simpleInfo);
      }
    }
    const newNodes = new Set(openNodes);

    if (openNodes.has(menuInfo.menuPath)) {
      newNodes.delete(menuInfo.menuPath);
    } else {
      newNodes.add(menuInfo.menuPath);
    }

    setOpenNodes(newNodes);
  };

  const dependenciesCheck = () => {
    const wholeCodes = new Set(appCodes?.accessibles);

    if (menuInfo.dependencies) {
      for (const code of menuInfo.dependencies) {
        if (!wholeCodes.has(code)) {
          return false;
        }
      }
    }

    return true;
  };

  if (!dependenciesCheck()) {
    return null;
  }

  return (
    <>
      <button
        className={clsx(
          'flex flex-shrink-0 items-center h-[34px] px-[8px] cursor-default rounded-[8px] hover:bg-[#EEF1F7] text-[14px] w-full',
          {
            'font-[400] text-[#5B5B5B] bg-transparent':
              isLeaf && currentMenuInfo.menuPath !== menuInfo.menuPath,
          },
          {
            'font-[400] text-[#5B5B5B] bg-[#EEF1F7]':
              isLeaf && currentMenuInfo.menuPath === menuInfo.menuPath,
          },
          {
            'font-[500] text-[#363636] mt-[8px] bg-transparent': !isLeaf,
          },
        )}
        onClick={onClickHandler}
      >
        {menuInfo.name}
        <div className="flex-grow" />

        {!isLeaf && (
          <ChevronDownIcon
            className={clsx('size-[16px]', {
              'rotate-180 transition-transform': openNodes.has(
                menuInfo.menuPath,
              ),
              'rotate-0 transition-transform': !openNodes.has(
                menuInfo.menuPath,
              ),
            })}
          />
        )}
      </button>
      {!isLeaf &&
        openNodes.has(menuInfo.menuPath) &&
        menuInfo.children &&
        menuInfo.children.map((info) => (
          <Item key={info.menuPath} menuInfo={info} />
        ))}
    </>
  );
}
