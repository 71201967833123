import { ColorPicker } from '@dop-ui/react/widgets/color-picker';
import { PencilIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

export interface Props {
  title: string;
  color: string;
  onChange: (color: string) => void;
}

export function ColorPickerInput({ title, color, onChange }: Props) {
  const [colorPickerOpenState, setColorPickerOpenState] =
    useState<boolean>(false);
  const colorPickerDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        colorPickerDivRef?.current &&
        !colorPickerDivRef?.current.contains(event.target as Node)
      ) {
        setColorPickerOpenState(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center gap-4 mt-6">
      <span className="w-[200px] font-medium leading-[21px] -tracking-[0.28px] text-[#363636]">
        {title}
      </span>
      <div ref={colorPickerDivRef} className="relative">
        <button
          onClick={() => setColorPickerOpenState((prev) => !prev)}
          className="rounded-full size-[26px] border-solid border border-[#D8D8D8]"
          style={{ backgroundColor: color }}
        />
        <ColorPicker
          className={clsx('absolute z-10 mt-2', {
            hidden: !colorPickerOpenState,
          })}
          color={color}
          onChange={onChange}
        />
      </div>
      <div className="relative">
        <input
          type="text"
          value={color}
          onChange={(e) => onChange(e.target.value)}
          pattern="^#[A-Fa-f0-9]{6}$"
          className="px-3 h-10 border-solid border rounded-lg border-[#D8D8D8] w-[270px]"
        />
        <PencilIcon
          className="absolute size-5 end-3 bottom-0 top-1/2 transform -translate-y-1/2"
          onClick={() => setColorPickerOpenState((prev) => !prev)}
        />
      </div>
    </div>
  );
}

export default ColorPickerInput;
