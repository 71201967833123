import { atom } from 'jotai';

const activateFilters = atom<Map<string, string>>(new Map());

export const activatedFiltersAtom = atom(
  (get) => get(activateFilters),
  (get, set, newValues: { key: string; value: string }[]) => {
    const newFilters = new Map(get(activateFilters));
    newValues.forEach(({ key, value }) => {
      newFilters.set(key, value);
    });
    set(activateFilters, newFilters);
  },
);

export const deleteActivatedFiltersAtom = atom(
  null,
  (get, set, keys: string[]) => {
    const newFilters = new Map(get(activateFilters));
    keys.forEach((key) => {
      newFilters.delete(key);
    });
    set(activateFilters, newFilters);
  },
);
