import { useState } from 'react';
import { ScheduleInfo } from '../../types';
import { Button } from '@dop-ui/react/shared/ui/button';
import { Primitives } from '@dop-ui/react/shared/ui/dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCompanySchedule, QUERY_KEY } from '../../apis/schedule-manage';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useSetAtom } from 'jotai';
import { deleteScheduleDialogOpenAtom } from '../../store/dialog_open_atom';

type DeletionType =
  | 'DELETE_THIS_YEAR_ONLY'
  | 'DELETE_FROM_NEXT_YEAR'
  | 'DELETE_ALL';

interface Props {
  scheduleInfoList: ScheduleInfo[];
}

export function Content({ scheduleInfoList }: Props) {
  const { t } = useTranslation('component');
  const setDialogOpen = useSetAtom(deleteScheduleDialogOpenAtom);
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [deletionType, setDeletionType] = useState<DeletionType>(
    'DELETE_THIS_YEAR_ONLY',
  );

  const isRepeatScheduleExist = scheduleInfoList.some(
    (info) => info.calendarRepeatType !== 'NONE',
  );

  const deleteScheduleListMutation = useMutation({
    mutationFn: (info: { ids: number[]; calendarDeletionType?: string }) =>
      deleteCompanySchedule(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toaster.info(t('globalconfig.common.toastMessage.deleted'));
      setDialogOpen(false);
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const onClickDelete = () => {
    const ids = scheduleInfoList.map((info) => info.id ?? -1);
    deleteScheduleListMutation.mutate({
      ids,
      calendarDeletionType: isRepeatScheduleExist ? deletionType : undefined,
    });
  };

  return (
    <>
      <p className="text-[#363636] //text-[--color-text-level1] text-[20px] font-[600] leading-[30px]">
        {t('globalconfig.basicManagement.companySchedule.schedule.delete')}
      </p>
      <div className="mt-[16px]">
        <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {isRepeatScheduleExist
            ? t(
                'globalconfig.basicManagement.companySchedule.schedule.delete.confirm2',
              )
            : t(
                'globalconfig.basicManagement.companySchedule.schedule.delete.confirm',
              )}
        </p>
        <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {t(
            'globalconfig.basicManagement.companySchedule.schedule.delete.warning',
          )}
        </p>
        {isRepeatScheduleExist && (
          <div className="mt-[16px] flex flex-col gap-[8px]">
            <div>
              <input
                id={'deletion_type_delete_this_year_only'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="deletionType"
                value="DELETE_THIS_YEAR_ONLY"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.onlyThisYear',
                )}
                checked={deletionType === 'DELETE_THIS_YEAR_ONLY'}
                onChange={() => setDeletionType('DELETE_THIS_YEAR_ONLY')}
              />
              <label
                className="ml-[4px]"
                htmlFor="deletion_type_delete_this_year_only"
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.onlyThisYear',
                )}
              </label>
            </div>
            <div>
              <input
                id={'deletion_type_delete_from_next_year'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="deletionType"
                value="DELETE_FROM_NEXT_YEAR"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.afterThisYear',
                )}
                checked={deletionType === 'DELETE_FROM_NEXT_YEAR'}
                onChange={() => setDeletionType('DELETE_FROM_NEXT_YEAR')}
              />
              <label
                className="ml-[4px]"
                htmlFor="deletion_type_delete_from_next_year"
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.afterThisYear',
                )}
              </label>
            </div>
            <div>
              <input
                id={'deletion_type_delete_all'}
                className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                name="deletionType"
                value="DELETE_ALL"
                title={t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.all',
                )}
                checked={deletionType === 'DELETE_ALL'}
                onChange={() => setDeletionType('DELETE_ALL')}
              />
              <label className="ml-[4px]" htmlFor="deletion_type_delete_all">
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.delete.type.all',
                )}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end mt-[24px] gap-[12px]">
        <Primitives.Close asChild>
          <Button size="medium" shape="rect" colorset="level2" variant="ghost">
            <p className="font-[500]">{t('dialog.cancel')}</p>
          </Button>
        </Primitives.Close>
        <Button
          size="medium"
          shape="rect"
          colorset="level1"
          variant="solid"
          onClick={onClickDelete}
        >
          <p className="font-[500]">{t('dialog.delete')}</p>
        </Button>
      </div>
    </>
  );
}
