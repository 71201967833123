import { clsx } from 'clsx';
import * as Popover from '@radix-ui/react-popover';
import { useFilterValuesContext } from './context';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export function Trigger({
  className,
  children,
  ...props
}: Popover.PopoverTriggerProps) {
  const { name, selectedOptions, selectedOptionLabels } =
    useFilterValuesContext();
  const options = Array.from(selectedOptions);

  return (
    <Popover.Anchor>
      <Popover.Trigger {...props}>
        {children ? (
          children
        ) : (
          <div
            className={clsx(
              'flex items-center h-[40px] px-[12px] border border-solid border-[#D8D8D8] //border-[--color-border-field] rounded-[8px]',
              'text-[#999999] //text-[--color-text-level3] text-[14px] font-[400]',
              className,
            )}
          >
            {name}
            {options.length > 0 && (
              <>
                {' : '}
                <span className="ml-[4px] text-[#0798AE] //text-[--primary-color-text-level1]">
                  {options
                    .map((value) => selectedOptionLabels.get(value))
                    .join(', ')}
                </span>
              </>
            )}
            <ChevronDownIcon className="ml-[4px] size-[16px]" />
          </div>
        )}
      </Popover.Trigger>
    </Popover.Anchor>
  );
}
