import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useBasicInfoContext } from '../../Context';

export function SiteList() {
  const { t } = useTranslation('component');

  const { basicInfoContext } = useBasicInfoContext();

  return (
    <div className="flex min-h-[40px] py-[12px]">
      <p className="flex-shrink-0 w-[180px] font-medium text-[16px] text-[#999]">
        {t('globalconfig.multiCompanyManagement.basicInfo.siteName.title')}
      </p>
      <div className="flex flex-wrap min-w-[300px] gap-1">
        {basicInfoContext.companyList.map((company) => (
          <div
            key={company.companyId}
            className="flex items-center h-[32px] px-[12px] border border-[#D8D8D8] rounded-[26px] bg-[#FFF]"
          >
            {company.companyName}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SiteList;
