import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { COMPANY_LIST_API } from '../../constants';
import { CompanyInfo } from './../types';

interface CompanyListResponse {
  data: {
    companyList: CompanyInfo[];
  };
}

export const QUERY_KEY = 'companyGroupQuery';

export async function getCompanyList(companyGroupId?: string | number) {
  if (!companyGroupId) return [];

  try {
    const { data } = (await (
      await fetch(`${COMPANY_LIST_API}/${companyGroupId}`)
    ).json()) as CompanyListResponse;
    return data.companyList;
  } catch (error) {
    console.error(error);
    return [];
  }
}
