import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import * as getProfileCardConfig from '../../apis/get-profile-card-config';
import { updateDisplayItem } from '../../apis/update-display-item';
import { ProfileCardItemDisplayDto } from '../../type';

interface Props {
  profileCardItemDisplayDtos: ProfileCardItemDisplayDto[];
}

export function DisplayItems({ profileCardItemDisplayDtos }: Props) {
  const { t } = useTranslation('component');
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const displayItems = profileCardItemDisplayDtos.sort((a, b) => {
    if (a.custom === b.custom) {
      return a.id - b.id;
    }
    return Number(a.custom) - Number(b.custom);
  });

  const updateDisplayItemMutation = useMutation({
    mutationFn: async (displayItem: ProfileCardItemDisplayDto) =>
      await updateDisplayItem(displayItem),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  return (
    <div className="flex flex-col flex-wrap gap-[24px] p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex flex-col justify-center gap-[8px]">
        <p className="text-[#333333] text-[22px] font-[500]">
          {t('globalconfig.basicManagement.profileManagement.displayedItems')}
        </p>
        <p className="text-[#AAA] font-[500]">
          {t(
            'globalconfig.basicManagement.profileManagement.displayedItems.description',
          )}
        </p>
      </div>
      <div className="grid grid-flow-col grid-rows-7 gap-4">
        {displayItems.map((item) => (
          <CheckBox
            key={`${item.id}-${item.name}-${item.custom}`}
            id={`${item.id}-${item.name}-${item.custom}`}
            label={item.name}
            defaultChecked={item.display}
            className={clsx({
              disable: !item.change,
            })}
            defaultDisabled={!item.change}
            onChange={() => {
              updateDisplayItemMutation.mutate({
                ...item,
                display: !item.display,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default DisplayItems;
