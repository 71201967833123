import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { EditAppInfo } from '../types';

interface AppListItem {
  companyAppId: number;
  appCode: string;
  appName: string;
  description: string;
  portalType: string;
  appManagerCount: number;
}

interface AppListAPIResponse {
  data: AppListAPIResponseData;
}

export interface AppListAPIResponseData {
  organizationRoleAppList?: AppListItem[];
  settingAppList?: AppListItem[];
  employeeAppList?: AppListItem[];
  businessAppList?: AppListItem[];
  isMaster?: boolean;
  masterCount?: number;
}

export interface ResponseData {
  organizationRoleAppList?: EditAppInfo[]; // 조직권한 - 1
  settingAppList?: EditAppInfo[]; // 통합설정 - 2
  employeeAppList?: EditAppInfo[]; // 임직원 - 3
  businessAppList?: EditAppInfo[]; // 경영업무 - 4
  isMaster?: boolean; // 최고관리자 여부
  masterCount?: number; // 최고관리자 수
}

export const QUERY_KEY = 'edit-app-info-list';

export async function getEditAppInfoList(): Promise<ResponseData> {
  const response = await fetch('/api/portal/setting/security/app-manager/app', {
    method: 'GET',
    headers: {
      'X-Referer-Info': window.location.hostname,
    },
  });

  if (response.ok) {
    const resData = (await response.json()) as AppListAPIResponse;

    if (resData.data) {
      const responseData = {
        organizationRoleAppList: resData.data.organizationRoleAppList
          ? resData.data.organizationRoleAppList.map(adapter)
          : [],
        settingAppList: resData.data.settingAppList
          ? resData.data.settingAppList.map(adapter)
          : [],
        employeeAppList: resData.data.employeeAppList
          ? resData.data.employeeAppList.map(adapter)
          : [],
        businessAppList: resData.data.businessAppList
          ? resData.data.businessAppList.map(adapter)
          : [],
        isMaster: resData.data.isMaster,
        masterCount: resData.data.masterCount,
      };

      return responseData;
    }

    return {};
  }

  return {};
}

function adapter(source: AppListItem): EditAppInfo {
  return {
    uid: `${source.companyAppId}`,
    name: source.appName,
    tooltip: source.description,
    count: source.appManagerCount,
  };
}
