import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table/index';
import { Direction } from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { TrashIcon } from '@heroicons/react/24/outline';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { useAuthContext } from '../../../../../../../../lib/auth/client';
import { Share, ShareInfo } from '../../../types';
import { ShareObject } from '../../common-table/share-object';
import Permission from '../../common-table/use-permission';
import { useActionsContext, useValuesContext } from '../../provider';
import * as boardShareListDeleteMutation from './apis/board-share-list-delete-mutation';
import * as boardShareListQuery from './apis/board-share-list-query';
import { ShareConfigDialogButton } from './share-config-dialog-button';

export function BoardContents() {
  const { t } = useTranslation('component');
  const { me } = useAuthContext();
  const [shareConfigDialogOpenState, setShareConfigDialogOpenState] =
    useState<boolean>(false);
  const toaster = useToastMessage();
  const { companyList } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();
  const [selectedBoardId, setSelectedBoardId] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const { data: boardShareList, error: boardListError } = useSuspenseQuery({
    queryKey: [boardShareListQuery.QUERY_KEY],
    queryFn: async () => {
      if (me?.companyGroupId)
        return await boardShareListQuery.getBoardSharedList(me?.companyGroupId);
      else return [];
    },
  });
  const deleteBoardShareListMutation = useMutation({
    mutationFn: async () => {
      if (me?.companyGroupId === undefined) return [];
      await boardShareListDeleteMutation.deleteBoardShareList(
        me?.companyGroupId,
        selectedSharedList ?? [],
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [boardShareListQuery.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.deleted'));
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [pageOffet, setPageOffset] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedSharedList, setSelectedSharedList] = useState<ShareInfo[]>();
  const [direction, setDirection] = useState<Direction>();
  const [orderType, setOrderType] = useState<string>('siteName');
  // Todo: 그룹웨어 API에 pagination 적용 시 삭제
  const totalPage = Math.ceil(boardShareList.length / pageOffet);

  const onEditHandler = (index: number) => {
    if (boardShareList?.[index]) {
      setShareConfigDialogOpenState(true);
      setSelectedCompany({
        companyId:
          companyList.find(
            (companyInfo) =>
              companyInfo.companyUuid === boardShareList[index]?.companyUuid,
          )?.companyId ?? -1,
        companyName: boardShareList[index]?.companyName,
        companyUuid: boardShareList[index]?.companyUuid,
      });
      setSelectedBoardId(boardShareList[index]?.id);
    }
  };

  const onDeleteHandler = () =>
    selectedSharedList &&
    selectedSharedList?.length > 0 &&
    deleteBoardShareListMutation.mutate();

  // TODO : 현재 그룹웨어 API에 pagination과 sort direction이 존재하지 않아 추후에 관련 작업이 필요합니다.
  const onSortChangeHandler = (direction: Direction, value: string) => {
    setDirection(direction);
    setOrderType(value);
  };

  const columnDefs: ColumnDef<ShareInfo>[] = [
    TablePrimitives.columnDefGenerator<ShareInfo>(
      'companyName',
      t('globalconfig.multiCompanyManagement.appShare.board.table.siteName'),
      'companyName',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    TablePrimitives.columnDefGenerator<ShareInfo>(
      'name',
      t('globalconfig.multiCompanyManagement.appShare.board.table.boardName'),
    ),
    {
      accessorKey: 'shares',
      id: 'shares',
      header: () => (
        <div className="flex items-center px-[--Space-Small] w-full justify-center">
          {t(
            'globalconfig.multiCompanyManagement.appShare.board.table.sharedObject',
          )}
        </div>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as Share[];
        return <ShareObject shareTargetList={shareTargetList} />;
      },
    },
    {
      id: 'permission',
      accessorKey: 'shares',
      header: () => (
        <div className="flex items-center px-[--Space-Small] w-full justify-center">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.userPermission',
          )}
        </div>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as Share[];
        return <Permission shareTargetList={shareTargetList} />;
      },
    },
  ];

  if (boardListError) {
    <></>;
  }

  return (
    <TablePrimitives.Root<ShareInfo>
      contents={boardShareList}
      columnDefs={columnDefs}
      onSelectContents={setSelectedSharedList}
      onClickRow={(index) => onEditHandler(index)}
      selectable
    >
      <div className="flex flex-wrap justify-between">
        <div className="flex items-center">
          <div className="h-3 border-l-1 border-[#D8D8D8] px-1" />
        </div>
        <div className="flex justify-end items-center gap-2">
          <ShareConfigDialogButton
            boardShareList={boardShareList}
            shareConfigDialogOpenState={shareConfigDialogOpenState}
            detailAppId={selectedBoardId}
            onChangeDetailAppId={setSelectedBoardId}
            onChangeShareConfigDialogOpenState={setShareConfigDialogOpenState}
          />
          <Button
            className="flex items-center gap-1 p-1"
            // TODO: 데이터 매핑 후, 삭제 API 연동 필요.
            onClick={onDeleteHandler}
          >
            <TrashIcon className="size-6 stroke-[#363636]" />
            <span className="text-[14px] font-normal leading-[150%] -tracking-[0.28px]">
              {t(
                'globalconfig.multiCompanyManagement.appShare.board.deleteButton.title',
              )}
            </span>
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[48px]"
            tableSize={pageOffet}
            options={[10, 20, 30]}
            onChangeTableSize={setPageOffset}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        className="text-center mt-4"
        emptyNotice={
          <div className="w-full py-[60px] flex items-center justify-center ">
            <span className="w-full text-center font-normal leading-norml=al text-[#888] text-ellipsis">
              {t('globalconfig.multiCompanyManagement.appShare.table.noData')}
            </span>
          </div>
        }
      />
      <div className="flex items-center justify-center gap-2">
        {
          <TablePrimitives.Pagination
            className="mt-[16px]"
            currentPage={currentPage}
            totalPage={totalPage ?? 0}
            onNextPage={setCurrentPage}
            onPreviousPage={setCurrentPage}
            onClickPage={setCurrentPage}
            onFirstPage={() => setCurrentPage(0)}
            onLastPage={() => setCurrentPage(99)}
          />
        }
      </div>
    </TablePrimitives.Root>
  );
}

export default BoardContents;
