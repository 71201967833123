import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { clsx } from 'clsx';
import { useValuesContext } from '../../../Context';
import { CheckInfo } from './types';

export interface Props {
  info: CheckInfo;
  onCheckClick?: (id: number) => void;
}

export function CheckInfoItem({ info, onCheckClick }: Props) {
  const { isMaster } = useValuesContext();

  return (
    <li key={info.id} className="check_list">
      <CheckBox
        className={clsx({ disable: isMaster })}
        defaultDisabled={isMaster}
        id={`${info.id}`}
        label={info.name}
        defaultChecked={info.isChecked}
        onChange={() => onCheckClick && onCheckClick(info.id)}
      />
    </li>
  );
}
