import { fetch } from '../../../shared/lib/fetch/client';
import { PopupAnnouncementListResponse } from '../ui/types';

export const QUERY_KEY = 'QueryKeys.PopupAnnouncement.User';

export async function getUsersPopupAnnouncement(): Promise<PopupAnnouncementListResponse> {
  const query = new URLSearchParams();

  const response = await fetch(
    `/api/portal/normal/basic/popup?${query.toString()}`,
  );
  const responseJson = (await response.json()) as {
    data: PopupAnnouncementListResponse;
    message?: string;
  };

  if (responseJson.message) {
    throw new Error(responseJson.message);
  }

  return responseJson.data;
}
