import { ImageInfo } from '../types';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.LogoThemeManagement.ThmumbnailQuery';

export async function getThumbnailByStoragePath(
  storagePath: string,
  title: string,
  category: string = 'attach',
  size: string = 'original',
  appCode: string = 'common',
) {
  const res = await fetch(
    `/thumb/${category}/${size}/${appCode}/${storagePath}`,
    {
      method: 'GET',
      headers: {
        'X-Referer-Info': window.location.host,
      },
    },
  );

  if (!res.ok) {
    throw new Error('Failed to get thumbnail');
  }

  const blobFile = await res.blob();
  return {
    name: title,
    path: storagePath,
    previewPath: URL.createObjectURL(blobFile),
    originFile: new File([blobFile], ''),
  } as ImageInfo;
}
