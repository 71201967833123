import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { EMP_PORTAL_GNB_USER_MODIFY_ALLOW_API } from '../../../../constants';
import { BaseResponse } from '../../../../types';
import { GNBMoficationConfig } from '../../../types';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.EmpAppManagement.GNB.UserModification';

export async function getUserModificationAllowInfo() {
  const response = await fetch(EMP_PORTAL_GNB_USER_MODIFY_ALLOW_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<GNBMoficationConfig>;

  return responseData.data;
}

export async function updateUserModificationAllowInfo(
  config: GNBMoficationConfig,
) {
  const response = await fetch(EMP_PORTAL_GNB_USER_MODIFY_ALLOW_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      configType: 'COMPANY_APP',
      configName: config.configName,
      configValue: `${config.configValue}`,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to update user modification allow info');
  }
}
