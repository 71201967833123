import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  useMutation,
  useSuspenseQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { isContentNeedsSaveAtom } from '../../../../../../store/isContentNeedsSaveAtom';
import { LinkInfo } from '../../../types';
import { TitleAndTextInput } from '../../title-and-textinput';
import {
  createLinkInfo,
  DETAIL_QUERY_KEY,
  getDetailLinkInfo,
  QUERY_KEY,
  updateLinkInfo,
} from '../apis/links-setting';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { QUERY_KEY as GnbMenu_QUERY_KEY } from '../../gnb-sort-tab/apis/gnb-sort';
import { QUERY_KEY as AppList_QUERY_KEY } from '../../../../../secureManagement/AppManagement/api/app-list-query';

interface Props {
  linkId: number;
  onClickDelete: (linkId: number) => void;
}

export function LinkConfig({ linkId, onClickDelete }: Props) {
  const { t } = useTranslation('component');
  const minLength = 1;
  const maxLength = 64;

  const languageList = [
    'KOREAN',
    'ENGLISH',
    'JAPANESE',
    'CHINESE_SIMPLIFIED',
    'CHINESE_TRADITIONAL',
    'VIETNAMESE',
  ];

  const toaster = useToastMessage();
  const queryClient = useQueryClient();
  const { confirm } = useMessageDialog();

  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const [isNameValid, setIsNameValid] = useState(true);

  const { data: detailLinkInfo, error } = useSuspenseQuery({
    queryKey: [DETAIL_QUERY_KEY, linkId],
    queryFn: async () => getDetailLinkInfo(linkId),
  });

  const [isWebActivated, setIsWebActivated] = useState(
    detailLinkInfo?.webActivated ?? true,
  );
  const [isMobileActivated, setIsMobileActivated] = useState(
    detailLinkInfo?.mobileActivated ?? true,
  );

  const [multiLanguage, setMultiLanguage] = useState<{ [key: string]: string }>(
    detailLinkInfo?.multiLanguage || {},
  );

  const [linkURL, setLinkURL] = useState<string>(detailLinkInfo?.url || '');

  const [webDisplayMode, setWebDisplayMode] = useState<string>(
    detailLinkInfo?.webNavigationMode || 'SAME_PAGE',
  );
  const [mobileDisplayMode, setMobileDisplayMode] = useState<string>(
    detailLinkInfo?.mobileNavigationMode || 'NEW_PAGE',
  );

  const updateLinkInfoMutaion = useMutation({
    mutationFn: async (info: LinkInfo) => updateLinkInfo(linkId, info),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
      void queryClient.invalidateQueries({ queryKey: [DETAIL_QUERY_KEY] });
      void queryClient.invalidateQueries({
        queryKey: [GnbMenu_QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [AppList_QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setIsNeedSave(false);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  const createLinkInfoMutaion = useMutation({
    mutationFn: async (info: LinkInfo) => createLinkInfo(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
      void queryClient.invalidateQueries({ queryKey: [DETAIL_QUERY_KEY] });
      void queryClient.invalidateQueries({
        queryKey: [GnbMenu_QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [AppList_QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setIsNeedSave(false);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  useEffect(() => {
    setMultiLanguage(detailLinkInfo?.multiLanguage || {});
    setIsWebActivated(detailLinkInfo?.webActivated ?? true);
    setIsMobileActivated(detailLinkInfo?.mobileActivated ?? true);
    setLinkURL(detailLinkInfo?.url || '');
    setWebDisplayMode(detailLinkInfo?.webNavigationMode || 'SAME_PAGE');
    setMobileDisplayMode(detailLinkInfo?.mobileNavigationMode || 'NEW_PAGE');
  }, [detailLinkInfo]);

  if (linkId === -1) return;

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > EmpAppManagement > LinkConfig > error',
      error,
    );
  }

  const isLanguageValid = (
    languageKey: string,
    isMandatory: boolean = false,
  ) => {
    const name = multiLanguage[languageKey];
    if (name === undefined) return true;
    if (!isMandatory && name.length === 0) return true;

    return name.length >= minLength && name.length <= maxLength;
  };

  const isUrlValid = (url: string) => {
    const urlRegex = /^(https?:\/\/)/i;
    return urlRegex.test(url);
  };

  const onChangeName = (name: string, language: string) => {
    const isNameValid =
      (name.length >= minLength && name.length <= maxLength) ||
      (language !== 'KOREAN' && name.length === 0);

    setMultiLanguage({
      ...multiLanguage,
      [language]: name,
    });

    setIsNameValid(
      languageList
        .map((lang) => {
          if (lang === language) {
            return isNameValid;
          }
          return isLanguageValid(lang, lang === 'KOREAN');
        })
        .reduce((prev, curr) => prev && curr, true),
    );
  };

  const onChangeWebDisplayMode = (mode: string) => {
    setWebDisplayMode(mode);
    setIsNeedSave(true);
  };

  const onChangeMobileDisplayMode = (mode: string) => {
    setMobileDisplayMode(mode);
    setIsNeedSave(true);
  };

  const checkDelete = async () => {
    if (
      await confirm(
        <div className="size-full px-[24px]">
          <p className="text-[#1A1A1A] text-[20px] font-[500]">
            {t('globalconfig.basicManagement.employeePortal.link.delete')}
          </p>
          <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
            {t(
              'globalconfig.basicManagement.employeePortal.link.delete.confirm',
            )}
          </p>
        </div>,
      )
    ) {
      onClickDelete(linkId);
    }
  };

  const onClickDeleteLink = () => {
    void checkDelete();
  };

  const onClickCancel = () => {
    setMultiLanguage(detailLinkInfo?.multiLanguage || {});
    setIsWebActivated(detailLinkInfo?.webActivated ?? true);
    setIsMobileActivated(detailLinkInfo?.mobileActivated ?? true);
    setLinkURL(detailLinkInfo?.url || '');
    setWebDisplayMode(detailLinkInfo?.webNavigationMode || 'SAME_PAGE');
    setMobileDisplayMode(detailLinkInfo?.mobileNavigationMode || 'NEW_PAGE');
    setIsNeedSave(false);
  };

  const onClickSave = () => {
    if (!isUrlValid(linkURL)) {
      toaster.warning(
        t('globalconfig.basicManagement.employeePortal.link.url.notValid'),
      );
      return;
    }

    const info = {
      webActivated: isWebActivated,
      mobileActivated: isMobileActivated,
      multiLanguage,
      url: linkURL,
      webNavigationMode: webDisplayMode,
      mobileNavigationMode: mobileDisplayMode,
    };
    if (linkId === -10) {
      createLinkInfoMutaion.mutate(info);
    } else {
      updateLinkInfoMutaion.mutate(info);
    }
  };

  return (
    <div className="flex-grow h-full pl-[40px] //pl-[--Space-3XLarge]">
      <div className="flex h-[35px] items-center">
        <p className="leading-[27px] text-[#363636] //text-[--color-text-level1] text-[18px] font-[600]">
          {linkId === -10
            ? t('globalconfig.basicManagement.employeePortal.link.addNew')
            : detailLinkInfo?.multiLanguage.KOREAN}
        </p>
        <div className="flex-grow" />
        <Button
          size="small"
          variant="outline"
          colorset="level1"
          shape="rect"
          onClick={onClickDeleteLink}
        >
          <TrashIcon className="size-[16px]" />
          {t('globalconfig.basicManagement.employeePortal.link.delete')}
        </Button>
      </div>
      <p className="mt-[24px] mb-[16px] leading-[24px] text-[#363636] //text-[--color-text-level1] text-[18px] font-[500]">
        {t('globalconfig.basicManagement.employeePortal.setApps.setPerDevice')}
      </p>

      <div className="flex items-center h-[38px]">
        <p className="leading-[21px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {t('globalconfig.basicManagement.employeePortal.setApps.device.web')}
        </p>
        <div className="flex-grow" />
        <Switch
          id={`${linkId}WebSupportSwitch`}
          checked={isWebActivated}
          onChange={(value) => {
            setIsWebActivated(value);
            setIsNeedSave(true);
          }}
        />
      </div>

      <div className="flex items-center h-[38px]">
        <p className="leading-[21px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {t(
            'globalconfig.basicManagement.employeePortal.setApps.device.mobile',
          )}
        </p>
        <div className="flex-grow" />
        <Switch
          id={`${linkId}MobileSupportSwitch`}
          checked={isMobileActivated}
          onChange={(value) => {
            setIsMobileActivated(value);
            setIsNeedSave(true);
          }}
        />
      </div>
      <div className="w-full h-[1px] my-[24px] flex-shrink-0 bg-[#D8D8D8]" />
      <div className="w-full pb-[24px]">
        <p className="leading-[24px] text-[#363636] //text-[--color-text-level1] text-[18px] font-[500]">
          {t('globalconfig.basicManagement.employeePortal.link.info')}
        </p>
        {languageList.map((language, index) => (
          <TitleAndTextInput
            key={language}
            className={clsx('relative w-full', {
              'mt-[12px]': index === 0,
              'mt-[8px]': index !== 0,
            })}
            title={
              t('globalconfig.basicManagement.employeePortal.link.appName') +
              t(
                'globalconfig.basicManagement.employeePortal.appInfo.lang.' +
                  language.toLowerCase(),
              )
            }
            value={multiLanguage[language] ?? ''}
            isMandatory={language === 'KOREAN'}
            minLength={minLength}
            maxLength={maxLength}
            onChangeName={(name) => {
              onChangeName(name, language);
              setIsNeedSave(true);
            }}
          />
        ))}
        <TitleAndTextInput
          className="mt-[24px] relative w-full"
          title={t('globalconfig.basicManagement.employeePortal.link.url')}
          value={linkURL}
          placeholder={t(
            'globalconfig.basicManagement.employeePortal.link.url.placeholder',
          )}
          isMandatory
          minLength={minLength}
          maxLength={255}
          onChangeName={(url) => {
            setLinkURL(url);
            setIsNeedSave(true);
          }}
        />
        <div className="flex mt-[24px]">
          <div className="flex items-center h-[32px] w-[192px]">
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[500]">
              {t('globalconfig.basicManagement.employeePortal.web.displayMode')}
            </p>
            <ToolTip.Root>
              <ToolTip.Trigger>
                <Button className="ml-[4px]">
                  <QuestionMarkCircleIcon
                    className="size-[20px]"
                    color="#AAAAAA"
                  />
                </Button>
              </ToolTip.Trigger>
              <ToolTip.Content className="z-[1001]">
                {t(
                  'globalconfig.basicManagement.employeePortal.web.displayMode.tooltip',
                )}
              </ToolTip.Content>
            </ToolTip.Root>
          </div>
          <div className="flex flex-col flex-grow py-[8px] gap-[8px]">
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              <input
                id={`${linkId}WebDisplayRadio1`}
                className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                value={'SAME_PAGE'}
                name={'WebDisplayRadio'}
                checked={webDisplayMode === 'SAME_PAGE'}
                onChange={(e) => onChangeWebDisplayMode(e.target.value)}
              />
              <label className="ml-[8px]" htmlFor={`${linkId}WebDisplayRadio1`}>
                {t(
                  'globalconfig.basicManagement.employeePortal.web.displayMode.samePage',
                )}
              </label>
            </p>
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              <input
                id={`${linkId}WebDisplayRadio2`}
                className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                value={'SAME_PAGE_IFRAME'}
                name={'WebDisplayRadio'}
                checked={webDisplayMode === 'SAME_PAGE_IFRAME'}
                onChange={(e) => onChangeWebDisplayMode(e.target.value)}
              />
              <label className="ml-[8px]" htmlFor={`${linkId}WebDisplayRadio2`}>
                {t(
                  'globalconfig.basicManagement.employeePortal.web.displayMode.sameWithIframe',
                )}
              </label>
            </p>
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              <input
                id={`${linkId}WebDisplayRadio3`}
                className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                value={'NEW_PAGE'}
                name={'WebDisplayRadio'}
                checked={webDisplayMode === 'NEW_PAGE'}
                onChange={(e) => onChangeWebDisplayMode(e.target.value)}
              />
              <label className="ml-[8px]" htmlFor={`${linkId}WebDisplayRadio3`}>
                {t(
                  'globalconfig.basicManagement.employeePortal.web.displayMode.newPage',
                )}
              </label>
            </p>
          </div>
        </div>
        <div className="flex mt-[24px]">
          <div className="flex items-center h-[32px] w-[192px]">
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[500]">
              {t(
                'globalconfig.basicManagement.employeePortal.mobile.displayMode',
              )}
            </p>
            <ToolTip.Root>
              <ToolTip.Trigger>
                <Button className="ml-[4px]">
                  <QuestionMarkCircleIcon
                    className="size-[20px]"
                    color="#AAAAAA"
                  />
                </Button>
              </ToolTip.Trigger>
              <ToolTip.Content className="z-[1001]">
                {t(
                  'globalconfig.basicManagement.employeePortal.mobile.displayMode.tooltip',
                )}
              </ToolTip.Content>
            </ToolTip.Root>
          </div>
          <div className="flex flex-col flex-grow py-[8px] gap-[8px]">
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              <input
                id={`${linkId}MobileDisplayRadio1`}
                className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                value={'NEW_PAGE'}
                name={'MobileDisplayRadio'}
                checked={mobileDisplayMode === 'NEW_PAGE'}
                onChange={(e) => onChangeMobileDisplayMode(e.target.value)}
              />
              <label
                className="ml-[8px]"
                htmlFor={`${linkId}MobileDisplayRadio1`}
              >
                {t(
                  'globalconfig.basicManagement.employeePortal.mobile.displayMode.newPage',
                )}
              </label>
            </p>
            <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              <input
                id={`${linkId}MobileDisplayRadio2`}
                className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                type="radio"
                value={'MOBILE_APP'}
                name={'MobileDisplayRadio'}
                checked={mobileDisplayMode === 'MOBILE_APP'}
                onChange={(e) => onChangeMobileDisplayMode(e.target.value)}
              />
              <label
                className="ml-[8px]"
                htmlFor={`${linkId}MobileDisplayRadio2`}
              >
                {t(
                  'globalconfig.basicManagement.employeePortal.mobile.displayMode.mobileApp',
                )}
              </label>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          variant="outline"
          colorset="level1"
          shape="rect"
          onClick={onClickCancel}
        >
          {t('globalconfig.common.button.cancel.title')}
        </Button>
        <Button
          size="medium"
          variant="solid"
          colorset="level1"
          shape="rect"
          disabled={!isNeedsSave || !isNameValid || linkURL.length === 0}
          onClick={onClickSave}
        >
          {t('globalconfig.common.button.save.title')}
        </Button>
      </div>
    </div>
  );
}
