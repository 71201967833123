import { formatDate } from '@dop-ui/react/shared/lib/date';
import { BaseResponse } from '../../types';
import {
  IP_BLOCK_LIST_DOWNLOAD_API,
  OVERSEAS_LOGIN_EXCLUDE_IP_USE_DOWNLOAD_API,
} from '../../constants';

export async function downloadIpList() {
  console.log('downloadIpList');
  const response = await fetch(IP_BLOCK_LIST_DOWNLOAD_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  if (response.ok) {
    const today = new Date();
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download =
      formatDate({ date: today, format: 'YYYYMMDDHHmmss' }) + '_IP_List.txt';
    a.click();
  } else {
    const data = (await response.json()) as BaseResponse<undefined>;
    throw new Error(data.message);
  }
}

export async function downloadOverseasIpList() {
  console.log('downloadIpList');
  const response = await fetch(OVERSEAS_LOGIN_EXCLUDE_IP_USE_DOWNLOAD_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  if (response.ok) {
    const today = new Date();
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download =
      formatDate({ date: today, format: 'YYYYMMDDHHmmss' }) + '_IP_List.txt';
    a.click();
  } else {
    const data = (await response.json()) as BaseResponse<undefined>;
    throw new Error(data.message);
  }
}
