import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import * as appDataQuery from '../../../api/appDataQuery';
import { AppContentsInfo, AppType } from '../../../types';
import { useActionsContext, useValuesContext } from '../../Provider';

export function AppInfoTable() {
  const { t } = useTranslation('component');
  const { deptId, selectedAppType } = useValuesContext();
  const { setSelectedRows } = useActionsContext();

  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [appDataQuery.QUERY_KEY],
    queryFn: async () =>
      await appDataQuery.appDataQuery(deptId, selectedAppType),
  });
  const [currentPage, setCurrentPage] = useState<number | undefined>(
    res?.page?.page,
  );

  useEffect(() => {
    refetch().catch((error) => console.error(error));
  }, [currentPage, selectedAppType, deptId, refetch]);

  if (error) {
    return <>{error}</>;
  }

  const getAppTypeString = (appType?: AppType) => {
    if (appType === 'approval') {
      return t('globalconfig.dataManagement.deptManagement.approval.title');
    } else if (appType === 'board') {
      return t('globalconfig.dataManagement.deptManagement.board.title');
    } else if (appType === 'report') {
      return t('globalconfig.dataManagement.deptManagement.report.title');
    } else {
      return t('globalconfig.dataManagement.deptManagement.group.title');
    }
  };

  const statusColumnDef = {
    accessorKey: 'status',
    id: 'status',
    header: () => (
      <div className="flex items-center px-5 py-3 gap-1">
        {t('globalconfig.common.table.header.status')}
      </div>
    ),
    cell: (value: CellContext<AppContentsInfo, unknown>) => (
      <div className="text-left px-5 py-3">
        <span
          className={clsx(
            'rounded-[8px] px-[6px] py-1 text-white text-[13px] font-[500] -tracking-[1px]',
            {
              'bg-[#7BD18B]': (value.renderValue() as string) === 'ACTIVE',
              'bg-[#A7A7A7]': (value.renderValue() as string) === 'INACTIVE',
            },
          )}
        >
          {(value.renderValue() as string) === 'ACTIVE'
            ? t('globalconfig.dataManagement.deptManagement.status.active')
            : t('globalconfig.dataManagement.deptManagement.status.inactive')}
        </span>
      </div>
    ),
  };

  const addedColumDefs =
    selectedAppType === 'report'
      ? [
          statusColumnDef,
          columnDefGenerator<AppContentsInfo>(
            'storageUsage',
            t('globalconfig.common.table.header.storageUsage'),
          ),
        ]
      : selectedAppType === 'board'
        ? [
            statusColumnDef,
            columnDefGenerator<AppContentsInfo>(
              'shared',
              t('globalconfig.common.table.header.shared'),
            ),
            columnDefGenerator<AppContentsInfo>(
              'storageUsage',
              t('globalconfig.common.table.header.storageUsage'),
            ),
          ]
        : [
            columnDefGenerator<AppContentsInfo>(
              'count',
              t('globalconfig.common.table.header.count'),
            ),
          ];
  const columnDefs: ColumnDef<AppContentsInfo>[] = [
    columnDefGenerator<AppContentsInfo>(
      'name',
      getAppTypeString(selectedAppType),
    ),
    ...addedColumDefs,
  ];

  return res?.data?.length === 0 || !res?.data ? (
    <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
      {t('globalconfig.common.table.noData')}
    </div>
  ) : (
    <TablePrimitives.Root
      selectable={true}
      contents={res?.data ?? []}
      columnDefs={columnDefs}
      onSelectContents={setSelectedRows}
    >
      <TablePrimitives.Contents />
      {currentPage !== undefined && (
        <TablePrimitives.Pagination
          currentPage={currentPage}
          onNextPage={setCurrentPage}
          onPreviousPage={setCurrentPage}
          onClickPage={setCurrentPage}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() => {
            setCurrentPage(res?.page?.totalPage ?? 0);
          }}
          totalPage={res?.page?.totalPage ?? 0}
        />
      )}
    </TablePrimitives.Root>
  );
}

export default AppInfoTable;
