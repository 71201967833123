import { AllowPersonalInfoEdit } from './components/allow-personal-info-edit';
import { DisplayItems } from './components/display-items';
import { CustomProfileManagement } from './components/custom-profile-management';
import {
  QUERY_KEY,
  getProfileCardConfig,
} from './apis/get-profile-card-config';
import { useSuspenseQuery } from '@tanstack/react-query';

export function FirstPage() {
  const { data: profileConfig, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getProfileCardConfig(),
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > BasicManagement > ProfileConfig : ',
      error,
    );
  }

  return (
    <div className="flex flex-col gap-[32px]">
      <AllowPersonalInfoEdit editedProfile={profileConfig.editedProfile} />
      <DisplayItems
        profileCardItemDisplayDtos={profileConfig.profileCardItemDisplayDtos}
      />
      <CustomProfileManagement />
    </div>
  );
}
