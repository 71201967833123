import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Switch } from '@daouoffice/ui';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { isContentNeedsSaveAtom } from '../../../../../../store/isContentNeedsSaveAtom';
import { InUseAppInfo, UpdateAppInfo } from '../../../types';
import { QUERY_KEY, updateAppStatus } from '../apis/apps-setting';
import { TitleAndTextInput } from '../../title-and-textinput';
import { QUERY_KEY as GnbMenu_QUERY_KEY } from '../../gnb-sort-tab/apis/gnb-sort';
import { QUERY_KEY as AppList_QUERY_KEY } from '../../../../../secureManagement/AppManagement/api/app-list-query';

interface Props {
  appInfo?: InUseAppInfo;
}

export function AppConfig({ appInfo }: Props) {
  const { t } = useTranslation('component');
  const minLength = 1;
  const maxLength = 64;

  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const [isNameValid, setIsNameValid] = useState(true);

  const languageList = useMemo(
    () => [
      'KOREAN',
      'ENGLISH',
      'JAPANESE',
      'CHINESE_SIMPLIFIED',
      'CHINESE_TRADITIONAL',
      'VIETNAMESE',
    ],
    [],
  );

  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [isWebActivated, setIsWebActivated] = useState(
    appInfo?.webActivated ?? false,
  );
  const [isMobileActivated, setIsMobileActivated] = useState(
    appInfo?.mobileActivated ?? false,
  );
  const [isMessengerActivated, setIsMessengerActivated] = useState(
    appInfo?.messengerActivated ?? false,
  );
  const [multiLanguage, setMultiLanguage] = useState<{ [key: string]: string }>(
    appInfo?.multiLanguage || {},
  );

  const appStatusUpdateMutation = useMutation({
    mutationFn: async (updateAppInfo: UpdateAppInfo) => {
      await updateAppStatus(updateAppInfo);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      void queryClient.invalidateQueries({
        queryKey: [GnbMenu_QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [AppList_QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setIsNeedSave(false);
    },
    onError: () => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  const hasLanguage = (languageKey: string) => {
    return multiLanguage[languageKey] !== undefined;
  };

  const isLanguageValid = useCallback(
    (languageKey: string, isMandatory: boolean = false) => {
      const name = multiLanguage[languageKey];
      if (isMandatory && name === undefined) return false;
      if (name === undefined) return true;
      if (!isMandatory && name.length === 0) return true;

      return name.length >= minLength && name.length <= maxLength;
    },
    [multiLanguage],
  );

  useEffect(() => {
    setIsNeedSave(false);
    setIsWebActivated(appInfo?.webActivated ?? false);
    setIsMobileActivated(appInfo?.mobileActivated ?? false);
    setIsMessengerActivated(appInfo?.messengerActivated ?? false);
    setMultiLanguage(appInfo?.multiLanguage || {});
  }, [appInfo, setIsNeedSave]);

  useEffect(() => {
    setIsNameValid(
      languageList
        .map((lang) => {
          return isLanguageValid(lang, lang === 'KOREAN');
        })
        .reduce((prev, curr) => prev && curr, true),
    );
  }, [isLanguageValid, languageList, multiLanguage]);

  if (!appInfo) {
    return;
  }

  const isDeviceSupooerted =
    appInfo.webUsageConfigExposed ||
    appInfo.mobileUsageConfigExposed ||
    appInfo.messengerUsageConfigExposed;

  const onClickCancel = () => {
    setIsNeedSave(false);
    setIsWebActivated(appInfo.webActivated);
    setIsMobileActivated(appInfo.mobileActivated);
    setIsMessengerActivated(appInfo.messengerActivated);
    setMultiLanguage(appInfo.multiLanguage);
  };

  const onClickSave = () => {
    appStatusUpdateMutation.mutate({
      id: appInfo.id,
      code: appInfo.code,
      webActivated: isWebActivated,
      mobileActivated: isMobileActivated,
      messengerActivated: isMessengerActivated,
      multiLanguage: multiLanguage,
    });
  };

  const onChangeName = (name: string, language: string) => {
    const isNameValid =
      (name.length >= minLength && name.length <= maxLength) ||
      (language !== 'KOREAN' && name.length === 0);

    setMultiLanguage({
      ...multiLanguage,
      [language]: name,
    });

    setIsNameValid(
      languageList
        .map((lang) => {
          if (lang === language) {
            return isNameValid;
          }
          return isLanguageValid(lang, lang === 'KOREAN');
        })
        .reduce((prev, curr) => prev && curr, true),
    );
  };

  return (
    <div className="flex-grow h-full pl-[40px] //pl-[--Space-3XLarge]">
      <p className="h-[35px] mb-[24px] leading-[27px] py-[4px] text-[#363636] //text-[--color-text-level1] text-[18px] font-[600]">
        {appInfo.name}
      </p>
      {isDeviceSupooerted && (
        <p className="mb-[16px] leading-[24px] text-[#363636] //text-[--color-text-level1] text-[18px] font-[500]">
          {t(
            'globalconfig.basicManagement.employeePortal.setApps.setPerDevice',
          )}
        </p>
      )}
      {appInfo.webUsageConfigExposed && (
        <div className="flex items-center h-[55px]">
          <p className="leading-[21px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
            {t(
              'globalconfig.basicManagement.employeePortal.setApps.device.web',
            )}
          </p>
          <div className="flex-grow" />
          <Switch
            id={`${appInfo.id}WebSupportSwitch`}
            checked={isWebActivated}
            onChange={(value) => {
              setIsWebActivated(value);
              setIsNeedSave(true);
            }}
          />
        </div>
      )}
      {appInfo.mobileUsageConfigExposed && (
        <div className="flex items-center h-[55px]">
          <p className="leading-[21px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
            {t(
              'globalconfig.basicManagement.employeePortal.setApps.device.mobile',
            )}
          </p>
          <div className="flex-grow" />
          <Switch
            id={`${appInfo.id}MobileSupportSwitch`}
            checked={isMobileActivated}
            onChange={(value) => {
              setIsMobileActivated(value);
              setIsNeedSave(true);
            }}
          />
        </div>
      )}
      {appInfo.messengerUsageConfigExposed && (
        <div className="flex items-center h-[55px]">
          <p className="leading-[21px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
            {t(
              'globalconfig.basicManagement.employeePortal.setApps.device.messenger',
            )}
          </p>
          <div className="flex-grow" />
          <Switch
            id={`${appInfo.id}MessengerSupportSwitch`}
            checked={isMessengerActivated}
            onChange={(value) => {
              setIsMessengerActivated(value);
              setIsNeedSave(true);
            }}
          />
        </div>
      )}
      {isDeviceSupooerted && (
        <div className="w-full h-[1px] mt-[32px] mb-[24px] flex-shrink-0 bg-[#D8D8D8]" />
      )}
      <div className="w-full py-[32px]">
        <p className="leading-[24px] text-[#363636] //text-[--color-text-level1] text-[18px] font-[500]">
          {appInfo.name}{' '}
          {t('globalconfig.basicManagement.employeePortal.appInfo.title')}
        </p>
        {languageList.map((language) => {
          if (hasLanguage(language)) {
            return (
              <TitleAndTextInput
                key={language}
                className="mt-[16px] relative w-full"
                title={
                  t(
                    'globalconfig.basicManagement.employeePortal.appInfo.appName',
                  ) +
                  t(
                    'globalconfig.basicManagement.employeePortal.appInfo.lang.' +
                      language.toLowerCase(),
                  )
                }
                value={multiLanguage[language] ?? ''}
                isMandatory={language === 'KOREAN'}
                minLength={minLength}
                maxLength={maxLength}
                onChangeName={(name) => {
                  onChangeName(name, language);
                  setIsNeedSave(true);
                }}
              />
            );
          }
        })}
      </div>
      <div className="w-full mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          variant="outline"
          colorset="level1"
          shape="rect"
          onClick={onClickCancel}
        >
          {t('globalconfig.common.button.cancel.title')}
        </Button>
        <Button
          size="medium"
          variant="solid"
          colorset="level1"
          shape="rect"
          disabled={!isNeedsSave || !isNameValid}
          onClick={onClickSave}
        >
          {t('globalconfig.common.button.save.title')}
        </Button>
      </div>
    </div>
  );
}
