import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { LOGO_THEME_CONFIG_API } from '../../constants';
import { LogoThemeConfigInfo } from '../types';
import { BaseResponse } from './../../types';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.LogoThemeManagement.LogoThemeQuery';

export async function getLogoThemeConfig() {
  const res = await fetch(LOGO_THEME_CONFIG_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  if (!res.ok) {
    return {} as LogoThemeConfigInfo;
  }

  const response = (await res.json()) as BaseResponse<LogoThemeConfigInfo>;
  return response?.data;
}
