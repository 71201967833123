import { OrgShareConfigIndicator } from './components/org-share-config/indicator';
import { MultiCompanyBasicInfo } from './components/info';
import { OrgDisplayOrderConfigIndicator } from './components/org-display-order-config/indicator';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Suspense, useEffect } from 'react';
import { useBasicInfoContext } from './Context';
import { useAuthContext } from '../../../../../lib/auth/client';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_KEY, getOrgConfig } from './apis/get-org-config';
import { OrgConfigInfo } from './type';

export function Main() {
  const { t } = useTranslation('component');

  const { setBasicInfoContext } = useBasicInfoContext();

  const { me } = useAuthContext();

  const { data: orgConfig, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      if (!me?.companyGroupId)
        return {
          companyGroupId: 0,
          isOrganizationChartShare: 'false',
          shareTarget: 'ALL',
          targetList: [],
          shareRange: 'CHART_AND_SEARCH',
          organizationChartOrder: [],
        } as OrgConfigInfo;
      return await getOrgConfig(me.companyGroupId);
    },
  });

  useEffect(() => {
    setBasicInfoContext((prev) => ({
      ...prev,
      orgConfig: orgConfig,
    }));
  }, [orgConfig]);

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > BasicInfo : ',
      error,
    );
  }

  if (!me) {
    return;
  }

  return (
    <div className="flex flex-wrap flex-col gap-[24px]">
      <h2 className="text-[#333] text-[20px] font-bold -tracking-[1.2px]">
        {t('globalconfig.multiCompanyManagement.basicInfo.title')}
      </h2>
      <Suspense fallback={<div>Loading ...</div>}>
        <MultiCompanyBasicInfo />
      </Suspense>
      <div className="w-full">
        <OrgShareConfigIndicator />
        <HDivider />
        <OrgDisplayOrderConfigIndicator />
      </div>
    </div>
  );
}

function HDivider() {
  return <div className="w-full h-[1px] bg-[#E5E5E5]" />;
}

export default Main;
