import { SERVICE_MAP_CONFIG_API } from '../../constants';
import { BaseResponse } from '../../types';
import { ServiceMapConfigInfo } from '../type';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export const QUERY_KEY = 'GlobalConfig.BasicManagement.ServiceMap.Config';

export async function getServiceMapConfig(): Promise<ServiceMapConfigInfo> {
  try {
    const response = await fetch(SERVICE_MAP_CONFIG_API, {
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    });

    if (!response.ok) {
      throw new Error('getServiceMapConfig 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponse<ServiceMapConfigInfo>;

    return resData.data;
  } catch (error) {
    console.log('error', error);
    return {
      configName: '',
      configValue: true,
    };
  }
}
