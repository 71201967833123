import { PROFILE_CARD_PERSONAL_INFO_API } from '../../constants';
import { EditePersonalInfo } from '../type';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

export async function updatePersonalInfo(personalInfo: EditePersonalInfo) {
  const response = await fetch(PROFILE_CARD_PERSONAL_INFO_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(personalInfo),
  });

  if (!response.ok) {
    throw new Error('updatePersonalInfo 오류 발생.');
  }

  return response;
}
