import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { PropsWithChildren, Suspense } from 'react';

export function Contents({ children }: PropsWithChildren) {
  const { t } = useTranslation('component');

  return (
    <div className="flex-col w-full h-full border rounded-xl p-10 justify-center">
      <div className="flex flex-col gap-2">
        <h2 className="text-[#333] text-[20px] font-semibold -tracking-[1.2px]">
          {t('globalconfig.secureManagement.tfaMemberConfig.title')}
        </h2>
        <p className="text-[#999]">
          {t('globalconfig.secureManagement.tfaMemberConfig.title.description')}
        </p>
      </div>
      <Suspense
        fallback={
          <div className="min-h-[282px] w-full flex items-center justify-center text-lg text-black font-medium">
            Loading...
          </div>
        }
      >
        <div className="flex-col flex w-full h-full">{children}</div>
      </Suspense>
    </div>
  );
}

export default Contents;
