import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ScheduleType } from '../../types';

interface Props {
  isHolidaySelectable: boolean;
  scheduleType: ScheduleType;
  disabled?: boolean;
  onChangeScheduleType: (scheduleType: ScheduleType) => void;
}

export function ScheduleTypeSelect({
  isHolidaySelectable,
  scheduleType,
  disabled,
  onChangeScheduleType,
}: Props) {
  const { t } = useTranslation('component');
  return (
    <div className="flex-grow flex items-center gap-[24px] h-[40px]">
      <div className="w-[80px]">
        <input
          id={'scheduleType_anniversary'}
          className="size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
          type="radio"
          name="scheduleType"
          value="ANNIVERSARY"
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.type.anniversary',
          )}
          checked={scheduleType === 'ANNIVERSARY'}
          disabled={disabled}
          onChange={() => onChangeScheduleType('ANNIVERSARY')}
        />
        <label
          className={clsx('ml-[4px]', {
            'cursor-not-allowed': disabled,
          })}
          htmlFor="scheduleType_anniversary"
        >
          {t(
            'globalconfig.basicManagement.companySchedule.schedule.type.anniversary',
          )}
        </label>
      </div>
      <div className="w-[80px]">
        <input
          id={'scheduleType_holiday'}
          className={clsx(
            'size-[16px] rounded-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]',
            { 'cursor-not-allowed': !isHolidaySelectable },
          )}
          type="radio"
          name="scheduleType"
          value="HOLIDAY"
          title={t(
            'globalconfig.basicManagement.companySchedule.schedule.type.holiday',
          )}
          checked={scheduleType === 'HOLIDAY'}
          disabled={!isHolidaySelectable || disabled}
          onChange={() => onChangeScheduleType('HOLIDAY')}
        />
        <label
          className={clsx('ml-[4px]', {
            'cursor-not-allowed': !isHolidaySelectable || disabled,
          })}
          htmlFor="scheduleType_holiday"
        >
          {t(
            'globalconfig.basicManagement.companySchedule.schedule.type.holiday',
          )}
        </label>
      </div>
    </div>
  );
}
