import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { API_BACK_UP_DATA_URL } from '../constants';

interface IResponse {
  data: {
    token: string;
    ecdHost: string;
  };
}

export const QUERY_KEY = 'backUpUrlQuery';

export async function getBackUpUrl() {
  try {
    const res = await fetch(API_BACK_UP_DATA_URL);
    const { data } = (await res.json()) as IResponse;
    return data && data?.token && data?.ecdHost
      ? `${data?.ecdHost}/sso?token=${data?.token}`
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
