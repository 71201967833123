import OrganizerShortcutSetting from './shortcut-setting';
import { useOrganizerValuesAtom } from '../Provider';
import OrganizerShortcut from './Shortcut';
import OrganizerMemo from './Memo';
import { Provider as ShortcutProvider } from './Shortcut/Context';
import DefaultAppContainer from './default-app-container';
import OrganizerChatbot from './chatbot';

function OrganizerDefaultApp() {
  const { panelState } = useOrganizerValuesAtom();

  return (
    <>
      <DefaultAppContainer visible={panelState === 'shortcut'}>
        <ShortcutProvider>
          <OrganizerShortcut />
        </ShortcutProvider>
      </DefaultAppContainer>

      <DefaultAppContainer visible={panelState === 'memo'}>
        <OrganizerMemo />
      </DefaultAppContainer>

      <DefaultAppContainer visible={panelState === 'setting'}>
        <OrganizerShortcutSetting />
      </DefaultAppContainer>

      <DefaultAppContainer visible={panelState === 'chatbot'}>
        <OrganizerChatbot />
      </DefaultAppContainer>
    </>
  );
}

export default OrganizerDefaultApp;
