import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';
import { SubTabType } from '../types';
import { useContextValues } from './Context';

export interface Props {
  type: SubTabType;
  title: string;
  onClickTab: (tabType: SubTabType) => void;
}

export const SubTab = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ type, title, onClickTab }, tabRef) => {
    const { selectedType, setSelectedType, isEditMode, setEditMode } =
      useContextValues();
    const { t } = useTranslation('component');
    const { confirm } = useMessageDialog();

    const onClickHandler = () => {
      const handleConfirm = async () => {
        if (type === 'perApps' && selectedType === 'perManagers') {
          const requestConfirm = (
            <div className="w-full !px-4 flex flex-col gap-4">
              <h2 className="text-[18px] text-black font-medium">
                {t(
                  'globalconfig.secureManagement.adminManagement.messageDialog.checkChange.title',
                )}
              </h2>
              <span className="text-[14px] text-black font-normal whitespace-normal">
                {t(
                  'globalconfig.secureManagement.adminManagement.messageDialog.checkChange.contents',
                )}
              </span>
            </div>
          );

          return await confirm(requestConfirm);
        } else return true;
      };

      if (isEditMode)
        handleConfirm()
          .then((result) => {
            if (result) {
              setEditMode(false);
              onClickTab(type);
              setSelectedType(type);
            }
          })
          .catch(console.error);
      else {
        onClickTab(type);
        setSelectedType(type);
      }
    };

    return (
      <button
        ref={tabRef}
        className={clsx(
          'select-none rect solid major square hover:bg-[rgba(221, 221, 221, 0.3137254902)] rounded-[8px] h-10',
          {
            active: selectedType === type,
          },
        )}
        onClick={onClickHandler}
      >
        {title}
      </button>
    );
  },
);

SubTab.displayName = 'SubTab';

export default SubTab;
