import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ProgressBar } from './components/progress-bar';

interface Props {
  totalUserCount: number;
  currentUsingUsersCount: number;
}

export function UsersInfo({ totalUserCount, currentUsingUsersCount }: Props) {
  const { t } = useTranslation('component');

  const remainUsersCount = totalUserCount - currentUsingUsersCount;

  return (
    <div className="border border-solid border-[#E6E7EA] rounded-[8px] px-[32px] 2xl:basis-1/3 flex-grow">
      <p className="mt-[32px] ml-[8px] text-[#333333] text-[18px] font-[500]">
        {t('globalconfig.basicManagement.serviceInfo.usersInfo.title')}
      </p>
      <p className="mt-[16px] ml-[8px] text-[#333333] text-[36px] font-[500]">
        {currentUsingUsersCount}
        {t('globalconfig.basicManagement.serviceInfo.usersInfo.inUse')}
      </p>
      <p className="mt-[48px] text-[#AAAAAA] text-[14px] font-[500]">
        {remainUsersCount}
        {t(
          'globalconfig.basicManagement.serviceInfo.usersInfo.possibleToAdd',
        )}{' '}
        | {currentUsingUsersCount}
        {t('globalconfig.basicManagement.serviceInfo.usersInfo.memberCount')}/
        {totalUserCount}
        {t('globalconfig.basicManagement.serviceInfo.usersInfo.memberCount')}
      </p>
      <ProgressBar
        className="w-full mt-[8px]"
        total={totalUserCount}
        used={currentUsingUsersCount}
      />
      {/** TODO: 추후 링크 수정해야함 (조직관리 경로 확정되면 수정 임시로 고객포털 연결중.) */}
      <a
        href="https://my.daouoffice.com/login"
        rel="noreferrer"
        target="_blank"
      >
        <div className="relative flex items-center justify-center mt-[16px] mb-[30px] py-[12px] w-full border border-solid border-[#9A9CA0] rounded-[8px]">
          <p className="text-[#888888] text-[14px] font-[500]">
            {t(
              'globalconfig.basicManagement.serviceInfo.usersInfo.linkToMemberManage',
            )}
          </p>
          <ArrowTopRightOnSquareIcon className="absolute size-[16px] top-[13px] right-[11px] bottom-[11px]" />
        </div>
      </a>
    </div>
  );
}
