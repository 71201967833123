import { useAtomValue } from 'jotai';
import { MessageDialogProvider } from '@dop-ui/react/features/message-dialog';
import { currentSelectedMenuInfoAtom } from '../../store/currentSelectedMenuInfoAtom';
import {
  APP_MANAGEMENT_MENU_PATH,
  getMenuInfoWithPath,
  getSubMenuInfos,
} from '../../menus';
import { appCodesAtom } from '../../store/appCodesAtom';
import { Header } from './Header/index';
import { ReactElement } from 'react';

export function Contents() {
  const currentSelectedMenuInfo = useAtomValue(currentSelectedMenuInfoAtom);
  const appCodes = useAtomValue(appCodesAtom);

  const menuInfo = getMenuInfoWithPath(currentSelectedMenuInfo.menuPath);

  const isMenuValid = () => {
    if (
      appCodes?.setting
        .map((codeInfo) => codeInfo.appCode)
        .includes(menuInfo?.menuCode ?? '')
    ) {
      return true;
    }
    if (
      appCodes?.business
        .map((codeInfo) => codeInfo.appCode)
        .includes(menuInfo?.menuCode ?? '')
    ) {
      return true;
    }
    if (
      appCodes?.employee
        .map((codeInfo) => codeInfo.appCode)
        .includes(menuInfo?.menuCode ?? '')
    ) {
      return true;
    }
    if (appCodes.accessibles.includes(menuInfo?.menuCode ?? '')) {
      return true;
    }

    return false;
  };

  if (!appCodes) return;
  if (!menuInfo) return;

  if (menuInfo.menuCode === '' || menuInfo.menuPath === '') return;

  let categoryName: ReactElement | undefined = undefined;
  const selectedMainCategory = `/${currentSelectedMenuInfo.menuPath.split('/')[1] ?? ''}`;

  if (selectedMainCategory === APP_MANAGEMENT_MENU_PATH) {
    const upperMenuInfo = getSubMenuInfos(selectedMainCategory, [
      currentSelectedMenuInfo.menuCode,
    ]);
    if (upperMenuInfo[0]) categoryName = upperMenuInfo[0].name;
  } else {
    const upperMenuInfo = getMenuInfoWithPath(selectedMainCategory);
    if (upperMenuInfo) categoryName = upperMenuInfo.name;
  }

  if (!isMenuValid()) {
    return (
      <MessageDialogProvider>
        <div className="size-full">
          <Header categoryName={categoryName} menuName={menuInfo.name} />
          <div className="flex items-center justify-center size-full">
            권한이 없는 메뉴 입니다.
          </div>
        </div>
      </MessageDialogProvider>
    );
  }

  return (
    <MessageDialogProvider>
      <div className="h-full w-full flex flex-col overflow-x-scroll">
        <Header categoryName={categoryName} menuName={menuInfo.name} />
        <div className="flex-1 max-h-full max-w-full min-w-[1012px] overflow-scroll">
          {menuInfo.page ? (
            menuInfo.page
          ) : (
            <div className="flex items-center justify-center size-full">
              현재 구현중인 메뉴입니다.
            </div>
          )}
        </div>
      </div>
    </MessageDialogProvider>
  );
}

export default Contents;
