import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';

export interface Props {
  createdAt: string;
  storageUsage: string;
  className?: string;
}

export function DeptInfoCard({ createdAt, storageUsage, className }: Props) {
  const { t } = useTranslation('component');
  const createdDate = new Date(createdAt);
  const createdAtString = `${createdDate.getFullYear()}-${String(createdDate.getMonth() + 1).padStart(2, '0')}-${createdDate.getDate().toString().padStart(2, '0')}`;

  return (
    <div
      className={clsx(
        'bg-[#F9FAFB] border rounded-lg px-5 py-4 gap-2',
        className,
      )}
    >
      <div className="flex">
        <span className="w-1/5 text-sm text-[#aaaaaa]">
          {t('globalconfig.dataManagement.deptManagement.info.createdAt')}
        </span>
        <span className="w-4/5 text-sm text-[#aaaaaa]">{createdAtString}</span>
      </div>
      <div className="flex mt-2">
        <span className="w-1/5 text-sm text-[#aaaaaa]">
          {t('globalconfig.dataManagement.deptManagement.info.capacity')}
        </span>
        <span className="w-4/5 text-sm text-[#aaaaaa]">{storageUsage}</span>
      </div>
    </div>
  );
}

export default DeptInfoCard;
