import { Suspense } from 'react';
import AssetSharedList from './asset-shared-list';
export function AssetContents() {
  return (
    <Suspense>
      <AssetSharedList />
    </Suspense>
  );
}

export default AssetContents;
