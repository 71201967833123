import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as getUsersPopupList from '../api/get-users-popup-announcement';
import { useAuthContext } from '../../../../next/lib/auth/client/Context';
import PopupAnnouncementList from './list';
import type { IPopupAnnouncement, IPopupAnnouncementStorage } from './types';
import { POPUP_STORAGE_KEY } from './constants';
import { date, isBefore, isValid } from '../../../shared/lib/date';
import { getLocalStorageItem } from '../../../shared/lib/utils/local-storage';

function Provider({ children }: PropsWithChildren) {
  const { me } = useAuthContext();
  const [usersAnnouncementList, setUsersAnnouncementList] = useState<
    IPopupAnnouncement[]
  >([]);
  const { data, error } = useQuery({
    queryKey: [getUsersPopupList.QUERY_KEY],
    queryFn: () => getUsersPopupList.getUsersPopupAnnouncement(),
  });
  const [open, setOpen] = useState(false);

  const isLoaded = useRef(false);

  const filteringAnnouncementList = () => {
    if (me) {
      const announcementList = data?.elements ?? [];
      let updatedUnscribes = getLocalStorageItem<IPopupAnnouncementStorage[]>({
        key: `${POPUP_STORAGE_KEY}-${me?.companyId}-${me?.id}`,
        defaultValue: [],
        type: 'object',
      });

      if (!updatedUnscribes) updatedUnscribes = [];

      updatedUnscribes = updatedUnscribes.filter((unscribe) =>
        announcementList.find(
          (announcement) =>
            unscribe.key &&
            unscribe.value &&
            announcement.id === Number(unscribe.key) &&
            isValid(date(unscribe.value)) &&
            isBefore({
              date: date(),
              compareDate: date(unscribe.value),
              unit: 's',
            }),
        ),
      );

      localStorage.setItem(
        `${POPUP_STORAGE_KEY}-${me?.companyId}-${me?.id}`,
        JSON.stringify(updatedUnscribes),
      );

      return announcementList.filter(
        (announcement) =>
          !updatedUnscribes.find(
            (unscribe) => Number(unscribe.key) === announcement.id,
          ),
      );
    }
    return [];
  };

  useEffect(() => {
    if (!me || !data) return;
    if (!isLoaded.current) {
      const filteredAnnouncementList = filteringAnnouncementList();
      setUsersAnnouncementList(filteredAnnouncementList);
      if (filteredAnnouncementList.length > 0) {
        setOpen(true);
      }
      isLoaded.current = true;
    }
  }, [data]);

  if (!me) {
    return <></>;
  }

  if (error) {
    console.log('Get PopupAnnouncementList Error > ', error);
    return <>{children}</>;
  }

  return (
    <>
      {usersAnnouncementList && usersAnnouncementList.length > 0 && (
        <PopupAnnouncementList
          previewMode={false}
          open={open}
          setOpen={setOpen}
          list={usersAnnouncementList}
          setList={setUsersAnnouncementList}
        />
      )}
      {children}
    </>
  );
}

export default Provider;
