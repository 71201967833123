import { fetch } from '@dop-ui/react/shared/lib/fetch/client';

interface Response {
  code: string;
  name: string;
  message: string;
}

export async function deleteAppManagerDataInfo(managers: number[]) {
  const pathUrl = `/api/portal/setting/security/app-manager`;
  const resp = await fetch(pathUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: managers,
    }),
  });

  if (!resp.ok) {
    throw new Error(((await resp.json()) as Response).message);
  }
}

export async function deleteTableDataInfo(
  companyAppId: string,
  managers: number[],
) {
  const pathUrl = `/api/portal/setting/security/app-manager/app/${companyAppId}`;
  const resp = await fetch(pathUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: managers,
    }),
  });

  if (!resp.ok) {
    throw new Error(((await resp.json()) as Response).message);
  }
}

export async function deleteMasterDataInfo(managers: number[]) {
  const pathUrl = `/api/portal/admin/security/master`;
  const resp = await fetch(pathUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: managers,
    }),
  });

  if (!resp.ok) {
    throw new Error(((await resp.json()) as Response).message);
  }
}
