import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { QUERY_KEY, getOrgCodeList } from '../../apis/get-org-code';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';

interface AdditionalClassProps {
  id: number;
  name: string;
}

interface Props {
  selectedSiteId: number;
  selectedClassName: string;
  selectedAdditionalClass: AdditionalClassProps;
  setSelectedAdditionalClass: (newClass: AdditionalClassProps) => void;
}

export function AdditionalClassSelect({
  selectedSiteId,
  selectedClassName,
  selectedAdditionalClass,
  setSelectedAdditionalClass,
}: Props) {
  const { t } = useTranslation('component');

  const { data: organizationCodes, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY, selectedSiteId, selectedClassName],
    queryFn: async () =>
      await getOrgCodeList(selectedSiteId, selectedClassName),
  });

  useEffect(() => {
    setSelectedAdditionalClass(
      organizationCodes[0]
        ? { id: organizationCodes[0].id, name: organizationCodes[0].name }
        : {
            id: -1,
            name: '',
          },
    );
  }, [organizationCodes]);

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > BasicInfo : ',
      error,
    );
  }

  return (
    <div className="flex items-center gap-2 min-w-[120px]">
      <select
        className={clsx(
          'h-[32px] w-[180px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]',
          {
            'bg-[#F2F2F2] border-none text-[#808080]':
              !organizationCodes || organizationCodes?.length <= 0,
          },
        )}
        value={selectedAdditionalClass.id}
        onChange={(e) => {
          const selectedId = Number(e.target.value);
          const selectedItem = organizationCodes.find(
            (item) => item.id === selectedId,
          );

          if (selectedItem) {
            setSelectedAdditionalClass({
              id: selectedItem.id,
              name: selectedItem.name,
            });
          }
        }}
      >
        {organizationCodes && organizationCodes.length > 0 ? (
          organizationCodes.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))
        ) : (
          <option key="option-no-data" value="no-data">
            {t(
              'globalconfig.multiCompanyManagement.appShare.classManagement.category.subClass.noData',
            )}
          </option>
        )}
      </select>
    </div>
  );
}

export default AdditionalClassSelect;
