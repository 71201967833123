import { PropsWithChildren } from 'react';

import styles from '../../popup-announcement.module.css';

interface Props {
  title: string;
  description?: string;
}
function FormField({ title, description, children }: PropsWithChildren<Props>) {
  return (
    <div className={styles.Form}>
      <p className={styles.FormTitle}>{title}</p>
      <div className={styles.FormContent}>{children}</div>
    </div>
  );
}

export default FormField;
