import { PropsWithChildren } from 'react';

interface Props {
  title: string;
  isRequired?: boolean;
}

export function Title({
  title,
  isRequired = false,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className="w-[120px] flex">
      <p className="relative text-[#363636] //text-[--color-text-level1] text-[14px] font-[500] opacity-80 leading-[40px]">
        {isRequired && (
          <span className="absolute size-[7px] top-[1px] left-[-10px] text-[#E84B4B] //text-[--color-text-caution] font-[400] opacity-100">
            *
          </span>
        )}
        {title}
      </p>
      {children}
    </div>
  );
}
