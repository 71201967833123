import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useSetAtom } from 'jotai';
import { USAGE_CURRENT_PATH } from '../../../../../menus/dataManagement/paths';
import { setMenuByPathAtom } from '../../../../../store/setMenuByPathAtom';

export interface Props {
  onSyncMemberInfo: () => void;
}

export function Header({ onSyncMemberInfo }: Props) {
  const { t } = useTranslation('component');
  const setMenuByPath = useSetAtom(setMenuByPathAtom);
  const isCustomerPortalManager = true;

  // 용량 사용 현황 버튼 클릭 시, 용량관리 > 용량 사용 현황으로 화면 전환
  const onClickStorageUsageDetailHandler = () =>
    setMenuByPath(USAGE_CURRENT_PATH);

  // 고객포털 계약자, 주문 담당자 등록된 데이터 관리자인지 확인 용량 구매하기 버튼 클릭 시, 고객포털 주문 페이지로 연결.
  // TODO: 고객 포털 자동 로그인 기능 추가 예정.
  const onClickStorageBuyStorage = () => {
    window.open('https://my.daouoffice.com/login');
  };

  return (
    <div className="flex items-center justify-between">
      <h2 className="font-[600] -tracking-[0.88px] text-[22px] text-[#333333]">
        {t('globalconfig.dataManagement.memberManagement.info')}
      </h2>
      <div className="flex items-center gap-2">
        <Button
          shape="rect"
          variant="outline"
          size="sm"
          colorset="level2"
          onClick={() => onSyncMemberInfo && onSyncMemberInfo()}
        >
          <ArrowPathIcon className="size-4 stroke-[#383838]" />
          {t(
            'globalconfig.dataManagement.memberManagement.button.storageusage.sync',
          )}
        </Button>
        <Button
          shape="rect"
          variant="outline"
          size="sm"
          colorset="level2"
          onClick={onClickStorageUsageDetailHandler}
        >
          {t(
            'globalconfig.dataManagement.memberManagement.storageusage.detail',
          )}
          <ChevronRightIcon className="size-4" />
        </Button>
        {isCustomerPortalManager && (
          <Button
            shape="rect"
            variant="outline"
            size="sm"
            colorset="level2"
            onClick={onClickStorageBuyStorage}
          >
            {t('globalconfig.dataManagement.memberManagement.storageusage.buy')}
            <ChevronRightIcon className="size-4" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default Header;
