import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from '@dop-ui/react/shared/ui/button';
import { CloseIcon, PlusIcon } from '@dop-ui/icons/react/dop/24';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  byteToMegabyte,
  megabyteToByte,
} from '../../../utils/byteConvertUtils';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import * as uploadPolicyApi from './apis/uploadPolicy';
import {
  newBlackListAndDuplicatedListInfo,
  oneDeletedBlacklist,
  removeDuplicates,
} from './utils';
import { BatchDialog } from './BatchDialog';
import { ExtensionInfo, Policy, StoragePolicyInfo } from './types';
import { NoSymbolIcon } from '@daouoffice/ui/lib/icons/heroicons/24/outline';

export function UploadPolicyManagement() {
  const { t } = useTranslation('component');

  const { data: policyData, error } = useQuery({
    queryKey: [uploadPolicyApi.QUERY_KEY],
    queryFn: uploadPolicyApi.getUploadPolicy,
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > DataManagement > UploadPolicyManagement',
      error,
    );
  }

  const [needsSave, setNeedsSave] = useAtom(isContentNeedsSaveAtom);

  const [currentStorages, setCurrentStorages] = useState<StoragePolicyInfo[]>(
    [],
  );
  const [isStoragesHaveChange, setIsStoragesHaveChange] = useState(false);

  const [currentMaxAttachSize, setCurrentMaxAttachSize] = useState(100);
  const [maxSizeOption, setMaxSizeOption] = useState('100');
  const [isVisibleAttachSizeSelfInput, setIsVisibleAttachSizeSelfInput] =
    useState(false);
  const [isMaxAttachSizeDirty, setIsMaxAttachSizeDirty] = useState(false);

  const [selectedStorageId, setSelectedStorageId] = useState<
    number | undefined
  >();
  const [selectedStorageBlackList, setSelectedStorageBlackList] = useState<
    ExtensionInfo[]
  >([]);
  const [blacklistText, setBlacklistText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const queryClient = useQueryClient();

  const toaster = useToastMessage();

  const saveMutation = useMutation({
    mutationFn: async (policy: Policy) =>
      uploadPolicyApi.setUploadPolicy(policy),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [uploadPolicyApi.QUERY_KEY],
      });
      toaster.info(t('organizer.memo.toast.saved'));
      setNeedsSave(false);
      setIsStoragesHaveChange(false);
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const setPolicyData = useCallback((policyData: Policy) => {
    setCurrentStorages(policyData.extensionTargetStorageList);
    setCurrentMaxAttachSize(policyData.maxAttachSize);
    const maxSize = byteToMegabyte(policyData.maxAttachSize);
    if (maxSize === 100 || maxSize === 500 || maxSize === 1024) {
      setMaxSizeOption(policyData.maxAttachSize.toString());
      setIsVisibleAttachSizeSelfInput(false);
    } else {
      setMaxSizeOption(attachSizeSelfInputKey);
      setIsVisibleAttachSizeSelfInput(true);
    }
    setIsStoragesHaveChange(false);
    setIsMaxAttachSizeDirty(false);
  }, []);

  useEffect(() => {
    if (policyData) {
      setPolicyData(policyData);
      if (selectedStorageId !== undefined) {
        return;
      }
      const defaultStorages = policyData.extensionTargetStorageList.filter(
        (storage) => storage.defaultApp,
      );
      if (defaultStorages.length === 0) {
        const nonDefaultStorages = policyData.extensionTargetStorageList.filter(
          (storage) => !storage.defaultApp,
        );
        setSelectedStorageId(nonDefaultStorages[0]?.storageInfoId);
        setSelectedStorageBlackList(
          nonDefaultStorages[0]?.extensionBlacklist ?? [],
        );
      } else {
        setSelectedStorageId(defaultStorages[0]?.storageInfoId);
        setSelectedStorageBlackList(
          defaultStorages[0]?.extensionBlacklist ?? [],
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyData, setPolicyData]);

  useEffect(() => {
    if (selectedStorageId) {
      const storage = currentStorages.find(
        (info) => info.storageInfoId === selectedStorageId,
      );
      if (storage) {
        setSelectedStorageBlackList(storage.extensionBlacklist);
      } else {
        setSelectedStorageBlackList([]);
      }
    }
  }, [currentStorages, selectedStorageId]);

  function applyStorageDiff(newStorages: StoragePolicyInfo[]) {
    setBlacklistText('');
    setCurrentStorages(newStorages);
    setIsStoragesHaveChange(true);
  }

  const onChangeBlackList = (text: string) => {
    if (text.match(/[^,a-zA-Z0-9]/g)) {
      setErrorMessage(
        t(
          'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.notAllowCharacter',
        ),
      );
    } else if (text.length > 64) {
      setErrorMessage(
        t(
          'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.length',
        ),
      );
    } else {
      setErrorMessage('');
    }
    setBlacklistText(text.replace(/[^,a-zA-Z0-9]/g, ''));
  };

  const onClickStorage = (storageId: number) => {
    setSelectedStorageId(storageId);
  };

  const onDeleteBlackList = (info: ExtensionInfo) => {
    const newStorages = currentStorages.map((storage) => {
      if (storage.storageInfoId === selectedStorageId) {
        const newList = oneDeletedBlacklist(storage.extensionBlacklist, info);
        return {
          ...storage,
          extensionBlacklist: newList,
        };
      }
      return storage;
    });
    applyStorageDiff(newStorages);
    setNeedsSave(true);
  };

  const onClickAddBlackList = () => {
    if (blacklistText.length > 0 && blacklistText.length <= 64) {
      setNeedsSave(true);
      const blackList = blacklistText
        .split(',')
        .filter((extensionName) => extensionName.trim().length > 0)
        .map((self) => self.toLowerCase());

      const duplicatesRemovedList = removeDuplicates(blackList).map((info) => ({
        extensionName: info,
      }));

      const newStorages = currentStorages.map((storage) => {
        if (storage.storageInfoId === selectedStorageId) {
          const listInfo = newBlackListAndDuplicatedListInfo(
            policyData?.defaultExtensionBlacklist || [],
            storage.extensionBlacklist,
            duplicatesRemovedList,
          );

          if (listInfo.notAdded.length > 0) {
            toaster.info(
              listInfo.notAdded.join(', ') +
                t(
                  'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.basicList',
                ),
            );
          }

          if (listInfo.alreadyAdded.length > 0) {
            toaster.info(
              listInfo.alreadyAdded.join(', ') +
                t(
                  'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.duplicate',
                ),
            );
          }

          return {
            ...storage,
            extensionBlacklist: listInfo.resultList,
          };
        }
        return storage;
      });

      applyStorageDiff(newStorages);
    } else {
      t(
        'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.error.length',
      );
    }
  };

  const attachSizeSelfInputKey = 'attachSize.selfInput';

  const onSelectSizeLimit = (sizeLimit: string) => {
    setMaxSizeOption(sizeLimit);
    if (sizeLimit === attachSizeSelfInputKey) {
      setIsVisibleAttachSizeSelfInput(true);
    } else {
      setCurrentMaxAttachSize(Number(sizeLimit));
      setIsVisibleAttachSizeSelfInput(false);
    }
    setIsMaxAttachSizeDirty(policyData?.maxAttachSize !== Number(sizeLimit));
    setNeedsSave(true);
  };

  const onChangeSizeLimit = (size: number) => {
    if (size <= 0) {
      setCurrentMaxAttachSize(megabyteToByte(1));
    } else if (size > 1024) {
      setCurrentMaxAttachSize(megabyteToByte(1024));
    } else {
      setCurrentMaxAttachSize(megabyteToByte(size));
    }
    setIsMaxAttachSizeDirty(policyData?.maxAttachSize !== size);
    setNeedsSave(true);
  };

  const onClickSave = () => {
    saveMutation.mutate({
      defaultExtensionBlacklist: policyData?.defaultExtensionBlacklist || [],
      extensionTargetStorageList: currentStorages,
      maxAttachCount: 0,
      maxAttachSize: currentMaxAttachSize,
    });
  };

  const onClickCancel = () => {
    if (policyData) {
      setPolicyData(policyData);
    }

    setNeedsSave(false);
  };

  const onClickBatchApply = (blackList: string[]) => {
    const newStorages = currentStorages.map((storage) => {
      const { resultList } = newBlackListAndDuplicatedListInfo(
        policyData?.defaultExtensionBlacklist || [],
        storage.extensionBlacklist,
        blackList.map((info) => ({ extensionName: info })),
      );
      return {
        ...storage,
        extensionBlacklist: resultList,
      };
    });
    applyStorageDiff(newStorages);
  };

  const ExtensionList = ({ list }: { list: StoragePolicyInfo[] }) => {
    return (
      <div className="w-full flex flex-col">
        {list &&
          list.map((policy) => {
            return (
              <button
                key={policy.storageInfoId}
                className={clsx(
                  'flex items-center justify-start gap-[8px] w-full px-[8px] h-[32px] text-start rounded-[8px] hover:bg-[#F2F2F2]',
                  {
                    'bg-[#F2F2F2]': selectedStorageId === policy.storageInfoId,
                  },
                )}
                onClick={() => onClickStorage(policy.storageInfoId)}
              >
                {policy.appIcon ? (
                  <i
                    className="size-[20px]"
                    dangerouslySetInnerHTML={{
                      __html: policy.appIcon.replace(
                        'width="24" height="24"',
                        'width="20" height="20"',
                      ),
                    }}
                  />
                ) : (
                  <NoSymbolIcon className="size-[20px]" />
                )}
                <p>{policy.storageName}</p>
              </button>
            );
          })}
      </div>
    );
  };

  return (
    <div className="mx-[72px] my-[40px] overflow-y-scroll">
      <div className="p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
        <p className="text-[#363636] //text-[--color-text-level1 text-[20px] font-[600]">
          {t(
            'globalconfig.dataManagement.uploadPolicyManagement.attatchedfile.set',
          )}
        </p>
        <div className="mt-[24px] py-[24px] flex flex-wrap items-center gap-[8px]">
          <p className="text-[16px] text-[#111111] font-[500] min-w-[240px]">
            {t(
              'globalconfig.dataManagement.uploadPolicyManagement.attatchedfile.storageLimit.title',
            )}
          </p>
          <p className="text-[14px] text-[#999999] //text-[--color-text-level3] flex-grow">
            {t(
              'globalconfig.dataManagement.uploadPolicyManagement.attatchedfile.storageLimit.description',
            )}
          </p>
          <div className="flex items-center gap-[6px]">
            <p>
              {t(
                'globalconfig.dataManagement.uploadPolicyManagement.attatchedfile.oneFile',
              )}
            </p>
            <select
              className="px-[8px] h-[40px] rounded-[8px] min-w-[90px]"
              value={maxSizeOption}
              onChange={(e) => onSelectSizeLimit(e.target.value)}
            >
              <option value={megabyteToByte(100)}>100MB</option>
              <option value={megabyteToByte(500)}>500MB</option>
              <option value={megabyteToByte(1024)}>1GB</option>
              <option value={attachSizeSelfInputKey}>
                {t(
                  'globalconfig.dataManagement.setNotification.standard.selfInput',
                )}
              </option>
            </select>
            {isVisibleAttachSizeSelfInput && (
              <>
                <div className="flex items-center px-[8px] w-[60px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px]">
                  <input
                    className="w-full"
                    value={byteToMegabyte(currentMaxAttachSize)}
                    type="number"
                    onChange={(e) => onChangeSizeLimit(Number(e.target.value))}
                  />
                </div>
                <span className="text-[14px] text-[#333333] font-[400]">
                  MB
                </span>
              </>
            )}
            <p>
              {t(
                'globalconfig.dataManagement.uploadPolicyManagement.attatchedfile.under',
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="relative mt-[32px] p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
        <BatchDialog
          defaultBlackList={policyData?.defaultExtensionBlacklist ?? []}
          onClickApply={onClickBatchApply}
        />
        <p className="text-[#363636] //text-[--color-text-level1 text-[20px] font-[600]">
          {t(
            'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.title',
          )}
        </p>
        <p className="mt-[8px] text-[14px] text-[#999999] //text-[--color-text-level3] whitespace-pre-wrap">
          {t(
            'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.description',
          )}
        </p>
        <div className="flex mt-[32px] min-h-[720px] overflow-y-scroll gap-[24px]">
          <div className="py-[8px] pr-[8px] w-[260px] flex-shrink-0">
            <ExtensionList
              list={currentStorages.filter((info) => info.defaultApp)}
            />
            <div className="my-[4px] w-full h-[1px] bg-[#CECECE]" />
            <ExtensionList
              list={currentStorages.filter((info) => !info.defaultApp)}
            />
          </div>
          <div className="flex flex-col pl-[28px] border-l border-solid border-[#D8D8D8] flex-grow">
            <div className="flex-shrink-0 min-h-[48px] pb-[12px] border-b border-solid border-[#D8D8D8]">
              <div className="flex items-center gap-[24px] h-[48px]">
                <p className="w-[178px] text-[#363636] //text-[--color-text-level1 text-[16px] font-[500] flex-shrink-0">
                  {t(
                    'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.add',
                  )}
                </p>
                <input
                  className="px-[8px] w-full h-[40px] border border-solid border-[#D8D8D8] rounded-[8px] disabled:opacity-20"
                  placeholder={t(
                    'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.placeholder',
                  )}
                  disabled={!selectedStorageId}
                  value={blacklistText}
                  onChange={(e) => onChangeBlackList(e.target.value)}
                />
                <Button
                  shape="rect"
                  variant="outline"
                  colorset="level1"
                  size="medium"
                  onClick={onClickAddBlackList}
                  disabled={
                    blacklistText.length === 0 || blacklistText.length > 64
                  }
                >
                  <PlusIcon className="size-[20px] mr-[8px]" />
                  {t('globalconfig.common.add')}
                </Button>
              </div>
              {errorMessage && (
                <p className="ml-[202px] text-[red]">{errorMessage}</p>
              )}
            </div>
            <div className="flex flex-wrap mt-[12px] gap-[4px]">
              {selectedStorageBlackList &&
                selectedStorageBlackList.map((info) => {
                  return (
                    <p
                      key={info.extensionId || info.extensionName}
                      className="min-w-[86px] px-[12px] border border-solid border-[#D8D8D8] h-[32px] rounded-full flex items-center"
                    >
                      <span className="flex-grow">{info.extensionName}</span>
                      <Button
                        className="ml-[8px] size-[18px] p-[3px]"
                        onClick={() => onDeleteBlackList(info)}
                      >
                        <CloseIcon className="size-[12px]" />
                      </Button>
                    </p>
                  );
                })}
            </div>
            <div className="flex-grow my-[8px]" />
            <div className="px-[12px] py-[8px] bg-[#F9FBFC] rounded-[8px]">
              <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[600]">
                {t(
                  'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.basicList.title',
                )}
              </p>
              <p className="text-[#999999] //text-[--color-text-level3] text-[14px] font-[400]">
                {t(
                  'globalconfig.dataManagement.uploadPolicyManagement.extensionLimit.basicList.description',
                )}
              </p>
              <div className="mt-[8px] text-[#363636] //text-[--color-text-level1] text-[14px]">
                {policyData?.defaultExtensionBlacklist &&
                  policyData.defaultExtensionBlacklist.join(', ')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
        <Button
          colorset="minor"
          shape="rect"
          variant="outline"
          size="medium"
          onClick={onClickCancel}
        >
          {t('dialog.cancel')}
        </Button>
        <Button
          colorset="major"
          shape="rect"
          variant="solid"
          size="medium"
          disabled={
            !(isStoragesHaveChange || isMaxAttachSizeDirty || needsSave)
          }
          onClick={onClickSave}
        >
          {t('dialog.save')}
        </Button>
      </div>
    </div>
  );
}
