import { Switch } from '@daouoffice/ui';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { ExclamationCircleIcon } from '@dop-ui/icons/react/dop/16';
import { TrashCanIcon } from '@dop-ui/icons/react/dop/24';
import { useSessionContext } from '@dop-ui/react/features/authenticate';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip/dop-tooltip';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { createCustomProfile } from '../../../apis/create-custom-profile';
import { deleteCustomProfile } from '../../../apis/delete-custom-profile';
import * as getCustomProfileDetail from '../../../apis/get-custom-profile-detail';
import * as getCustomProfileList from '../../../apis/get-custom-profile-list';
import * as getProfileCardConfig from '../../../apis/get-profile-card-config';
import { updateCustomProfile } from '../../../apis/update-custom-profile';
import { useProfileManagementContext } from '../../../context';
import { isCustomProfileNeedsAtom } from '../../../store/is-custom-profile-needs-save-atom';
import {
  CustomInputName,
  CustomProfileDetailInfo,
  defaultCustomProfileDetail,
} from '../../../type';
import { localeMap } from '../../../utils';
import { InputComponent } from './input-component';

export function CustomItems() {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const toaster = useToastMessage();
  const queryClient = useQueryClient();
  const stacker = useStackMethod();

  const { me } = useSessionContext();

  const {
    profileManagementContext: { profileId },
  } = useProfileManagementContext();

  const [isCustomProfileNeedsSave, setIsCustomProfileNeedsSave] = useAtom(
    isCustomProfileNeedsAtom,
  );

  const [customProfileConfig, setCustomProfileConfig] =
    useState<CustomProfileDetailInfo>(defaultCustomProfileDetail);

  const {
    activate,
    multiLanguageValueMap: {
      KOREAN,
      ENGLISH,
      JAPANESE,
      CHINESE_SIMPLIFIED,
      CHINESE_TRADITIONAL,
      VIETNAMESE,
    },
    autoSort,
    customProfileCardItemType,
  } = customProfileConfig;

  const { data: customProfileDetail, error } = useQuery({
    queryKey: [getCustomProfileDetail.QUERY_KEY, profileId],
    queryFn: async () =>
      await getCustomProfileDetail.getCustomProfileDetail(profileId!),
    enabled: profileId !== null,
  });

  const initializeEditCustomDetail = () => {
    if (customProfileDetail) {
      setCustomProfileConfig(customProfileDetail);
    }
  };

  useEffect(() => {
    initializeEditCustomDetail();
  }, [customProfileDetail]);

  const createCustomProfileMutation = useMutation({
    mutationFn: async () => await createCustomProfile(customProfileConfig),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getCustomProfileList.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      stacker.pop();
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  const deleteCustomProfileMutation = useMutation({
    mutationFn: async (profileId: number) =>
      await deleteCustomProfile(profileId),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getCustomProfileList.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.deleted'));
      stacker.pop();
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  const updateCustomProfileMutation = useMutation({
    mutationFn: async () =>
      await updateCustomProfile({
        id: profileId!,
        ...customProfileConfig,
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getCustomProfileList.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getCustomProfileDetail.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      stacker.pop();
    },
    onError: (e) => {
      toaster.warning(t('globalconfig.common.toastMessage.fail'));
      console.log(e);
    },
  });

  useEffect(() => {
    if (profileId) {
      if (
        JSON.stringify(customProfileConfig) ===
        JSON.stringify(customProfileDetail)
      ) {
        setIsCustomProfileNeedsSave(false);
      } else {
        setIsCustomProfileNeedsSave(true);
      }
    } else {
      if (
        customProfileConfig.multiLanguageValueMap[
          localeMap[me?.locale ?? 'ko_KR'] as CustomInputName
        ] === ''
      ) {
        setIsCustomProfileNeedsSave(false);
      } else {
        setIsCustomProfileNeedsSave(true);
      }
    }
  }, [customProfileConfig]);

  const handleClickCancle = () => {
    if (customProfileDetail) {
      initializeEditCustomDetail();
    } else {
      setCustomProfileConfig(defaultCustomProfileDetail);
    }
    setIsCustomProfileNeedsSave(false);
  };

  const handleClickSave = () => {
    if (profileId) {
      updateCustomProfileMutation.mutate();
    } else {
      createCustomProfileMutation.mutate();
    }
  };

  const handleClickDelete = async (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    if (
      await confirm(
        <div>
          <h2 className="pb-[8px]">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete.title',
            )}
          </h2>
          <p>
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete.description1',
            )}
          </p>
          <p>
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete.description2',
            )}
          </p>
        </div>,
      )
    ) {
      deleteCustomProfileMutation.mutate(profileId!);
    }
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > BasicManagement > ProfileConfig > CustomProfile > Detail: ',
      error,
    );
  }

  if (!me) {
    return;
  }

  return (
    <div className="flex flex-col mt-[24px] gap-[16px]">
      {profileId !== null && (
        <div className="flex justify-end items-center">
          <Button
            className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded"
            onClick={(e) => {
              void handleClickDelete(e);
            }}
          >
            <TrashCanIcon size={24} />
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.delete',
            )}
          </Button>
        </div>
      )}
      <ul className="flex flex-col gap-[8px]">
        <li className="flex items-center gap-[16px] min-h-[40px] py-[8px]">
          <p className="w-[200px] font-medium">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.isUsed',
            )}
          </p>
          <Switch
            id="profile.management.detail.switch"
            checked={activate}
            onChange={() => {
              setCustomProfileConfig((prev) => ({
                ...prev,
                activate: !activate,
              }));
            }}
          />
        </li>
        <InputComponent
          item={KOREAN}
          name="KOREAN"
          setCustomProfileConfig={setCustomProfileConfig}
        />
        <InputComponent
          item={ENGLISH!}
          name="ENGLISH"
          setCustomProfileConfig={setCustomProfileConfig}
        />
        <InputComponent
          item={JAPANESE!}
          name="JAPANESE"
          setCustomProfileConfig={setCustomProfileConfig}
        />
        <InputComponent
          item={CHINESE_SIMPLIFIED!}
          name="CHINESE_SIMPLIFIED"
          setCustomProfileConfig={setCustomProfileConfig}
        />
        <InputComponent
          item={CHINESE_TRADITIONAL!}
          name="CHINESE_TRADITIONAL"
          setCustomProfileConfig={setCustomProfileConfig}
        />
        <InputComponent
          item={VIETNAMESE!}
          name="VIETNAMESE"
          setCustomProfileConfig={setCustomProfileConfig}
        />
        <li className="flex items-center gap-[16px] min-h-[40px] py-[8px]">
          <div className="flex items-center w-[200px]">
            <p className="font-medium">
              {t(
                'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.autoSortDeptMembers',
              )}
            </p>
            <ToolTip.Root>
              <ToolTip.Trigger>
                <ExclamationCircleIcon
                  className="size-[16px] ml-[4px] text-[#AAAAAA]"
                  size={16}
                />
              </ToolTip.Trigger>
              <ToolTip.Content className="flex flex-col !items-start !max-w-full z-[1000]">
                <p>
                  {t(
                    'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.autoSortDeptMembers.tooltip',
                  )}
                </p>
              </ToolTip.Content>
            </ToolTip.Root>
          </div>
          <div className="flex items-center gap-2">
            <input
              className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              id={'autoSortYes'}
              name="autoSort"
              value={'YES'}
              checked={autoSort}
              onChange={(e) => {
                if (e.target.checked) {
                  setCustomProfileConfig((prev) => ({
                    ...prev,
                    autoSort: true,
                  }));
                }
              }}
            />
            <label htmlFor={'autoSortYes'}>
              {t(
                'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.autoSortDeptMembers.yes',
              )}
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              id={'autoSortNo'}
              name="autoSort"
              value={'NO'}
              checked={!autoSort}
              onChange={(e) => {
                if (e.target.checked) {
                  setCustomProfileConfig((prev) => ({
                    ...prev,
                    autoSort: false,
                  }));
                }
              }}
            />
            <label htmlFor={'autoSortNo'}>
              {t(
                'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.autoSortDeptMembers.no',
              )}
            </label>
          </div>
        </li>
        <li className="flex items-center gap-[16px] min-h-[40px] py-[8px]">
          <p className="w-[200px] font-medium">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.dataType',
            )}
          </p>
          <div className="flex items-center gap-2 min-w-[120px]">
            <select
              className="h-[32px] w-[180px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
              value={customProfileCardItemType}
              onChange={(e) => {
                setCustomProfileConfig((prev) => ({
                  ...prev,
                  customProfileCardItemType: e.target.value,
                }));
              }}
            >
              <option value="TEXT">
                {t(
                  'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.dataType.text',
                )}
              </option>
              <option value="DATE">
                {t(
                  'globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.dataType.date',
                )}
              </option>
            </select>
          </div>
        </li>
      </ul>
      <div className="mt-[50px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          shape="rect"
          variant="outline"
          colorset="level2"
          onClick={handleClickCancle}
        >
          {t('dialog.cancel')}
        </Button>
        <Button
          size="medium"
          shape="rect"
          variant="solid"
          colorset="level1"
          disabled={
            !isCustomProfileNeedsSave ||
            customProfileConfig.multiLanguageValueMap[
              localeMap[me.locale]! as CustomInputName
            ] === ''
          }
          onClick={handleClickSave}
        >
          {t('dialog.save')}
        </Button>
      </div>
    </div>
  );
}
export default CustomItems;
