import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { TagType } from '../check-box';

export interface Props {
  /** @property tagType 값 (기본값: 0) */
  as?: TagType;
  /** @property tab-index 값 (기본값: 0) */
  tabIndex?: number;
  /** @property className */
  className?: string;
  /** aria-checked 속성. Variation 확장용 */
  ariaChecked?: boolean;
  /** aria-disabled 속성. Variation 확장용 */
  ariaDisabledBy?: boolean;
  /** aria-required 속성. Variation 확장용 */
  ariaRequiredBy?: boolean;
}

export function Root({
  as = 'div',
  tabIndex = 0,
  className,
  children,
}: PropsWithChildren<Props>) {
  const Comp = as;

  return (
    <Comp className={clsx('dop_checkbox', className)} tabIndex={tabIndex}>
      {children}
    </Comp>
  );
}

export default Root;
