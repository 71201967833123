import { useQuery } from '@tanstack/react-query';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as loginSecureConfigAPIs from './apis/loginSecureConfig';
import { TitleAndDescription } from './TitleAndDescription';
import { InUseIndicator } from './InUseIndicator';

export function OverseasLoginRestrictionIndicator() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();
  const {
    data: configData,
    error,
    isLoading,
  } = useQuery({
    queryKey: [loginSecureConfigAPIs.QUERY_KEY_OVERSEAS_LOGIN],
    queryFn: loginSecureConfigAPIs.getOverseasLoginConfig,
  });

  if (error) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > AccessIpConfig Error: ',
      error,
    );
  }

  if (isLoading || !configData)
    return (
      <TitleAndDescription
        title={t(
          'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.title',
        )}
        description={t(
          'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.description',
        )}
      />
    );

  return (
    <TitleAndDescription
      title={t(
        'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.title',
      )}
      description={t(
        'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.description',
      )}
    >
      <InUseIndicator inUse={configData.isBlockAbroadLogin} />
      <Button onClick={() => stacker.push('overseasLogin')}>
        <ChevronRightIcon className="size-[24px]" />
      </Button>
    </TitleAndDescription>
  );
}
