import { IconContainer } from '@dop-ui/icons/react/dop/IconContainer';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef } from 'react';
import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';
import { postSimpleCode } from '../api/postSimpleCode';
import { SimpleCodeFormPrimitives } from '../index';
import { clientType, ResponseBody } from '../types';
import { getOnlyNumber, isContinuousNumbers, isSameNumbers } from '../utils';
import { useActionsContext, useValuesContext } from './primitives/provider';

export interface Props {
  /** @property title 텍스트 */
  title?: string;
  /** @property className */
  className?: string;
  /** @property type */
  type?: clientType;
  /** @property onSuccess 함수 */
  onSuccess?: () => void;
}

export function SimpleCodeRegister({ className, type, onSuccess }: Props) {
  const { t } = useTranslation('component');
  const { simpleCode, simpleCodeCheck, errorMessage } = useValuesContext();
  const { setSimpleCode, setSimpleCodeCheck, setErrorMessage } =
    useActionsContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const enterKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && registerHandler();
  };

  const registerHandler = () => {
    checkIsValidSimpleCode();
  };

  const checkIsValidSimpleCode = () => {
    if (
      simpleCode === simpleCodeCheck &&
      simpleCode.length === 4 &&
      simpleCode.length === 4
    ) {
      handleSimpleCode();
    } else if (simpleCode.length === 0) {
      setErrorMessage(t('simplecode.empty.regist.password'));
    } else if (simpleCodeCheck.length === 0) {
      setErrorMessage(t('simplecode.empty.regist.password.check'));
    } else setErrorMessage(t('simplecode.error.invalid.number'));
  };

  const handleSimpleCode = () => {
    if (isSameNumbers(simpleCode)) {
      setErrorMessage(`'${simpleCode}' ` + t('simplecode.error.same.number'));
    } else if (isContinuousNumbers(simpleCode)) {
      setErrorMessage(
        `'${simpleCode}' ` + t('simplecode.error.continuous.number'),
      );
    } else {
      registerSimpleCode();
    }
  };

  const registerSimpleCode = () => {
    void postSimpleCode(simpleCode, simpleCodeCheck)
      .then((res: ResponseBody<string>) => {
        setErrorMessage(res?.message ?? '');
        res?.data && onSuccessHandler();
      })
      .catch((e: Error) => {
        setErrorMessage(e.message);
      });
  };

  const onSuccessHandler = () => {
    setSimpleCode('');
    onSuccess && onSuccess();
  };

  const setErrorMessageByTextChanged = (text: string) => {
    text.length > 4
      ? setErrorMessage(t('simplecode.error.max.length'))
      : isNaN(Number(text))
        ? setErrorMessage(t('simplecode.error.not.number'))
        : setErrorMessage('');
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <SimpleCodeFormPrimitives.Contents className={className}>
      {type === 'chat' && (
        <>
          <SimpleCodeFormPrimitives.Title
            title={t('screenlock.title')}
            className="mb-4 text-3xl pb-4"
          />
          <IconContainer size={50}>
            <LockClosedIcon />
          </IconContainer>
        </>
      )}
      <SimpleCodeFormPrimitives.Title
        title={t('simplecode.register.title')}
        className="mb-4 pt-4"
      />
      <SimpleCodeFormPrimitives.InfoMessage
        message={t('simplecode.register.info.text')}
        className="mb-4"
      />
      <SimpleCodeFormPrimitives.TextField
        ref={inputRef}
        value={simpleCode}
        placeholder={t('simplecode.placeholder.simple.code')}
        className="mb-4"
        onTextChange={(text) => {
          setErrorMessageByTextChanged(text);
          text.length <= 4 && setSimpleCode(getOnlyNumber(text));
        }}
        onKeyPress={enterKeyHandler}
      />
      <SimpleCodeFormPrimitives.TextField
        value={simpleCodeCheck}
        placeholder={t('simplecode.placeholder.simple.code.check')}
        className="mb-4"
        onTextChange={(text) => {
          setErrorMessageByTextChanged(text);
          text.length <= 4 && setSimpleCodeCheck(getOnlyNumber(text));
        }}
        onKeyPress={enterKeyHandler}
      />
      <SimpleCodeFormPrimitives.ErrorMessage
        message={errorMessage ?? ''}
        className="mb-4"
      />
      <SimpleCodeFormPrimitives.ActionButton
        title={t('simplecode.register.button.title')}
        className="w-full"
        handleOnAction={registerHandler}
      />
    </SimpleCodeFormPrimitives.Contents>
  );
}

export default SimpleCodeRegister;
