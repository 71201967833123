import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as getCalenderShareList from './apis/get-calendar-share-list';
import { useAuthContext } from '../../../../../../../../lib/auth/client';
import { ShareConfigDialogButton } from './share-config-dialog-button';
import { useActionsContext, useValuesContext } from '../../provider';
import { deleteCalendarShareList } from './apis/delete-calendar-share-list';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { Share, ShareInfo } from '../../../types';
import { Direction } from './types';
import { UsePermission } from '../../common-table/use-permission';
import { ShareObject } from '../../common-table/share-object';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
} from '@dop-ui/icons/react/dop/24';

export function CalendarContents() {
  const { t } = useTranslation('component');
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const { companyList } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [direction, setDirection] = useState<Direction>('asc');
  const [selectedContents, setSelectedContents] = useState<ShareInfo[]>([]);

  const [shareConfigDialogOpenState, setShareConfigDialogOpenState] =
    useState<boolean>(false);
  const [displayCalendarShareList, setDisplayCalendarShareList] = useState<
    ShareInfo[]
  >([]);
  const [detailAppId, setDetailAppId] = useState<number | null>(null);

  const { me } = useAuthContext();

  const { data: calendarShareList, error } = useSuspenseQuery({
    queryKey: [getCalenderShareList.QUERY_KEY],
    queryFn: async () => {
      if (me?.companyGroupId === undefined) return [];
      return await getCalenderShareList.getCalenderShareList(
        me?.companyGroupId,
      );
    },
  });

  useEffect(() => {
    setPage(0);
  }, [size]);

  const totalPage = Math.ceil(calendarShareList.length / size);

  const deleteCalendarShareListMutation = useMutation({
    mutationFn: async () => {
      if (me?.companyGroupId === undefined) return [];
      await deleteCalendarShareList(me?.companyGroupId, selectedContents);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getCalenderShareList.QUERY_KEY],
      });
      toaster.info(t('globalconfig.common.toastMessage.deleted'));
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    const sortedList = [...calendarShareList].sort((a, b) => {
      return direction === 'asc'
        ? a.companyName.localeCompare(b.companyName)
        : b.companyName.localeCompare(a.companyName);
    });
    const startIndex = page * size;
    const endIndex = startIndex + size;
    setDisplayCalendarShareList(sortedList.slice(startIndex, endIndex));
  }, [calendarShareList, page, size, direction]);

  const SortChevron = () => {
    if (direction === 'asc')
      return <ChevronUpIcon className="inline ml-[8px] size-[16px]" />;
    else return <ChevronDownIcon className="inline ml-[8px] size-[16px]" />;
  };

  const columnDefs: ColumnDef<ShareInfo>[] = [
    {
      id: 'companyName',
      accessorKey: 'companyName',
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => handleClickSort()}
        >
          {t('globalconfig.multiCompanyManagement.appShare.calendar.siteName')}
          <SortChevron />
        </button>
      ),
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <button className="flex items-center px-[--Space-Small]">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.calendarName',
          )}
        </button>
      ),
    },
    {
      id: 'shares',
      accessorKey: 'shares',
      header: () => (
        <button className="flex items-center px-[--Space-Small]">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.shareTarget',
          )}
        </button>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as Share[];
        return <ShareObject shareTargetList={shareTargetList} />;
      },
    },
    {
      id: 'permission',
      accessorKey: 'shares',
      header: () => (
        <button className="flex items-center px-[--Space-Small]">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.userPermission',
          )}
        </button>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as Share[];
        return <UsePermission shareTargetList={shareTargetList} />;
      },
    },
  ];

  const handleClickSort = () => {
    setDirection(direction === 'asc' ? 'desc' : 'asc');
  };

  const handleClickDelete = () => {
    if (selectedContents && selectedContents.length > 0) {
      deleteCalendarShareListMutation.mutate();
    }
  };

  const handleClickRow = (index: number) => {
    if (displayCalendarShareList && displayCalendarShareList.length > index) {
      const selectedItem = displayCalendarShareList[index];

      if (selectedItem) {
        setSelectedCompany({
          companyId:
            companyList.find(
              (item) => item.companyUuid === selectedItem.companyUuid,
            )?.companyId ?? 0,
          companyName: selectedItem.companyName,
          companyUuid: selectedItem.companyUuid,
        });
        setDetailAppId(selectedItem.id);
        setShareConfigDialogOpenState(true);
      }
    }
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > AppShare > Calendar : ',
      error,
    );
  }

  if (!me) {
    return;
  }

  return (
    <TablePrimitives.Root<ShareInfo>
      className="mt-[24px]"
      columnDefs={columnDefs}
      contents={displayCalendarShareList ?? []}
      selectable
      onSelectContents={setSelectedContents}
      onClickRow={(index) => handleClickRow(index)}
    >
      <div className="flex mb-[16px]">
        <div className="spacer flex-grow" />
        <div className="flex justify-center items-center">
          <ShareConfigDialogButton
            calendarShareList={calendarShareList}
            shareConfigDialogOpenState={shareConfigDialogOpenState}
            detailAppId={detailAppId}
            onChangeDetailAppId={setDetailAppId}
            onChangeShareConfigDialogOpenState={setShareConfigDialogOpenState}
          />
          <Button
            className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded hover:bg-[#f2f2f2]"
            onClick={handleClickDelete}
          >
            <DeleteIcon size={24} />
            {t('globalconfig.multiCompanyManagement.appShare.calendar.delete')}
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[52px]"
            tableSize={size}
            options={[10, 20, 30, 40]}
            onChangeTableSize={setSize}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        emptyNotice={
          <div className="w-full h-[80px] flex items-center justify-center ">
            <span className="text-[--color-text-level3] text-[14px] font-[400]">
              {t(
                'globalconfig.multiCompanyManagement.appShare.calendar.noList',
              )}
            </span>
          </div>
        }
      ></TablePrimitives.Contents>
      <TablePrimitives.Pagination
        className="mt-[16px]"
        currentPage={page}
        totalPage={totalPage ?? 0}
        onNextPage={setPage}
        onPreviousPage={setPage}
        onClickPage={setPage}
        onFirstPage={() => setPage(0)}
        onLastPage={() => setPage(totalPage - 1 ?? 0)}
      />
    </TablePrimitives.Root>
  );
}

export default CalendarContents;
