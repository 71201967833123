'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useSessionContext } from '@dop-ui/react/features/authenticate';
import { CogIcon } from '@dop-ui/icons/react/dop/24/Cog';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { AppsDockConfigClient } from '../AppsDock/AppsDockConfig/AppsDockConfigClient';
import { FuncButton } from '../FuncButton';

export function AppsDockConfigButton() {
  const { t } = useTranslation();
  const {
    me: { enableGnbControl },
  } = useSessionContext();

  return (
    enableGnbControl && (
      <Dialog.Root modal={false}>
        <Dialog.Trigger>
          <FuncButton icon={CogIcon} label={t('메뉴 설정')} />
        </Dialog.Trigger>
        <Dialog.Content size="resizeable">
          <AppsDockConfigClient />
        </Dialog.Content>
      </Dialog.Root>
    )
  );
}

export default AppsDockConfigButton;
