import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { clsx } from 'clsx';
import { useState } from 'react';
import * as Progress from '@daouoffice/ui/lib/foundation/Progress';
import { useBasicInfoContext } from '../../Context';
import { ChevronDownIcon, PlusIcon } from '@dop-ui/icons/react/dop/24';

export function TotalAccount() {
  const { t } = useTranslation('component');

  const {
    basicInfoContext: { companyList },
  } = useBasicInfoContext();

  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  const usedAccount = companyList.reduce((usedAccount, company) => {
    return usedAccount + company.usedAccountCount;
  }, 0);
  const avalableAccount = companyList.reduce((usedAccount, company) => {
    return usedAccount + company.availableAccountCount;
  }, 0);
  const totalAccount = usedAccount + avalableAccount;

  const displayDetailList = isShowMore ? companyList : companyList.slice(0, 3);

  return (
    <div className="flex flex-col min-h-[40px] py-[12px] gap-3">
      <div className="flex items-center gap-[2px] h-[40px]">
        <p className="flex-shrink-0 w-[180px] font-medium text-[16px] text-[#999]">
          {t(
            'globalconfig.multiCompanyManagement.basicInfo.totalAccounts.title',
          )}
        </p>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <AccountSummary
              title={t(
                'globalconfig.multiCompanyManagement.basicInfo.totalAccounts.allAcounts',
              )}
              count={totalAccount}
            />
            <div className="w-[1px] h-[12px] bg-[#D8D8D8]"></div>
            <AccountSummary
              title={t(
                'globalconfig.multiCompanyManagement.basicInfo.totalAccounts.usingAcounts',
              )}
              count={usedAccount}
            />
            <div className="w-[1px] h-[12px] bg-[#D8D8D8]"></div>
            <AccountSummary
              title={t(
                'globalconfig.multiCompanyManagement.basicInfo.totalAccounts.availableAcounts',
              )}
              count={avalableAccount}
            />
          </div>
          <Button
            className="flex flex-shrink-0 justify-center items-center w-[20px] h-[20px] border border-solid border-[#D8D8D8] rounded"
            styleType="ghost"
            size="none"
            onClick={() => {
              setIsShowDetail(!isShowDetail);
              setIsShowMore(false);
            }}
          >
            <ChevronDownIcon
              size={16}
              className={clsx('size-[16px]', {
                'rotate-180 transition-transform': isShowDetail,
                'rotate-0 transition-transform': !isShowDetail,
              })}
            />
          </Button>
        </div>
      </div>
      {isShowDetail && (
        <div className="flex flex-col pl-[180px]">
          <ul
            className="flex flex-col py-[24px] pl-[24px] pr-[40px] gap-4 border border-[#D8D8D8] rounded-lg "
            style={{
              listStyle: 'disc',
              listStyleType: 'disc',
              listStylePosition: 'initial',
              listStyleImage: '',
            }}
          >
            {displayDetailList.map((item) => (
              <li key={item.companyId} className="flex flex-col py-[4px] gap-2">
                <div className="flex before:content-['•'] before:mx-2">
                  <p className="font-medium flex-grow">{item.companyName}</p>
                  <div className="flex items-center gap-[6px]">
                    <AccountSummary
                      title={t(
                        'globalconfig.multiCompanyManagement.basicInfo.totalAccounts.usingAcounts',
                      )}
                      count={item.usedAccountCount}
                    />
                    <div className="w-[1px] h-[12px] bg-[#D8D8D8]"></div>
                    <AccountSummary
                      title={t(
                        'globalconfig.multiCompanyManagement.basicInfo.totalAccounts.availableAcounts',
                      )}
                      count={item.availableAccountCount}
                    />
                  </div>
                </div>
                <div className="flex pl-[22px]">
                  <div
                    style={{
                      width: `${((item.availableAccountCount + item.usedAccountCount) / totalAccount) * 100}%`,
                    }}
                  >
                    <Progress.Root className="h-[6px] bg-[#E0F2E1]">
                      <Progress.Indicator
                        className="from-[#6EE488] to-[#6EE488]"
                        percent={
                          (item.usedAccountCount /
                            (item.availableAccountCount +
                              item.usedAccountCount)) *
                          100
                        }
                      />
                    </Progress.Root>
                  </div>
                </div>
              </li>
            ))}
            {companyList.length > 3 && !isShowMore && (
              <li className="flex justify-center h-[40px] py-[4px]">
                <Button
                  className="flex items-center px-[8px] gap-1 bg-transparent"
                  onClick={() => {
                    setIsShowMore(true);
                  }}
                >
                  <PlusIcon size={16} />
                  {t(
                    'globalconfig.multiCompanyManagement.basicInfo.more.title',
                  )}
                </Button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

interface AccountSummaryProps {
  title: string;
  count: number;
}
function AccountSummary({ title, count }: AccountSummaryProps) {
  const { t } = useTranslation('component');

  return (
    <div className="flex items-center gap-1 text-[#999]">
      <p className="text-[#999] text-[13px]">{title}</p>
      <p className="text-[13px]">
        <span className="font-medium text-[#5B5B5B]">
          {count.toLocaleString('en-US')}
        </span>
        {t('globalconfig.multiCompanyManagement.basicInfo.totalAccounts.count')}
      </p>
    </div>
  );
}

export default TotalAccount;
