import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ProfileTable } from './profile-table';
import { ArrowLeftIcon } from '@dop-ui/icons/react/dop/24';
export function CustomProfileList() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();

  const onClickBackButton = () => {
    stacker.pop();
  };

  return (
    <div className="flex flex-col flex-wrap gap-[32px] p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex flex-col gap-[8px]">
        <div className="flex items-center">
          <Button
            className="!size-[24px] mr-[8px]"
            size="none"
            onClick={() => void onClickBackButton()}
          >
            <ArrowLeftIcon size={24} />
          </Button>
          <p className="text-[#333] text-[18px] font-medium -tracking-[1.2px]">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement',
            )}
          </p>
        </div>
        <p className="py-[4px] text-[13px] text-[#888]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.description',
          )}
        </p>
      </div>
      <ProfileTable />
    </div>
  );
}

export default CustomProfileList;
