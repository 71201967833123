import { fetch } from '../../../../../../lib/fetch/client';
import { ACCESS_ROLE_INFO_URL } from '../../constants';
import { AppLockInfo } from '../types';

interface ResponseBody {
  data: AppLockInfo;
}

export const QUERY_KEY = 'deviceAccessQuery';

export async function getDeviceAccess(companyAppId: number) {
  try {
    const { data } = (await (
      await fetch(`${ACCESS_ROLE_INFO_URL}/${companyAppId}/access-role-info`)
    ).json()) as ResponseBody;
    return data;
  } catch (error) {
    console.error(error);
    return {} as AppLockInfo;
  }
}
