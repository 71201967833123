export const COMPANY_GROUP_API_PREFIX = '/api/portal/setting/company-group';
export const GROUPWARE_COMPANY_GROUP_API_PREFIX =
  '/gw/ad/api/system/companygroup';
export const GROUPWARE_API = '/gw/ad/api';

/**
 * @description 회사 리스트 조회 API
 */
export const COMPANY_LIST_API = `${COMPANY_GROUP_API_PREFIX}/company/list`;

/**
 * @description 회사 조직 코드 조회 PREFIX
 */
export const BASE_INFO_ORG_CODE_URL =
  '/api/portal/setting/organization-code/list';
export const ORG_CODE_URL = (
  orgType: 'position' | 'grade' | 'duty' | 'usergroup',
) => GROUPWARE_API + '/' + orgType + '/list';
