import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import type { PopupAnnouncementPaths } from './types';

export interface IPopupAnnouncementContextValues {
  path: PopupAnnouncementPaths;
  updateIdx: number | null;
}

export interface IPopupAnnouncementContextActions {
  setPath: Dispatch<SetStateAction<PopupAnnouncementPaths>>;
  setUpdateIdx: Dispatch<SetStateAction<number | null>>;
}

export const ValuesContext = createContext<IPopupAnnouncementContextValues>({
  path: 'main',
  updateIdx: null,
});

export const ActionsContext = createContext<IPopupAnnouncementContextActions>({
  setPath: () => undefined,
  setUpdateIdx: () => undefined,
});

export function Provider({ children }: PropsWithChildren) {
  const [path, setPath] = useState<PopupAnnouncementPaths>('main');
  const [updateIdx, setUpdateIdx] = useState<number | null>(null);

  const ctxValues = {
    path,
    updateIdx,
  };

  const ctxActions = {
    setPath,
    setUpdateIdx,
  };
  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}
export const useValuesContext = () => useContext(ValuesContext);
export const useActionsContext = () => useContext(ActionsContext);

export const usePopupAnnouncementContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();
  return { ...values, ...actions };
};
