import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { CustomInputName, CustomProfileDetailInfo } from '../../../type';
import { useAuthContext } from '../../../../../../../../lib/auth/client';
import { localeMap } from '../../../utils';
import { XMarkCircleIcon } from '@dop-ui/icons/react/dop/16';

type InputComponentProps = {
  item: string;
  name: CustomInputName;
  setCustomProfileConfig: React.Dispatch<
    React.SetStateAction<CustomProfileDetailInfo>
  >;
};

export function InputComponent({
  item,
  name,
  setCustomProfileConfig,
}: InputComponentProps) {
  const { t } = useTranslation('component');

  const { me } = useAuthContext();
  if (!me) {
    return;
  }

  const handleChange = (value: string) => {
    setCustomProfileConfig((prev) => ({
      ...prev,
      multiLanguageValueMap: {
        ...prev.multiLanguageValueMap,
        [name]: value,
      },
    }));
  };

  const handleClickClear = () => {
    setCustomProfileConfig((prev) => ({
      ...prev,
      multiLanguageValueMap: {
        ...prev.multiLanguageValueMap,
        [name]: '',
      },
    }));
  };

  return (
    <li className="flex items-center gap-[16px] min-h-[40px] relative">
      <p className="w-[200px] font-medium flex-shrink-0">
        {t(
          `globalconfig.basicManagement.profileManagement.customProfileItemManagement.detail.name.${name}`,
        )}
      </p>
      <div className="flex items-center w-full h-[40px] px-[12px] border border-[#D8D8D8] rounded-lg">
        <input
          type="text"
          className="w-full text-[14px]"
          value={item}
          onChange={(e) => handleChange(e.target.value)}
        />
        {item != '' && (
          <Button
            className="!w-[20px] !h-[20px] p-[3px]"
            onClick={() => handleClickClear()}
          >
            <XMarkCircleIcon size={14} className="text-[#363636]" />
          </Button>
        )}
      </div>
      {name === localeMap[me.locale] && (
        <span className="absolute left-[-10px] bottom-[9px] text-[#E84B4B]">
          *
        </span>
      )}
    </li>
  );
}
