import { useEffect, useState } from 'react';

import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { ColumnDef } from '@tanstack/react-table';
import { useActionsContext, useValuesContext } from '../../provider';
import { useAuthContext } from '../../../../../../../../lib/auth/client';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as AssetListQueries from '../../../apis/asset-list-query';
import * as SharedAssetListQueries from '../../../apis/shared-asset-list.query';
import { createAssetShare } from '../../../apis/create-asset-mutation';
import { deleteAssetShare } from '../../../apis/delete-asset-mutation';
import { getPostRequestDto } from '../../../utils';

import {
  Templates,
  useToastMessage,
} from '@dop-ui/react/features/toast-message';
import ShareConfigDialog from '../../share-config-dialog';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { DeleteIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { AscendIcon, DescendIcon, SortIcon } from '@dop-ui/icons/react/dop/16';
import ShareAreaConfig from '../../share-area-config';
import ShareObject from '../../common-table/share-object';
import type {
  AppChildInfo,
  AssetTreeNodeModel,
  Share,
  ShareInfo,
  ShareNodeInfo,
} from '../../../types';

function AssetSharedList() {
  const { me } = useAuthContext();
  const { companyList, selectedCompany } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();
  const [shareConfigDialogOpenState, setShareConfigDialogOpenState] =
    useState<boolean>(false);
  const [shareNodeList, setSharedNodeList] = useState<ShareNodeInfo[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<AppChildInfo>({
    id: 0,
    name: '',
  });

  const [selectedShareContents, setSelectedShareContents] = useState<
    ShareInfo[]
  >([]);

  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(10);
  const [sortDirection, setSortDirection] = useState('');
  const [appId, setAppId] = useState<number | null>(0);

  const toaster = useToastMessage();

  const { t } = useTranslation('component');

  const {
    data: { data: sharedList, totalPage, message: errorMessage },
    error,
  } = useSuspenseQuery({
    queryKey: [SharedAssetListQueries.QUERY_KEY, page, offset, sortDirection],
    queryFn: async () =>
      SharedAssetListQueries.getSharedAssetList({
        companyGroupId: me?.companyGroupId,
        offset,
        page,
        sortDirection,
      }),
  });

  const { data: assetListData } = useQuery({
    queryKey: [AssetListQueries.QUERY_KEY, selectedCompany?.companyUuid],
    queryFn: async () =>
      AssetListQueries.getAssetList(selectedCompany?.companyUuid),
    enabled: !!selectedCompany?.companyUuid && shareConfigDialogOpenState,
  });

  useEffect(() => {
    initializeShareList();
  }, [selectedAsset]);

  const columnDef: ColumnDef<ShareInfo>[] = [
    {
      id: 'siteName',
      accessorFn: (data: ShareInfo) => data.companyName,
      header: () => (
        <div className="flex gap-2">
          {t(
            'globalconfig.multiCompanyManagement.appShare.asset.table.siteName',
          )}
          {sortIcon()}
        </div>
      ),
      cell: (data) => {
        const siteName = data.getValue() as string;
        return <p>{siteName}</p>;
      },
    },
    {
      id: 'assetName',
      accessorFn: (data: ShareInfo) => data.name,
      header: () => (
        <p className="flex">
          {t('globalconfig.multiCompanyManagement.appShare.asset.table.asset')}
        </p>
      ),
      cell: (data) => {
        const assetName = data.getValue() as string;
        return <p>{assetName}</p>;
      },
    },
    {
      id: 'sharesInfo',
      accessorKey: 'shares',
      header: () => (
        <p className="flex">
          {t('globalconfig.multiCompanyManagement.appShare.asset.table.share')}
        </p>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as Share[];
        return <ShareObject shareTargetList={shareTargetList} />;
      },
    },
  ];

  const queryClient = useQueryClient();
  const createAssetShareMutation = useMutation({
    mutationFn: createAssetShare,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [SharedAssetListQueries.QUERY_KEY],
      });
      toaster.info(
        <Templates.Basic
          description={t(
            'globalconfig.multiCompanyManagement.appShare.asset.onSave',
          )}
        />,
      );
    },
    onError: (e) => {
      toaster.info(<Templates.Basic description={e.message} />);
    },
  });

  const deleteAssetShareMutation = useMutation({
    mutationFn: deleteAssetShare,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [SharedAssetListQueries.QUERY_KEY],
      });

      toaster.info(
        <Templates.Basic
          description={t(
            'globalconfig.multiCompanyManagement.appShare.asset.onDelete',
          )}
        />,
      );
    },
    onError: (e) => {
      toaster.info(<Templates.Basic description={e.message} />);
    },
  });

  const handleOnSave = () => {
    if (shareNodeList.length === 0) {
      toaster.warning(
        t('globalconfig.multiCompanyManagement.appShare.toast.add.noData'),
      );
      return;
    }
    if (me) {
      const companyGroupId = me.companyGroupId as string;
      createAssetShareMutation.mutate({
        companyGroupId,
        data: getPostRequestDto({
          companyGroupId: companyGroupId,
          selectedApp: selectedAsset,
          selectedCompany: selectedCompany!,
          shareNodeList: shareNodeList,
        }),
      });
    }
  };

  const handleOnDelete = () => {
    if (selectedShareContents.length === 0) {
      toaster.warning(
        t('globalconfig.multiCompanyManagement.appShare.toast.add.noData'),
      );
      return;
    }
    if (me) {
      const companyGroupId = me.companyGroupId as string;
      deleteAssetShareMutation.mutate({
        companyGroupId,
        data: selectedShareContents,
      });
    }
  };

  const handleOnCancel = () => {
    setShareConfigDialogOpenState(false);
  };

  const initializeShareList = () => {
    if (!selectedAsset.id) {
      setSharedNodeList([]);
      return;
    }
    const sharedTargetList = sharedList.filter(
      (item) =>
        item.companyUuid === selectedCompany?.companyUuid &&
        item.id === selectedAsset?.id,
    )[0]?.shares;

    if (!sharedTargetList || sharedTargetList.length === 0) {
      setSharedNodeList([]);
      return;
    }

    const sharedTargetNodeList = sharedTargetList
      ?.map((item) => item.nodes.map((item) => item))
      .flat();

    setSharedNodeList(sharedTargetNodeList);
  };

  const handleOnClickRow = (index: number) => {
    const company = companyList.find(
      (item) => item.companyUuid === sharedList[index]!.companyUuid,
    );
    if (sharedList && sharedList[index] && company) {
      setSelectedCompany({
        companyId: company.companyId,
        companyName: sharedList[index].companyName,
        companyUuid: sharedList[index].companyUuid,
      });
      setAppId(sharedList[index]?.id);
      setShareConfigDialogOpenState(true);
    }
  };

  const handleOnClickAdd = () => {
    if (companyList && companyList[0]) {
      setSelectedCompany({
        companyId: companyList[0].companyId,
        companyName: companyList[0].companyName,
        companyUuid: companyList[0].companyUuid,
      });
    }
    setAppId(null);
    setShareConfigDialogOpenState(false);
  };

  const handleOnChangeSort = () => {
    setSortDirection((prev) => {
      if (prev === '') return 'asc';
      else if (prev === 'asc') return 'desc';
      else return '';
    });
  };

  const sortIcon = () => {
    let icon = null;

    if (sortDirection === '') {
      icon = <SortIcon />;
    } else {
      if (sortDirection === 'asc') {
        icon = <AscendIcon />;
      } else {
        icon = <DescendIcon />;
      }
    }
    return <Button onClick={() => handleOnChangeSort()}>{icon}</Button>;
  };

  if (errorMessage) {
    return (
      <TablePrimitives.Root<ShareInfo> columnDefs={[]} contents={[]}>
        <TablePrimitives.Contents
          emptyNotice={
            <div className="flex items-center justify-center w-full h-[256px]">
              {errorMessage}
            </div>
          }
        />
      </TablePrimitives.Root>
    );
  }
  if (error) {
    return (
      <TablePrimitives.Root<ShareInfo> columnDefs={[]} contents={[]}>
        <TablePrimitives.Contents
          emptyNotice={
            <div className="flex items-center justify-center w-full h-[256px]">
              {error.message}
            </div>
          }
        />
      </TablePrimitives.Root>
    );
  }

  return (
    <TablePrimitives.Root<ShareInfo>
      columnDefs={columnDef}
      contents={sharedList}
      onClickRow={handleOnClickRow}
      selectable
      onSelectContents={setSelectedShareContents}
    >
      <div className="flex items-center gap-1 mt-6 mb-4 justify-end">
        <ShareConfigDialog
          trigger={
            <Button onClick={handleOnClickAdd}>
              <PlusIcon width={24} height={24} />
              <p>
                {t(
                  'globalconfig.multiCompanyManagement.appShare.asset.addAssetShare',
                )}
              </p>
            </Button>
          }
          openState={shareConfigDialogOpenState}
          onOpenStateChange={(openState) =>
            setShareConfigDialogOpenState(openState)
          }
          onSave={handleOnSave}
          onCancel={handleOnCancel}
        >
          <ShareAreaConfig<AssetTreeNodeModel>
            defaultAppId={appId}
            shares={shareNodeList}
            appChildList={shareConfigDialogOpenState ? assetListData : []}
            onChange={(asset) => {
              console.log(asset);
              setSelectedAsset({ id: asset.id, name: asset.name });
            }}
            onChangeShares={(newShares) => {
              setSharedNodeList(newShares);
            }}
          />
        </ShareConfigDialog>

        <Button onClick={() => void handleOnDelete()}>
          <DeleteIcon size={24} />
          <p>
            {t(
              'globalconfig.multiCompanyManagement.appShare.asset.deleteAssetShare',
            )}
          </p>
        </Button>

        <TablePrimitives.SizeSelector
          tableSize={offset}
          options={[10, 20, 30]}
          onChangeTableSize={setOffset}
        />
      </div>

      <TablePrimitives.Contents
        emptyNotice={
          <div className="flex items-center justify-center w-full h-[256px]">
            {t('globalconfig.common.table.noData')}
          </div>
        }
      />
      <TablePrimitives.Pagination
        currentPage={page}
        totalPage={totalPage + 1}
        onNextPage={(page) => setPage(page)}
        onClickPage={(page) => setPage(page)}
        onFirstPage={() => setPage(0)}
        onLastPage={() => setPage(totalPage)}
        onPreviousPage={() => setPage((prev) => prev - 1)}
      />
    </TablePrimitives.Root>
  );
}

export default AssetSharedList;
