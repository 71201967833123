import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import * as deptEntireDataQuery from '../../../api/deptEntireDataQuery';
import { formatBytes } from '../../../utils/byteFormatter';
import { useContext } from '../../Provider';
import { AppList } from './AppList';
import { DeptInfoCard } from './DeptInfoCard';

export function DefaultContents() {
  const { t } = useTranslation('component');
  const { deptName, deptId } = useContext();
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [deptEntireDataQuery.QUERY_KEY],
    queryFn: async () => await deptEntireDataQuery.deptEntireDataQuery(deptId),
  });

  useEffect(() => {
    refetch().catch(console.error);
  }, [deptId, refetch]);

  if (error) {
    <>{error}</>;
  }

  return (
    <>
      <div className="pt-6 px-2 h-auto w-full flex flex-col flex-grow">
        {deptId !== -1 ? (
          <>
            <h2 className="font-medium text-2xl">{deptName}</h2>
            <DeptInfoCard
              className="mt-6"
              createdAt={
                res?.data?.createdAt ?? t('globalconfig.common.nullInfo')
              }
              storageUsage={formatBytes(res?.data?.attachSize ?? 0)}
            />
            <div className="mt-10">
              <AppList appList={res?.data?.appList ?? []} />
            </div>
          </>
        ) : (
          <h1 className="w-full flex h-full justify-center text-center items-center text-xl">
            {t('globalconfig.dataManagement.deptManagement.empty')}
          </h1>
        )}
      </div>
    </>
  );
}
