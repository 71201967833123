import { CUSTOM_PROFILE_CARD_API } from '../../constants';
import { BaseResponse } from '../../types';
import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { CustomProfileDetailInfo } from '../type';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.ProfileManagement.CustomProfileDetail';

export async function getCustomProfileDetail(
  profileId: number,
): Promise<CustomProfileDetailInfo> {
  try {
    const response = await fetch(`${CUSTOM_PROFILE_CARD_API}/${profileId}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    });

    if (!response.ok) {
      throw new Error('getCustomProfileDetail 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponse<CustomProfileDetailInfo>;

    return resData.data;
  } catch (error) {
    console.log('error', error);
    return {
      id: 0,
      activate: true,
      multiLanguageValueMap: {
        KOREAN: '',
      },
      autoSort: false,
      customProfileCardItemType: 'TEXT',
    };
  }
}
