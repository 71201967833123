import { NodeShareType, Share } from '../../types';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

interface Props {
  shareTargetList: Share[];
}

export function ShareObject({ shareTargetList }: Props) {
  const { t } = useTranslation('component');

  const getNodeName = (key: NodeShareType) => {
    const classNames: Record<NodeShareType, string> = {
      company: '',
      user: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.user',
      ),
      department: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.department',
      ),
      subdepartment: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.department',
      ),
      duty: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.duty',
      ),
      position: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.position',
      ),
      grade: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.grade',
      ),
      usergroup: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.userGroup',
      ),
    };

    return classNames[key];
  };

  return (
    <div className="flex flex-col justify-center py-2">
      {shareTargetList.map((item) => {
        const shareTarget = item.nodes;
        return shareTarget.map((orgInfo) => {
          return (
            <p key={orgInfo.id}>
              {orgInfo?.nodeType === 'company'
                ? orgInfo?.nodeCompanyName
                : `${getNodeName(orgInfo?.nodeType)}: ${orgInfo?.nodeCompanyName}-${orgInfo?.nodeValue}`}
            </p>
          );
        });
      })}
    </div>
  );
}

export default ShareObject;
