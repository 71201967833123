import { TabContents } from './components/tab-contents';
import { TabProvider } from './components/tab-provider';
import { TabSelector } from './components/tab-selector';

export function BusinessPortalAppManagement() {
  return (
    <div className="mt-[24px] px-[72px] pb-[40px]">
      <TabProvider>
        <TabSelector />
        <TabContents />
      </TabProvider>
    </div>
  );
}
