import { Button } from '@dop-ui/react/shared/ui/button/dop-button';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { PropsWithChildren, useState } from 'react';
import { useDebounce } from '../../../hooks';

export interface Props {
  className?: string;
  keyword?: string;
  onKeywordChange: (keyword: string) => void;
}

export function SearchBar({
  className,
  keyword: defaultKeyword,
  children,
  onKeywordChange,
}: PropsWithChildren<Props>) {
  const [keyword, setKeyword] = useState<string>(defaultKeyword ?? '');

  const debouncedHandleSearch = useDebounce((value: string) => {
    setKeyword(value);
    onKeywordChange(value);
  }, 250);

  const onChangeHandler = (value: string) => {
    setKeyword(value);
    debouncedHandleSearch(value);
  };

  const onClickClearBtn = () => {
    debouncedHandleSearch('');
  };

  return (
    <div
      className={clsx(
        'flex border items-center justify-between border-gray-300 rounded-[8px] py-[3px] px-[6px]',
        className,
      )}
    >
      <MagnifyingGlassIcon
        className="size-[20px] flex-shrink-0"
        color="#AAAAAA"
      />
      {children}
      <input
        className="ps-2 focus:outline-none min-w-0 w-full text-[14px] font-[400]"
        onChange={(e) => onChangeHandler(e.target.value)}
        placeholder={'검색어를 입력해주세요.'}
        value={keyword}
      />
      {keyword != '' && (
        <Button
          className="w-[32px] h-[32px] p-1 bg-transparent"
          onClick={onClickClearBtn}
        >
          <i className="icon w-[20px] h-[20px]">
            <XCircleIcon
              width={20}
              height={20}
              fill="#AAAAAA"
              className="p-[1px]"
            />
          </i>
        </Button>
      )}
    </div>
  );
}

export default SearchBar;
