import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { COMPANY_SCHEDULE_MANAGEMENT_API } from '../../constants';
import { BaseResponse, BaseResponseWithPage } from '../../types';
import { ScheduleInfo } from '../types';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.CompanyScheduleManagement';

export async function getCompanySchedule(year: number) {
  const response = await fetch(COMPANY_SCHEDULE_MANAGEMENT_API + `/${year}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponseWithPage<ScheduleInfo>;

  return responseData.data.elements ?? [];
}

export async function loadNewHolidayList(year: number) {
  const response = await fetch(
    COMPANY_SCHEDULE_MANAGEMENT_API + `/default/${year}`,
    {
      method: 'POST',
      headers: {
        'X-Referer-Info': window.location.host,
      },
    },
  );

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponse<null>;
    throw new Error(responseData.message);
  }

  const responseData = (await response.json()) as BaseResponse<{
    size: number;
  }>;

  return responseData.data.size;
}

export async function addCompanySchedule(data: ScheduleInfo) {
  const response = await fetch(COMPANY_SCHEDULE_MANAGEMENT_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponseWithPage<null>;
    throw new Error(responseData.message);
  }
}

export async function updateCompanySchedule(data: ScheduleInfo) {
  const response = await fetch(COMPANY_SCHEDULE_MANAGEMENT_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponseWithPage<null>;
    throw new Error(responseData.message);
  }
}

export async function deleteCompanySchedule({
  ids,
  calendarDeletionType,
}: {
  ids: number[];
  calendarDeletionType?: string;
}) {
  const response = await fetch(COMPANY_SCHEDULE_MANAGEMENT_API, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({ ids, calendarDeletionType }),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponseWithPage<null>;
    throw new Error(responseData.message);
  }
}
