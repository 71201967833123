import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { ApiResponse } from '../types';

interface Params {
  popupAnnouncementIds: number[];
}

export async function deletePopupAnnouncement({
  popupAnnouncementIds: popupNoticeIds,
}: Params): Promise<{ data: { data: string } }> {
  const response = await fetch(`/api/portal/setting/basic/popup`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: popupNoticeIds,
    }),
  });

  const responseJson = (await response.json()) as ApiResponse<{
    data: { data: string };
  }>;

  if (responseJson.message) {
    throw new Error(responseJson.message);
  }

  return responseJson.data;
}
