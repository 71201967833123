import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { EMP_PORTAR_LINK_MANAGEMENT_API } from '../../../../constants';
import { BaseResponse, BaseResponseWithPage } from '../../../../types';
import { LinkInfo, LinkSimpleInfo } from '../../../types';
import { BASE_LINK_INFO } from '../../../constants';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.EmpAppManagement.LinksInfo';

export async function getLinksInfo() {
  const response = await fetch(EMP_PORTAR_LINK_MANAGEMENT_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponseWithPage<LinkSimpleInfo>;

  return responseData.data.elements;
}

export const DETAIL_QUERY_KEY =
  'GlobalConfig.BasicManagement.EmpAppManagement.LinkDetailInfo';

export async function getDetailLinkInfo(linkId: number) {
  if (linkId === -1 || linkId === -10) return BASE_LINK_INFO;

  const response = await fetch(EMP_PORTAR_LINK_MANAGEMENT_API + `/${linkId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData = (await response.json()) as BaseResponse<LinkInfo>;

  return responseData.data;
}

export async function updateLinkInfo(linkId: number, linkInfo: LinkInfo) {
  const response = await fetch(EMP_PORTAR_LINK_MANAGEMENT_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({ id: linkId, ...linkInfo }),
  });

  if (!response.ok) {
    throw new Error('Update Link Info Fail');
  }
}

export async function createLinkInfo(linkInfo: LinkInfo) {
  const response = await fetch(EMP_PORTAR_LINK_MANAGEMENT_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(linkInfo),
  });

  if (!response.ok) {
    throw new Error('Create Link Info Fail');
  }
}

export async function deleteLinkInfo(linkId: number) {
  const response = await fetch(EMP_PORTAR_LINK_MANAGEMENT_API + `/${linkId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  if (!response.ok) {
    throw new Error('Delete Link Info Fail');
  }
}
