import { useState } from 'react';
import { clsx } from 'clsx';

export interface Props {
  className?: string;
  tableSize?: number;
  options?: number[];
  onChangeTableSize: (size: number) => void;
}

export function SizeSelector({
  className,
  tableSize: defaultTableSize = 20,
  options = [20, 40, 60, 80],
  onChangeTableSize,
}: Props) {
  const [tableSize, setTableSize] = useState<number>(defaultTableSize);

  const onValueChangeHandler = (value: string) => {
    const numericValue = Number(value);

    if (numericValue) {
      onChangeTableSize(numericValue);
      setTableSize(numericValue);
    }
  };

  return (
    <select
      id="select_page_size"
      className={clsx(
        'w-24 ps-2 py-1 pe-1 border border-solid border-[#D8D8D8] rounded-md text-left focus:outline-none text-[13px]',
        className,
      )}
      value={tableSize}
      onChange={(e) => onValueChangeHandler(e.target.value)}
    >
      {options.map((optionValue) => (
        <option key={`opt_${optionValue}`} value={optionValue}>
          {optionValue}
        </option>
      ))}
    </select>
  );
}

export default SizeSelector;
